import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'src/utils/axios';
import { generalFollowActions } from 'src/store/slices/generalFollowSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: 'Segoe UI'
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }
}));

function PaymentExpectedReceipt() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const invoiceData = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  );

  const [paymentExpectedReceiptInput, setPaymentExpectedReceiptInput] =
    useState(
      invoiceData[0]?.Exchange?.AirConditionerComplementaryReceiptNumber
    );
  const [loading, setLoading] = useState(false);

  function handleInputChange(val) {
    // Sadece rakamlara izin ver
    let sanitizedValue = val.replace(/[^0-9]/g, ''); // Sadece sayılar kalsın

    // 9 karakter sınırı
    if (sanitizedValue.length > 9) {
      sanitizedValue = sanitizedValue.slice(0, 9); // 9 karakterden fazlasını kes
    }

    changePaymentExpectedReceiptNumber(sanitizedValue); // Temizlenmiş sayıları işleme gönder
  }

  function changePaymentExpectedReceiptNumber(val) {
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.AirConditionerComplementaryReceiptNumber = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setPaymentExpectedReceiptInput(val);
  }

  return (
    <Grid style={{ paddingTop: 5 }}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
        <CardContent>
          <Typography
            sx={{
              width: '100%',
              fontWeight: 'bold',
              color: 'red',
              fontSize: '14px',
              marginBottom: '1rem'
            }}
            variant="subtitle1"
            gutterBottom
          >
            {'Ödeme/Onay Beklenen Fiş Numarası'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="input-with-icon-textfield"
                label="Ödeme/Onay Beklenen Fiş Numarası"
                size="small"
                value={paymentExpectedReceiptInput}
                onChange={(event) => {
                  handleInputChange(event.target.value);
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default PaymentExpectedReceipt;
