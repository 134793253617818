import { Card, CardContent, Container, Divider, Avatar, Typography, colors, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getUser } from 'src/utils/Common';
import logo from 'src/assets/images/konsep_2.jpg'
import avatar from 'src/assets/images/technician.png';
import { isProduction } from 'src/utils/contants';
import Confetti from 'react-confetti';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
  cover: {
    position: 'relative',
    height: 200,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '&:before': {
      position: 'absolute',
      content: '" "',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage: 'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)'
    },
    '&:hover': {
      '& $changeButton': {
        visibility: 'visible'
      }
    }
  },
  changeButton: {
    visibility: 'hidden',
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    backgroundColor: colors.blueGrey[900],
    color: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(3),
      bottom: 'auto'
    },
    '&:hover': {
      backgroundColor: colors.blueGrey[900]
    }
  },
  addPhotoIcon: {
    marginRight: theme.spacing(1)
  },
  container: {
    padding: theme.spacing(2, 3),
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  avatar: {
    border: `2px solid ${theme.palette.common.white}`,
    height: 120,
    width: 120,
    top: -60,
    left: theme.spacing(3),
    position: 'absolute'
  },
  details: {
    marginLeft: 136
  },
  actions: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    },
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  pendingButton: {
    color: theme.palette.common.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900]
    }
  },
  personAddIcon: {
    marginRight: theme.spacing(1)
  },
  mailIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Profile() {
  const classes = useStyles();
  const SessionUser = getUser();
  const user = {
    name: SessionUser.FirstName + " " + SessionUser.LastName,
    bio: SessionUser.ServiceShopCode + " " + SessionUser.ServiceShopName,
    avatar: avatar,
    cover: logo,
    connectedStatus: 'not_connected',
    Message: SessionUser.Message
  };
  const [connectedStatus] = React.useState(user.connectedStatus);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  var message = SessionUser.Message?.split('\n', 3)
  var email = message[1]

  return (
    <>
      {< Confetti />}
      <div
      // {...rest}
      // className={clsx(classes.root, className)}
      >
        <div
          className={classes.cover}
          style={{ backgroundImage: `url(${user.cover})` }}
        >

        </div>
        <Container
          maxWidth="lg"
          className={classes.container}
        >
          <Avatar
            alt="Person"
            className={classes.avatar}
            src={user.avatar}
          />
          <div className={classes.details}>
            <Typography
              component="h1"
              variant="h6"
            >
              {user.name}
            </Typography>
          </div>

        </Container>
        <Container maxWidth="xl" sx={{ paddingTop: 5 }} >
          <Card>
            <CardContent>
              <Typography sx={{ width: '100%', fontWeight: 'bold' }} variant="subtitle1">
                Dia - Online Arçelik Ürün Değişim Sistemlerine Hoşgeldiniz...
              </Typography>
            </CardContent>
            <Divider sx={{ backgroundColor: '#FF9800' }} />
            <CardContent>
              <Typography sx={{ width: '100%' }} variant="subtitle1">
                {email}
              </Typography>
            </CardContent>
            {/* <Divider /> */}
          </Card>
        </Container>
        <Grid sx={{ paddingTop: 5 }}></Grid>
      </div>
    </>
  );
}

export default Profile;
