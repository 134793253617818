import { Card, CardContent, Grid, TextField, Typography, Dialog, DialogContent, DialogActions, Tooltip, IconButton, DialogTitle, Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Segoe UI",
    width: '100%',
    height: '100%'
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: '12px'
  },
  text: {
    fontSize: 12,
  }

}));

function Print({ open, setOpen, data }) {
  const classes = useStyles();


  const bankaKodu = data[0]?.BANKAKODU ?? '-';
  const bankaAdi = data[0]?.BANKA_ADI ?? '-';
  const ibanNo = data[0]?.IBANNO ?? '-';
  const formatBankaVerisi = `${bankaKodu} / ${bankaAdi} / ${ibanNo}`;

  const vergiNumarası = data[0]?.VERGI_NUMARASI ?? '-';
  const vergiDairesi = data[0]?.VERGI_DAIRESI ?? '-';
  const formatVergiVerisi = `${vergiDairesi} / ${vergiNumarası}`;

  const urunSeriNo = data[0]?.SERINO ?? '-';
  const urunAlınanModel = data[0]?.KUL_MODEL_KODU ?? '-';
  const formatUrunVerisi = `${urunSeriNo} / ${urunAlınanModel}`;


  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        size="lg"
        fullScreen
      >
        <DialogActions>
          <Tooltip title="Kapat">
            <IconButton
              sx={{ color: 'black' }}
              aria-label="close"
              component="span"
              variant="contained"
              onClick={() => { setOpen(false) }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogActions>
        <DialogContent>
          <Container maxWidth="md">
            <Grid container justifyContent="center" alignItems="center">
              <img
                width="150px"
                height="60px"
                alt="Arcelik"
                src="/images/arcelik.png"
              />
            </Grid>
            <Grid item xs={12} sm={12} >
              <Typography variant="body1" sx={{ width: '100%', fontWeight: 'bold', textAlign: 'center', fontSize: '14px', paddingTop: '50px' }} >
                ÜRÜN DEĞİŞİM BİLGİ VE TALEP FORMU  NO :  {data[0]?.FIS_NO ? data[0]?.FIS_NO : '-'}
              </Typography>
            </Grid>
            <Grid container spacing={2} style={{ paddingLeft: '60px', paddingTop: '5px' }}>
              <Grid item xs={2} sm={2}>   </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="body1" className={classes.text} sx={{ width: '100%', fontWeight: 'bold', textAlign: 'center' }} >
                  Değişim İstek Tarihi: {data[0]?.DEG_ISTEK_TARIH ? data[0]?.DEG_ISTEK_TARIH : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.text} sx={{ width: '100%', fontWeight: 'bold', textAlign: 'center' }} >
                  İşlem tarihi: {data[0]?.ISLEM_TARIHI ? data[0]?.ISLEM_TARIHI : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>   </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1" sx={{ width: '100%', fontWeight: 'bold', textAlign: 'center', fontSize: '14px' }} >
                  Tüketici Bilgileri
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Ad Soyad:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.ADSOYAD ? data[0]?.ADSOYAD : "-"}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Adres:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.ADRES ? data[0]?.ADRES : "-"}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Posta Kodu:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.POSTA_KODU ? data[0]?.POSTA_KODU : "-"}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Şehir:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.SEHIR ? data[0]?.SEHIR : "-"}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Vergi Dairesi/Num:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {formatVergiVerisi}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Telefon(Ev/İş/Cep):
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.TEL1 ? data[0]?.TEL1 : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Banka Kod/Adı/IbanNo :
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {formatBankaVerisi}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1" sx={{ width: '100%', fontWeight: 'bold', textAlign: 'center', fontSize: "14px" }} >
                  Şikayet konusu olan mamulün yerine yeni ürün aldım.
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography variant="body1" sx={{ width: '100%', fontWeight: 'bold', textAlign: 'center', fontSize: "14px" }} >
                  TARİH
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography variant="body1" sx={{ width: '100%', fontWeight: 'bold', textAlign: 'center' }} >
                  İMZA
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
                <Typography variant="body1" sx={{ width: '100%', fontWeight: 'bold', textAlign: 'center', fontSize: '14px', paddingTop: '10px', paddingTop: '50px' }} >
                  Ürün Bilgileri
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Stok No:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.URUN_STOK_NO ? data[0]?.URUN_STOK_NO : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Ürün Açıklama:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.URUN_ACIKLAMA ? data[0]?.URUN_ACIKLAMA : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Ay/Yıl Kodu:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.AYYIL ? data[0]?.AYYIL : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Seri No (Model):
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {formatUrunVerisi}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Garanti Baş. Tarihi:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.GARANTI_TARIHI ? data[0]?.GARANTI_TARIHI : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Servis Hizmet Fişi:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.FIS_NO ? data[0]?.FIS_NO : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Ürün Değişim Tutarı:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.TUTAR ? data[0]?.TUTAR : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Değişim Türü:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.DEG_TUR_ACIKLAMA ? data[0]?.DEG_TUR_ACIKLAMA : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Teminsiz Stok:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.TEMINSIZSTOK ? data[0]?.TEMINSIZSTOK : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Değişim Nedeni:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.DEGISIM_NEDENI ? data[0]?.DEGISIM_NEDENI : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" className={classes.title}>
                  Bölge Notu:
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="body1" className={classes.text}>
                  {data[0]?.BOLGE_NOTU ? data[0]?.BOLGE_NOTU : '-'}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography variant="body1" sx={{ width: '100%', fontWeight: 'bold', fontSize: '14px', paddingTop: '10px' }}>
                  YETKİLİ SERVİS KODU ({data[0]?.SERVIS})
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" sx={{ width: '100%', fontWeight: 'bold', fontSize: '14px', paddingTop: '10px' }}>
                  TARIH
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="body1" sx={{ width: '100%', fontWeight: 'bold', fontSize: '14px', paddingTop: '10px' }}>
                  İMZA + KASE
                </Typography>
              </Grid>

            </Grid>
          </Container>
        </DialogContent>
      </Dialog >
    </>
  );
}

export default Print;