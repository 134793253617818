import { Card, CardContent, Grid, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Autocomplete, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomOut from '@mui/icons-material/ZoomOut';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'src/utils/axios';
import { confirmAlert } from 'react-confirm-alert';
import AlertAndLoading from 'src/components/AlertAndLoading';
import { getReceiptInfoChat } from 'src/store/slices/informationChatSlice';
import { getDetail } from 'src/store/slices/generalFollowSlice';
import { getUser } from 'src/utils/Common';
import { Description } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  divCenter: {
    textAlign: 'center'
  },
  div: {
    fontWeight: 'bold',
    fontSize: '12px',
    width: '100%',
    color: 'black',
    marginBottom: '7.5px'
  },
  div2: {
    fontSize: '12px',
    width: '100%',
    color: 'black'
  }
}));

function GeneralInfo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();


  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);
  const [openCustomerTypePopUp, setOpenCustomerTypePopUp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });
  const [selectedOption, setSelectedOption] = React.useState({ id: invoiceData[0]?.Consumer.ConsumerType, description: invoiceData[0]?.Consumer?.ConsumerTypeDesc });
  const [customerTypeInfo, setCustomerTypeInfo] = React.useState('');

  const options = invoiceData[0].Consumer.ConsumerType === 2
    ? [{ id: 1, description: 'Müşteri' }]
    : [{ id: 2, description: 'Tüzel - Şirket' }];

  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  const handleTextChange = (event) => {
    setCustomerTypeInfo(event.target.value);
  };

  const getTypeColor = (ConsumerType) => {
    let typographyStyle = {};
    let icon = null;

    if (ConsumerType === 1) {
      typographyStyle = { color: 'red', fontSize: '12px', fontWeight: 'bold' };
      icon = (
        <IconButton style={{ fontSize: 3 }} >
          <ZoomOut fontSize="small" sx={{ color: 'green' }} onClick={() => { setOpenCustomerTypePopUp(true) }} />
        </IconButton>
      );
    } else if (ConsumerType === 2) {
      typographyStyle = { color: 'orange' };
      icon = (
        <IconButton style={{ fontSize: 3 }} >
          <ZoomOut fontSize="small" sx={{ color: 'green' }} onClick={() => { setOpenCustomerTypePopUp(true) }} />
        </IconButton>
      );
    } else if (ConsumerType === 3) {
      typographyStyle = { color: 'green' };
    }

    return (
      <div className={classes.div2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="div"
          style={typographyStyle}
        >
          {invoiceData[0]?.Consumer?.ConsumerTypeDesc}
        </Typography>
        {icon}
      </div>
    );
  };
  const handleSaveClick = () => {
    if (selectedOption.id == null || selectedOption.id == undefined) {
      return
    }
    if (customerTypeInfo.length < 15) {
      InfoAlert('Açıklamanız 15 karakterden uzun olmalı ...', 'error', 'Uyarı');
      return
    }
    setOpenCustomerTypePopUp(false)
    const data = {
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo,
      ServiceNumber: invoiceData[0]?.Exchange?.Service,
      CustomerNumber: invoiceData[0]?.Consumer?.Id,
      UserId: invoiceData[0]?.UserID,
      NewTaxNumber: null,
      Type: 2,
      NewCustomerType: selectedOption.id,
      Desc: customerTypeInfo
    }
    const data2 = {
      SessionUser: SessionUser?.UserId || 0,
      UnitCode: invoiceData[0]?.Exchange?.Service,
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo
    }
    confirmAlert({
      message: ('Bu işlemi gerçekleştirmek istediğinizden emin misiniz?'),
      buttons: [
        {
          label: ('Evet'),
          onClick: () => {
            setLoading(true);
            axios.post('DiaUpdateCustomerTypeAndTaxNumber', data, {
              headers: { 'Content-Type': 'application/json' }
            }).then(response => {
              if (response.data.ErrorCode === "0") {
                InfoAlert('İşleminiz başarılıyla gerçekleşmiştir...', 'success', 'Başarılı');
                setLoading(false);
                dispatch(getDetail(data2)).then(response => { }).catch(err => { setLoading(false); return });
                dispatch(getReceiptInfoChat([invoiceData[0]?.Exchange?.ApplyNo, invoiceData[0]?.Exchange?.Service])).then(response => {
                }).catch(err => { setLoading(false); return });
                setCustomerTypeInfo('');
                return
              } else {
                setLoading(false);
                return
              }
            }).catch(err => {
              setLoading(false);
              return
            });
          }
        },
        {
          label: ('Hayır'),
          onClick: () => {
          }
        }
      ],
    })

  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };
  return (
    <Card sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }} >
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      {invoiceData[0]?.VMEInvoice != 0 || invoiceData[0]?.VMEInvoice != "0" ?
        <Grid item xs={12}  >
          <Typography variant="body2"
            color="textSecondary"
            component="div"
            style={{ color: '#FF9800', fontWeight: 'bold', textAlign: 'center' }} >
            {invoiceData[0].Consumer.ConsumerType == 1 || invoiceData[0].Consumer.ConsumerType == 2 ? invoiceData[0].VMEInvoice : null}
          </Typography>
        </Grid>
        : null}
      {invoiceData[0]?.VMRetailerInvoice != 0 ?
        <Grid item xs={12}  >
          <Typography variant="body2"
            color="textSecondary"
            component="div"
            style={{ color: '#FF9800', fontWeight: 'bold', textAlign: 'center' }} >
            {invoiceData[0].Consumer.ConsumerType == 3 ? invoiceData[0].VMRetailerInvoice : null}
          </Typography>
        </Grid>
        : null}
      {invoiceData[0]?.ShowVerificationMessage != '0' ?
        <Grid item xs={12}  >
          <Typography variant="body2"
            color="textSecondary"
            component="div"
            style={{ color: '#FF9800', fontWeight: 'bold', textAlign: 'center' }} >
            {invoiceData[0]?.ShowVerificationMessage}
          </Typography>
        </Grid>
        : null}
      <CardContent>
        <Grid container spacing={2} style={{ paddingTop: 20 }}>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div}>
              {('Müşteri Tipi:')}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div}
            >
              {('Servis:')}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div}
            >
              {('Durum:')}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div} >
              {('Ara Durum:')}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div}>
              {('Çağrı T:')}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div}>
              {('Değişim T:')}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div}>
              {('Araç Yükleme T:')}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div}>
              {('Servise Tes:')}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div}>
              {('Fatura T:')}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div}>
              {('Gps No:')}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div}>
              {('Sas:')}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div}>
              {('Sd:')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} >
          <Grid item xs={1} className={classes.divCenter} >
            {getTypeColor(invoiceData[0]?.Consumer?.ConsumerType)}
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div2} >
              {invoiceData[0]?.Service + '-' + invoiceData[0]?.ServiceName ? invoiceData[0]?.Service + '-' + invoiceData[0]?.ServiceName : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div"
              className={classes.div2} >
              {invoiceData[0]?.Exchange?.StatuDesc ? invoiceData[0]?.Exchange?.StatuDesc : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div" className={classes.div2}>
              {invoiceData[0]?.Exchange?.IndermediateState ? invoiceData[0]?.Exchange?.IndermediateState : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div" className={classes.div2}>
              {invoiceData[0]?.Apply?.ProcessDate ? moment(invoiceData[0]?.Apply?.ProcessDate).format('DD.MM.YYYY') : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div" className={classes.div2}>
              {invoiceData[0]?.Exchange?.ExchangeDate ? moment(invoiceData[0]?.Exchange?.ExchangeDate).format('DD.MM.YYYY') : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div" className={classes.div2}>
              {invoiceData[0]?.Exchange?.VehicleLoadDate ? moment(invoiceData[0]?.Exchange?.VehicleLoadDate).format('DD.MM.YYYY HH:mm:ss') : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div" className={classes.div2} >
              {invoiceData[0]?.Exchange?.ServiceDeliveryDate ? moment(invoiceData[0]?.Exchange?.ServiceDeliveryDate).format('DD.MM.YYYY') : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div" className={classes.div2} >
              {invoiceData[0]?.Exchange?.ReceiptDate ? moment(invoiceData[0]?.Exchange?.ReceiptDate).format('DD.MM.YYYY') : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div" className={classes.div2} >
              {invoiceData[0]?.Exchange.GPSNO ? invoiceData[0]?.Exchange.GPSNO : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div" className={classes.div2} >
              {invoiceData[0]?.Consumer.ChangeSasNo ? invoiceData[0]?.Consumer.ChangeSasNo : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.divCenter} >
            <Typography variant="body2"
              color="textSecondary"
              component="div" className={classes.div2} >
              {invoiceData[0]?.Consumer.ChangeSdNo ? invoiceData[0]?.Consumer.ChangeSdNo : '-'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <Dialog
        open={openCustomerTypePopUp}
        onClose={() => setOpenCustomerTypePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle sx={{ width: '100%', color: 'red', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>Müşteri Tipi Değiştirme</DialogTitle>
        <DialogContent>
          <Grid item xs={12} md={12}>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.description}
              value={selectedOption}
              onChange={(event, newValue) => setSelectedOption(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth size="small" placeholder='Müşteri Tipi' />}
            />
          </Grid>
          <br></br>
          <Grid item xs={12} md={12}>
            <TextField
              label="Açıklama"
              fullWidth
              maxRows={5}
              size="medium"
              multiline
              value={customerTypeInfo}
              onChange={handleTextChange}
            />
          </Grid>
          <br></br>
          <Grid item xs={12} md={12}>
            <Button
              size="small"
              fullWidth
              onClick={() => { handleSaveClick() }}
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
            >
              {'Kaydet'}
            </Button>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => { setOpenCustomerTypePopUp(false) }}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default GeneralInfo;