import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Chart, { Bar } from 'react-chartjs-2';
import Page from 'src/components/Page';


export const pieChartData = {
  labels: ['Red', 'Blue', 'Yellow',],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3],
      backgroundColor: [
        'rgba(3, 201, 215, 1)',
        'rgba(251, 150, 120, 1)',
        'rgba(0, 194, 146, 1)',
      ],
      borderColor: [
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
      ],
      borderWidth: 5,
    },
  ],
};
export const options = {
  indexAxis: 'y',
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const lineChartData = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [10, 40, 32, 45],
      borderColor: 'rgba(3, 201, 215, 1)',
      tension: 0.3,
      backgroundColor: 'rgba(3, 201, 215, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: [20, 10, 32, 9],
      borderColor: 'rgba(251, 150, 120, 1)',
      tension: 0.3,
      backgroundColor: 'rgba(251, 150, 120, 0.5)',
    },
  ],
};
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI",
  },
}));
export const options2 = {
  indexAxis: 'y',

};
function Dashboard() {
  const classes = useStyles();


  return (
    <Page className={classes.root} title="Dia Yönetim Ekranı">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item sm={6} style={{ marginBottom: 20 }}>
            <Card style={{ borderRadius: 20, boxShadow: 'rgb(90 114 123 / 11%) 0px 7px 30px 0px' }}>
              <CardContent>
                <Typography style={{ color: '#404040', fontWeight: 500, padding: 16, }} variant="div" component="div">
                  Bölgelere Göre Değişim Sayısı
                </Typography>
                <div style={{ padding: 16, height: 300, }}>
                  <Chart type={"pie"}
                    data={pieChartData}
                    options={{ maintainAspectRatio: false }} />
                </div>
              </CardContent>

            </Card>
          </Grid>
          <Grid item sm={6} style={{ marginBottom: 20 }}>
            <Card style={{ borderRadius: 20, boxShadow: 'rgb(90 114 123 / 11%) 0px 7px 30px 0px' }}>
              <CardContent>
                <Typography style={{ color: '#404040', fontWeight: 500, padding: 16, }} variant="div" component="div">
                  Ürün Grubuna Göre Değişim Sayısı ve Süresi
                </Typography>
                <div style={{ padding: 16, height: 300, }}>
                  <Chart type={"line"} data={lineChartData}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        x: { grid: { display: false } },
                        y: { grid: { display: true, borderDash: [8, 4] } }
                      }
                    }}
                  />
                </div>
              </CardContent>

            </Card>
          </Grid>
          <Grid item sm={6} style={{ marginBottom: 20 }}>
            <Card style={{ borderRadius: 20, boxShadow: 'rgb(90 114 123 / 11%) 0px 7px 30px 0px' }}>
              <CardContent>
                <Typography style={{ color: '#404040', fontWeight: 500, padding: 16, }} variant="div" component="div">
                  Ürün Grubuna Göre Değişim Sayısı ve Süresi
                </Typography>
                <div style={{ padding: 16, height: 300, }}>
                  <Bar type={"bar"}
                    options={{
                      indexAxis: 'y', maintainAspectRatio: false,
                      scales: {
                        x: { grid: { display: true, borderDash: [8, 4] } },
                        y: { grid: { display: false } }
                      }
                    }}
                    data={lineChartData}
                  />
                </div>
              </CardContent>

            </Card>


          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default Dashboard;