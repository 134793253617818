import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};

export const getReceiptInfoChat = createAsyncThunk('/GetReceiptInfoChat', async (params) => {
  const response = await axios.get(`/GetReceiptInfoChat/${params[0]}/${params[1]}`);
  return response.data;
});

const informationChatSlice = createSlice({
  name: 'informationChat',
  initialState: {
    loading: '',
    error: '',
    informationChat: {},

  },
  reducers: {
    resetChat(state, action) {
      state.informationChat = []
    }
  },
  extraReducers: {
    [getReceiptInfoChat.pending]: (state) => {
      state.loading = true;
    },
    [getReceiptInfoChat.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getReceiptInfoChat.fulfilled]: (state, action) => {
      state.informationChat = action.payload;
      state.loading = false;
    },

  }
});
export const informationChatActions = informationChatSlice.actions;
export default informationChatSlice;