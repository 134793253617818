import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};

export const getRegions = createAsyncThunk('/getRegions', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '1', ItemValue: null }, dataConfig);
  return response.data;
});

export const getCities = createAsyncThunk('/getCities', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '2', ItemValue: null }, dataConfig);
  return response.data;
});

export const getStatuses = createAsyncThunk('/getStatuses', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '3', ItemValue: null }, dataConfig);
  return response.data;
});

export const getChangeReasons = createAsyncThunk('/getChangeReasons', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '4', ItemValue: null }, dataConfig);
  return response.data;
});

export const getCustomerTypes = createAsyncThunk('/getCustomerTypes', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '5', ItemValue: null }, dataConfig);
  return response.data;
});

export const getConfirmTypes = createAsyncThunk('/getConfirmTypes', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '6', ItemValue: null }, dataConfig);
  return response.data;
});

export const getProducts = createAsyncThunk('/getProducts', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '7', ItemValue: null }, dataConfig);
  return response.data;
});

export const getSubStatuses = createAsyncThunk('/getSubStatuses', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '8', ItemValue: null }, dataConfig);
  return response.data;
});

export const getProducter = createAsyncThunk('/getProducter', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '9', ItemValue: null }, dataConfig);
  return response.data;
});

export const getProvince = createAsyncThunk('/getProvince', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '10', ItemValue: null }, dataConfig);
  return response.data;
});

export const getWarrantyType = createAsyncThunk('/getWarrantyType', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '11', ItemValue: null }, dataConfig);
  return response.data;
});

export const getSolutionCenterSubStatus = createAsyncThunk('/getSolutionCenterSubStatus', async () => {
  const response = await axios.post('/GetCatalogItems', { ItemId: '17', ItemValue: null }, dataConfig);
  return response.data;
});

export const getModelCode = createAsyncThunk('/getModelCode', async (data) => {
  const response = await axios.post('/GetCatalogItems', data, dataConfig);
  return response.data;
});

export const getTailUsers = createAsyncThunk('/getTailUsers', async () => {
  const response = await axios.post('/GetTailUsers', dataConfig);
  return response.data;
});

export const setJobTail = createAsyncThunk('/setJobTail', async (data) => {
  const response = await axios.post('/SetJobTail', data, dataConfig);
  return response.data;
});

export const updateJobTail = createAsyncThunk('/UpdateJobTail', async (data) => {
  const response = await axios.post('/UpdateJobTail', data, dataConfig);
  return response.data;
});

export const getJobTail = createAsyncThunk('/GetJobTail', async () => {
  const response = await axios.post('/GetJobTail', dataConfig);
  return response.data;
});

export const updateBusyPeriodOrder = createAsyncThunk('/UpdateBusyPeriodOrder', async (data) => {
  const response = await axios.post('/UpdateBusyPeriodOrder', data, dataConfig);
  return response.data;
});

export const getJobPoolOrder = createAsyncThunk('/GetJobPoolOrder', async () => {
  const response = await axios.get('/GetJobPoolOrder', dataConfig);
  return response.data;
});

export const getTailList = createAsyncThunk('/GetTailList', async () => {
  const response = await axios.post('/GetTailList', dataConfig);
  return JSON.parse(response.data);
});

const assigmentSlice = createSlice({
  name: 'assigment',
  initialState: {
    loading: '',
    error: '',
    regions: [],
    cities: [],
    products: [],
    statuses: [],
    changeReasons: [],
    confirmTypes: [],
    subStatuses: [],
    customerTypes: [],
    tailUsers: [],
    producters: [],
    provinces: [],
    warrantyTypes: [],
    modelCodes: [],
    solutionCenterSubStatus: []

  },
  reducers: {
  },
  extraReducers: {
    [getRegions.pending]: (state) => {
      state.loading = true;
    },
    [getRegions.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getRegions.fulfilled]: (state, action) => {
      state.regions = action.payload;
      state.loading = false;
    },
    [getCities.pending]: (state) => {
      state.loading = true;
    },
    [getCities.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getCities.fulfilled]: (state, action) => {
      state.cities = action.payload;
      state.loading = false;
    },
    [getProducts.pending]: (state) => {
      state.loading = true;
    },
    [getProducts.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.products = action.payload;
      state.loading = false;
    },
    [getStatuses.pending]: (state) => {
      state.loading = true;
    },
    [getStatuses.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getStatuses.fulfilled]: (state, action) => {
      state.statuses = action.payload;
      state.loading = false;
    },
    [getChangeReasons.pending]: (state) => {
      state.loading = true;
    },
    [getChangeReasons.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getChangeReasons.fulfilled]: (state, action) => {
      state.changeReasons = action.payload;
      state.loading = false;
    },
    [getConfirmTypes.pending]: (state) => {
      state.loading = true;
    },
    [getConfirmTypes.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getConfirmTypes.fulfilled]: (state, action) => {
      state.confirmTypes = action.payload;
      state.loading = false;
    },
    [getSubStatuses.pending]: (state) => {
      state.loading = true;
    },
    [getSubStatuses.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getSubStatuses.fulfilled]: (state, action) => {
      state.subStatuses = action.payload;
      state.loading = false;
    },
    [getCustomerTypes.pending]: (state) => {
      state.loading = true;
    },
    [getCustomerTypes.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getCustomerTypes.fulfilled]: (state, action) => {
      state.customerTypes = action.payload;
      state.loading = false;
    },
    [getTailUsers.pending]: (state) => {
      state.loading = true;
    },
    [getTailUsers.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getTailUsers.fulfilled]: (state, action) => {
      state.tailUsers = action.payload;
      state.loading = false;
    },
    [getProducter.pending]: (state) => {
      state.loading = true;
    },
    [getProducter.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getProducter.fulfilled]: (state, action) => {
      state.producters = action.payload;
      state.loading = false;
    },
    [getProvince.pending]: (state) => {
      state.loading = true;
    },
    [getProvince.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getProvince.fulfilled]: (state, action) => {
      state.provinces = action.payload;
      state.loading = false;
    },
    [getWarrantyType.pending]: (state) => {
      state.loading = true;
    },
    [getWarrantyType.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getWarrantyType.fulfilled]: (state, action) => {
      state.warrantyTypes = action.payload;
      state.loading = false;
    },
    [getModelCode.pending]: (state) => {
      state.loading = true;
    },
    [getModelCode.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getModelCode.fulfilled]: (state, action) => {
      state.modelCodes = action.payload;
      state.loading = false;
    },
    [getSolutionCenterSubStatus.pending]: (state) => {
      state.loading = true;
    },
    [getSolutionCenterSubStatus.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getSolutionCenterSubStatus.fulfilled]: (state, action) => {
      state.solutionCenterSubStatus = action.payload;
      state.loading = false;
    }
  }
});
export const assigmentActions = assigmentSlice.actions;
export default assigmentSlice;