import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { CardContent, Grid, MenuItem, TextField, Card,Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDiscountedUser, AddOrUpdateDiscountedUser } from 'src/store/slices/dynamicCatalogSlice';
import { getUser } from 'src/utils/Common';
import AlertAndLoading from 'src/components/AlertAndLoading';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI",
    width: '100%',
    height: '100%'
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }

}));



function DiscountedUserDynamic() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();
  const [discountedUser, setDiscountedUser] = React.useState([]);
  const [updatePopUp, setUpdatePopUp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isDiscountedUserUpdate, setDiscountedUserUpdate] = React.useState(true);
  const [addOrUpdateDiscountedUserNameSurname, setaddOrUpdateDiscountedUserNameSurname] = React.useState("");
  const [addOrUpdateDiscountedUserIdentityNo, setaddOrUpdateDiscountedUserIdentityNo] = React.useState("");
  const [addOrUpdateDiscountedUserActive, setaddOrUpdateDiscountedUserActive] = React.useState(1);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [warningColor, setWarningColor] = React.useState('error');
  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });

  useEffect(() => {
    setLoading(true);
    dispatch(getDiscountedUser()).then(response => {
      if (response.payload?.length > 0) {
        setDiscountedUser(response.payload);
        setLoading(false);
      }
    })
  }, [dispatch]);

  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  const updateDiscountedUserClick = (row) => {
    setDiscountedUserUpdate(true);
    setaddOrUpdateDiscountedUserIdentityNo(row.IDENTITY_NO);
    setaddOrUpdateDiscountedUserNameSurname(row.NAME_SURNAME);
    setaddOrUpdateDiscountedUserActive(row.ACTIVE);
    setUpdatePopUp(true);
  }
  function addDiscountedUserClick() {
    setDiscountedUserUpdate(false);
    clearAddOrUpdateDiscountedUser();
    setUpdatePopUp(true);
  }
  function addOrUpdateDiscountedUserClick() {
    if(addOrUpdateDiscountedUserIdentityNo.length <= 0 || addOrUpdateDiscountedUserNameSurname <= 0){
      InfoAlert('Lütfen Kutucukları Boş Bırakmayınız!...', 'error', 'Hata');
      return;
    }


    const data = {
      Identity_No: addOrUpdateDiscountedUserIdentityNo,
      Name_Surname: addOrUpdateDiscountedUserNameSurname,
      Active: addOrUpdateDiscountedUserActive === 1 ? true : false,
      Created_User: SessionUser?.UserId?.toString()
    }
    setLoading(true)
    dispatch(AddOrUpdateDiscountedUser(data)).then(response => {
      setUpdatePopUp(false);
      clearAddOrUpdateDiscountedUser();
      dispatch(getDiscountedUser()).then(response => {
        if (response.payload?.length > 0) {
          setDiscountedUser(response.payload);
          setLoading(false);
        }
      })
      setLoading(false);
    })
  }
  function clearAddOrUpdateDiscountedUser() {
    setaddOrUpdateDiscountedUserNameSurname("");
    setaddOrUpdateDiscountedUserIdentityNo("");
    setaddOrUpdateDiscountedUserActive(1);
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };


  const columns = [
    { field: 'IDENTITY_NO', headerName: 'Sicil No', width: 400 },
    { field: 'NAME_SURNAME', headerName: 'Ad Soyad', width: 400 },
    { field: 'ACTIVE', headerName: 'Aktif', width: 200, align: 'center',
    renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', "& > :not(style)": { m: 1 } }}>
            <Checkbox
              checkedIcon={<ThumbUpAltIcon color='success' />}
              icon={<ThumbDownAltIcon sx={{ color: 'red' }} />}
              name="checkedB"
              checked={cellValues.row.ACTIVE === 1}
            />
          </Box>
        );
      }
    },
    {
      field: 'Güncelle',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', "& > :not(style)": { m: 1 } }}>
            <Tooltip title="Güncelle">
              <IconButton onClick={() => { updateDiscountedUserClick(cellValues.row) }} >
                <CreateIcon sx={{ color: '#FF9800' }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }, width: 200
    },
    {
      field: 'InsertButton',
      headerName: '',
      width: 120,
      filterable: false,
      renderHeader: (cellValues) => (
        <Box sx={{ justifyContent: 'center', "& > :not(style)": { m: 1 } }}>
            <Tooltip title="Ekle">
              <IconButton onClick={() => addDiscountedUserClick(cellValues.row)} >
                <PersonAddIcon sx={{ color: '#FF9800' }} />
              </IconButton>
            </Tooltip>
        </Box>
      ),
    },
    
  ];


  return (
    <>
      {/* <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={8} >
        <Snackbar sx={{ width: '100%', position: 'center', zIndex: 'top' }} open={uyari} autoHideDuration={6000} onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Alert
            onClose={uyariKapat} variant="filled" severity={warningColor} >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Stack> */}
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <Card>
        <CardContent>
          <Grid item sm={12} style={{ paddingTop: 5 }}></Grid>
          <DataGrid
            style={{ height: 600, width: '100%', textAlign: 'center', justifyItems: 'center', }}
            columns={columns}
            rows={discountedUser}
            getRowId={(row) => row.DISCOUNTED_ID}
          />
        </CardContent>
      </Card>
      <Dialog
        open={updatePopUp}
        onClose={() => setUpdatePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ width: '100%' }} >{isDiscountedUserUpdate ? 'Güncelleme' : 'Yeni Kullanıcı'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                select
                onChange={(e) => setaddOrUpdateDiscountedUserActive(e.target.value)}
                autoComplete="no"
                id="Aktif"
                label={('Aktif')}
                variant="outlined"
                defaultValue={addOrUpdateDiscountedUserActive}
                style={{ marginTop: 5 }}
              >
                <MenuItem key={1} value={1}>
                  {('Aktif')}
                </MenuItem>

                <MenuItem key={0} value={0}>
                  {('Pasif')}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="input-with-icon-textfield"
                label="Sicil No"
                onChange={(e) => setaddOrUpdateDiscountedUserIdentityNo(e.target.value)}
                value={addOrUpdateDiscountedUserIdentityNo}
                size="small"
                fullWidth
                variant="outlined"
                disabled={isDiscountedUserUpdate}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="input-with-icon-textfield"
                label="Ad Soyad"
                onChange={(e) => setaddOrUpdateDiscountedUserNameSurname(e.target.value)}
                value={addOrUpdateDiscountedUserNameSurname}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => addOrUpdateDiscountedUserClick()}
            style={{ marginTop: 10 }}
          >{isDiscountedUserUpdate ? 'Güncelle' : 'Ekle'}</Button>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => setUpdatePopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DiscountedUserDynamic;
