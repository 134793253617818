import { Card, CardContent, CardHeader, Container, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Page from 'src/components/Page';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
}));

function Overview() {
  const classes = useStyles();


  return (
    <Page className={classes.root} title="Dia Bayi Değişimleri Ekranı">
      <Container maxWidth="xl">
        <Card>
          <CardHeader title="Dia Bayi Değişimleri Ekranı" />
          <Divider />
          <CardContent>
            Dia Bayi Değişimleri Ekranı Hoşgeldiniz
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default Overview;