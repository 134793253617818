import { AppBar, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';


const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
}));

function Topbar({ className, ...rest }) {
  const classes = useStyles();


  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="secondary"
    >
      <Toolbar>
        {/* <img
            alt="Logo"
            src={logo}
          /> */}
        <Typography
          variant="h4"
          component="h3"
          style={{ fontFamily: 'AssistantRegular',color:'#FF9800'}}
        >
         
          </Typography>

      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
