import { Card, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import React from 'react';
import ConsumerInformation from 'src/components/ConsumerInformation';
import ExchangeInformation from 'src/components/ExchangeInformation';
import GeneralInfo from 'src/components/GeneralInfo';
import InformationChat from 'src/components/InformationChat';
import { generalFollowActions } from 'src/store/slices/generalFollowSlice';
import { useDispatch, useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  cards: {
    paddingBottom: theme.spacing(3),
    width: '100%',
    height: '100%'
  },
}));

function InvoiceDetail() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const receiptNumber = useSelector((state) => state.generalFollowSlice.invoiceDetail)[0]?.Apply?.ReceiptNumber;
  const dataSort = useSelector((state) => state.generalFollowSlice.updatedDataGridSort);
  if (!receiptNumber) {
    return (<></>);
  }

  return (
    <>
      <Card className={classes.cards}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} >
            <GeneralInfo />
          </Grid>
          <Grid item xs={12} md={4} >
            <ConsumerInformation />
          </Grid>
          <Grid item xs={12} md={4} >
            <ExchangeInformation />
          </Grid>
          <Grid item xs={12} md={4} >
            <InformationChat />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default InvoiceDetail;

