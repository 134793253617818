import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, TextField, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import axios from 'src/utils/axios';


const useStyles = makeStyles((theme) => ({
  div: {
    fontWeight: 'bold',
    fontSize: '12px',
    width: '100%'
  }
}));

function DirectionProcess() {
  const classes = useStyles();
  const [expandedConfirm, setExpandedConfirm] = React.useState(false);
  const [selectedDirectionStatus, setSelectedDirectionStatus] = React.useState(null);
  const [uyari, setUyari] = React.useState(false);
  const [responseMessage, setresponseMessage] = React.useState({
    ErrorCode: '0',
    ErrorDescription: ('success_message')
  });

  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);
  const directionStatus = [
    { Dsc: 'Satış Onayı', Id: 12 },
    // { Dsc: 'Yedek Parça Onayı', Id: 18 },
    { Dsc: 'KGY Onayı', Id: 22 },
  ];

  function DirectionStatus() {
    const data = {
      //Buraya servis numarası ve başvuru numarası yazılacak!!
      ServiceNumber: invoiceData[0]?.Exchange?.Service?.toString(),//Servis Numarası
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(),//Başvuru No
    }
    if (selectedDirectionStatus?.Id == 12) {
      axios.post('TransferSalesRegion', data, {
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
      }).catch(err => {
        setresponseMessage({
          ErrorCode: 0,
          ErrorDescription: err.response.data,
        });
        setUyari(true);
        return
      });
    }
    // if (selectedDirectionStatus?.Id == 18) {
    //   axios.post('TransferSparePart', data, {
    //     headers: { 'Content-Type': 'application/json' }
    //   }).then(response => {
    //   }).catch(err => {
    //     setresponseMessage({
    //       ErrorCode: 0,
    //       ErrorDescription: err.response.data,
    //     });
    //     setUyari(true);
    //     return
    //   });
    // }
    if (selectedDirectionStatus?.Id == 22) {
      axios.post('TransferKGY', data, {
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
      }).catch(err => {
        setresponseMessage({
          ErrorCode: 0,
          ErrorDescription: err.response.data,
        });
        setUyari(true);
        return
      });
    }

  }
  return (
    <>
      <Grid style={{ paddingTop: 10 }}></Grid>
      <Accordion expanded={expandedConfirm === 'panel1'} onChange={(event, isExpanded) => setExpandedConfirm(isExpanded ? "panel1" : false)} sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.div} variant="subtitle1">
            Yönlendirme
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelsConfirmContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                id="combo-box-demo"
                options={directionStatus}
                getOptionLabel={(i) => i.Dsc}
                value={selectedDirectionStatus || null}
                size="small"
                onChange={(event, val) => { setSelectedDirectionStatus(val); }}
                renderInput={(params) => <TextField {...params} label="Yönlendirme Tipi" />}
              />
            </Grid>
            <Grid item xs={4} md={12}>
              <Button
                fullWidth
                size="medium"
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                onClick={() => DirectionStatus()}
              >
                Yönlendir
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid style={{ paddingTop: 20 }}></Grid>
    </>
  );
}

export default DirectionProcess;