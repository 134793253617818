import { Backdrop, CardContent, CircularProgress, Grid, TextField, Button, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CreateIcon from '@mui/icons-material/Create';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { getAllRefundConvinceType, addOrUpdateConvinceType } from 'src/store/slices/dynamicCatalogSlice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';



const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI",
    width: '100%',
    height: '100%'
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }

}));



function ConvinceTypeDynamic() {
  const classes = useStyles();
  const dispatch = useDispatch();


  const [loading, setLoading] = React.useState(false);
  const [convinceData, setConvienceData] = React.useState([]);
  const [updatePopUp, setUpdatePopUp] = React.useState(false);
  const [isConvinceTypeUpdate, setIsConvinceTypeUpdate] = React.useState(false);
  const [addOrUpdateConvinceActive, setAddOrUpdateConvinceActive] = React.useState(true);
  const [addOrUpdateConvinceId, setaddOrUpdateConvinceId] = React.useState("");
  const [addOrUpdateConvince, setAddOrUpdateConvince] = React.useState("");
  const [selectedConvinceType, setSelectedConvinceType] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllRefundConvinceType()).then(response => {
      if (response.payload?.length > 0) {
        setConvienceData(response.payload)
        setLoading(false);
      }
      setLoading(false);
    })
  }, [dispatch]);

  function updateConvinceTypeClick(row) {
    setIsConvinceTypeUpdate(true);
    setaddOrUpdateConvinceId(row.ID);
    setAddOrUpdateConvince(row.IKNA_TURU);
    setAddOrUpdateConvinceActive(row.AKTIF);
    setSelectedConvinceType(row);
    setUpdatePopUp(true);
  }

  function clearAddOrUpdateConvince() {
    setaddOrUpdateConvinceId("");
    setAddOrUpdateConvince("");
    setAddOrUpdateConvinceActive(true);
  }

  const addConvinceClick = (row) => {
    setIsConvinceTypeUpdate(false);
    clearAddOrUpdateConvince();
    setUpdatePopUp(true);
  }

  function addOrUpdateConvinceTypeClick() {
    var ConvinceId = addOrUpdateConvinceId.toString();
    var ConvinceType = addOrUpdateConvince.toString();
    var Active = addOrUpdateConvinceActive;

    let Reasondata = {
      ConvinceId: ConvinceId,
      ConvinceType: ConvinceType,
      Active: Active
    };

    setLoading(true)
    dispatch(addOrUpdateConvinceType(Reasondata)).then(response => {
      dispatch(getAllRefundConvinceType()).then(response => {
        if (response.payload?.length > 0) {
          setConvienceData(response.payload)
          setLoading(false);
        }
      })
      setUpdatePopUp(false);
      clearAddOrUpdateConvince(addOrUpdateConvinceId);
      setLoading(false);
    })
  }


  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CardContent>
        <TableContainer component={Paper} sx={{ maxHeight: 600, minHeight: 300 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left">İkna Türü</TableCell>
                <TableCell align="center">Aktif</TableCell>
                <TableCell align="center">Güncelle</TableCell>
                <TableCell align="center">
                  <Tooltip title="Ekle">
                    <IconButton onClick={() => addConvinceClick()} >
                      <PersonAddIcon sx={{ color: '#FF9800' }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {convinceData?.map((row) => (
                <TableRow
                  key={row.ID}
                >
                  <TableCell align="left">{row.IKNA_TURU}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      name="checkedB"
                      checkedIcon={<ThumbUpAltIcon color='success' />}
                      icon={<ThumbDownAltIcon sx={{ color: 'red' }} />}
                      checked={row.AKTIF === true}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Güncelle">
                      <IconButton onClick={() => updateConvinceTypeClick(row)}>
                        <CreateIcon sx={{ color: '#FF9800' }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <Dialog
        open={updatePopUp}
        onClose={() => setUpdatePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        size="md"
        fullWidth
      >
        <DialogTitle sx={{ width: '100%' }} >{isConvinceTypeUpdate ? "Güncelleme" : "Yeni İkna Türü"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                select
                autoComplete="no"
                id="Aktif"
                label={('Aktif')}
                variant="outlined"
                value={addOrUpdateConvinceActive}
                onChange={(e) => setAddOrUpdateConvinceActive(e.target.value)}
                style={{ marginTop: 5 }}
              >
                <MenuItem key={0} value={true}>
                  {('Aktif')}
                </MenuItem>

                <MenuItem key={1} value={false}>
                  {('Pasif')}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="outlined-multiline-flexible"
                label="İkna Türü"
                fullWidth
                multiline
                onChange={(e) => setAddOrUpdateConvince(e.target.value)}
                maxRows={4}
                variant="outlined"
                value={addOrUpdateConvince}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => addOrUpdateConvinceTypeClick()}
            style={{ marginTop: 10 }}
          >{isConvinceTypeUpdate ? 'Güncelle' : 'Ekle'}</Button>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => setUpdatePopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConvinceTypeDynamic;
