import { Card, CardContent, Grid, Button, Autocomplete, TextField, DialogContent, Backdrop, CircularProgress, Stack, Snackbar, Alert } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import EditIcon from '@mui/icons-material/Edit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterListIcon from '@mui/icons-material/FilterList';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import * as XLSX from 'xlsx'
import AddBoxIcon from '@mui/icons-material/AddBox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import MaterialTable from 'material-table'
import axios from 'src/utils/axios';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'src/utils/Common';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { getRegions } from 'src/store/slices/assigmentSlice';
import AlertAndLoading from 'src/components/AlertAndLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Segoe UI",
    width: '100%',
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }
}));


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBoxIcon {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutlineIcon {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAltIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterListIcon {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeftIcon {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownwardIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <RemoveIcon {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumnIcon {...props} ref={ref} />)
};

const EXTENSIONS = ['xlsx', 'xls', 'csv']
const columns = ["Stok", "StokMiktarı", "Bölge", "Yükleyen"]

function StockExcelUpload() {
  const classes = useStyles();
  const SessionUser = getUser();
  const dispatch = useDispatch();

  const regions = useSelector((state) => state.assigmentSlice.regions);

  const [selectedRegion, setSelectedRegion] = React.useState(null);
  const [saveFileData, setSaveFileData] = useState([]);
  const [colDefs, setColDefs] = useState()
  const [loading, setLoading] = React.useState(false);

  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });

  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  const getExention = (file) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    return EXTENSIONS.includes(extension) // return boolean
  }

  const importExcel = (e) => {
    const files = e.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      setLoading(true)
      if (getExention(file)) {
        reader.readAsBinaryString(file)
      }
      reader.onload = (event) => {
        //parse data
        const bstr = event.target.result
        const workBook = XLSX.read(bstr, { type: "binary" })
        //get first sheet
        const workSheetName = workBook.SheetNames[0]
        const workSheet = workBook.Sheets[workSheetName]
        //convert to array
        const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
        const headers = fileData[0]
        const tempResult = []
        for (var i = 1; i < fileData.length; i++) {
          const tmp = {
            Stok: String(fileData[i][0]),
            StokMik: fileData[i][1],
            Bölge: selectedRegion ? selectedRegion : SessionUser.TopServiceShopCode,
            UserId: SessionUser.UserId
          }
          tempResult.push(tmp)
        }
        findTheDifference(tempResult)
        setColDefs(columns.map(head => ({ title: head, field: head })))
        setLoading(false)
        fileData.splice(0, 1)
      }
    }
  }
  const findTheDifference = (data) => {
    var changedValues = []
    data.map(i => {
      try {
        changedValues.push(
          {
            Stok: i.Stok,
            StokMiktarı: i.StokMik,
            Bölge: selectedRegion ? selectedRegion : SessionUser.TopServiceShopCode,
            Yükleyen: SessionUser.UserId
          }
        );
      } catch (ex) {
      }
    })
    setSaveFileData(changedValues);
  }

  const dfSave = () => {
    setLoading(true)
    var data = [];
    saveFileData.map(i => {
      try {
        data.push(
          {
            ProductCode: i.Stok,
            Count: i.StokMiktarı,
            TopRegionId: selectedRegion ? selectedRegion : SessionUser.TopServiceShopCode,
            UserId: SessionUser.UserId
          }
        );
      } catch (ex) {
        setLoading(false)
      }
    })
    axios.post('SetProductAvalibleCount', data, {
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      setSaveFileData([])
      // setresponseMessage({
      //   ErrorCode: 1000,
      //   ErrorDescription: 'İşleminiz başarılıyla gerçekleşmiştir...',
      // });
      // setUyari(true);
      InfoAlert('İşleminiz başarılıyla gerçekleşmiştir...', 'success', 'Başarılı');
      setLoading(false)
      return
    }).catch(err => {
      InfoAlert('Kaydedilemedi... Lütfen daha sonra tekrar deneyiniz...', 'error', 'Hata');
      // setresponseMessage({
      //   ErrorCode: 0,
      //   ErrorDescription: 'Kaydedilemedi... Lütfen daha sonra tekrar deneyiniz...',
      // });
      // setUyari(true);
      setLoading(false)
      return
    });
  }

  return (
    <>
      {/* <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={8}>
        <Snackbar sx={{ width: '100%' }} open={uyari} autoHideDuration={6000} onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >{responseMessage.ErrorCode === 0 ?
          <Alert icon={<MoodBadIcon />}
            style={{ minWidth: 500 }}
            sx={{
              "& .MuiAlert-icon": {
                color: "white"
              },
              height: 75,
              alignItems: "center",
              verticalAlign: "center"

            }} onClose={uyariKapat} variant="filled" severity="error" >
            {responseMessage.ErrorDescription}
          </Alert>
          :
          <Alert icon={<TagFacesIcon />}
            style={{ minWidth: 500 }}
            sx={{
              "& .MuiAlert-icon": {
                color: "white"
              },
              height: 75,
              alignItems: "center",
              verticalAlign: "center"

            }} onClose={uyariKapat} variant="filled" severity="success" >
            {responseMessage.ErrorDescription}
          </Alert>}
        </Snackbar>
      </Stack> */}
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Autocomplete
                id="combo-box-demo"
                options={regions}
                getOptionLabel={(option) => option.Dsc}
                size="small"
                fullWidth
                value={regions.find(i => i.Id == selectedRegion) || regions.find(i => i.Id == SessionUser.TopServiceShopCode)}
                onChange={(event, val) => {
                  var value = val?.Id ? val.Id.toString() : SessionUser.TopServiceShopCode.toString()
                  setSelectedRegion(value);
                  setSaveFileData([]);
                  setColDefs([]);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Bölge" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2} >
              <input
                id="FileInput"
                type='file'
                onChange={importExcel}
                onClick={(event) => { event.target.value = null }}
                hidden
                accept=".xlsx, .xls, .xml"
              >
              </input>
              <label htmlFor="FileInput">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  component="span"
                  sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                >
                  {'Yükle'}
                </Button>
              </label>

            </Grid>
            <Grid item xs={12} md={5} ></Grid>
            <Grid item xs={12} md={2} >
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={dfSave}
                component="span"
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              >
                {'Kaydet'}
              </Button>

            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <DialogContent>
              <MaterialTable
                title={'Stok Adetleri'}
                data={saveFileData}
                columns={colDefs}
                icons={tableIcons}
                fullWidth
              />
            </DialogContent>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default StockExcelUpload;
