import CreateIcon from '@mui/icons-material/Create';
import {
  Backdrop, Card, CardContent, CircularProgress, Grid, Stack,
  TextField, Button, InputAdornment, Autocomplete, Snackbar, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getProductBasedExchangeRateForDynamic, getModelForDynamic, updateProductBasedExchangeRate, deleteProductBasedExchangeRate, addProductBasedExchangeRate } from 'src/store/slices/dynamicCatalogSlice';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { getUser } from 'src/utils/Common';
import { confirmAlert } from 'react-confirm-alert';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI",
    width: '100%',
    height: '100%'
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }

}));

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  limit: 20,
});


function ProductBasedExchangeRateDynamic() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [productBasedExchangeRate, setProductBasedExchangeRate] = React.useState([]);
  const [searchedProductBasedExchangeRate, setSearchedProductBasedExchangeRate] = React.useState([]);
  const [searcedText, setSearchedText] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [models, setModels] = React.useState([]);
  const [modelAutoCompleteOptions, setModelAutoCompleteOptions] = React.useState([]);
  const [updatedModel, setUpdatedModel] = React.useState(null);
  const [selectedModelForUpdate, setSelectedModelForUpdate] = React.useState(null);
  const [updated_0_12_Ay, setUpdated_0_12_Ay] = React.useState(null);
  const [updated_13_24_Ay, setUpdated_13_24_Ay] = React.useState(null);
  const [updated_25_36_Ay, setUpdated_25_36_Ay] = React.useState(null);
  const [updated_37_60_Ay, setUpdated_37_60_Ay] = React.useState(null);
  const [updated_61_72_Ay, setUpdated_61_72_Ay] = React.useState(null);
  const [updated_73_84_Ay, setUpdated_73_84_Ay] = React.useState(null);
  const [updated_85_120_Ay, setUpdated_85_120_Ay] = React.useState(null);
  const [updated_121_180_Ay, setUpdated_121_180_Ay] = React.useState(null);
  const [productBasedExchangeRateAddPopup, setProductBasedExchangeRateAddPopup] = React.useState(false);
  const [addedModel, setAddedModel] = React.useState(null);
  const [added_0_12_Ay, setAdded_0_12_Ay] = React.useState(null);
  const [added_13_24_Ay, setAdded_13_24_Ay] = React.useState(null);
  const [added_25_36_Ay, setAdded_25_36_Ay] = React.useState(null);
  const [added_37_60_Ay, setAdded_37_60_Ay] = React.useState(null);
  const [added_61_72_Ay, setAdded_61_72_Ay] = React.useState(null);
  const [added_73_84_Ay, setAdded_73_84_Ay] = React.useState(null);
  const [added_85_120_Ay, setAdded_85_120_Ay] = React.useState(null);
  const [added_121_180_Ay, setAdded_121_180_Ay] = React.useState(null);
  const [addedModelError, setAddedModelError] = React.useState(false);
  const [added_0_12_AyError, setAdded_0_12_AyError] = React.useState(false);
  const [added_13_24_AyError, setAdded_13_24_AyError] = React.useState(false);
  const [added_25_36_AyError, setAdded_25_36_AyError] = React.useState(false);
  const [added_37_60_AyError, setAdded_37_60_AyError] = React.useState(false);
  const [added_61_72_AyError, setAdded_61_72_AyError] = React.useState(false);
  const [added_73_84_AyError, setAdded_73_84_AyError] = React.useState(false);
  const [added_85_120_AyError, setAdded_85_120_AyError] = React.useState(false);
  const [added_121_180_AyError, setAdded_121_180_AyError] = React.useState(false);
  const [uyari, setUyari] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState({
    ErrorCode: '0',
    ErrorDescription: ('success_message')
  });


  useEffect(() => {
    setLoading(true);
    dispatch(getProductBasedExchangeRateForDynamic()).then(response => {
      if (response.payload?.length > 0) {
        setProductBasedExchangeRate(response.payload.map(res => ({ ...res, editable: false })));
        setLoading(false);
      }
      setLoading(false);
    })
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    dispatch(getModelForDynamic()).then(response => {
      if (response.payload?.length > 0) {
        setModels(response.payload);
        setModelAutoCompleteOptions(response.payload.map(res => ({ ...res, label: res.ACIKLAMA, value: res.MODEL_KODU })));
        setLoading(false);
      }
      setLoading(false);
    })
  }, [dispatch]);

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };


  const search = (text) => {
    var result = productBasedExchangeRate.filter(i => i.ACIKLAMA.toLocaleLowerCase("tr-TR").includes(text.toLocaleLowerCase("tr-TR")) || i.KUL_MODEL_KODU.toLocaleLowerCase("tr-TR").includes(text.toLocaleLowerCase("tr-TR")));
    setSearchedText(text);
    setSearchedProductBasedExchangeRate(result);
  }

  const editRow = (editedRow) => {
  
    setSelectedModelForUpdate(editedRow);
    setUpdatedModel(editedRow);
    setUpdated_0_12_Ay(editedRow['0_12_Ay']);
    setUpdated_13_24_Ay(editedRow['13_24_Ay']);
    setUpdated_25_36_Ay(editedRow['25_36_Ay']);
    setUpdated_37_60_Ay(editedRow['37_60_Ay']);
    setUpdated_61_72_Ay(editedRow['61_72_Ay']);
    setUpdated_73_84_Ay(editedRow['73_84_Ay']);
    setUpdated_85_120_Ay(editedRow['85_120_Ay']);
    setUpdated_121_180_Ay(editedRow['121_180_Ay']);


    const updatedRows = productBasedExchangeRate.map((row) => {
      if (row.MODEL_KODU === editedRow.MODEL_KODU) {
        row.editable = true;
      }
      else {
        row.editable = false;
      }
      return row;
    });



    setProductBasedExchangeRate(updatedRows);
  }

  const cancelEdit = (id) => {
    //change the editable property of the json when id is equal from productBasedExchangeRate array
    const updatedRows = productBasedExchangeRate.map((row) => {
      if (row.MODEL_KODU === id) {
        row.editable = false;
        row.ACIKLAMA = selectedModelForUpdate.ACIKLAMA;
        row.KUL_MODEL_KODU = selectedModelForUpdate.KUL_MODEL_KODU;
        row.MODEL_KODU = selectedModelForUpdate.MODEL_KODU;
      }
      return row;
    });

    setProductBasedExchangeRate(updatedRows);
  }

  const updateExchangeRate = () => {
    setLoading(true);
    var User = getUser().FirstName + ' ' + getUser().LastName;
    if (updatedModel ) {
      var updatedProductBasedExchangeRateData = {
        NewModelCode: updatedModel.MODEL_KODU,
        OldModelCode: selectedModelForUpdate.MODEL_KODU,
        //ACIKLAMA: updatedModel.ACIKLAMA,
        //KUL_MODEL_KODU: updatedModel.KUL_MODEL_KODU,
        //IndirimiYapan: User,
        Month_0_12_Rate: updated_0_12_Ay || 0,
        Month_13_24_Rate: updated_13_24_Ay || 0,
        Month_25_36_Rate: updated_25_36_Ay || 0,
        Month_37_60_Rate: updated_37_60_Ay || 0,
        Month_61_72_Rate: updated_61_72_Ay || 0,
        Month_73_84_Rate: updated_73_84_Ay || 0,
        Month_85_120_Rate: updated_85_120_Ay || 0,
        Month_121_180_Rate: updated_121_180_Ay || 0,
        User: User
      }
      dispatch(updateProductBasedExchangeRate(updatedProductBasedExchangeRateData))
        .then(response => {
          if (response.payload.ErrorCode == 0) {
            //change the editable property of the json when id is equal from productBasedExchangeRate array
            const updatedRows = productBasedExchangeRate.map((row) => {
              if (row.MODEL_KODU === updatedModel.MODEL_KODU) {
                row.editable = false;
                row['0_12_Ay'] = updated_0_12_Ay || 0;
                row['13_24_Ay'] = updated_13_24_Ay || 0;
                row['25_36_Ay'] = updated_25_36_Ay || 0;
                row['37_60_Ay'] = updated_37_60_Ay || 0;
                row['61_72_Ay'] = updated_61_72_Ay || 0;
                row['73_84_Ay'] = updated_73_84_Ay || 0;
                row['85_120_Ay'] = updated_85_120_Ay || 0;
                row['121_180_Ay'] = updated_121_180_Ay || 0;
                row.IndirimiYapan = User;
              }
              return row;
            });

            setProductBasedExchangeRate(updatedRows);

            setUpdatedModel(null);
            setUpdated_0_12_Ay(null);
            setUpdated_13_24_Ay(null);
            setUpdated_25_36_Ay(null);
            setUpdated_37_60_Ay(null);
            setUpdated_61_72_Ay(null);
            setUpdated_73_84_Ay(null);
            setUpdated_85_120_Ay(null);
            setUpdated_121_180_Ay(null);
            setSelectedModelForUpdate(null);
            setLoading(false);
            setResponseMessage(response.payload);
            setUyari(true);
          }
          else {
            setResponseMessage(response.payload);
            setUyari(true);
            setLoading(false);
          }
        }).catch(err => {
          setErrorResponseMessageManuel("Bir şeyler ters gitti. Lütfen daha sonra tekrar deneyiniz!");
          setLoading(false);
        });

    }
  }
  const deleteRow = (id) => {
    //confirm delete
    confirmAlert({
      message: 'Bu kaydı silmek istediğinize emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            dispatch(deleteProductBasedExchangeRate(id))
              .then(response => {
                if (response.payload.ErrorCode == 0) {

                  const updatedRows = productBasedExchangeRate.filter((row) => {
                    return row.MODEL_KODU !== id;
                  });
                  setProductBasedExchangeRate(updatedRows);

                  setLoading(false);
                  setResponseMessage(response.payload);
                  setUyari(true);
                }
                else {
                  setResponseMessage(response.payload);
                  setUyari(true);
                  setLoading(false);
                }
              }).catch(err => {
                setErrorResponseMessageManuel("Bir şeyler ters gitti. Lütfen daha sonra tekrar deneyiniz!");
                setLoading(false);
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });


  }

  const addNewProductBasedExchangeRate = () => {
    setLoading(true);
    var error = false;
    var User = getUser().FirstName + ' ' + getUser().LastName;
    if (!addedModel) {
      setAddedModelError(true);
      setLoading(false);
      error = true;
    }
    if (!added_0_12_Ay) {
      setAdded_0_12_AyError(true);
      setLoading(false);
      error = true;

    }
    if (!added_13_24_Ay) {
      setAdded_13_24_AyError(true);
      setLoading(false);
      error = true;

    }
    if (!added_25_36_Ay) {
      setAdded_25_36_AyError(true);
      setLoading(false);
      error = true;
    }
    if (!added_37_60_Ay) {
      setAdded_37_60_AyError(true);
      setLoading(false);
      error = true;
    }
    if (!added_61_72_Ay) {
      setAdded_61_72_AyError(true);
      setLoading(false);
      error = true;
    }
    if (!added_73_84_Ay) {
      setAdded_73_84_AyError(true);
      setLoading(false);
      error = true;
    }
    if (!added_85_120_Ay) {
      setAdded_85_120_AyError(true);
      setLoading(false);
      error = true;
    }
    if (!added_121_180_Ay) {
      setAdded_121_180_AyError(true);
      setLoading(false);
      error = true;
    }
    if (error) {
      console.log(addedModelError);
      return;
    }
    if (productBasedExchangeRate.filter(x => x.MODEL_KODU == addedModel.MODEL_KODU).length > 0) {
      setErrorResponseMessageManuel("Bu model kodu zaten var");
      setLoading(false);
      return;
    }
    var addedProductBasedExchangeRateData = {
      NewModelCode: addedModel.MODEL_KODU,
      OldModelCode: addedModel.MODEL_KODU,
      Month_0_12_Rate: added_0_12_Ay,
      Month_13_24_Rate: added_13_24_Ay,
      Month_25_36_Rate: added_25_36_Ay,
      Month_37_60_Rate: added_37_60_Ay,
      Month_61_72_Rate: added_61_72_Ay,
      Month_73_84_Rate: added_73_84_Ay,
      Month_85_120_Rate: added_85_120_Ay,
      Month_121_180_Rate: added_121_180_Ay,
      User: User
    }
    dispatch(addProductBasedExchangeRate(addedProductBasedExchangeRateData))
      .then(response => {
        if (response.payload.ErrorCode == 0) {
          //add the new row to the productBasedExchangeRate array
          var updatedRows = productBasedExchangeRate;
          updatedRows.push({
            MODEL_KODU: addedModel.MODEL_KODU,
            ACIKLAMA: addedModel.ACIKLAMA,
            KUL_MODEL_KODU: addedModel.KUL_MODEL_KODU,
            editable: false,
            '0_12_Ay': added_0_12_Ay,
            '13_24_Ay': added_13_24_Ay,
            '25_36_Ay': added_25_36_Ay,
            '37_60_Ay': added_37_60_Ay,
            '61_72_Ay': added_61_72_Ay,
            '73_84_Ay': added_73_84_Ay,
            '85_120_Ay': added_85_120_Ay,
            '121_180_Ay': added_121_180_Ay,
            IndirimiYapan: User
          });

          setProductBasedExchangeRate(updatedRows);
          setAddedModel(null);
          setAdded_0_12_Ay(null);
          setAdded_13_24_Ay(null);
          setAdded_25_36_Ay(null);
          setAdded_37_60_Ay(null);
          setAdded_61_72_Ay(null);
          setAdded_85_120_Ay(null);
          setAdded_121_180_Ay(null);
          setProductBasedExchangeRateAddPopup(false);
          setLoading(false);
          setResponseMessage(response.payload);
          setUyari(true);
        }
        else {
          setResponseMessage(response.payload);
          setUyari(true);
          setProductBasedExchangeRateAddPopup(false);
          setLoading(false);
        }
      }).catch(err => {
        setErrorResponseMessageManuel("Bir şeyler ters gitti. Lütfen daha sonra tekrar deneyiniz!");
        setProductBasedExchangeRateAddPopup(false);
        setLoading(false);
      });
  }

  const setErrorResponseMessageManuel = (message) => {
    var manuelResponse = {
      ErrorCode: 1000,
      ErrorDescription: message
    }
    setResponseMessage(manuelResponse);
    setUyari(true);
  }
  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                variant="standard"
                type='search'
                size='small'
                onInput={(e) => { search(e.target.value) }}
                placeholder="Ara..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={7}>

            </Grid>
            <Grid item xs={12} md={2}>
              <label htmlFor="FileInput">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  component="span"
                  sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                  onClick={() => { setProductBasedExchangeRateAddPopup(true) }}
                >
                  Yeni Oran Ekle
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} md={12}>
              <TableContainer component={Paper} sx={{ maxHeight: 500, minHeight: 300 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Model Kodu</TableCell>
                      <TableCell align="center">Açıklama</TableCell>
                      <TableCell align="center">0-1 YIL</TableCell>
                      <TableCell align="center">1-2 YIL</TableCell>
                      <TableCell align="center">2-3 YIL</TableCell>
                      <TableCell align="center">3-5 YIL</TableCell>
                      <TableCell align="center">5-6 YIL</TableCell>
                      <TableCell align="center">6-7 YIL</TableCell>

                      <TableCell align="center">7-10 YIL</TableCell>
                      <TableCell align="center">10-15 YIL</TableCell>
                      <TableCell align="center">İndirimi Yapan</TableCell>
                      <TableCell align="center">İşlem</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searcedText.length > 0 && searchedProductBasedExchangeRate.length == 0 ? <TableRow> <TableCell colSpan={11} align="center">Hiç Kayıt Bulunamadı!</TableCell> </TableRow> : ((searchedProductBasedExchangeRate.length > 0 ? searchedProductBasedExchangeRate : productBasedExchangeRate).map((row) => (
                      <TableRow
                        key={row.MODEL_KODU}
                      >
                        {/* <TableCell align="center">{row.editable ?
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={modelAutoCompleteOptions}
                            size="large"
                            filterOptions={filterOptions}
                            defaultValue={row.KUL_MODEL_KODU}
                            sx={{ width: 200 }}
                            onChange={(event, newValue) => {
                              setUpdatedModel(newValue);
                              change the model code of the json when id is equal from productBasedExchangeRate array
                              var updatedRows = productBasedExchangeRate.map((element) => {
                                if (element.editable === true) {
                                  element.KUL_MODEL_KODU = newValue.KUL_MODEL_KODU;
                                  element.ACIKLAMA = newValue.ACIKLAMA;
                                  element.MODEL_KODU = newValue.MODEL_KODU;
                                }
                                return element;
                              });
                              console.log(updatedRows);
                              setProductBasedExchangeRate(updatedRows);
                            }}
                            renderInput={(params) => <TextField {...params} label="Model Kodu" onChange={
                              (e) => {
                                searchModelOnChange(e.target.value);
                              }
                            } />}
                          /> : row.KUL_MODEL_KODU}</TableCell> */
                          <TableCell align="center">{row.KUL_MODEL_KODU}</TableCell>
                        }
                        <TableCell align="center">{row.ACIKLAMA}</TableCell>
                        <TableCell align="center">{row.editable ?
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            onChange={(e) => { setUpdated_0_12_Ay(e.target.value) }}
                            defaultValue={row['0_12_Ay']} /> : row['0_12_Ay']}</TableCell>
                        <TableCell align="center">{row.editable ?
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="number"
                            size="small"
                            fullWidth
                            onChange={(e) => { setUpdated_13_24_Ay(e.target.value) }}
                            defaultValue={row['13_24_Ay']} /> : row['13_24_Ay']}</TableCell>
                        <TableCell align="center">{row.editable ?
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            onChange={(e) => { setUpdated_25_36_Ay(e.target.value) }}
                            defaultValue={row['25_36_Ay']} /> : row['25_36_Ay']}</TableCell>
                        <TableCell align="center">{row.editable ?
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            onChange={(e) => { setUpdated_37_60_Ay(e.target.value) }}
                            defaultValue={row['37_60_Ay']} /> : row['37_60_Ay']}</TableCell>
                        <TableCell align="center">{row.editable ?
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            onChange={(e) => { setUpdated_61_72_Ay(e.target.value) }}
                            defaultValue={row['61_72_Ay']} /> : row['61_72_Ay']}</TableCell>
                            <TableCell align="center">{row.editable ?
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            onChange={(e) => { setUpdated_73_84_Ay(e.target.value) }}
                            defaultValue={row['73_84_Ay']} /> : row['73_84_Ay']}</TableCell>

                        <TableCell align="center">{row.editable ?
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            onChange={(e) => { setUpdated_85_120_Ay(e.target.value) }}
                            defaultValue={row['85_120_Ay']} /> : row['85_120_Ay']}</TableCell>
                        <TableCell align="center">{row.editable ?
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            onChange={(e) => { setUpdated_121_180_Ay(e.target.value) }}
                            defaultValue={row['121_180_Ay']} /> : row['121_180_Ay']}</TableCell>
                        <TableCell align="center">{row.IndirimiYapan}</TableCell>
                        <TableCell align="center">
                          {row.editable ? (<>
                            <Tooltip title="Kaydet" placement="right">
                              <IconButton onClick={() => { updateExchangeRate() }} >
                                <CheckIcon sx={{ color: '#4CAF50' }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="İptal" placement="right">
                              <IconButton onClick={() => { cancelEdit(row.MODEL_KODU) }} >
                                <ClearIcon sx={{ color: 'red' }} />
                              </IconButton>
                            </Tooltip> </>
                          ) : (<>
                            <Tooltip title="Güncelle" placement="right">
                              <IconButton onClick={() => { editRow(row) }} >
                                <CreateIcon sx={{ color: '#FF9800' }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Sil" placement="right">
                              <IconButton onClick={() => { deleteRow(row.MODEL_KODU) }} >
                                <DeleteIcon sx={{ color: 'red' }} />
                              </IconButton>
                            </Tooltip> </>)}
                        </TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    )))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent >
      </Card>
      <Dialog
        open={productBasedExchangeRateAddPopup}
        onClose={() => setProductBasedExchangeRateAddPopup(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ width: '100%' }} >{"Ürün Bazlı Değişim Oranı Ekleme"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={modelAutoCompleteOptions}
                filterOptions={filterOptions}
                size="small"
                sx={{ mt: 1 }}
                fullWidth
                onChange={(event, newValue) => {
                  setAddedModel(newValue);
                  setAddedModelError(false);
                }}
                renderInput={(params) =>
                  <TextField
                    {...params} label="Model Kodu"
                    error={addedModelError}
                    helperText={addedModelError ? "Model Seçiniz" : ""}
                    variant="outlined"
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                id="Year0-1"
                label="0-1 Yıl"
                variant="outlined"
                value={added_0_12_Ay}
                error={added_0_12_AyError}
                helperText={added_0_12_AyError ? "0-1 Yıl Değeri Giriniz" : ""}
                onChange={(e) => { setAdded_0_12_Ay(e.target.value); setAdded_0_12_AyError(false); }}></TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="Year1-2"
                label="1-2 Yıl"
                size="small"
                fullWidth
                variant="outlined"
                value={added_13_24_Ay}
                error={added_13_24_AyError}
                helperText={added_13_24_AyError ? "1-2 Yıl Değeri Giriniz" : ""}
                onChange={(e) => { setAdded_13_24_Ay(e.target.value); setAdded_13_24_AyError(false); }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="Year2-3"
                label="2-3 Yıl"
                size="small"
                fullWidth
                variant="outlined"
                value={added_25_36_Ay}
                error={added_25_36_AyError}
                helperText={added_25_36_AyError ? "2-3 Yıl Değeri Giriniz" : ""}
                onChange={(e) => { setAdded_25_36_Ay(e.target.value); setAdded_25_36_AyError(false); }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="Year3-5"
                label="3-5 Yıl"
                size="small"
                fullWidth
                variant="outlined"
                value={added_37_60_Ay}
                error={added_37_60_AyError}
                helperText={added_37_60_AyError ? "3-4 Yıl Değeri Giriniz" : ""}
                onChange={(e) => { setAdded_37_60_Ay(e.target.value); setAdded_37_60_AyError(false); }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="Year5-6"
                label="5-6 Yıl"
                size="small"
                fullWidth
                variant="outlined"
                value={added_61_72_Ay}
                error={added_61_72_AyError}
                helperText={added_61_72_AyError ? "5-10 Yıl Değeri Giriniz" : ""}
                onChange={(e) => { setAdded_61_72_Ay(e.target.value); setAdded_61_72_AyError(false); }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="Year6-10"
                label="6-10 Yıl"
                size="small"
                fullWidth
                variant="outlined"
                value={added_73_84_Ay}
                error={added_73_84_AyError}
                helperText={added_73_84_AyError ? "6-10 Yıl Değeri Giriniz" : ""}
                onChange={(e) => { setAdded_73_84_Ay(e.target.value); setAdded_73_84_AyError(false); }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="Year6-10"
                label="6-10 Yıl"
                size="small"
                fullWidth
                variant="outlined"
                value={added_85_120_Ay}
                error={added_85_120_AyError}
                helperText={added_85_120_AyError ? "6-10 Yıl Değeri Giriniz" : ""}
                onChange={(e) => { setAdded_85_120_Ay(e.target.value); setAdded_85_120_AyError(false); }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="Year10-15"
                label="10-15 Yıl"
                size="small"
                fullWidth
                variant="outlined"
                value={added_121_180_Ay}
                error={added_121_180_AyError}
                helperText={added_121_180_AyError ? "10-15 Yıl Değeri Giriniz" : ""}
                onChange={(e) => { setAdded_121_180_Ay(e.target.value); setAdded_121_180_AyError(false); }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={() => { addNewProductBasedExchangeRate() }}
                style={{ marginTop: 10 }}>
                Ekle
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => setProductBasedExchangeRateAddPopup(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Stack spacing={8}>
        <Snackbar sx={{ width: '100%' }} open={uyari} autoHideDuration={6000} onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >{responseMessage.ErrorCode == 1000 ?
          <Alert icon={<MoodBadIcon />}
            style={{ minWidth: 500 }}
            sx={{
              "& .MuiAlert-icon": {
                color: "white"
              },
              height: 75,
              alignItems: "center",
              verticalAlign: "center"

            }} onClose={uyariKapat} variant="filled" severity="error" >
            {responseMessage.ErrorDescription}
          </Alert>
          :
          <Alert icon={<TagFacesIcon />}
            style={{ minWidth: 500 }}
            sx={{
              "& .MuiAlert-icon": {
                color: "white"
              },
              height: 75,
              alignItems: "center",
              verticalAlign: "center"

            }} onClose={uyariKapat} variant="filled" severity="success" >
            {responseMessage.ErrorDescription}
          </Alert>}
        </Snackbar>
      </Stack>
    </>
  );
}

export default ProductBasedExchangeRateDynamic;
