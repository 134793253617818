import { Card, CardContent, Tooltip, IconButton, Grid, TextField, Button, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getQualityProductControl } from 'src/store/slices/dynamicCatalogSlice';
import { InsertQualityProductControl } from 'src/store/slices/dynamicCatalogSlice';
import { deleteQualityProduct } from 'src/store/slices/dynamicCatalogSlice';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { getUser } from 'src/utils/Common';
import AlertAndLoading from 'src/components/AlertAndLoading';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }
}));


function ProductQualityControlDynamic() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();
  const [qualityProduct, setQualityProduct] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [AddQualityProductModelCode, setAddQualityProductModelCode] = React.useState("");
  const [updatePopUp, setUpdatePopUp] = React.useState(false);
  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });

  useEffect(() => {
    setLoading(true);
    dispatch(getQualityProductControl()).then(response => {
      if (response.payload?.length > 0) {
        setQualityProduct(response.payload);
        setLoading(false);
      }
      setLoading(false);
    })
  }, [dispatch]);


  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  function addDiscountedUserClick() {
    setUpdatePopUp(true);
  }
  function AddQualityProductClick() {

    if (AddQualityProductModelCode.length <= 0) {
      InfoAlert('Lütfen Model Kodunu Giriniz!...', 'error', 'Hata');
      return;
    }
    const data = {
      ModelCode: AddQualityProductModelCode,
      SicilNo: SessionUser?.UserId?.toString(),
    }
    setLoading(true)
    dispatch(InsertQualityProductControl(data)).then(response => {
      setUpdatePopUp(false);
      setAddQualityProductModelCode("");
      dispatch(getQualityProductControl()).then(response => {
        if (response.payload?.length > 0) {
          setQualityProduct(response.payload);
          setLoading(false);
        }
      })
      setLoading(false);
    })
  }

  function deleteQualityProductClick(ModelCode, Id) {
    setLoading(true)
    dispatch(deleteQualityProduct([ModelCode, Id])).then(response => {
      dispatch(getQualityProductControl()).then(response => {
        if (response.payload?.length > 0) {
          setQualityProduct(response.payload);
          setLoading(false);
        }
        setQualityProduct([]);
      })
      setLoading(false);
    })
  }


  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };


  const columns = [
    { field: 'MODEL_CODE', headerName: 'Model Kodu', width: 400 },
    { field: 'SICILNO', headerName: 'Sicil No', width: 400 },
    {
      field: 'CREATED_DATE', headerName: 'Eklenen Tarih', width: 200,
      renderCell: (cellValues) => {
        return (
          moment(cellValues.row.CREATED_DATE).format('DD-MM-YYYY')
        );
      }
    },
    {
      field: 'Sil',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', "& > :not(style)": { m: 1 } }}>
            <Tooltip title="Sil">
              <IconButton onClick={() => deleteQualityProductClick(cellValues.row.MODEL_CODE, cellValues.row.ID)}>
                <DeleteIcon sx={{ color: '#FF9800' }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }, width: 200
    },
    {
      field: 'InsertButton',
      headerName: '',
      width: 120,
      filterable: false,
      renderHeader: (cellValues) => (
        <Box sx={{ justifyContent: 'center', "& > :not(style)": { m: 1 } }}>
          <Tooltip title="Ekle">
            <IconButton onClick={() => addDiscountedUserClick(cellValues.row)} >
              <PersonAddIcon sx={{ color: '#FF9800' }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },

  ];

  return (
    <>
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <Card>
        <CardContent>
          <Grid item sm={12} style={{ paddingTop: 5 }}></Grid>
          <DataGrid
            style={{ height: 600, width: '100%', textAlign: 'center', justifyItems: 'center', }}
            columns={columns}
            rows={qualityProduct}
            getRowId={(row) => row.ID}
          />
        </CardContent>
      </Card>

      <Dialog
        open={updatePopUp}
        onClose={() => setUpdatePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        size="sm"
      >
        <DialogContent>
          <Grid item xs={12} md={12}>
            <TextField
              id="input-with-icon-textfield"
              label="Model Kodu"
              onChange={(e) => setAddQualityProductModelCode(e.target.value)}
              value={AddQualityProductModelCode}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => AddQualityProductClick()}
            style={{ marginTop: 10 }}
          >{'Ekle'}</Button>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => { setUpdatePopUp(false); setAddQualityProductModelCode("") }}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>

    </>

  );
}

export default ProductQualityControlDynamic;
