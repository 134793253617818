
import CloseIcon from '@mui/icons-material/Close';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CreateIcon from '@mui/icons-material/Create';
import Collapse from '@mui/material/Collapse';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Grid, MenuItem, TextField, ListItem, List, ListItemIcon, Tooltip, ListItemText, CardContent, ListItemButton, Checkbox, Backdrop, CircularProgress, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getExchangeReasonForDynamic, addNewExchangeReasonForDynamic, updateNewExchangeReasonForDynamic, updateExchangeReasonSurveyControl } from 'src/store/slices/dynamicCatalogSlice';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { confirmAlert } from 'react-confirm-alert';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  }

}));

const ExchangeTypesDynamic = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [exchangeReason, setExchangeReason] = React.useState([]);
  const [exchangeReasonAddPopUp, setExchangeReasonAddPopUp] = React.useState(false);
  const [exchangeReasonUpdatePopUp, setExchangeReasonUpdatePopUp] = React.useState(false);
  const [SelectedValue, setSelectedValue] = React.useState([]);
  const [loading, setLoading] = React.useState(false)
  const [newExchangeReasonDetail, setNewExchangeReasonDetail] = React.useState('');
  const [newExchangeReasonDetailError, setNewExchangeReasonDetailError] = React.useState(false);
  const [newExchangeReasonTop, setNewExchangeReasonTop] = React.useState('');
  const [newExchangeReasonTopError, setNewExchangeReasonTopError] = React.useState(false);
  const [newExchangeReasonActive, setNewExchangeReasonActive] = React.useState(1);
  const [newExchangeReasonActiveError, setNewExchangeReasonActiveError] = React.useState(false);
  const [updatedExchangeReasonDetail, setUpdatedExchangeReasonDetail] = React.useState('');
  const [updatedExchangeReasonDetailError, setUpdatedExchangeReasonDetailError] = React.useState(false);
  const [updatedExchangeReasonTop, setUpdatedExchangeReasonTop] = React.useState('');
  const [updatedExchangeReasonTopError, setUpdatedExchangeReasonTopError] = React.useState(false);
  const [updatedExchangeReasonActive, setUpdatedExchangeReasonActive] = React.useState(1);
  const [updatedExchangeReasonActiveError, setUpdatedExchangeReasonActiveError] = React.useState(false);
  const [updatedExchangeReasonId, setUpdatedExchangeReasonId] = React.useState(0);


  const handleClick = (index) => {
    var SelectedValueCopy = [...SelectedValue];
    SelectedValueCopy[index].IsOpen = !SelectedValueCopy[index].IsOpen;
    setSelectedValue(SelectedValueCopy);
  };

  const handleClickExchangeReasonAddPopUp = () => {
    setExchangeReasonAddPopUp(!exchangeReasonAddPopUp);
    //set all error false
    setNewExchangeReasonDetailError(false);
    setNewExchangeReasonTopError(false);
    setNewExchangeReasonActiveError(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getExchangeReasonForDynamic())
      .then(response => {
        setLoading(false);
        setExchangeReason(response.payload);
        var findIn = [];
        for (var i = 0; i < response.payload.length; i++) {
          findIn.push({
            id: i,
            IsOpen: false
          })
        }
        setSelectedValue(findIn);
      }).catch(err => { setLoading(false); return });
  }, [dispatch]);

  const handleNewExchangeReasonButtonClick = () => {
    if (newExchangeReasonDetail == '') {
      setNewExchangeReasonDetailError(true);
    }
    else {
      setNewExchangeReasonDetailError(false);
    }
    if (newExchangeReasonTop == '') {
      setNewExchangeReasonTopError(true);
    }
    else {
      setNewExchangeReasonTopError(false);
    }
    if (newExchangeReasonDetail != '' && newExchangeReasonTop != '') {
      var newExchangeReason = {
        ExchangeReasonTop: newExchangeReasonTop,
        ExchangeReasonDesc: newExchangeReasonDetail,
        Active: newExchangeReasonActive
      }
      dispatch(addNewExchangeReasonForDynamic(newExchangeReason))
        .then(response => {
          if (response.payload.ErrorCode == 0) {
            getNewExchangeReason();
          }
          else {
            console.log(response.payload.ErrorDescription);
          }
        }).catch(err => { return });

      setExchangeReasonAddPopUp(false);
      setNewExchangeReasonDetail('');
      setNewExchangeReasonTop('');
      setNewExchangeReasonActive(0);
    }
  };

  const handleClickExchangeReasonUpdatePopUp = (id) => {
    setExchangeReasonUpdatePopUp(!exchangeReasonUpdatePopUp);

    var data = {};
    exchangeReason.forEach(element => {
      element.Detail.forEach(element2 => {
        if (element2.DEGISIM_NEDENI == id) {
          setUpdatedExchangeReasonDetail(element2.ACIKLAMA);
          setUpdatedExchangeReasonTop(element.Id);
          setUpdatedExchangeReasonActive(element2.Aktif);
          setUpdatedExchangeReasonId(element2.DEGISIM_NEDENI);
        }
      });
    });
    //set all error false
    setUpdatedExchangeReasonDetailError(false);
    setUpdatedExchangeReasonTopError(false);
    setUpdatedExchangeReasonActiveError(false);
  };

  const handleUpdatedExchangeReasonButtonClick = () => {
    if (updatedExchangeReasonDetail == '') {
      setUpdatedExchangeReasonDetailError(true);
    }
    else {
      setUpdatedExchangeReasonDetailError(false);
    }
    if (updatedExchangeReasonTop == '') {
      setUpdatedExchangeReasonTopError(true);
    }
    else {
      setUpdatedExchangeReasonTopError(false);
    }
    if (updatedExchangeReasonDetail != '' && updatedExchangeReasonTop != '') {
      var updatedExchangeReason = {
        ExchangeReasonTop: updatedExchangeReasonTop,
        ExchangeReasonDesc: updatedExchangeReasonDetail,
        Active: updatedExchangeReasonActive,
        ExchangeReasonId: updatedExchangeReasonId
      }
      dispatch(updateNewExchangeReasonForDynamic(updatedExchangeReason))
        .then(response => {
          if (response.payload.ErrorCode == 0) {
            getNewExchangeReason();
          }
          else {
            console.log(response.payload.ErrorDescription);
          }
        }).catch(err => { return });

      setExchangeReasonUpdatePopUp(false);
      setUpdatedExchangeReasonDetail('');
      setUpdatedExchangeReasonTop('');
      setUpdatedExchangeReasonActive(0);
      setUpdatedExchangeReasonId(0);
    }
  };

  async function getNewExchangeReason() {
    setLoading(true);
    await dispatch(getExchangeReasonForDynamic())
      .then(response => {
        setLoading(false);
        setExchangeReason(response.payload);
      }).catch(err => { setLoading(false); return });
  }


  const handleSurveyChange = (id, checked) => {

    const data = {
      Id: id,
      SurveyCheck: checked
    }
    console.log(data);
    confirmAlert({
      message: ('İade İkna Anketi aktiflik durumunu değiştirmek istediğinizden emin misiniz?'),
      buttons: [
        {
          label: ('Evet'),
          onClick: () => {
            setLoading(true);
            dispatch(updateExchangeReasonSurveyControl(data)).then(response => {
              dispatch(getExchangeReasonForDynamic()).then(response => {
                if (response.payload?.length > 0) {
                  setExchangeReason(response.payload);
                  setLoading(false);
                }
              })
            })
          }
        },
        {
          label: ('Hayır'),
          onClick: () => { }
        }
      ]
    });
  }



  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>

        <Grid item xs={12} md={2}>
          {/* <label htmlFor="FileInput">
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              component="span"
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={handleClickExchangeReasonAddPopUp}
            >
              Yeni Değişim Nedeni Ekle
            </Button>
          </label> */}
        </Grid>
        <Grid item xs={12} md={8}>
          <CardContent >
            {exchangeReason?.map((index, i) => (
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
              >
                <ListItemButton key={index.Id} onClick={() => { handleClick(i) }}>
                  <ListItemIcon>
                    <Checkbox
                      name="checkedB"
                      checkedIcon={<ClearAllIcon color='success' />}
                      icon={<ClearAllIcon sx={{ color: 'red' }} />}
                      checked={index.Active == true}
                    />
                  </ListItemIcon>
                  <ListItemText primary={index.Dsc} sx={{ fontWeight: 'bold' }} />
                  {SelectedValue[i]?.IsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={SelectedValue[i]?.IsOpen}
                  timeout="auto" unmountOnExit>
                  {index?.Detail?.map((index, i) => (
                    <List component="div" disablePadding>
                      <ListItem sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <Checkbox
                            name="checkedB"
                            checkedIcon={<ThumbUpAltIcon color='success' />}
                            icon={<ThumbDownAltIcon sx={{ color: 'red' }} />}
                            checked={index.Aktif == true}
                          />
                        </ListItemIcon>
                        <ListItem key={index.DEGISIM_TURU}
                          className={classes.nested} >
                          <ListItemText primary={index?.ACIKLAMA} />
                        </ListItem>
                        <ListItemIcon>
                          <IconButton
                            color="primary"
                            aria-label="close"
                            component="span"
                            variant="contained"
                            onClick={() => handleClickExchangeReasonUpdatePopUp(index?.DEGISIM_NEDENI)}
                          >
                            <CreateIcon
                              sx={{ color: '#FF9800' }}
                            />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemIcon>
                          <Tooltip
                            title='İade İkna Anketi Aktiflik'>
                            <Checkbox
                              name="checkedB"
                              size='medium'
                              checked={index?.ANKET_KONTROL === true}
                              onChange={(event) => { handleSurveyChange(index.DEGISIM_NEDENI, event.target.checked) }}
                            />
                          </Tooltip>
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  ))}
                </Collapse>
              </List>
            ))}
          </CardContent>
        </Grid >
        <Grid item xs={12} md={2}>
          <Tooltip title="Yeni Değişim Nedeni Ekle">
            <IconButton
              color="primary"
              aria-label="close"
              component="span"
              variant="contained"
              onClick={handleClickExchangeReasonAddPopUp}
            >
              <PersonAddIcon sx={{ color: '#FF9800' }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid >
      <Dialog
        open={exchangeReasonAddPopUp}
        onClose={() => setExchangeReasonAddPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ width: '100%' }} >{"Değişim Nedeni Ekleme"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                select
                autoComplete="no"
                id="exchangeReasonActive"
                label="Aktif"
                variant="outlined"
                sx={{ mt: 1 }}
                value={newExchangeReasonActive}
                error={newExchangeReasonActiveError}
                helperText={newExchangeReasonActiveError ? "Aktiflik Durumu Seçiniz" : ""}
                onChange={(e) => setNewExchangeReasonActive(e.target.value)}
              >
                <MenuItem key={0} value={0}>
                  Pasif
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Aktif
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                select
                autoComplete="no"
                id="exchangeReasonName"
                label="Üst Değişim Nedeni"
                variant="outlined"
                value={newExchangeReasonTop}
                error={newExchangeReasonTopError}
                helperText={newExchangeReasonTopError ? "Üst Değişim Nedeni Seçiniz" : ""}
                onChange={(e) => setNewExchangeReasonTop(e.target.value)}
              >
                {exchangeReason?.filter((index) => index.Active == true).map((index) => (
                  <MenuItem key={index.Id} value={index.Id}>
                    {index.Dsc}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="exchangeReasonDetail"
                label="Yeni Değişim Nedeni"
                size="small"
                fullWidth
                variant="outlined"
                value={newExchangeReasonDetail}
                error={newExchangeReasonDetailError}
                helperText={newExchangeReasonDetailError ? "Yeni Değişim Nedeni Giriniz" : ""}
                onChange={(e) => setNewExchangeReasonDetail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={handleNewExchangeReasonButtonClick}
                style={{ marginTop: 10 }}>
                Ekle
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => setExchangeReasonAddPopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={exchangeReasonUpdatePopUp}
        onClose={() => setExchangeReasonUpdatePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ width: '100%' }} >{"Değişim Nedeni Güncelleme"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                select
                autoComplete="no"
                id="exchangeReasonActive"
                label="Aktif"
                variant="outlined"
                sx={{ mt: 1 }}
                value={updatedExchangeReasonActive}
                error={updatedExchangeReasonActiveError}
                helperText={updatedExchangeReasonActiveError ? "Aktiflik Durumu Seçiniz" : ""}
                onChange={(e) => setUpdatedExchangeReasonActive(e.target.value)}
              >
                <MenuItem key={0} value={0}>
                  Pasif
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Aktif
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                select
                autoComplete="no"
                id="exchangeReasonName"
                label="Üst Değişim Nedeni"
                variant="outlined"
                value={updatedExchangeReasonTop}
                error={updatedExchangeReasonTopError}
                helperText={updatedExchangeReasonTopError ? "Üst Değişim Nedeni Seçiniz" : ""}
                onChange={(e) => setUpdatedExchangeReasonTop(e.target.value)}
              >
                {exchangeReason?.filter((index) => index.Active == true).map((index) => (
                  <MenuItem key={index.Id} value={index.Id}>
                    {index.Dsc}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="exchangeReasonDetail"
                label="Yeni Değişim Nedeni"
                size="small"
                fullWidth
                variant="outlined"
                value={updatedExchangeReasonDetail}
                error={updatedExchangeReasonDetailError}
                helperText={updatedExchangeReasonDetailError ? "Yeni Değişim Nedeni Giriniz" : ""}
                onChange={(e) => setUpdatedExchangeReasonDetail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={handleUpdatedExchangeReasonButtonClick}
                style={{ marginTop: 10 }}>
                Güncelle
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => setExchangeReasonUpdatePopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ExchangeTypesDynamic;
