import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { getExchangeReceiptsBasedWarrantyType } from 'src/store/slices/generalMonitoringSlice';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  TextField
} from '@mui/material';
import { getStatusItems } from 'src/store/slices/commonSlice';
function ExchangeReceiptsBasedWarrantyTypesChart() {
  const dispatch = useDispatch();

  const statusData = useSelector((state) => state.commonSlice.statuses);

  const [mainData, setMainData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedStatus, setStatus] = React.useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    dispatch(getStatusItems());
    GetExchangeReceiptsBasedWarrantyType('0');
  }, []);

  const GetExchangeReceiptsBasedWarrantyType = (status) => {
    setLoading(true);
    dispatch(getExchangeReceiptsBasedWarrantyType(status))
      .then((response) => {
        const data = Array.isArray(response.payload) ? response.payload : [];
        setMainData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const state = {
    options: {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories: mainData?.map((item) => item.GARANTI_TIPI),
        title: {
          text: 'Garanti Tipi',
          style: {
            fontSize: '0.8rem',
            fontWeight: 'bold'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '0.8rem'
          }
        }
      }
    },
    series: [
      {
        name: 'Toplam Fiş Sayısı',
        data: mainData?.map((item) => item.TOPLAM_FIS_SAYISI)
      }
    ]
  };

  const handleSearchData = () => {
    let statusList = [];
    selectedStatus.map((row) => {
      statusList.push(row.Id);
    });

    if (statusList.length <= 0) {
      return;
    }

    GetExchangeReceiptsBasedWarrantyType(statusList.toString());
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card container spacing={2}>
          <CardHeader
            titleTypographyProps={{
              variant: 'body1',
              fontWeight: 'bold'
            }}
            title="Garanti Durumuna Göre Açık Olan Değişim Fişlerinin Dağılımı"
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={6} />
              <Grid item xs={12} sm={4} md={3}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  id="multiple-limit-tags"
                  disableCloseOnSelect
                  options={statusData || []}
                  getOptionLabel={(option) => option.Dsc}
                  value={selectedStatus || 0}
                  size="small"
                  fullWidth
                  onChange={(event, val) => {
                    setStatus(val);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.Dsc}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Durum" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="medium"
                  onClick={() => {
                    handleSearchData();
                  }}
                  sx={{
                    color: '#FF9800',
                    backgroundColor: '#424242',
                    paddingBottom: 1
                  }}
                >
                  Ara
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            </Grid>
            <Grid item xs={12}>
              {loading ? (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    {Array.from(new Array(3)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width="100%"
                        height={150}
                        sx={{ margin: 1 }}
                      />
                    ))}
                  </Grid>
                </>
              ) : (
                <Chart
                  options={state.options}
                  series={state.series}
                  type="bar"
                  width={'100%'}
                  height={450}
                />
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ExchangeReceiptsBasedWarrantyTypesChart;
