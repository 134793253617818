import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import {
  generalFollowActions,
  getBrandCodeOfProduct
} from 'src/store/slices/generalFollowSlice';
import axios from 'src/utils/axios';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import AlertAndLoading from 'src/components/AlertAndLoading';
import { confirmAlert } from 'react-confirm-alert';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: 'Segoe UI'
  },
  cardscontentInfo: {
    minHeight: 250
  },
  content: {
    justifyContent: 'space-between'
  },
  panelsContent: {
    minHeight: 260
  }
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value || 0
          }
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowNegative={false}
      isNumericString
      suffix="₺"
    />
  );
});

function DifferentModelSuggest() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const invoiceData = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  );
  const offerProductPrice = useSelector(
    (state) => state.generalFollowSlice.updatedOfferPrice
  );
  const topModelDifferentPrice = useSelector(
    (state) => state.generalFollowSlice.updatedTopModelDifferentPrice
  );

  const subModelDifferenceAmount = useSelector(
    (state) => state.generalFollowSlice.updatedSubModelDifferenceAmount
  );

  const giveProductPriceSelector = useSelector(
    (state) => state.generalFollowSlice.updatedGiveProductPrice
  );

  const receivedProductPriceSelector = useSelector(
    (state) => state.generalFollowSlice.updatedReceivedProductPrice
  );

  const updateInvoiceChangedSelector = useSelector(
    (state) => state.generalFollowSlice.updatedInvoiceChanged
  );

  const [expanded, setExpanded] = React.useState(true);
  const [giveStokNumber, setGiveStokNumber] = React.useState(
    invoiceData[0]?.Exchange?.GiveProductStokNumber
      ? invoiceData[0]?.Exchange?.GiveProductStokNumber
      : invoiceData[0]?.Model?.UserModelCode
  );
  const [giveProductDesc, setGiveProductDesc] = React.useState(
    invoiceData[0]?.Exchange?.GiveProductDesc
      ? invoiceData[0]?.Exchange?.GiveProductDesc
      : ''
  );
  const [giveProductPrice, setGiveProductPrice] = React.useState(
    invoiceData[0]?.Exchange?.GiveProductPrice
      ? invoiceData[0]?.Exchange?.GiveProductPrice
      : 0
  );
  const [stockTotal, setStockTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [BuyStokNumber, setBuyStokNumber] = React.useState(
    invoiceData[0]?.Model?.RevisionStokNo
      ? invoiceData[0]?.Model?.RevisionStokNo
      : ''
  );
  const [buyProductDesc, setBuyProductDesc] = React.useState(
    invoiceData[0]?.Model?.ProductDesc ? invoiceData[0]?.Model?.ProductDesc : ''
  );
  const [buyProductPrice, setBuyProductPrice] = React.useState(
    invoiceData[0]?.Exchange?.ReceivedProductPrice
      ? invoiceData[0]?.Exchange?.ReceivedProductPrice
      : 0
  );
  const [buyStockTotal, setBuyStockTotal] = React.useState(0);
  const [stockRegionName, setStockRegionName] = React.useState('');

  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({
    ErrorType: 'error',
    ErrorTitle: 'Uyarı'
  });

  const totalProductPrice =
    parseFloat(topModelDifferentPrice) + parseFloat(offerProductPrice);

  useEffect(() => {
    if (updateInvoiceChangedSelector) {
      console.log("Tetiklendim xd")
      changeGiveProductPriceSelector(giveProductPriceSelector)
      changeReceviedProductPriceSelector(receivedProductPriceSelector)
      dispatch(generalFollowActions.updateInvoiceChanged(false))
    }
  }, [updateInvoiceChangedSelector])

  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    });
    setUyari(true);
    return;
  }
  function changeReceviedProductPriceSelector(val) {
    //verilen ürün stok numarası
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.ReceivedProductPrice = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setBuyProductPrice(val);
  }

  function changeGiveProductPriceSelector(val) {
    //verilen ürün stok numarası
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.GiveProductPrice = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setGiveProductPrice(val);
  }

  function changeGiveStokNumber(val) {
    //verilen ürün stok numarası
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.GiveProductStokNumber = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setGiveStokNumber(val);
  }

  function changeBuyStokNumber(val) {
    //Alınan ürün stok numarası
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Model.RevisionStokNo = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setBuyStokNumber(val);
  }

  function changeGiveStokProductDesc(val) {
    //verilen ürün açıklama
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.GiveProductDesc = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setGiveProductDesc(val);
  }

  function changeBuyStokProductDesc(val) {
    //Alınan ürün açıklama
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Model.ProductDesc = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setBuyProductDesc(val);
  }

  function changeGiveProductPrice(val) {
    //verilen prekande fiyat
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    if (invoiceData[0]?.Consumer?.UpTypeId === 1) {
      tempData[0].Exchange.ReceivedProductPrice = val;
      setBuyProductPrice(val);
    } else if (invoiceData[0]?.Consumer?.UpTypeId === 3) {
      let fark = val - totalProductPrice;
      if (fark < 0) {
        fark = 0;
      }
      tempData[0].Exchange.ReceivedProductPrice = fark;
      setBuyProductPrice(fark);
    } else if (
      invoiceData[0]?.Consumer?.UpTypeId === 6 &&
      invoiceData[0]?.Consumer?.ConsumerType === 1
    ) {
      let fark =
        val - totalProductPrice + parseFloat(subModelDifferenceAmount);
      if (fark < 0) {
        fark = 0;
      }
      tempData[0].Exchange.ReceivedProductPrice = fark;
      setBuyProductPrice(fark);
    } else {
      tempData[0].Exchange.GiveProductPrice = val;
      setGiveProductPrice(val);
    }
    tempData[0].Exchange.GiveProductPrice = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setGiveProductPrice(val);
    // var tempData = JSON.parse(JSON.stringify(invoiceData))
    // tempData[0].Exchange.GiveProductPrice = val
    // dispatch(generalFollowActions.updateNewData(tempData))
    // setGiveProductPrice(val)
  }

  function changeBuyProductPrice(val) {
    //alınan ürün prekande fiyat
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.ReceivedProductPrice = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setBuyProductPrice(val);
  }

  function changeGiveStokTotal(val) {
    //verilen ürün stok adet
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.stokTotal = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setStockTotal(val);
  }

  function buyProductPriceCalculate(val) {
    // Verilen Perakende Fiyat Hesaplama
    if (invoiceData[0]?.Consumer?.UpTypeId === 1) {
      changeBuyProductPrice(val);
    }
    if (invoiceData[0]?.Consumer?.UpTypeId === 3) {
      let fark = val - totalProductPrice;
      changeBuyProductPrice(fark);
    }
    changeGiveProductPrice(val);
  }

  // function buyProductPriceCalculateButton() { // Sorgula Butonunun Arkasında Verilen Perakende Fiyat Hesaplama
  //   let value = giveProductPrice
  //   buyProductPriceCalculate(value);
  // }

  function diffModelStokControl() {
    if (giveStokNumber.length <= 0) {
      return;
    }
    const data = [
      giveStokNumber?.trim()?.toString() || null,
      invoiceData[0]?.Model?.RevisionStokNo?.toString() || null,
      null,
      null,
      invoiceData[0]?.Exchange?.Service
    ];
    setLoading(true);
    axios
      .get(
        `/GetProductPrice/${data[0]}/${data[1]}/${data[2]}/${data[3]}/${data[4]}`,
        {}
      )
      .then((response) => {
        var tempData = JSON.parse(JSON.stringify(invoiceData));
        tempData[0].Exchange.GiveProductDesc =
          response.data.GiveProductStokDesc;
        tempData[0].Exchange.GiveProductPrice =
          response.data.GiveProductStokPrice;
        tempData[0].Exchange.GiveProductStokNumber =
          response.data.GiveProductStokNumber;
        tempData[0].Exchange.ReceivedProductPrice =
          response.data.ProductBuyStockPrice;

        dispatch(generalFollowActions.updateNewData(tempData));
        setGiveProductDesc(response.data.GiveProductStokDesc);
        setGiveStokNumber(response.data.GiveProductStokNumber);
        setGiveProductPrice(response.data.GiveProductStokPrice);
        setStockTotal(response.data.StoreStockTotal);
        setBuyStokNumber(response.data.ProductGetStockNumber);
        setStockRegionName(response.data.StoreStockName);
        setBuyProductDesc(response.data.ProductGetStockDesc);
        setBuyProductPrice(response.data.ProductBuyStockPrice);

        const currentData = tempData;

        //MHTU-3836 Ürün Açıklama Güncellenmesi içindir.
        var giveProductDescription = tempData[0].Exchange.GiveProductDesc;
        var giveProductStokNum = tempData[0].Exchange.GiveProductStokNumber;
        var giveProductPrice = tempData[0].Exchange.GiveProductPrice;

        let fark = 0;
        var buyProductPrice = 0;

        if (invoiceData[0]?.Consumer?.UpTypeId === 1) {
          buyProductPrice = response.data.GiveProductStokPrice;
          setBuyProductPrice(response.data.GiveProductStokPrice);
        }
        if (invoiceData[0]?.Consumer?.UpTypeId === 3) {
          fark = response.data.GiveProductStokPrice - totalProductPrice;
          if (fark < 0) {
            fark = 0;
          }
          buyProductPrice = fark;
          setBuyProductPrice(fark);
        }
        if (
          invoiceData[0]?.Consumer?.UpTypeId === 6 &&
          invoiceData[0]?.Consumer?.ConsumerType === 1
        ) {
          let fark =
            response.data.GiveProductStokPrice -
            totalProductPrice + parseFloat(subModelDifferenceAmount);
          if (fark < 0) {
            fark = 0;
          }
          buyProductPrice = fark;
          setBuyProductPrice(fark);
        }
        setLoading(false);

        //MHTU-3836 BAŞLANGIÇ
        //Arçelik-Beko-Altus-Grundig-Leisure
        const brands = [3, 4, 18, 28, 43];
        if (
          giveProductStokNum &&
          invoiceData[0]?.Exchange?.IsManagerAppliedDifferenBrand === 0 &&
          brands.includes(invoiceData[0]?.Apply.BrandCode)
        ) {
          dispatch(getBrandCodeOfProduct(giveProductStokNum.toString())).then(
            (response) => {
              if (response.payload?.length > 0) {
                var BrandCode = response.payload[0]?.MARKA_KODU;
                if (BrandCode !== invoiceData[0]?.Apply.BrandCode) {
                  confirmAlert({
                    message:
                      'Farklı Marka Ürün Seçtiniz! Yönetici Onayı Alarak Devam Edebilirsiniz. Devam Etmek istiyor musunuz?',
                    buttons: [
                      {
                        label: 'Devam Et',
                        onClick: () => {
                          var tempData = JSON.parse(
                            JSON.stringify(invoiceData)
                          );
                          tempData[0].Exchange.DifferentBrandManagerControl = 1;
                          tempData[0].Exchange.GiveProductStokNumber =
                            giveProductStokNum;
                          tempData[0].Exchange.GiveProductDesc =
                            giveProductDescription;
                          tempData[0].Exchange.GiveProductPrice =
                            giveProductPrice;
                          tempData[0].Exchange.ReceivedProductPrice =
                            buyProductPrice;
                          dispatch(
                            generalFollowActions.updateNewData(tempData)
                          );
                        }
                      },
                      {
                        label: 'Vazgeç',
                        onClick: () => {
                          var tempData = JSON.parse(
                            JSON.stringify(invoiceData)
                          );
                          tempData[0].Exchange.DifferentBrandManagerControl = 0;
                          tempData[0].Exchange.GiveProductStokNumber = null;
                          tempData[0].Exchange.GiveProductDesc = null;
                          tempData[0].Exchange.GiveProductPrice = null;
                          tempData[0].Exchange.ReceivedProductPrice =
                            buyProductPrice;
                          setGiveStokNumber([]);
                          setGiveProductDesc([]);
                          setGiveProductPrice(0);
                          dispatch(
                            generalFollowActions.updateNewData(tempData)
                          );
                        }
                      }
                    ],
                    closeOnEscape: false,
                    closeOnClickOutside: false
                  });
                } else {
                  var tempData = JSON.parse(JSON.stringify(currentData));
                  tempData[0].Exchange.DifferentBrandManagerControl = 0;
                  tempData[0].Exchange.ReceivedProductPrice = buyProductPrice;
                  tempData[0].Exchange.GiveProductStokNumber =
                    giveProductStokNum;
                  tempData[0].Exchange.GiveProductDesc = giveProductDescription;
                  tempData[0].Exchange.GiveProductPrice = giveProductPrice;
                  dispatch(generalFollowActions.updateNewData(tempData));
                }
              }
            }
          );
        }
        //MHTU-3836 BİTİŞ

        if (response.data.isProductControl == 1) {
          InfoAlert(
            'Değişim Grubunda Üründe şu anda kalite problemi mevcut...',
            'info',
            'Bilgilendirme'
          );
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  return (
    <>
      <AlertAndLoading
        Message={responseMessage}
        open={uyari}
        AlertType={alertType}
        onClose={uyariKapat}
        loading={loading}
      />
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}
      >
        <AccordionSummary
          classes={{ content: classes.content }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            sx={{
              width: '100%',
              fontWeight: 'bold',
              color: 'red',
              fontSize: '14px'
            }}
            variant="subtitle1"
          >
            Ürün Bilgileri
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelsContent}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Tooltip title="Alınan Ürün">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Alınan Ürün"
                    size="small"
                    value={BuyStokNumber ? BuyStokNumber : ''}
                    fullWidth
                    disabled
                    variant="outlined"
                    onChange={(event) => {
                      changeBuyStokNumber(event.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setBuyStokNumber('');
                            }}
                          >
                            <CloseIcon size="small" sx={{ color: '#424242' }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Tooltip title="Verilen Ürün">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Verilen Ürün"
                    size="small"
                    value={giveStokNumber ? giveStokNumber : ''}
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                      changeGiveStokNumber(event.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setGiveStokNumber('');
                            }}
                          >
                            <CloseIcon size="small" sx={{ color: '#424242' }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Tooltip title="Açıklama">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Açıklama"
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={buyProductDesc ? buyProductDesc : ''}
                    onChange={(event) => {
                      changeBuyStokProductDesc(event.target.value);
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Tooltip title="Açıklama">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Açıklama"
                    size="small"
                    value={giveProductDesc ? giveProductDesc : ''}
                    onChange={(event) => {
                      changeGiveStokProductDesc(event.target.value);
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <TextField
                  label="Alınan Perakende Fiyat"
                  value={buyProductPrice}
                  onChange={(event) => {
                    changeBuyProductPrice(event.target.value);
                  }}
                  name="numberformat"
                  id="formatted-numberformat-input"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <TextField
                  label="Verilen Perakende Fiyat"
                  value={giveProductPrice}
                  onChange={(event) => {
                    changeGiveProductPrice(event.target.value);
                  }}
                  name="numberformat"
                  id="formatted-numberformat-input"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                  variant="outlined"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Tooltip title="Alınan Ürün Stok Adedi">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Stok Adedi"
                    size="small"
                    value={buyStockTotal}
                    fullWidth
                    disabled
                    variant="outlined"
                    onChange={(event) => {
                      setBuyStockTotal(event.target.value);
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Tooltip title="Verilen Ürün Depo Adı">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Depo Adı"
                    size="small"
                    value={stockRegionName}
                    disabled
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                      setStockRegionName(event.target.value);
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Tooltip title="Stok Adedi">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Stok Adedi"
                    size="small"
                    fullWidth
                    variant="outlined"
                    disabled
                    value={stockTotal}
                    onChange={(event) => {
                      changeGiveStokTotal(event.target.value);
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Tooltip title="Sorgula">
                  <IconButton
                    className={classes.action}
                    size="large"
                    key="close"
                    onClick={() => {
                      diffModelStokControl();
                    }}
                  >
                    <SavedSearchIcon sx={{ color: '#FF9800' }} size="large" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid container spacing={2} style={{ paddingTop: 5 }}></Grid>
    </>
  );
}

export default DifferentModelSuggest;
