import { Backdrop, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useOktaAuth } from '@okta/okta-react';
import axios2 from 'axios';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect } from 'react';
import PWAPrompt from 'react-ios-pwa-prompt';
import { renderRoutes } from 'react-router-config';
import { Switch, useHistory } from 'react-router-dom';
import routes from 'src/routes';
import axios from 'src/utils/axios';
import { useDispatch } from 'react-redux';
import { getUser, setIpAddress, setRoleSession, setSessionToken, setUserSession } from 'src/utils/Common';
import Topbar from './Topbar';



const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }
}));

function Auth() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();
  const [Loading, setLoading] = React.useState(true);
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };
  const history = useHistory();
  const { oktaAuth, authState } = useOktaAuth();

  navigator.geolocation.getCurrentPosition(success, error, options);
  function success(pos) {
    // var crd = pos.coords;
  }

  function error(err) {
    console.log('Geolocation error:' + err.message);
  }

  useEffect(() => {
    if (authState === null) {
      const fromUri = history.createHref(history.location);
      oktaAuth.signInWithRedirect(fromUri);
    }
    if (authState !== null && authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        if (info === undefined) {
          const fromUri = history.createHref(history.location);
          oktaAuth.signInWithRedirect(fromUri);
        }
        const UserNames = info?.preferred_username.split('@');
        const dataUser = [{
          UserName: UserNames[0],
          TerminalId: '1',
          VersionNr: process.env.REACT_APP_VERSION,
          TerminalIp: '1',
          LanguageId: '1'
        }];
        const configUser = {
          headers: {
            'Content-Type': 'application/json',
            IpAddress: 1,
            'max-age': 86400,
            'X-Frame-Options': 'DENY',
            'X-XSS-Protection': '1; mode=block',
            accessToken: authState.accessToken.accessToken,
            Authorization: `Bearer ${authState.accessToken.accessToken}`
          }
        };

        const Address = `${process.env.REACT_APP_API_URL}/GetUser`;
        const Address1 = `${process.env.REACT_APP_API_URL}/SetUserLog`;
        axios2.post(Address1, { UserName: dataUser[0].UserName, Status: 1 }, configUser)
        axios2.post(Address, dataUser, configUser)
          .then((response) => {
            setSessionToken(authState.accessToken.accessToken, response.data[0].SessionTokenWs || '');
            setIpAddress(1);
            setUserSession(authState.accessToken.accessToken, response.data[0].SessionTokenWs || '', response.data[0]);
            setLoading(false);
            axios.get("/GetUserRoles/" + dataUser[0].UserName, {
              headers: { 'Content-Type': 'application/json' }
            }).then(response1 => {
              setLoading(false);
              setRoleSession(response1?.data[0]);
              history.push('/Kullanici');
            });

          }).catch((error) => {
            setLoading(false);
            history.push('/Unauthorized');
          });
      });
    }
    if (authState !== null && !authState.isAuthenticated) {
      const fromUri = history.createHref(history.location);
      oktaAuth.signInWithRedirect(fromUri);
    }
  }, []);

  return (
    <>
      <Topbar style={{ backgroundColor: "#424242" }} />
      <div className={classes.container}>
        <div className={classes.content}>
          {Loading &&
            <Backdrop className={classes.backdrop} open={Loading}>
              <Grid
                container
                alignItems="center"
                justify="center"
                alignContent="center"
              >
                <Grid item xs={12} align="center">
                  <CircularProgress color="inherit" />
                </Grid>
                <Grid item xs={12} align="center">
                  <Typography variant="body2" style={{ color: "#FF9800" }}>
                    <b>Yönlendiriliyorsunuz...</b>
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
          }
          <PWAPrompt
            promptOnVisit={1}
            timesToShow={3}
            copyTitle={'Ana Ekrana Ekle'}
            copyBody={
              'Mobil cihazınızın tarayıcısı üzerinden erişebileceğiniz Artemis uygulaması kolayca indirilebilir ve cihazınızda yer kaplamaz.'
            }
            copyShareButtonLabel={
              "1) Alt tarafta bulunan 'Paylaş' ikonuna dokunun"
            }
            copyAddHomeButtonLabel={
              "2) Açılan menüde bulunan 'Ana Ekrana Ekle'ye tıklayın"
            }
            copyClosePrompt={'Kapat'}
            permanentlyHideOnDismiss={false}
          />
          <Suspense fallback={null}>
            <Switch>{renderRoutes(routes)}</Switch>
          </Suspense>
        </div>
      </div>
    </>
  );
}

Auth.propTypes = {
  route: PropTypes.object
};

export default Auth;
