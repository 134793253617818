import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { Backdrop, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import { getTailUsers } from 'src/store/slices/assigmentSlice';
import { getUserAssigned, transferJobTail } from 'src/store/slices/controlApprovalSlice';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }
}));



function Overview() {
  const classes = useStyles();
  const [uyari, setUyari] = React.useState(false);
  const [loading, setLoading] = React.useState(false)

  const dispatch = useDispatch();
  const [searchName, setSearchName] = React.useState('')
  const [userAssigned, setUserAssigned] = React.useState([])
  const [selectedUser, setSelectedUser] = React.useState(-1)
  const [rows, setRows] = React.useState([])
  const [tailUsers, setTailUsers] = React.useState([])
  const [transferTo, setTransferTo] = React.useState('')
  const [selectedTail, setSelectedTail] = React.useState([]);
  const [transferDialogOpen, setTransferDialogOpen] = React.useState(false);

  const [responseMessage, setresponseMessage] = React.useState({
    ErrorCode: '0',
    ErrorDescription: ('success_message')
  });

  useEffect(() => {
    dispatch(getUserAssigned()).then(response => {
      setUserAssigned(response.payload)
    })
    setLoading(true)
    dispatch(getTailUsers()).then(response => {
      setTailUsers(response.payload)
      setLoading(false)

    })

  }, [dispatch]);


  const handleUserClick = (row, index) => {
    setSelectedTail([])
    if (selectedUser > -1 && selectedUser == index) {
      setSelectedUser(-1);
      setRows([])
      return
    }
    setSelectedUser(index);

    const formattedData = row.map((row) => {
      if (!row.WaitingDsc) {
        row.WaitingTotalTime = '0';
      }
      return row;
    })
    setRows(formattedData);
  };

  const columns = [
    // { field: 'ApplicationNumber', headerName: 'ApplicationNumber', flex: 1, },
    { field: 'ReceiptNumber', headerName: 'Fiş Numarası', width: 120 },
    { field: 'StatusDsc', headerName: 'Durum', width: 350 },
    { field: 'TotalTime', headerName: 'Kuyruğa Atanma Süresi', width: 200 },
    { field: 'AssignedUserTime', headerName: 'Agenta Atanma Süresi', width: 200 },
    { field: 'WaitingTotalTime', headerName: 'Bekleme Nedeni Süresi', width: 200 },
    { field: 'WaitingDsc', headerName: 'Bekleme Nedeni', width: 500 },
  ];

  const handleUnassign = () => {
    confirmAlert({
      message: ('Atamayı kaldırmak istediğine emin misin?'),
      buttons: [
        {
          label: ('Evet'),
          onClick: () => transferToUser("")
        },
        {
          label: ('Hayır'),
          onClick: () => { }
        }
      ]
    });
  }
  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  const transferToUser = (transferTo) => {
    if (!selectedTail) return
    var temp = []
    selectedTail.map(r => {
      temp.push(rows.find(i => i.id == r))
    })
    const data = {
      Users: transferTo,
      ApplicationNumbers: temp.map(i => { return i.ApplicationNumber }).join(','),
      ReceiptNumbers: temp.map(i => { return i.ReceiptNumber }).join(','),
      ReceiptStatus: temp.map(i => { return i.ReceiptStatus }).join(','),
    }
    dispatch(transferJobTail(data)).then(response => {
      clearForm()
      setTransferDialogOpen(false)
      setresponseMessage({
        ErrorCode: response.payload.ErrorCode,
        ErrorDescription: response.payload.ErrorDescription
      });
      // setUyariTip('error');
      setUyari(true);
      dispatch(getUserAssigned()).then(response => {
        setUserAssigned(response.payload)
      })
    })
  }
  function clearForm() {
    setRows([])
    setSelectedUser('')
    setTransferTo([])
  }
  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={8}>
        <Snackbar open={uyari} autoHideDuration={6000} onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          {responseMessage.ErrorCode == '1000' ?
            <Alert onClose={uyariKapat} severity="error"  >
              {responseMessage.ErrorDescription}
            </Alert> :
            <Alert onClose={uyariKapat} severity="success"  >
              {responseMessage.ErrorDescription}
            </Alert>
          }
        </Snackbar>
      </Stack>
      {/* <Container maxWidth="xl"> */}
      <Card className={classes.generalCards}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              Kontrol ve Onay
            </Grid>

            <Typography sx={{ padding: '1%', width: '100%', fontWeight: 'bold' }} variant="subtitle1"></Typography>
            <Grid item xs={4} md={4}>
              <nav aria-label="secondary mailbox folders">

                <TextField
                  id="standard-basic"
                  label="Ara"
                  value={searchName}
                  onChange={(event) => {
                    setSearchName(event.target.value)
                    handleUserClick([], -1)
                  }
                  } fullWidth size="small" />
                <List style={{ height: 400, width: '100%', overflowY: 'auto' }}>
                  {userAssigned?.filter(i => i.UserName?.toLowerCase().includes(searchName.toLowerCase()))?.map((row, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton selected={selectedUser === index}
                        onClick={() => handleUserClick(row.Applications, index)}>
                        <ListItemText primary={row.UserName} />
                        <Badge badgeContent={row.Count} color="success">
                          <WorkOutlineIcon sx={{ color: '#FF9800' }} />
                        </Badge>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </nav>
            </Grid>
            <Grid item xs={8} md={8}>
              <div style={{ height: 500, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  onSelectionModelChange={(selection) => {
                    setSelectedTail(selection)
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={8} md={8}></Grid>

            <Grid item xs={4} md={4} alignContent="right" alignSelf="right" alignItems="right" textAlign="right" >
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button
                  size="small"
                  variant="outlined"
                  disabled={selectedUser > -1 ? false : true}
                  onClick={() => handleUnassign()}>Boşa Çıkar</Button>
                <Button
                  disabled={selectedTail.length > 0 ? false : true}
                  size="small"
                  variant="contained"
                  onClick={() => setTransferDialogOpen(true)}>Seçilileri Aktar</Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Dialog
        onClose={() => setTransferDialogOpen(false)}
        open={transferDialogOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle style={{ alignSelf: "center" }} >Başkasına Aktar</DialogTitle>
        <DialogContent style={{ padding: 10, alignSelf: "center" }}>

          <Autocomplete
            id="combo-box-demo"
            options={tailUsers}
            getOptionLabel={(option) => option.ADSOYAD ? option.ADSOYAD + ' (' + option.SICILNO + ')' : ''}
            sx={{ width: 300 }}
            value={transferTo}
            onChange={(event, value) => { setTransferTo(value) }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.ID}>
                  {option.ADSOYAD ? option.ADSOYAD + ' (' + option.SICILNO + ')' : ''}
                </li>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Aktarılacak Kişi" />}
          />

          <Button
            fullWidth
            variant="contained"
            style={{ marginTop: 20, marginBottom: 20 }}
            onClick={() => transferToUser(transferTo?.ID.toString())}
          >
            Aktar
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Overview;