import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  TextField
} from '@mui/material';
import {
  getSubStatus,
  getUserTypeBasedSubStatusCounts
} from 'src/store/slices/generalMonitoringSlice';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { getStatusItems } from 'src/store/slices/commonSlice';

const useStyles = makeStyles((theme) => ({
  firstCell: {
    borderLeft: 'none'
  }
}));

function UserTypeBasedSubStatusChart() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [mainData, setMainData] = React.useState([]);
  const [allSubStatus, setAllSubStatus] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const statusData = useSelector((state) => state.commonSlice.statuses);
  const [selectedStatus, setStatus] = React.useState([]);

  const allCustomerTypes = ['Bayi', 'Müşteri', 'Tüzel Kişi-Şirket'];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    dispatch(getStatusItems());
    GetUserTypeBasedSubStatusCounts('0');
    GetAllSubStatus();
  }, []);

  const GetUserTypeBasedSubStatusCounts = (status) => {
    setLoading(true);
    dispatch(getUserTypeBasedSubStatusCounts(status))
      .then((response) => {
        const data = Array.isArray(response.payload) ? response.payload : [];
        setMainData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetAllSubStatus = () => {
    dispatch(getSubStatus()).then((response) => {
      const data = response.payload || [];
      setAllSubStatus(data);
    });
  };

  // Transform data into matrix format
  const matrixData = allCustomerTypes.map((musteriTipi) => {
    return allSubStatus.map((araDurum) => {
      const entry = mainData.find(
        (item) =>
          item.MUSTERI_TIPI_ACIKLAMA === musteriTipi &&
          item.ARA_DURUM_ACIKLAMA === araDurum.ACIKLAMA
      );
      return entry ? entry.ADET : 0;
    });
  });

  const series = allCustomerTypes.map((musteriTipi, index) => ({
    name: musteriTipi,
    data: matrixData[index]
  }));

  const options = {
    chart: {
      type: 'heatmap'
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            { from: 0, to: 0, color: '#3498DB', name: 'Yok' }, // Canlı Mavi
            { from: 1, to: 50, color: '#1ABC9C', name: 'Çok Düşük' }, // Canlı Yeşil
            { from: 51, to: 500, color: '#F1C40F', name: 'Düşük' }, // Parlak Sarı
            { from: 501, to: 5000, color: '#E67E22', name: 'Orta' }, // Canlı Turuncu
            { from: 5001, to: 20000, color: '#E74C3C', name: 'Yüksek' }, // Canlı Kırmızı
            { from: 20001, to: 100000, color: '#9B59B6', name: 'Çok Yüksek' } // Canlı Mor
          ]
        },
        hover: {
          color: undefined // Hover rengini kaldırmak için bu özelliği kullanabilirsiniz
        }
      }
    },
    stroke: {
      width: 1
    },
    dataLabels: {
      enabled: true
    },
    xaxis: {
      categories: allSubStatus.map((status) => status.ACIKLAMA),
      labels: {
        rotate: -45
      }
    }
  };

  const handleSearchData = () => {
    let statusList = [];
    selectedStatus.map((row) => {
      statusList.push(row.Id);
    });

    if (statusList.length <= 0) {
      return;
    }

    GetUserTypeBasedSubStatusCounts(statusList.toString());
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            titleTypographyProps={{
              variant: 'body1',
              fontWeight: 'bold'
            }}
            title="Müşteri Tipi Bazında Ara Durum Dağılımları"
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={8} />
              <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  id="multiple-limit-tags"
                  disableCloseOnSelect
                  options={statusData || []}
                  getOptionLabel={(option) => option.Dsc}
                  value={selectedStatus || 0}
                  size="small"
                  fullWidth
                  onChange={(event, val) => {
                    setStatus(val);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.Dsc}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Durum" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="medium"
                  onClick={() => {
                    handleSearchData();
                  }}
                  sx={{
                    color: '#FF9800',
                    backgroundColor: '#424242',
                    paddingBottom: 1
                  }}
                >
                  Ara
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            </Grid>
            <Grid item xs={12}>
              {loading ? (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    {Array.from(new Array(3)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width="100%"
                        height={150}
                        sx={{ margin: 1 }}
                      />
                    ))}
                  </Grid>
                </>
              ) : (
                <Chart
                  options={options}
                  series={series}
                  type="heatmap"
                  height={500}
                />
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default UserTypeBasedSubStatusChart;
