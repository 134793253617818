import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import Chart from 'react-apexcharts';
import { useDispatch } from 'react-redux';
import {
  getAgentLoginLogoutInformations,
  getAllRoles
} from 'src/store/slices/generalMonitoringSlice';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function LoginLogoutInformationChart() {
  const dispatch = useDispatch();

  const [mainData, setMainData] = React.useState([]);
  const [roleTypes, setRoleTypes] = React.useState([]);
  const [selectedRoleType, setSelectedRoleType] = React.useState([]);

  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    GetAllRoles();
    GetAgentLoginLogoutInformations('0');
  }, []);

  const GetAgentLoginLogoutInformations = (RoleId) => {
    setLoading(true);
    dispatch(getAgentLoginLogoutInformations(RoleId))
      .then((response) => {
        const data = Array.isArray(response.payload) ? response.payload : [];
        setMainData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetAllRoles = () => {
    setLoading(true);
    dispatch(getAllRoles())
      .then((response) => {
        const data = response.payload || [];
        setRoleTypes(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSearchData = () => {
    let roleTypeList = [];
    selectedRoleType.map((row) => {
      roleTypeList.push(row.Id);
    });

    if (roleTypeList.length <= 0) {
      return;
    }

    GetAgentLoginLogoutInformations(roleTypeList.toString());
  };

  const chartData = {
    series: [
      {
        name: 'Kullanıcı Verileri',
        data: [
          mainData[0]?.KULLANICISAYISI,
          mainData[0]?.GUNLUKAKTIFADET,
          mainData[0]?.ANLIKAKTIFADET
        ]
      }
    ],
    options: {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%'
        }
      },
      xaxis: {
        categories: [
          'Kullanıcı Sayısı',
          'Günlük Aktif Adet',
          'Anlık Aktif Adet'
        ],
        title: {
          text: 'Veri Türü',
          style: {
            fontSize: '0.8rem',
            fontWeight: 'bold'
          }
        }
      },
      yaxis: {
        title: {
          text: 'Değer',
          style: {
            fontSize: '0.8rem',
            fontWeight: 'bold'
          }
        },
        labels: {
          style: {
            fontSize: '0.8rem'
          }
        }
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (value) => `${value}`
        }
      },
      title: {
        text: 'Kullanıcı İstatistikleri',
        align: 'center'
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card container spacing={2}>
          <CardHeader
            titleTypographyProps={{
              variant: 'body1',
              fontWeight: 'bold'
            }}
            title="Günlük Giriş Çıkış Bilgileri"
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={6} />
              <Grid item xs={12} sm={4} md={3}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  id="multiple-limit-tags"
                  disableCloseOnSelect
                  options={roleTypes || []}
                  getOptionLabel={(option) => option.Dsc}
                  value={selectedRoleType || 0}
                  size="small"
                  fullWidth
                  onChange={(event, val) => {
                    setSelectedRoleType(val);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.Dsc}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Profil Tipleri"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="medium"
                  onClick={() => {
                    handleSearchData();
                  }}
                  sx={{
                    color: '#FF9800',
                    backgroundColor: '#424242',
                    paddingBottom: 1
                  }}
                >
                  Ara
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            </Grid>
            <Grid item xs={12}>
              {loading ? (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    {Array.from(new Array(3)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width="100%"
                        height={150}
                        sx={{ margin: 1 }}
                      />
                    ))}
                  </Grid>
                </>
              ) : (
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  width={'100%'}
                  height={450}
                />
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default LoginLogoutInformationChart;
