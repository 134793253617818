import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};
export const setExchangeOfficeSaveSubStatus = createAsyncThunk('/SetExchangeOfficeSaveSubStatus', async () => {
  const response = await axios.post('/SetExchangeOfficeSaveSubStatus', dataConfig);
  return response.data;
});

const exchangeOfficeProcessSlice = createSlice({
  name: 'exchangeOfficeProcess',
  initialState: {
    loading: '',
    error: '',
    saveSubstatus: {}

  },
  reducers: {
  },
  extraReducers: {
    [setExchangeOfficeSaveSubStatus.pending]: (state) => {
      state.loading = true;
    },
    [setExchangeOfficeSaveSubStatus.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [setExchangeOfficeSaveSubStatus.fulfilled]: (state, action) => {
      state.saveSubstatus = action.payload;
      state.loading = false;
    }
  }
});
export const exchangeOfficeProcessActions = exchangeOfficeProcessSlice.actions;
export default exchangeOfficeProcessSlice;