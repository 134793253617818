export const isProduction = process.env.REACT_APP_CUSTOM_NODE_ENV === 'production';
export const isPreProduction = process.env.REACT_APP_CUSTOM_NODE_ENV === 'preproduction';
export const isDevelopment = process.env.REACT_APP_CUSTOM_NODE_ENV === 'development';
export const oktaIssuer = process.env.REACT_APP_OKTA_ISSUER;
export const oktaClientId = process.env.REACT_APP_OKTA_CLIENT_ID;
export const baseURL = process.env.REACT_APP_BASE_URL;
export const apiURL = process.env.REACT_APP_API_URL;
export const aronURL = process.env.REACT_APP_ARON_URL;
export const YsPortalURL = process.env.REACT_APP_YSPORTAL_URL;
export const pushServerPublicKey = process.env.REACT_APP_PUSH_SERVER_PUBLIC_KEY;
export const googleTrackingId = process.env.REACT_APP_GOOGLE_TRACKING_ID;
export const appDefaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
export const appLogo = '/images/logos/logo--white.svg';
export const defaultUserPicture = '';
