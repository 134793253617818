export const Types = {
  SET_ALERT: 'SET_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT',
}

export const setAlert = (message) => ({
  type: Types.SET_ALERT,
  message,
});

export const closeAlert = () => ({
  type: Types.CLOSE_ALERT,
});

