import { Stack, Snackbar, Alert, AlertTitle, CircularProgress, CircularProgressClassKey, Backdrop } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import React from 'react';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
}));
function AlertAndLoading({ Message, AlertType, open, onClose, loading }) {

  const classes = useStyles();

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={8} >
        <Snackbar sx={{ width: '100%', position: 'center', zIndex: 'top' }} open={open} autoHideDuration={6000} onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Alert severity={AlertType.ErrorType} variant="filled"
            sx={{
              minHeight: 75,
              alignItems: "center",
              verticalAlign: "center",
              minWidth: '30%'
            }}
            onClose={onClose}
          >
            <strong>{(AlertType.ErrorTitle)}</strong>
            <AlertTitle>{(Message)}</AlertTitle>
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}
AlertAndLoading.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default AlertAndLoading;
