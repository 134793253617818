import axios from 'axios';
import { getToken, getTokenWs, getIpAddress } from './Common'
import { getUser, handleLogout } from 'src/utils/Common'


const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

// instance.defaults.timeout = 30000;

instance.interceptors.request.use(
  function (config) {
    //
    var AuthToken = getToken();

    AuthToken = '';
    if (AuthToken === '' || AuthToken === undefined || AuthToken === null) {
      AuthToken = getToken();
    }

    let AuthTokenWs = getTokenWs();
    let IpAddress = getIpAddress();
    let user = getUser();
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${AuthToken}`,
      'SessionToken': AuthToken,
      'SessionTokenWs': AuthTokenWs,
      'IpAddress': IpAddress || '',
      'Auth-UserId': user ? user.UserId : '',
      'max-age': 86400,
      'X-Frame-Options': 'DENY',
      'X-XSS-Protection': '1;mode=block',
      'Access-Control-Allow-Origin': '*'
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response
      && error.response.data
      && error.response.data.error === '401 Unauthorized'
    ) {
      alert(
        'Başka bir cihazdan giriş yaptığınız için oturumunuz sonlandırıldı!..'
      );
      handleLogout();
    }
    return Promise.reject(error);
  }
);

export default instance;
