import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};

export const productGroupStatusChangeChart = createAsyncThunk(
  '/ProductGroupStatusChangeChart',
  async (params) => {
    const response = await axios.get('/ProductGroupStatusChangeChart');
    return response.data;
  }
);

export const getUserTypeBasedSubStatusCounts = createAsyncThunk(
  '/GetUserTypeBasedSubStatusCounts',
  async (params) => {
    const response = await axios.get(
      `/GetUserTypeBasedSubStatusCounts/${params}`
    );
    return response.data;
  }
);

export const getSubStatus = createAsyncThunk(
  '/GetSubStatus',
  async (params) => {
    const response = await axios.get('/GetSubStatus');
    return response.data;
  }
);

export const getExchangeReceiptDayDistributions = createAsyncThunk(
  '/GetExchangeReceiptDayDistributions',
  async (params) => {
    const response = await axios.get('/GetExchangeReceiptDayDistributions');
    return response.data;
  }
);

export const getTodayRefundReceiptAndTotalPrice = createAsyncThunk(
  '/GetTodayRefundReceiptAndTotalPrice',
  async (params) => {
    const response = await axios.get('/GetTodayRefundReceiptAndTotalPrice');
    return response.data;
  }
);

export const getExchangeReceiptsBasedWarrantyType = createAsyncThunk(
  '/GetExchangeReceiptsBasedWarrantyType',
  async (params) => {
    const response = await axios.get(
      `/GetExchangeReceiptsBasedWarrantyType/${params}`
    );
    return response.data;
  }
);

export const getAllOpenExchangeReceipts = createAsyncThunk(
  '/GetAllOpenExchangeReceipts',
  async (params) => {
    const response = await axios.get('/GetAllOpenExchangeReceipts');
    return response.data;
  }
);

export const getAgentLoginLogoutInformations = createAsyncThunk(
  '/GetAgentLoginLogoutInformations',
  async (params) => {
    const response = await axios.get(
      `/GetAgentLoginLogoutInformations/${params}`
    );
    return response.data;
  }
);

export const getAssignedJobPoolInformations = createAsyncThunk(
  '/GetAssignedJobPoolInformations',
  async (params) => {
    const response = await axios.get(
      `/GetAssignedJobPoolInformations/${params}`
    );
    return response.data;
  }
);

export const getAssembledReceiptOfExchangeReceipts = createAsyncThunk(
  '/GetAssembledReceiptOfExchangeReceipts',
  async (params) => {
    const response = await axios.get('/GetAssembledReceiptOfExchangeReceipts');
    return response.data;
  }
);

export const getJobTailTypes = createAsyncThunk(
  '/GetJobTailTypes',
  async (params) => {
    const response = await axios.get('/GetJobTailTypes');
    return response.data;
  }
);

export const getAllRoles = createAsyncThunk('/GetAllRoles', async (params) => {
  const response = await axios.get('/GetAllRoles');
  return response.data;
});
