import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ExchangeOfficeProcess from './ExchangeOfficeProcess';
import SolutionCenterProcess from './SolutionCenterProcess';
import { getUserRole } from 'src/utils/Common';
import { getUser } from 'src/utils/Common';
import { useSelector } from 'react-redux';
import { getTotalDayOfExchangeVoucher } from 'src/store/slices/generalFollowSlice';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  div: {
    fontWeight: 'bold',
    fontSize: '14px',
    width: '100%',
    color: 'red'
  }
}));

function ApprovalProcess() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();
  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);
  const receiptStatus = useSelector((state) => state.generalFollowSlice.invoiceDetail)[0]?.Exchange?.Status;

  const [expandedConfirm, setExpandedConfirm] = React.useState(true);
  const [totalDayOfExchangeVoucher, setTotalDayOfExchangeVoucher] = React.useState([]);

  const userRole = getUserRole().Component

  // useEffect(() => {
  //   calculateTotalDayOfExchangeVoucher(invoiceData[0]?.Apply?.ReceiptNumber)
  // }, [])


  // const calculateTotalDayOfExchangeVoucher = (ReceiptNumber) => {
  //   dispatch(getTotalDayOfExchangeVoucher(ReceiptNumber)).then((response) => {
  //     if (response.payload?.length > 0) {
  //       setTotalDayOfExchangeVoucher(response.payload[0].TOPLAM_GUN_SAYISI);
  //     }
  //   })
  // }

  return (
    <>
      <Accordion expanded={expandedConfirm} sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography className={classes.div} variant="subtitle1">
                Onay İptal İşlemleri
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              {
                (invoiceData[0]?.Consumer.UpTypeId == 7 && invoiceData[0]?.Exchange.GPSNO != 0 && receiptStatus === 31) ?
                  <Typography className={classes.div} variant="subtitle1">
                    {'Değişim Çeki ' + invoiceData[0]?.Exchange?.TotalDayOfWebPoint + ' Gün Önce Tanımlanmıştır.'}
                  </Typography>
                  :
                  null
              }
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails >
          {<SolutionCenterProcess />}
          {(userRole?.filter(i => i.COMPONENT_NAME == "ExchangeOfficeProcess").length > 0) ? <ExchangeOfficeProcess /> : null}
        </AccordionDetails>
      </Accordion>

    </>

  );
}

export default ApprovalProcess;