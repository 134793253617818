import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Card, CardContent } from '@mui/material';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DiscountRateDynamic from 'src/components/DiscountRateDynamic';
import DiscountedUserDynamic from 'src/components/DiscountedUserDynamic';
import LegalEntityControlDynamic from 'src/components/LegalEntityControlDynamic';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ServiceRefundDynamic from 'src/components/ServiceRefundDynamic';
import ConvinceTypeDynamic from 'src/components/ConvinceTypeDynamic';
import { getChangeReasonDetails, getChangeReasons, getConfirmTypeItems } from 'src/store/slices/commonSlice';
import AddCardIcon from '@mui/icons-material/AddCard';
import AbsPriceUploadDynamic from 'src/components/AbsPriceUploadDynamic';
import ConfirmTypeDynamic from 'src/components/ConfirmTypeDynamic';
import SetStatus from 'src/components/SetStatus';
import CheckIcon from '@mui/icons-material/Check';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ExchangeReasonDynamic from 'src/components/ExchangeReasonDynamic';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import ProductBasedExchangeRateDynamic from 'src/components/ProductBasedExchangeRateDynamic';
import PercentIcon from '@mui/icons-material/Percent';
import OtomaticApprovalDynamic from 'src/components/OtomaticApprovalDynamic';
import ProductQualityControlDynamic from 'src/components/ProductQualityControlDynamic';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import CampaignProductSkuDynamic from 'src/components/CampaignProductSkuDynamic';
import CampaignIcon from '@mui/icons-material/Campaign';
import AddReactionIcon from '@mui/icons-material/AddReaction';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI",
    width: '100%',
    height: '100%'
  }

}));



function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('1');
  const [confirmTypeRows, setConfirmRows] = React.useState('');
  const [changeReasonRows, setChangeReasonRows] = React.useState('');
  const [changeReasonDetailRows, setChangeReasonDetailRows] = React.useState('');

  const confirmTypeData = useSelector((state) => state.commonSlice.confirmTypeItems);
  const changeReasonData = useSelector((state) => state.commonSlice.changeReasons);
  const changeReasonDetailData = useSelector((state) => state.commonSlice.changeReasonDetails);

  useEffect(() => {
    dispatch(getConfirmTypeItems())
    dispatch(getChangeReasons())
    dispatch(getChangeReasonDetails())
  }, [dispatch]);

  useEffect(() => {
    setConfirmRows(confirmTypeData);
    setChangeReasonRows(changeReasonData);
    setChangeReasonDetailRows(changeReasonDetailData);
  }, [confirmTypeData, changeReasonData, changeReasonDetailData]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList variant="scrollable" onChange={handleChange}
                  sx={{
                    textColor: '#424242',
                    "& .css-xe9pe1-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                      color: "#FF9800"
                    }, "& .css-10hkket.Mui-selected": {
                      color: "#FF9800"
                    }
                  }}
                  TabIndicatorProps={{ style: { background: '#FF9800' } }}>
                  <Tab sx={{ width: '100%' }} icon={<CompareArrowsIcon />} label="İndirim Talep Edilen Kişi" value="1" />
                  <Tab sx={{ width: '100%' }} icon={<DoDisturbOffIcon />} label="Servis Red Nedeni" value="2" />
                  <Tab sx={{ width: '100%' }} icon={<LocalOfferIcon />} label="Kişi Bazlı İndirim Oranları" value="3" />
                  <Tab sx={{ width: '100%' }} icon={<PersonSearchIcon />} label="Tüzel-Kişi Kontrol Kelimeler" value="4" />
                  <Tab sx={{ width: '100%' }} icon={< AddCardIcon />} label="Abs Fiyat Yükleme" value="5" />
                  <Tab sx={{ width: '100%' }} icon={< CheckIcon />} label="Onay Türü ve Detay" value="6" />

                  <Tab sx={{ width: '100%' }} icon={< SwapHorizontalCircleIcon />} label="Değişim Nedeni ve Detay" value="7" />
                  <Tab sx={{ width: '100%' }} icon={< DisplaySettingsIcon />} label="Ara Durumlar" value="8" />
                  <Tab sx={{ width: '100%' }} icon={< ThumbUpAltIcon />} label="Otomatik Onay Değişim Nedenleri" value="9" />
                  <Tab sx={{ width: '100%' }} icon={< FmdBadIcon />} label="Ürün Kalite Problemleri" value="10" />

                  <Tab sx={{ width: '100%' }} icon={<PercentIcon />} label="Ürün Bazlı Değişim Oranları" value="11" />
                  <Tab sx={{ width: '100%' }} icon={<CampaignIcon />} label="Kampanya ve Ürün Model Kodu" value="12" />
                  <Tab sx={{ width: '100%' }} icon={<AddReactionIcon />} label="İkna Türleri" value="13" />

                </TabList>
              </Box>
              <TabPanel value="1">
                <DiscountedUserDynamic />
              </TabPanel>
              <TabPanel value="2">
                <ServiceRefundDynamic />
              </TabPanel>
              <TabPanel value="3">
                <DiscountRateDynamic />
              </TabPanel>
              <TabPanel value="4">
                <LegalEntityControlDynamic />
              </TabPanel>
              <TabPanel value="5">
                <AbsPriceUploadDynamic />
              </TabPanel>
              <TabPanel value="6">
                <ConfirmTypeDynamic />
              </TabPanel>
              <TabPanel value="7">
                <ExchangeReasonDynamic />
              </TabPanel>
              <TabPanel value="8">
                <SetStatus />
              </TabPanel>
              <TabPanel value="9">
                <OtomaticApprovalDynamic />
              </TabPanel>
              <TabPanel value="10">
                <ProductQualityControlDynamic />
              </TabPanel>
              <TabPanel value="11">
                <ProductBasedExchangeRateDynamic />
              </TabPanel>
              <TabPanel value="12">
                <CampaignProductSkuDynamic />
              </TabPanel>
              <TabPanel value="13">
                <ConvinceTypeDynamic />
              </TabPanel>

            </TabContext>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default Overview;
