import { Grid, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalFollowActions } from 'src/store/slices/generalFollowSlice';

const useStyles = makeStyles((theme) => ({
  cardscontent: {
    minHeight: 600,
    width: '100%'
  },
}));

function SolutionCenterSubStatus() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);
  const subStatusData = useSelector((state) => state.assigmentSlice.subStatuses);
  const solutionCenterSubStatusData = useSelector((state) => state.assigmentSlice.solutionCenterSubStatus);

  const [subStatus, setSubStatus] = React.useState(!invoiceData[0]?.Exchange?.SubStatus ? null : subStatusData.filter(i => i.Id == invoiceData[0]?.Exchange?.SubStatus)[0]);

  // Değişim çeki alanında bulunan sorgula butonuna basıldığında muadil belirleme ara durumu süreci için oluşturulmuştur.
  // MHTU-2925
  useEffect(() => {
    const data = {
      Id: invoiceData[0]?.Exchange?.SubStatus,
      Dsc: invoiceData[0]?.Exchange?.SubStatus === 0 ? '' : invoiceData[0]?.Exchange?.IndermediateState
    }
    changeSubStatus(data);
  }, [invoiceData[0].Exchange.SubStatus]);

  function changeSubStatus(val) {//Alt durum 
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.SubStatus = val?.Id
    tempData[0].Exchange.IndermediateState = val?.Dsc
    dispatch(generalFollowActions.updateNewData(tempData))
    setSubStatus(val)
  }

  return (
    <>
      {invoiceData[0].Exchange.Status != 14 ?
        <Grid item xs={12} md={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={solutionCenterSubStatusData}
            getOptionLabel={(i) => i.Dsc}
            value={subStatus}
            disabled={invoiceData[0].Exchange.Status == 24 || invoiceData[0].Exchange.Status == 27 ? true : false}
            size="small"
            renderInput={(params) => <TextField {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontSize: '14px' },
              }}
              label="Ara Durum" size='small' />}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography variant="body2" style={{ fontSize: '14px' }}>{option.Dsc}</Typography>
              </li>
            )}
            onChange={(event, val) => {
              changeSubStatus(val);
            }}
          />
        </Grid> : null
      }
    </>
  );
}

export default SolutionCenterSubStatus;