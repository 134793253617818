import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { Backdrop, CardContent, CircularProgress, Grid, MenuItem, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getServiceRejectionReasons } from 'src/store/slices/dynamicCatalogSlice';
import { AddOrUpdateServiceRejectionReason } from 'src/store/slices/dynamicCatalogSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI",
    width: '100%',
    height: '100%'
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }

}));



function ServiceRefundDynamic() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ServiceRejectionReasons, setServiceRejectionReasons] = React.useState(null);
  const [updatePopUp, setUpdatePopUp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isServiceRejectionReasonUpdate, setIsServiceRejectionReasonUpdate] = React.useState(false);
  const [addOrUpdateServiceRejectionReasonId, setaddOrUpdateServiceRejectionReasonId] = React.useState("");
  const [addOrUpdateServiceRejectionReason, setaddOrUpdateServiceRejectionReason] = React.useState("");
  const [addOrUpdateServiceRejectionReasonActive, setaddOrUpdateServiceRejectionReasonActive] = React.useState(true);
  const [selectedServiceRejectionReason, setSelectedServiceRejectionReason] = React.useState([]);



  useEffect(() => {
    setLoading(true);
    dispatch(getServiceRejectionReasons()).then(response => {
      if (response.payload?.length > 0) {
        setServiceRejectionReasons(response.payload);
        setLoading(false);
      }
      setLoading(false);
    })
  }, [dispatch]);

  function updateServiceRejectionReasonClick(row) {
    setIsServiceRejectionReasonUpdate(true);
    setaddOrUpdateServiceRejectionReasonId(row.DEGISIM_RED_NEDENI);
    setaddOrUpdateServiceRejectionReason(row.ACIKLAMA);
    setaddOrUpdateServiceRejectionReasonActive(row.AKTIF);
    setSelectedServiceRejectionReason(row);
    setUpdatePopUp(true);
  }
  const addServiceRejectionReasonClick = (row) => {
    setIsServiceRejectionReasonUpdate(false);
    clearAddOrUpdateServiceReason();
    setUpdatePopUp(true);
  }
  function clearAddOrUpdateServiceReason() {
    setaddOrUpdateServiceRejectionReasonId("");
    setaddOrUpdateServiceRejectionReason("");
    setaddOrUpdateServiceRejectionReasonActive(true);
  }
  function addOrUpdateServiceRejectionReasonClick() {
    var reasonId = addOrUpdateServiceRejectionReasonId.toString();
    var Reason = addOrUpdateServiceRejectionReason.toString();
    var Active = addOrUpdateServiceRejectionReasonActive;

    let Reasondata = {
      ReasonId: reasonId,
      Reason: Reason,
      Active: Active
    };

    setLoading(true)
    dispatch(AddOrUpdateServiceRejectionReason(Reasondata)).then(response => {
      dispatch(getServiceRejectionReasons()).then(response => {
        if (response.payload?.length > 0) {
          setServiceRejectionReasons(response.payload);
          setLoading(false);
        }
      })
      setUpdatePopUp(false);
      clearAddOrUpdateServiceReason(addOrUpdateServiceRejectionReasonId);
      setLoading(false);
    })
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CardContent>
        <TableContainer component={Paper} sx={{ maxHeight: 500, minHeight: 300 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Red Nedeni</TableCell>
                <TableCell align="center">Aktif</TableCell>
                <TableCell align="center">Güncelle</TableCell>
                <TableCell align="center">
                  <Tooltip title="Ekle">
                    <IconButton onClick={() => addServiceRejectionReasonClick()} >
                      <PersonAddIcon sx={{ color: '#FF9800' }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ServiceRejectionReasons?.map((row) => (
                <TableRow
                  key={row.DEGISIM_RED_NEDENI}
                >
                  <TableCell align="left">{row.ACIKLAMA}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      name="checkedB"
                      checkedIcon={<ThumbUpAltIcon color='success' />}
                      icon={<ThumbDownAltIcon sx={{ color: 'red' }} />}
                      checked={row.AKTIF === true}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Güncelle">
                      <IconButton onClick={() => updateServiceRejectionReasonClick(row)}>
                        <CreateIcon sx={{ color: '#FF9800' }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <Dialog
        open={updatePopUp}
        onClose={() => setUpdatePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ width: '100%' }} >{isServiceRejectionReasonUpdate ? "Güncelleme" : "Yeni Red Nedeni"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                select
                autoComplete="no"
                onChange={(e) => setaddOrUpdateServiceRejectionReasonActive(e.target.value)}
                id="Aktif"
                label={('Aktif')}
                variant="outlined"
                value={addOrUpdateServiceRejectionReasonActive}
                style={{ marginTop: 5 }}
              >
                <MenuItem key={0} value={true}>
                  {('Aktif')}
                </MenuItem>

                <MenuItem key={1} value={false}>
                  {('Pasif')}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="outlined-multiline-flexible"
                label="Red Nedeni"
                fullWidth
                multiline
                onChange={(e) => setaddOrUpdateServiceRejectionReason(e.target.value)}
                maxRows={4}
                variant="outlined"
                value={addOrUpdateServiceRejectionReason}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => addOrUpdateServiceRejectionReasonClick()}
            style={{ marginTop: 10 }}
          >{isServiceRejectionReasonUpdate ? 'Güncelle' : 'Ekle'}</Button>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => setUpdatePopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ServiceRefundDynamic;
