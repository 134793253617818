import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import React from 'react';
import axios from 'src/utils/axios';
import { Tooltip } from '@mui/material';

function MalzemeSearch({ setMalzeme, value, searchParam, error, setProduct }) {
  const [openMalzeme, setOpenMalzeme] = React.useState(false);
  const [optionsMalzeme, setOptionsMalzeme] = React.useState([]);
  const loadingMalzeme = openMalzeme && optionsMalzeme.length === 0;
  const [searchMalzeme, setSearchMalzeme] = React.useState('');

  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);


  React.useEffect(() => {
    let active = true;
    if (searchMalzeme.length > 0 && openMalzeme) {
      axios
        .get(
          `/GetMaterialCodeSearch/${searchMalzeme.toString().toLocaleLowerCase('tr-TR')}/${setProduct}`
        )
        .then(res => {
          if (active) {
            if (res.data.length > 0) {
              setOptionsMalzeme(res.data);
            } else {
              setOptionsMalzeme([]);
            }
          }
        })
        .catch(err => {
          setOptionsMalzeme([]);
        });
    }
    return () => {
      active = false;
    };
  }, [searchMalzeme, openMalzeme, loadingMalzeme]);

  React.useEffect(() => {
    if (searchParam.length > 0) {
      setSearchMalzeme(searchParam);
    }
  }, [searchParam]);

  const updateMalzeme = malzeme => {
    setMalzeme(malzeme);
    value.ACIKLAMA = malzeme.ACIKLAMA;
    // value.MALZEME_STOK_NO = malzeme.MALZEME_STOK_NO;
  };

  return (
    <>
      <Tooltip title={value ? value.ACIKLAMA : ''}>
        <Autocomplete
          id="asynchronous-demo"
          open={openMalzeme}
          value={value}
          disabled={invoiceData[0]?.Exchange?.ExchangeReson != 302}
          onOpen={() => {
            setOpenMalzeme(true);
          }}
          onClose={() => {
            setOpenMalzeme(false);
          }}
          getOptionLabel={option => {
            return option.ACIKLAMA
              ? `${option.ACIKLAMA}`
              : '-';
          }}
          options={optionsMalzeme}
          filterOptions={(optionsMalzeme, state) => optionsMalzeme}
          loading={loadingMalzeme}
          onChange={(event, newValueMalzeme) => {
            if (newValueMalzeme && newValueMalzeme.ACIKLAMA) {
              updateMalzeme(newValueMalzeme);
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              onChange={e => {
                setSearchMalzeme(e.target.value);
              }}
              variant="outlined"
              size="small"
              label={'Teminsiz Stok'}
              // helperText={value ? value.ACIKLAMA : ''}
              error={error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingMalzeme ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}

            />
          )}

        />

      </Tooltip>
    </>
  );
}

export default MalzemeSearch;
