import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};


export const getRegionItems = createAsyncThunk('/GetRegionItems', async () => {
  const response = await axios.post('/GetRegionItems', dataConfig);
  return response.data;
});

export const getCityItems = createAsyncThunk('/GetCityItems', async (data) => {
  const response = await axios.post('/GetCityItems', data, dataConfig);
  return response.data;
});

export const SMSSend = createAsyncThunk('/SMSSend', async (data) => {
  const response = await axios.post('/SMSSend', data, dataConfig);
  return response.data;
});

export const getStatusItems = createAsyncThunk('/GetStatusItems', async () => {
  const response = await axios.post('/GetStatusItems', dataConfig);
  return response.data;
});

//aron durumlar
export const getAronStatusItems = createAsyncThunk('/GetAronStatusItems', async () => {
  const response = await axios.get('/GetAronStatusItems', dataConfig);
  return response.data;
});

export const getRefundMoneyItems = createAsyncThunk('/GetRefundMoneyItems', async () => {
  const response = await axios.get('/GetRefundMoneyItems', dataConfig);
  return response.data;
});

export const getChangeReasonItems = createAsyncThunk('/GetChangeReasonItems', async () => {
  const response = await axios.post('/GetChangeReasonItems', dataConfig);
  return response.data;
});

export const getCustomerTypes = createAsyncThunk('/GetCustomerTypes', async () => {
  const response = await axios.post('/GetCustomerTypes', dataConfig);
  return response.data;
});


export const getConfirmTypeItems = createAsyncThunk('/GetConfirmTypeItems', async () => {
  const response = await axios.post('/GetConfirmTypeItems', dataConfig);
  return response.data;
});

export const getProductItems = createAsyncThunk('/GetProductItems', async () => {
  const response = await axios.post('/GetProductItems', dataConfig);
  return response.data;
});

export const getSubStatusItems = createAsyncThunk('/GetSubStatusItems', async () => {
  const response = await axios.post('/GetSubStatusItems', dataConfig);
  return response.data;
});

export const getChangeReasons = createAsyncThunk('/GetChangeReasons', async () => {
  const response = await axios.post('/GetChangeReasons', dataConfig);
  return response.data;
});

export const getChangeReasonDetails = createAsyncThunk('/GetChangeReasonDetails', async () => {
  const response = await axios.post('/GetChangeReasonDetails', dataConfig);
  return response.data;
});

export const getChangeReasonWithUp = createAsyncThunk('/GetChangeReasonWithUp', async (UpChange) => {
  const response = await axios.get(`/GetChangeReasonWithUp/${UpChange}`, dataConfig);
  return response.data;
});

export const getServiceRefund = createAsyncThunk('/GetServiceRefund', async () => {
  const response = await axios.get('/GetServiceRefund', dataConfig);
  return response.data;
});

export const getRefundConvince = createAsyncThunk('/GetRefundConvince', async () => {
  const response = await axios.get('/GetRefundConvince', dataConfig);
  return response.data;
});


export const getRefundConvinceType = createAsyncThunk('/GetRefundConvinceType', async () => {
  const response = await axios.get('/GetRefundConvinceType', dataConfig);
  return response.data;
});

export const getConfirmType = createAsyncThunk('/GetConfirmType', async () => {
  const response = await axios.get('/GetConfirmType', dataConfig);
  return response.data;
});

export const getConfirmTypeWithUp = createAsyncThunk('/GetConfirmTypeWithUp', async (UpConfirmType) => {
  const response = await axios.get(`/GetConfirmTypeWithUp/${UpConfirmType}`, dataConfig);
  return response.data;
});

export const getSubStatusForExOffice = createAsyncThunk('/GetSubStatusForExOffice', async () => {
  const response = await axios.get('/GetSubStatusForExOffice', dataConfig);
  return response.data;
});

export const getTourInformations = createAsyncThunk('/GetTourInformations', async (TopServiceShopCode) => {
  const response = await axios.get(`/GetTourInformations/${TopServiceShopCode}`, dataConfig);
  return response.data;
});

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    loading: '',
    error: '',
    catalogItems: [],
    regionItems: [],
    cityItems: [],
    confirmTypeItems: [],
    changeReasonItems: [],
    statuses: [],
    aronStatuses: [],
    refundMoney: [],
    subStatuses: [],
    products: [],
    customerTypes: [],
    changeReasons: [],
    changeReasonDetails: [],
    upChangeReasons: [],
    serviceRefunds: [],
    confirmTypes: [],
    upConfirmTypes: [],
    subStatusesForExOffice: [],
    lastUpdateDate: '',
  },
  reducers: {
  },
  extraReducers: {
    [getRegionItems.pending]: (state) => {
      state.loading = true;
    },
    [getRegionItems.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getRegionItems.fulfilled]: (state, action) => {
      state.regionItems = action.payload;
      state.loading = false;
      state.lastUpdateDate = Date();
    },
    [getCityItems.pending]: (state) => {
      state.loading = true;
    },
    [getCityItems.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getCityItems.fulfilled]: (state, action) => {
      state.cityItems = action.payload;
      state.loading = false;
    },
    [getConfirmTypeItems.pending]: (state) => {
      state.loading = true;
    },
    [getConfirmTypeItems.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getConfirmTypeItems.fulfilled]: (state, action) => {
      state.confirmTypeItems = action.payload;
      state.loading = false;
    },
    [getChangeReasonItems.pending]: (state) => {
      state.loading = true;
    },
    [getChangeReasonItems.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getChangeReasonItems.fulfilled]: (state, action) => {
      state.changeReasonItems = action.payload;
      state.loading = false;
    },
    [getStatusItems.pending]: (state) => {
      state.loading = true;
    },
    [getStatusItems.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getStatusItems.fulfilled]: (state, action) => {
      state.statuses = action.payload;
      state.loading = false;
    },
    [getAronStatusItems.pending]: (state) => {
      state.loading = true;
    },
    [getAronStatusItems.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getAronStatusItems.fulfilled]: (state, action) => {
      state.aronStatuses = action.payload;
      state.loading = false;
    },
    [getRefundMoneyItems.pending]: (state) => {
      state.loading = true;
    },
    [getRefundMoneyItems.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getRefundMoneyItems.fulfilled]: (state, action) => {
      state.refundMoney = action.payload;
      state.loading = false;
    },
    [getSubStatusItems.pending]: (state) => {
      state.loading = true;
    },
    [getSubStatusItems.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getSubStatusItems.fulfilled]: (state, action) => {
      state.subStatuses = action.payload;
      state.loading = false;
    },
    [getProductItems.pending]: (state) => {
      state.loading = true;
    },
    [getProductItems.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getProductItems.fulfilled]: (state, action) => {
      state.products = action.payload;
      state.loading = false;
    },
    [getCustomerTypes.pending]: (state) => {
      state.loading = true;
    },
    [getCustomerTypes.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getCustomerTypes.fulfilled]: (state, action) => {
      state.customerTypes = action.payload;
      state.loading = false;
    },
    [getChangeReasons.pending]: (state) => {
      state.loading = true;
    },
    [getChangeReasons.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getChangeReasons.fulfilled]: (state, action) => {
      state.changeReasons = action.payload;
      state.loading = false;
    },
    [getChangeReasonDetails.pending]: (state) => {
      state.loading = true;
    },
    [getChangeReasonDetails.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getChangeReasonDetails.fulfilled]: (state, action) => {
      state.changeReasonDetails = action.payload;
      state.loading = false;
    },
    [getChangeReasonWithUp.pending]: (state) => {
      state.loading = true;
    },
    [getChangeReasonWithUp.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getChangeReasonWithUp.fulfilled]: (state, action) => {
      state.upChangeReasons = action.payload;
      state.loading = false;
    },
    [getServiceRefund.pending]: (state) => {
      state.loading = true;
    },
    [getServiceRefund.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getServiceRefund.fulfilled]: (state, action) => {
      state.serviceRefunds = action.payload;
      state.loading = false;
    },
    [getConfirmType.pending]: (state) => {
      state.loading = true;
    },
    [getConfirmType.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getConfirmType.fulfilled]: (state, action) => {
      state.confirmTypes = action.payload;
      state.loading = false;
    },
    [getConfirmTypeWithUp.pending]: (state) => {
      state.loading = true;
    },
    [getConfirmTypeWithUp.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getConfirmTypeWithUp.fulfilled]: (state, action) => {
      state.upConfirmTypes = action.payload;
      state.loading = false;
    },
    [getSubStatusForExOffice.pending]: (state) => {
      state.loading = true;
    },
    [getSubStatusForExOffice.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getSubStatusForExOffice.fulfilled]: (state, action) => {
      state.subStatusesForExOffice = action.payload;
      state.loading = false;
    }
  }
});
export const commonActions = commonSlice.actions;
export default commonSlice;