import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CloseIcon from '@mui/icons-material/Close';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { generalFollowActions } from 'src/store/slices/generalFollowSlice';
import axios from 'src/utils/axios';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  tableCell: {
    fontWeight: '600',
    fontSize: '14px',
    color: 'black',
    whiteSpace: 'nowrap'
  },
  tableCellBilgi: {
    fontSize: '14px',
    color: 'black',
    whiteSpace: 'nowrap'
  }
}));
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowNegative={false}
      isNumericString
      suffix="₺"
    />
  );
});
function InvoiceRefund() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const invoiceData = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  );

  const offerProductPrice = useSelector(
    (state) => state.generalFollowSlice.updatedOfferPrice
  );
  const topModelDifferentPrice = useSelector(
    (state) => state.generalFollowSlice.updatedTopModelDifferentPrice
  );

  const subModelDifferenceAmount = useSelector(
    (state) => state.generalFollowSlice.updatedSubModelDifferenceAmount
  );

  const [receiptNumber, setReceiptNumber] = React.useState(
    invoiceData[0]?.Consumer?.ChangeInvoiceNo
  );
  const [receiptDate, setReceiptDate] = React.useState(
    moment(invoiceData[0]?.Consumer?.ChangeInvoiceDate).format('DD.MM.YYYY')
  );
  const [invoiceAmount, setInvoiceAmount] = React.useState(
    invoiceData[0]?.Consumer?.ChangeInvoiceAmount
      ? invoiceData[0]?.Consumer?.ChangeInvoiceAmount.toString().replace(
          '.',
          ','
        )
      : 0
  );
  const [invoiceItemQuantity, setInvoiceItemQuantity] = React.useState(
    invoiceData[0]?.Consumer?.ChangeInvoiceItemQuantity
  );
  const [retailerProductPriceData, setRetailerProductPriceData] =
    React.useState([]);
  const [retailerProductPricePopUp, setRetailerProductPricePopUp] =
    React.useState(false);
  const [productPriceData, setProductPriceData] = React.useState([]);
  const [productPricePopUp, setProductPricePopUp] = React.useState(false);

  const totalProductPrice =
    parseFloat(topModelDifferentPrice) + parseFloat(offerProductPrice);

  const [loading, setLoading] = React.useState(false);

  function changeInvoiceNo(val) {
    //Fatura No
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Consumer.ChangeInvoiceNo = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setReceiptNumber(val);
  }

  function changeInvoiceDate(val) {
    //Fatura Tarihi
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Consumer.ChangeInvoiceDate = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setReceiptDate(val);
  }

  function changeInvoiceAmount(val) {
    //Fatura Tutarı
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    if (
      invoiceData[0]?.Consumer?.UpTypeId === 6 &&
      (invoiceData[0]?.Consumer?.ConsumerType === 2 ||
        invoiceData[0]?.Consumer?.ConsumerType === 3)
    ) {
      tempData[0].Exchange.ReceivedProductPrice = val;
      let value = parseFloat(val) +totalProductPrice - parseFloat(subModelDifferenceAmount);
      tempData[0].Exchange.GiveProductPrice = value < 0 ? 0 : value
      dispatch(generalFollowActions.updateGiveProductPrice(tempData[0].Exchange.GiveProductPrice));
      dispatch(generalFollowActions.updateReceivedProductPrice(tempData[0].Exchange.ReceivedProductPrice));
      dispatch(generalFollowActions.updateInvoiceChanged(true)); 
    }

    tempData[0].Consumer.ChangeInvoiceAmount = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setInvoiceAmount(val);
  }

  function changeInvoiceItemQuantity(val) {
    //Toplam ürün adet
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Consumer.ChangeInvoiceItemQuantity = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setInvoiceItemQuantity(val);
  }

  function GetRetailerProductSellPrice() {
    setLoading(true);
    const data = [
      invoiceData[0]?.Retailer?.UserCode?.toString(),
      invoiceData[0]?.Model?.UserModelCode.toString()
    ];
    axios
      .get(`/ProductSellPrice/${data[0]}/${data[1]}`, {})
      .then((response) => {
        if (response?.data?.length > 0) {
          setRetailerProductPriceData(response.data);
          setRetailerProductPricePopUp(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function GetProductSellPrice() {
    setLoading(true);
    axios
      .get(
        `/ProductSellPrice/${0}/${invoiceData[0]?.Model?.UserModelCode.toString()}`,
        {}
      )
      .then((response) => {
        if (response?.data?.length > 0) {
          setProductPriceData(response.data);
          setProductPricePopUp(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <Grid style={{ paddingTop: 5 }}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography
                sx={{
                  width: '100%',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  color: 'red'
                }}
                variant="subtitle1"
              >
                İade Fatura Alanı
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Bayi-Ürün Bazlı Satış Fiyat">
                <IconButton
                  sx={{ color: '#FF9800' }}
                  onClick={() => GetRetailerProductSellPrice()}
                >
                  <EqualizerIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Ürün Bazlı Satış Fiyat">
                <IconButton onClick={() => GetProductSellPrice()}>
                  <AutoGraphIcon sx={{ color: '#FF9800' }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-with-icon-textfield"
                label="Fatura No"
                value={receiptNumber}
                onChange={(event) => {
                  changeInvoiceNo(event.target.value);
                }}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-with-icon-textfield"
                label="Fatura Tarihi"
                value={receiptDate}
                onChange={(event) => {
                  changeInvoiceDate(event.target.value);
                }}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Fatura Tutarı"
                name="numberformat"
                id="formatted-numberformat-input"
                size="small"
                value={invoiceAmount}
                onChange={(event) => {
                  changeInvoiceAmount(event.target.value);
                }}
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-with-icon-textfield"
                label="Toplam Ürün Adet"
                size="small"
                value={invoiceItemQuantity}
                onChange={(event) => {
                  changeInvoiceItemQuantity(event.target.value);
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/*Bayi Ürün Bazlı Satış Detay popup */}

      <Dialog
        open={retailerProductPricePopUp}
        onClose={() => setRetailerProductPricePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            width: '100%',
            color: 'red',
            fontSize: '14px',
            fontWeight: 'bold'
          }}
        >
          {'Bayi-Ürün Bazlı Satış Fiyat'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow sx={{ width: '100%' }}>
                      <TableCell className={classes.tableCell} align="center">
                        Bayi Kodu
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Bayi
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Fatura Tarihi
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Fatura No
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Fatura Satır
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Koşul Kodu
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Koşul
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Vergili Fiyat
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Vergisiz Fiyat
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Ortalama Vade
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {retailerProductPriceData?.map((row) => (
                      <TableRow
                        key={row.DealerCode}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.DealerCode}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.DealerDescription}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.InvoiceDate}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.InvoiceNr}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.InvoiceRow}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.ConditionNr}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.ConditionDescription}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.TaxPrice}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.NoTaxPrice}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.AvgPrice}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            sx={{ color: '#424242' }}
            onClick={() => setRetailerProductPricePopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>

      {/*Ürün Bazlı Satış Detay popup */}

      <Dialog
        open={productPricePopUp}
        onClose={() => setProductPricePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            width: '100%',
            color: 'red',
            fontSize: '14px',
            fontWeight: 'bold'
          }}
        >
          {'Ürün Bazlı Satış Fiyat'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow sx={{ width: '100%' }}>
                      <TableCell className={classes.tableCell} align="center">
                        Bayi Kodu
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Bayi
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Fatura Tarihi
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Fatura No
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Fatura Satır
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Koşul Kodu
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Koşul
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Vergili Fiyat
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Vergisiz Fiyat
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Ortalama Vade
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productPriceData?.map((row) => (
                      <TableRow
                        key={row.DealerCode}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.DealerCode}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.DealerDescription}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.InvoiceDate}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.InvoiceNr}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.InvoiceRow}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.ConditionNr}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.ConditionDescription}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.TaxPrice}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.NoTaxPrice}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.AvgPrice}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            sx={{ color: '#424242' }}
            onClick={() => setProductPricePopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default InvoiceRefund;
