import { Autocomplete, Grid, TextField, IconButton, Tooltip, Box, Button, Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { getChangeReasons } from 'src/store/slices/assigmentSlice';
import { useDispatch } from 'react-redux';
import { fillMainProductGroups, secondMainProductGroups, thirdMainProductGroups, showList, deleteAutomaticApprovalChangeReason, xd, insertDeletedAutomaticApprovalChangeReason, deletedAutomaticApprovalChangeReasonShowList } from 'src/store/slices/dynamicCatalogSlice';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import AlertAndLoading from 'src/components/AlertAndLoading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getUser } from 'src/utils/Common';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  content: {
    justifyContent: "space-between"
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }
}));


function OtomaticApprovalDynamic() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();

  const [changeReason, setChangeReason] = React.useState([]);
  const [selectedChangeReason, setSelectedChangeReason] = React.useState(null);
  const [mainProductGroups, setMainProductGroups] = React.useState([]);
  const [secondProductGroups, setSecondProductGroups] = React.useState([]);
  const [thirdProductGroups, setThirdProductGroups] = React.useState([]);
  const [showLists, setShowLists] = React.useState([]);
  const [mainProductId, setMainProductId] = React.useState(null);
  const [secondMainProductId, setSecondMainProductId] = React.useState(null);
  const [thirdMainProductId, setThirdMainProductId] = React.useState(null);
  const [exchangeReasonId, setExchangeReasonId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });
  const [activeExpanded, setActiveExpanded] = React.useState(false);
  const [deletedExpanded, setDeletedExpanded] = React.useState(false);
  const [deletedShowLists, setDeletedShowLists] = React.useState([]);


  useEffect(() => {
    dispatch(getChangeReasons()).then(response => {
      setChangeReason(response?.payload)
    });
    dispatch(fillMainProductGroups()).then(response => {
      setMainProductGroups(response.payload)
    });
    dispatch(showList()).then(response => {
      setShowLists(response.payload)
    });
    dispatch(deletedAutomaticApprovalChangeReasonShowList()).then(response => {
      setDeletedShowLists(response.payload)
    });
  }, [dispatch]);


  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  function getSecondMainProduct(val) {
    if (val) {
      setMainProductId(val)
      var request = val.toString();
      setLoading(true);
      dispatch(secondMainProductGroups(request)).then(response => {
        setSecondProductGroups(response?.payload)
        setLoading(false);
      });
    }
    return
  }

  function getThirdMainProduct(val) {
    if (val) {
      setSecondMainProductId(val)
      var request = val.toString();
      setLoading(true);
      dispatch(thirdMainProductGroups(request)).then(response => {
        setThirdProductGroups(response?.payload)
        setLoading(false);
      });
    }
    return

  }

  function DeleteRow(cellValues) {
    const data = {
      DeletedDsc: cellValues.row.ACIKLAMA,
      DeletedSecondDsc: cellValues.row.ACIKLAMA2,
      DeletedThirdDsc: cellValues.row.ACIKLAMA3,
      DeletedExchangeReason: cellValues.row.NEDEN,
      SessionUser: SessionUser?.FirstName + " " + SessionUser?.LastName
    }
    confirmAlert({
      message: ('Silmek İstediğinizden Emin misiniz?'),
      buttons: [
        {
          label: ('Evet'),
          onClick: () => {
            dispatch(deleteAutomaticApprovalChangeReason([cellValues.row.ID])).then(response => {
              dispatch(showList()).then(response => {
                if (response.payload?.length > 0) {
                  setShowLists(response?.payload)
                }
              })
            })

            dispatch(insertDeletedAutomaticApprovalChangeReason(data)).then(response => {
              dispatch(deletedAutomaticApprovalChangeReasonShowList()).then(response => {
                if (response.payload?.length > 0) {
                  setDeletedShowLists(response.payload)
                }
              })
            })
          }
        },
        {
          label: ('Hayır'),
          onClick: () => { }
        }
      ],
    })





  }

  function InsertRow() {
    if (mainProductId == null || secondMainProductId == null || thirdMainProductId == null || exchangeReasonId == null) {
      InfoAlert('Lütfen Kutucukları Boş Bırakmayınız!...', 'error', 'Hata');
      return;
    }

    const data = {
      Dsc: mainProductId,
      SecondDsc: secondMainProductId,
      ThirdDsc: thirdMainProductId,
      ExchangeReason: exchangeReasonId,
      SessionUser: SessionUser?.FirstName + " " + SessionUser?.LastName
    }

    dispatch(xd(data)).then(response => {
      dispatch(showList()).then(response => {
        if (response.payload?.length > 0) {
          setShowLists(response.payload)
        }
      })
    })
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  const columns = [
    { field: 'ID', headerName: 'ID', flex: 1, },
    { field: 'ACIKLAMA', headerName: 'ANA URUN GRUBU', flex: 1, },
    { field: 'ACIKLAMA2', headerName: '2. SEVIYE ANA URUN GRUBU', flex: 1, },
    { field: 'ACIKLAMA3', headerName: '3. SEVIYE ANA URUN GRUBU', flex: 1, },
    { field: 'NEDEN', headerName: 'DEGISIM NEDENI', flex: 1, },
    {
      field: 'EKLEME_TARIHI', headerName: 'EKLEME TARIHI', flex: 1,
      renderCell: (cellValues) => {
        return (
          cellValues.row.EKLEME_TARIHI ? moment(cellValues.row.EKLEME_TARIHI).format('DD-MM-YYYY') : ''
        );
      }
    },
    { field: 'SICIL_NO', headerName: 'EKLEYEN KİŞİ', flex: 1, },
    {
      field: 'Sil',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', "& > :not(style)": { m: 1 } }}>
            <Tooltip title="sil">
              <IconButton  >
                <DeleteIcon onClick={() => DeleteRow(cellValues)} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }, width: 150
    }
  ];

  const DeletedAutomaticApprovalsColumns = [
    { field: 'ID', headerName: 'ID', flex: 1, },
    { field: 'ACIKLAMA', headerName: 'ANA URUN GRUBU', flex: 1, },
    { field: 'ACIKLAMA2', headerName: '2. SEVIYE ANA URUN GRUBU', flex: 1, },
    { field: 'ACIKLAMA3', headerName: '3. SEVIYE ANA URUN GRUBU', flex: 1, },
    { field: 'NEDEN', headerName: 'DEGISIM NEDENI', flex: 1, },
    {
      field: 'EKLEME_TARIHI', headerName: 'EKLEME TARIHI', flex: 1,
      renderCell: (cellValues) => {
        return (
          moment(cellValues.row.EKLEME_TARIHI).format('DD-MM-YYYY HH:mm:ss')
        );
      }
    },
    { field: 'SICIL_NO', headerName: 'EKLEYEN KİŞİ', flex: 1, }
  ];

  return (
    <>
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={mainProductGroups}
            getOptionLabel={(option) => option.Display}
            id="combo-box-demo"
            size="small"
            fullWidth
            renderInput={(params) => <TextField {...params} label="Ana Ürün Grubu Kodu-Adı" />}
            onChange={(event, val) => {
              getSecondMainProduct(val?.Value)

            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={secondProductGroups}
            getOptionLabel={(option) => option.Display}
            id="combo-box-demo"
            size="small"
            fullWidth
            renderInput={(params) => <TextField {...params} label="2.Seviye  Ürün Grubu Kodu-Adı" />}
            onChange={(event, val) => {
              getThirdMainProduct(val?.Value)
            }}
          /> </Grid>
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={thirdProductGroups}
            getOptionLabel={(option) => option.Display}
            id="combo-box-demo"
            size="small"
            fullWidth
            renderInput={(params) => <TextField {...params} label="3.Seviye  Ürün Grubu Kodu-Adı" />}
            onChange={(event, val) => {
              // getThirdMainProduct(val?.Value)
              // setSecondMainProductId(val?.Value)
              setThirdMainProductId(val?.Value)
            }}
          /> </Grid>
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            fullWidth
            options={changeReason}
            getOptionLabel={(option) => option.Dsc}
            renderInput={(params) => <TextField {...params} label="Değişim Nedeni-Detayı" />}
            onChange={(event, val) => {
              setSelectedChangeReason(val?.Id);
              setExchangeReasonId(val?.Id)
            }}
          /> </Grid>
        <br></br>
        <Grid item xs={9} md={9}></Grid>
        <Grid item xs={3} md={3}>
          <Button
            fullWidth
            size="small"
            variant="contained"
            style={{ marginTop: 10 }}
            onClick={() => { InsertRow(); }}>
            Ekle
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
        </Grid>
      </Grid>
      {/* Aktif - Silinmiş Sekmeleri */}
      <Accordion expanded={activeExpanded} onChange={() => setActiveExpanded(!activeExpanded)} sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
        <AccordionSummary
          classes={{ content: classes.content }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', fontWeight: 'bold', color: 'red', fontSize: '14px' }} variant="subtitle1">
            Aktif Kurallar
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={showLists}
              columns={columns}
              getRowId={(row) => row.ID}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <br></br>
      <Accordion expanded={deletedExpanded} onChange={() => setDeletedExpanded(!deletedExpanded)} sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
        <AccordionSummary
          classes={{ content: classes.content }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', fontWeight: 'bold', color: 'red', fontSize: '14px' }} variant="subtitle1">
            Silinmiş Kurallar
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={deletedShowLists}
              columns={DeletedAutomaticApprovalsColumns}
              getRowId={(row) => row.ID}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default OtomaticApprovalDynamic;
