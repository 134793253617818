import {
    Typography, useMediaQuery, useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import avatar from 'src/assets/images/technician.png';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  }
}));

function Unauthorized() {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page
      className={classes.root}
      title="Giriş Yetkiniz Bulunmuyor"
    >
      <Typography
        align="center"
        variant={mobileDevice ? 'h4' : 'h1'}
      >
        Giriş Yetkiniz Bulunmuyor!...
      </Typography>

      <div className={classes.imageContainer}>
        <img
          alt="Under development"
          className={classes.image}
          src={avatar}
        />
      </div>
    </Page>
  );
}

export default Unauthorized;
