import SendIcon from '@mui/icons-material/Send';
import { Backdrop, Card, CardContent, CircularProgress, Grid, IconButton, Button, TextField, Typography, Alert, Stack, Snackbar } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { orange } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { generalFollowActions } from 'src/store/slices/generalFollowSlice';
import axios from 'src/utils/axios';
import { getUser, getUserRole } from 'src/utils/Common';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
}));

function SapRefundProcess() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();
  const Role = getUserRole().RoleId;

  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);
  const UpdatedInformation = useSelector((state) => state.generalFollowSlice.updatedProductInformation);

  const [refundInvoiceNo, setRefundInvoiceNo] = React.useState(invoiceData[0]?.Exchange?.RefundInvoiceNumber ? invoiceData[0]?.Exchange?.RefundInvoiceNumber : '');
  const [refundCustomerTc, setRefundCustomerTc] = React.useState(invoiceData[0]?.Exchange?.RefundCustomerTC);
  const [refundCustomerName, setRefundCustomerName] = React.useState(invoiceData[0]?.Exchange?.RefundCustomerName);
  const [uyari, setUyari] = React.useState(false);
  const [uyariClose, setUyariClose] = React.useState('error');
  const [loading, setLoading] = React.useState(false);
  const [serviceTakeCustomerProduct, setServiceTakeCustomerProduct] = React.useState(invoiceData[0]?.Exchange?.ServiceTakeCustomerProduct);

  const [responseMessage, setresponseMessage] = React.useState({
    ErrorCode: '0',
    ErrorDescription: ('success_message')
  });
  function pageUpdate() {
    dispatch(generalFollowActions.updateActiveTab("1"));
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  function removeRowInTable() {
    const orjinalData = [...UpdatedInformation];
    const removeRow = orjinalData?.findIndex(x => x.FIS_NO == invoiceData[0]?.Apply?.ReceiptNumber);
    orjinalData.splice(removeRow, 1);
    const xd = orjinalData.length
    dispatch(generalFollowActions.updateProductInformation(orjinalData))
    dispatch(generalFollowActions.updateRowCount(xd))
  }

  function changeRefundCustomerTc(val) {
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.RefundCustomerTC = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setRefundCustomerTc(val)
  }

  function changeRefundCustomerName(val) {
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.RefundCustomerName = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setRefundCustomerName(val)
  }

  const data = {
    ServiceNumber: invoiceData[0]?.Exchange?.Service?.toString(),//Servis Numarası
    ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(),//Başvuru No
    CustomerType: invoiceData[0]?.Consumer?.ConsumerType,//Müşteri tipi
    TopConfirmType: invoiceData[0]?.Consumer?.UpTypeId,//Üst onay türü
    GiveProductPrice: invoiceData[0]?.Exchange?.GiveProductPrice,//verilen ürün prekande fiyat
    ProductBuyStockPrice: invoiceData[0]?.Exchange.ReceivedProductPrice,//alınan ürün prekande fiyat
    PaymentCustomerTC: invoiceData[0]?.Exchange.RefundCustomerTC,//Tc müşteri
    PaymentCustomerName: invoiceData[0]?.Exchange.RefundCustomerName//müşteri ad
  }
  function sendRefund() {
    confirmAlert({
      message: ('Müşterinin para iadesi işlemleri için belgeleri hazırladığınızı ve mali işlere ilettiğinizi belirtiyorsunuz. Onaylıyor musunuz?'),
      buttons: [
        {
          label: ('Evet'),
          onClick: () => {
            setLoading(true);
            axios.post('SetRefund', data, {
              headers: { 'Content-Type': 'application/json' }
            }).then(response => {
              if (response.data.ErrorCode == 0) {
                setRefundInvoiceNo(response.data.Description)
                setLoading(false);
                setUyari(true);
                setUyariClose('success');
                setresponseMessage({
                  ErrorCode: 0,
                  ErrorDescription: "İşleminiz Başarı ile Gerçekleşmiştir",
                });
                removeRowInTable();
                setTimeout(pageUpdate, 500);
                return;
              } else {
                setUyari(true);
                setUyariClose('error');
                setresponseMessage({
                  ErrorCode: 1000,
                  ErrorDescription: response.data.ErrorDescription,
                });
                setLoading(false);
                return;
              }
            }).catch(err => {
              setUyariClose('error');
              setresponseMessage({
                ErrorCode: 0,
                ErrorDescription: "Bir hata oluştu!...",
              });
              setLoading(false);
              return
            });
          }
        },
        {
          label: ('Hayır'),
          onClick: () => { }
        }
      ],
    })
  }


  function TakeService(check) {
    if (check == false) {
      confirmAlert({
        message: ('Servis tarafından seçilen ayarı değiştirmek üzeresiniz! Emin misiniz?'),
        buttons: [
          {
            label: ('Evet'),
            onClick: () => {
              setLoading(true);
              const data = {
                isServiceGetProduct: check,
                ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo.toString(),
                ServiceNumber: invoiceData[0]?.Exchange?.Service.toString(),
                UserID: invoiceData[0]?.UserID
              }
              setServiceTakeCustomerProduct(check);
              setLoading(true);
              axios.post('BackToProductService', data, {
                headers: { 'Content-Type': 'application/json' }
              }).then(response => {
                if (response.data == true) {
                  setresponseMessage({
                    ErrorCode: 1000,
                    ErrorDescription: "İşleminiz Başarı ile Gerçekleşmiştir",
                  });
                  setUyariClose('success');
                  setUyari(true);
                  setLoading(false);
                  removeRowInTable();
                  setTimeout(pageUpdate, 500);
                  return
                } else {
                  setUyari(true);
                  setUyariClose('error');
                  setresponseMessage({
                    ErrorCode: 0,
                    ErrorDescription: "Bir hata oluştu!...",
                  });
                  setLoading(false);
                  return
                }
              }).catch(err => {
                setLoading(false);
                return;
              });
            }
          },
          {
            label: ('Hayır'),
            onClick: () => { }
          }
        ],
      })
    }
  }

  function ParaIadesiBelgeNoTemizle() {
    const data = {
      ServiceNumber: invoiceData[0]?.Exchange?.Service?.toString(),//Servis Numarası
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(),//Başvuru No
      SessionUser: SessionUser?.UserId?.toString() //Sicil No
    }
    setLoading(true);
    axios.post('ParaIadesiBelgeNoSil', data, {
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      if (response.data.ErrorCode == 0) {
        setLoading(false);
        setUyari(true);
        setUyariClose('success');
        setresponseMessage({
          ErrorCode: 0,
          ErrorDescription: "İşleminiz Başarı ile Gerçekleşmiştir",
        });
        removeRowInTable();
        setTimeout(pageUpdate, 500);
        return;
      } else {
        setUyari(true);
        setUyariClose('error');
        setresponseMessage({
          ErrorCode: 1000,
          ErrorDescription: response.data.ErrorDescription,
        });
        setLoading(false);
        return;
      }
    });
    setLoading(false);
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={8}>
        <Snackbar sx={{ width: '100%' }} open={uyari} autoHideDuration={6000} onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
          <Alert icon={<TagFacesIcon />}
            style={{ minWidth: 500 }}
            sx={{
              "& .MuiAlert-icon": {
                color: "white"
              },
              height: 75,
              alignItems: "center",
              verticalAlign: "center"

            }} onClose={uyariKapat} variant="filled" severity={uyariClose} >
            {responseMessage.ErrorDescription}
          </Alert>
        </Snackbar>
      </Stack>

      {invoiceData[0].Consumer.UpTypeId != 7 ?
        <>
          <Card sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
            <CardContent>
              <Grid item xs={12} md={12}>
                <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 'bold', color: 'red' }} variant="subtitle1">
                  Para İadesi Ödeme Yapılacak Kişi Farklı ise:
                </Typography>
              </Grid>
              < Grid container spacing={2}>
                <Grid item xs={2} md={2}>
                  <Tooltip title="Ürünün Eskisi Yetkili Servisimiz Tarafından Teslim alınmıştır">
                    <Checkbox
                      checked={serviceTakeCustomerProduct}
                      onChange={(event) => { TakeService(event.target.checked); }}
                      sx={{
                        color: orange[800],
                        '&.Mui-checked': {
                          color: orange[600],
                        },
                      }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Tooltip title="Ödeme yapılacak kişi farklı ise">
                    <TextField
                      id="input-with-icon-textfield"
                      label="TC Kimlik"
                      size="small"
                      fullWidth
                      variant="outlined"
                      disabled={invoiceData[0]?.Exchange?.RefundIs == true ? true : false}
                      value={refundCustomerTc}
                      onChange={(event) => { changeRefundCustomerTc(event.target.value); }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Tooltip title="Ödeme yapılacak kişi farklı ise">
                    <TextField
                      id="input-with-icon-textfield"
                      label="İsim Soyisim"
                      size="small"
                      fullWidth
                      variant="outlined"
                      disabled={invoiceData[0]?.Exchange?.RefundIs == true ? true : false}
                      value={refundCustomerName}
                      onChange={(event) => { changeRefundCustomerName(event.target.value); }}
                    />
                  </Tooltip>
                </Grid>
                {(invoiceData[0]?.Consumer?.UpTypeId == 2 || invoiceData[0]?.Consumer?.UpTypeId == 6) && (invoiceData[0]?.Consumer?.ConsumerType != 3) && (invoiceData[0]?.Exchange?.Status == 15 || invoiceData[0]?.Exchange?.Status == 16 || invoiceData[0]?.Exchange?.Status == 17) ?
                  <Grid item xs={6} md={6}>
                    <Tooltip title="Belge Numarası">
                      <TextField
                        id="input-with-icon-textfield"
                        label="Belge Numarası"
                        size="small"
                        fullWidth
                        variant="outlined"
                        disabled
                        value={refundInvoiceNo ? refundInvoiceNo : ''}
                        onChange={(event) => { setRefundInvoiceNo(event.target.value); }}
                      />
                    </Tooltip>
                  </Grid> : null}
                <Grid item xs={4} md={4} />
                {(invoiceData[0]?.Consumer?.UpTypeId == 2 || invoiceData[0]?.Consumer?.UpTypeId == 6) && (invoiceData[0]?.Consumer?.ConsumerType != 3) && (invoiceData[0]?.Exchange?.Status == 15 || invoiceData[0]?.Exchange?.Status == 16 || invoiceData[0]?.Exchange?.Status == 17) && (Role?.filter(i => i.ROLE_ID == 7).length > 0) && (invoiceData[0]?.Exchange?.RefundInvoiceNumber?.length > 0) ?
                  <Grid item xs={2} md={2}>
                    <Tooltip title="Belge Numarası Sil">
                      <IconButton
                        className={classes.action}
                        size="medium"
                        sx={{ backgroundColor: '#424242' }}
                        onClick={() => { ParaIadesiBelgeNoTemizle(); }}
                      >
                        <DeleteSweepIcon size="small" sx={{ color: '#FF9800' }} />
                      </IconButton>
                    </Tooltip>

                  </Grid>
                  :
                  (invoiceData[0]?.Consumer?.UpTypeId == 2 || invoiceData[0]?.Consumer?.UpTypeId == 6) && (invoiceData[0]?.Consumer?.ConsumerType != 3) && (invoiceData[0]?.Exchange?.Status == 15 || invoiceData[0]?.Exchange?.Status == 16 || invoiceData[0]?.Exchange?.Status == 17) ?
                    <Grid item xs={2} md={2}>
                      <Tooltip title="Gönder">
                        <IconButton
                          className={classes.action}
                          size="medium"
                          key="close"
                          sx={{ backgroundColor: '#424242' }}
                          // disabled={invoiceData[0]?.Exchange?.RefundIs == true ? true : false}
                          onClick={() => { sendRefund() }}
                        >
                          <SendIcon size="small" sx={{ color: '#FF9800' }} />
                        </IconButton>
                      </Tooltip>
                    </Grid> : null}
              </Grid>
            </CardContent>
          </Card>
          <Grid style={{ paddingTop: 5 }} ></Grid>
        </> :
        <>
          {/* Değişim Çeki mali işlere gönderme kısmı buraya gelecek */}
          <Card sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
            <CardContent>
              <Grid item xs={12} md={12}>
                <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 'bold', color: 'red' }} variant="subtitle1">
                  Değişim Çeki Belge Numarası:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={1} md={1}>
                    <Tooltip title="Ürünün Eskisi Yetkili Servisimiz Tarafından Teslim alınmıştır">
                      <Checkbox
                        checked={serviceTakeCustomerProduct}
                        onChange={(event) => { TakeService(event.target.checked); }}
                        sx={{
                          color: orange[800],
                          '&.Mui-checked': {
                            color: orange[600],
                          },
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Tooltip title="Belge Numarası">
                      <TextField
                        id="input-with-icon-textfield"
                        label="Belge Numarası"
                        size="small"
                        fullWidth
                        variant="outlined"
                        disabled
                        value={refundInvoiceNo ? refundInvoiceNo : ''}
                        onChange={(event) => { setRefundInvoiceNo(event.target.value); }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3} md={3}></Grid>
                  {(invoiceData[0]?.Consumer?.UpTypeId == 7) && (invoiceData[0]?.Consumer?.ConsumerType != 3) && (invoiceData[0]?.Exchange?.Status == 15 || invoiceData[0]?.Exchange?.Status == 16 || invoiceData[0]?.Exchange?.Status == 17) && (Role?.filter(i => i.ROLE_ID == 7).length > 0) && (invoiceData[0]?.Exchange?.RefundInvoiceNumber?.length > 0) ?
                    <Grid item xs={2} md={2}>
                      <Tooltip title="Belge Numarası Sil">
                        <IconButton
                          className={classes.action}
                          size="medium"
                          sx={{ backgroundColor: '#424242' }}
                          onClick={() => { ParaIadesiBelgeNoTemizle(); }}
                        >
                          <DeleteSweepIcon size="small" sx={{ color: '#FF9800' }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    :
                    <Grid item xs={2} md={2}>
                      <Tooltip title="Gönder">
                        <IconButton
                          className={classes.action}
                          size="small"
                          key="close"
                          sx={{ backgroundColor: '#424242' }}
                          // disabled={invoiceData[0]?.Exchange?.RefundIs == true ? true : false}
                          onClick={() => { sendRefund() }}
                        >
                          <SendIcon size="small" sx={{ color: '#FF9800' }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid style={{ paddingTop: 5 }} ></Grid>
        </>
      }
    </>

  );
}

export default SapRefundProcess;