import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Typography
} from '@mui/material';
import Chart from 'react-apexcharts';
import { useDispatch } from 'react-redux';
import { getAllOpenExchangeReceipts } from 'src/store/slices/generalMonitoringSlice';

function OpenExchangeReceiptsWithDaysRangeChart() {
  const dispatch = useDispatch();

  const [mainData, setMainData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    GetAllOpenExchangeReceipts();
  }, []);

  const GetAllOpenExchangeReceipts = () => {
    setLoading(true);
    dispatch(getAllOpenExchangeReceipts())
      .then((response) => {
        const data = Array.isArray(response.payload) ? response.payload : [];
        setMainData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const state = {
    options: {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories: mainData?.map((item) => item.DayRange),
        title: {
          text: 'Gün Aralıkları',
          style: {
            fontSize: '0.8rem',
            fontWeight: 'bold'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '0.8rem'
          }
        }
      }
    },
    series: [
      {
        name: 'Toplam Fiş Sayısı',
        data: mainData?.map((item) => item.RecordCount)
      }
    ]
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card container spacing={2}>
          <CardHeader
            titleTypographyProps={{
              variant: 'body1',
              fontWeight: 'bold'
            }}
            title="Değişim Tarihine Göre Açık Olan Değişimlerin Gün Dağılımları"
          />
          <CardContent>
            {loading ? (
              <>
                <Grid item xs={12} md={12} lg={12}>
                  {Array.from(new Array(3)).map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      width="100%"
                      height={150}
                      sx={{ margin: 1 }}
                    />
                  ))}
                </Grid>
              </>
            ) : (
              <Chart
                options={state.options}
                series={state.series}
                type="bar"
                width={'100%'}
                height={507}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default OpenExchangeReceiptsWithDaysRangeChart;
