import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { getUser } from 'src/utils/Common';
import { makeStyles } from '@mui/styles';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Autocomplete
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getTourInformations } from 'src/store/slices/commonSlice';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'src/utils/axios';
import { confirmAlert } from 'react-confirm-alert';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { getRegions } from 'src/store/slices/assigmentSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Segoe UI',
    width: '100%',
    height: '100%'
  },
  buttons: {
    '& .css-u1865r-MuiButtonBase-root-MuiButton-root:hover': {
      backgroundColor: '#424242'
    }
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  checked: {
    color: '#424242',
    '&.Mui-checked': {
      color: '#FF9800'
    }
  }
}));

function TourManagement() {
  const SessionUser = getUser();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [tourGeneralInformations, setTourGeneralInformations] = React.useState(
    []
  );
  const [tourInfoUpdatePopUp, setTourInfoUpdatePopUp] = React.useState(false);
  const [regionList, setRegionList] = React.useState([]);
  const [selectedUpdateRegion, setSelectedUpdateRegion] = React.useState(null);

  const tourNoInputRef = useRef(null);
  const tourNameInputRef = useRef(null);

  const [tourMondayCheck, setTourMondayCheck] = React.useState([]);
  const [tourTuesdayCheck, setTourTuesdayCheck] = React.useState([]);
  const [tourWednesdayCheck, setTourWednesdayCheck] = React.useState([]);
  const [tourThursdayCheck, setTourThursdayCheck] = React.useState([]);
  const [tourFridayCheck, setTourFridayCheck] = React.useState([]);
  const [tourSaturdayCheck, setTourSaturdayCheck] = React.useState([]);
  const [tourSundayCheck, setTourSundayCheck] = React.useState([]);

  const [tourSaturdayWorkingCheck, setTourSaturdayWorkingCheck] =
    React.useState([]);
  const [tourShipmentCheck, setTourShipmentCheck] = React.useState([]);
  const [tourDeliveryCheck, setTourDeliveryCheck] = React.useState([]);

  const [tourNoCheck, setTourNoCheck] = React.useState([]);
  const [tourNameCheck, setTourNameCheck] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [uyari, setUyari] = React.useState(false);
  const [responseMessage, setresponseMessage] = React.useState({
    ErrorCode: '0',
    ErrorDescription: 'success_message'
  });

  useEffect(() => {
    getRegionList();
    getTourInfo(SessionUser.TopServiceShopCode);
  }, []);

  const getRegionList = () => {
    setLoading(true);
    dispatch(getRegions())
      .then((response) => {
        const data = response.payload || [];
        const filteredData = data.slice(1);
        setRegionList(filteredData);

        const defaultRegion = filteredData.find(
          (i) => i.Id === SessionUser?.TopServiceShopCode
        );
        setSelectedUpdateRegion(defaultRegion);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getTourInfo = (SelectedService) => {
    setLoading(true);
    dispatch(getTourInformations(SelectedService))
      .then((response) => {
        const data = response.payload || [];
        setTourGeneralInformations(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  };

  const handleGetSelectedServiceTourInfo = () => {
    getTourInfo(
      selectedUpdateRegion.Id
        ? selectedUpdateRegion.Id
        : SessionUser.TopServiceShopCode
    );
  };

  const createTour = () => {
    if (
      tourNoInputRef.current.value.length <= 0 ||
      tourNameInputRef.current.value.length <= 0 ||
      !selectedUpdateRegion?.Id
    ) {
      setresponseMessage({
        ErrorCode: 1000,
        ErrorDescription:
          'Lütfen Tur Numarası, Tur adı veya Bölge alanlarını doldurunuz!...'
      });
      setUyari(true);
      return;
    }

    const data = {
      TourNo: tourNoInputRef.current.value,
      TourName: tourNameInputRef.current.value,
      TopServiceShopCode: selectedUpdateRegion?.Id
    };
    setLoading(true);
    axios
      .post('TourManagementCreateTours', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response.data.ErrorCode === '0') {
          setresponseMessage({
            ErrorCode: 0,
            ErrorDescription: 'İşleminiz başarılıyla gerçekleşmiştir...'
          });
          setUyari(true);

          getTourInfo(selectedUpdateRegion?.Id);

          tourNoInputRef.current.value = '';
          tourNameInputRef.current.value = '';

          setLoading(false);
          return;
        } else {
          setresponseMessage({
            ErrorCode: 1000,
            ErrorDescription: response.data.ErrorDescription
          });
          setUyari(true);

          tourNoInputRef.current.value = '';
          tourNameInputRef.current.value = '';

          setLoading(false);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  };

  const deleteTour = (row) => {
    const data = {
      TourNo: row.Tur,
      TourName: row.TUR_ADI,
      TopServiceShopCode: selectedUpdateRegion?.Id
    };

    confirmAlert({
      message: 'Bu işlemi gerçekleştirmek istediğinizden emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            axios
              .post('TourManagementDeleteTours', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                if (response.data.ErrorCode === '0') {
                  setresponseMessage({
                    ErrorCode: 0,
                    ErrorDescription: 'İşleminiz başarılıyla gerçekleşmiştir...'
                  });
                  setUyari(true);
                  getTourInfo(selectedUpdateRegion?.Id);
                  setLoading(false);
                  return;
                } else {
                  setLoading(false);
                  return;
                }
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => {}
        }
      ]
    });
  };

  const updateTourInformationClick = (row) => {
    setTourMondayCheck(row.PAZARTESI);
    setTourTuesdayCheck(row.SALI);
    setTourWednesdayCheck(row.CARSAMBA);
    setTourThursdayCheck(row.PERSEMBE);
    setTourFridayCheck(row.CUMA);
    setTourSaturdayCheck(row.CUMARTESI);
    setTourSundayCheck(row.PAZAR);

    setTourSaturdayWorkingCheck(row.CUMARTESI_CALISMA_DURUMU);
    setTourShipmentCheck(row.YUKLEMEID);
    setTourDeliveryCheck(row.TESLIMATID);

    setTourNoCheck(row.Tur);
    setTourNameCheck(row.TUR_ADI);

    setTourInfoUpdatePopUp(true);
  };

  const updateInformationsOfTour = () => {
    const data = {
      Monday: tourMondayCheck,
      Tuesday: tourTuesdayCheck,
      Wednesday: tourWednesdayCheck,
      Thursday: tourThursdayCheck,
      Friday: tourFridayCheck,
      Saturday: tourSaturdayCheck,
      Sunday: tourSundayCheck,
      SaturdayWorkingStatus: tourSaturdayWorkingCheck,
      ShipmentId: tourShipmentCheck,
      DeliveryId: tourDeliveryCheck,
      TourNo: tourNoCheck,
      TourName: tourNameCheck,
      TopServiceShopCode: selectedUpdateRegion?.Id
    };
    setLoading(true);
    axios
      .post('TourManagementUpdateInformation', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response.data.ErrorCode === '0') {
          setresponseMessage({
            ErrorCode: 0,
            ErrorDescription: 'İşleminiz başarılıyla gerçekleşmiştir...'
          });
          setUyari(true);

          getTourInfo(selectedUpdateRegion?.Id);
          setTourInfoUpdatePopUp(false);

          setLoading(false);
          return;
        } else {
          setresponseMessage({
            ErrorCode: 1000,
            ErrorDescription: response.data.ErrorDescription
          });
          setUyari(true);

          setTourInfoUpdatePopUp(false);

          setLoading(false);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  };

  const columns = [
    { field: 'Tur', headerName: 'Tur', width: 200 },
    { field: 'TUR_ADI', headerName: 'Tur Adı', width: 200 },
    {
      field: 'PAZARTESI',
      headerName: 'Pazartesi',
      width: 130,
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}>
            <Checkbox
              name="checkedB"
              checked={cellValues.row.PAZARTESI}
              className={classes.checked}
            />
          </Box>
        );
      }
    },
    {
      field: 'SALI',
      headerName: 'Salı',
      width: 130,
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}>
            <Checkbox
              name="checkedB"
              checked={cellValues.row.SALI}
              className={classes.checked}
            />
          </Box>
        );
      }
    },
    {
      field: 'CARSAMBA',
      headerName: 'Çarşamba',
      width: 130,
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}>
            <Checkbox
              name="checkedB"
              checked={cellValues.row.CARSAMBA}
              className={classes.checked}
            />
          </Box>
        );
      }
    },
    {
      field: 'PERSEMBE',
      headerName: 'Perşembe',
      width: 130,
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}>
            <Checkbox
              name="checkedB"
              checked={cellValues.row.PERSEMBE}
              className={classes.checked}
            />
          </Box>
        );
      }
    },
    {
      field: 'CUMA',
      headerName: 'Cuma',
      width: 130,
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}>
            <Checkbox
              name="checkedB"
              checked={cellValues.row.CUMA}
              className={classes.checked}
            />
          </Box>
        );
      }
    },
    {
      field: 'CUMARTESI',
      headerName: 'Cumartesi',
      width: 130,
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}>
            <Checkbox
              name="checkedB"
              checked={cellValues.row.CUMARTESI}
              className={classes.checked}
            />
          </Box>
        );
      }
    },
    {
      field: 'PAZAR',
      headerName: 'Pazar',
      width: 130,
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}>
            <Checkbox
              name="checkedB"
              checked={cellValues.row.PAZAR}
              className={classes.checked}
            />
          </Box>
        );
      }
    },
    {
      field: 'CUMARTESI_CALISMA_DURUMU',
      headerName: 'Cumartesi Çalışma Durumu',
      width: 210,
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}>
            <Checkbox
              name="checkedB"
              checked={cellValues.row.CUMARTESI_CALISMA_DURUMU}
              className={classes.checked}
            />
          </Box>
        );
      }
    },
    { field: 'YUKLEME', headerName: 'Yükleme', width: 100 },
    { field: 'TESLIMAT', headerName: 'Teslimat', width: 100 },
    {
      field: 'Güncelleme',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}>
            <Button
              variant="outlined"
              size="small"
              fullWidth
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={() => {
                updateTourInformationClick(cellValues.row);
              }}
            >
              Güncelle
            </Button>
          </Box>
        );
      },
      width: 150
    },
    {
      field: 'Sil',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}>
            <Button
              variant="outlined"
              size="small"
              fullWidth
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={() => {
                deleteTour(cellValues.row);
              }}
            >
              Sil
            </Button>
          </Box>
        );
      },
      width: 150
    }
  ];

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={8}>
        <Snackbar
          sx={{ width: '100%' }}
          open={uyari}
          autoHideDuration={6000}
          onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          {responseMessage.ErrorCode === 1000 ? (
            <Alert
              icon={<MoodBadIcon />}
              style={{ minWidth: 500 }}
              sx={{
                '& .MuiAlert-icon': {
                  color: 'white'
                },
                height: 75,
                alignItems: 'center',
                verticalAlign: 'center'
              }}
              onClose={uyariKapat}
              variant="filled"
              severity="error"
            >
              {responseMessage.ErrorDescription}
            </Alert>
          ) : (
            <Alert
              icon={<TagFacesIcon />}
              style={{ minWidth: 500 }}
              sx={{
                '& .MuiAlert-icon': {
                  color: 'white'
                },
                height: 75,
                alignItems: 'center',
                verticalAlign: 'center'
              }}
              onClose={uyariKapat}
              variant="filled"
              severity="success"
            >
              {responseMessage.ErrorDescription}
            </Alert>
          )}
        </Snackbar>
      </Stack>
      <Card container className={classes.root} component={Page}>
        <Grid item xs={12} sm={12} md={12}>
          <CardHeader
            title={`Tur Yönetimi: ${
              selectedUpdateRegion
                ? selectedUpdateRegion?.Id
                : SessionUser.TopServiceShopCode
            }`}
            sx={{ color: 'red' }}
          />
          <CardContent>
            {/* Sayfanın datagride sürekli render atmasından dolayı textfieldlarda yazı yazarken kasma oluyordu. Bu yüzden useRef tercih edildi. */}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  id="combo-box-demo"
                  options={regionList}
                  getOptionLabel={(option) => option.Dsc}
                  value={selectedUpdateRegion}
                  size="small"
                  fullWidth
                  onChange={(event, val) => {
                    setSelectedUpdateRegion(val);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Bölge" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                  onClick={() => {
                    handleGetSelectedServiceTourInfo();
                  }}
                >
                  Bölgeyi Getir
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={2} />
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Tur Numarası"
                  inputRef={tourNoInputRef}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Tur Adı"
                  inputRef={tourNameInputRef}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                  onClick={() => {
                    createTour();
                  }}
                >
                  Tur Yarat
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CardContent>
            <DataGrid
              style={{
                height: 600,
                width: '100%',
                textAlign: 'center',
                justifyItems: 'center'
              }}
              columns={columns}
              rows={tourGeneralInformations}
              getRowId={(rows) => rows.ID}
              rowsPerPageOptions={[25, 50, 100]}
            />
          </CardContent>
        </Grid>
      </Card>

      {/* Tur Bilgileri Güncelleme PopUp */}
      <Dialog
        open={tourInfoUpdatePopUp}
        onClose={() => setTourInfoUpdatePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            width: '100%',
            color: 'red',
            fontSize: '16px',
            fontWeight: 'bold'
          }}
        >
          {' '}
          Tur Bilgilerini Güncelle
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Tur Numarası"
                size="small"
                fullWidth
                disabled
                variant="outlined"
                value={tourNoCheck}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Tur Adı"
                size="small"
                fullWidth
                disabled
                autoComplete="no"
                variant="outlined"
                value={tourNameCheck}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Pazartesi"
                size="small"
                fullWidth
                select
                autoComplete="no"
                variant="outlined"
                value={tourMondayCheck}
                onChange={(event) => {
                  setTourMondayCheck(event.target.value);
                }}
              >
                <MenuItem key={1} value={true}>
                  {'Aktif'}
                </MenuItem>

                <MenuItem key={0} value={false}>
                  {'Pasif'}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Salı"
                size="small"
                fullWidth
                select
                autoComplete="no"
                variant="outlined"
                value={tourTuesdayCheck}
                onChange={(event) => {
                  setTourTuesdayCheck(event.target.value);
                }}
              >
                <MenuItem key={1} value={true}>
                  {'Aktif'}
                </MenuItem>

                <MenuItem key={0} value={false}>
                  {'Pasif'}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Çarşamba"
                size="small"
                fullWidth
                select
                autoComplete="no"
                variant="outlined"
                value={tourWednesdayCheck}
                onChange={(event) => {
                  setTourWednesdayCheck(event.target.value);
                }}
              >
                <MenuItem key={1} value={true}>
                  {'Aktif'}
                </MenuItem>

                <MenuItem key={0} value={false}>
                  {'Pasif'}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Perşembe"
                size="small"
                fullWidth
                select
                autoComplete="no"
                variant="outlined"
                value={tourThursdayCheck}
                onChange={(event) => {
                  setTourThursdayCheck(event.target.value);
                }}
              >
                <MenuItem key={1} value={true}>
                  {'Aktif'}
                </MenuItem>

                <MenuItem key={0} value={false}>
                  {'Pasif'}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Cuma"
                size="small"
                fullWidth
                select
                autoComplete="no"
                variant="outlined"
                value={tourFridayCheck}
                onChange={(event) => {
                  setTourFridayCheck(event.target.value);
                }}
              >
                <MenuItem key={1} value={true}>
                  {'Aktif'}
                </MenuItem>

                <MenuItem key={0} value={false}>
                  {'Pasif'}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Cumartesi"
                size="small"
                fullWidth
                select
                autoComplete="no"
                variant="outlined"
                value={tourSaturdayCheck}
                onChange={(event) => {
                  setTourSaturdayCheck(event.target.value);
                }}
              >
                <MenuItem key={1} value={true}>
                  {'Aktif'}
                </MenuItem>

                <MenuItem key={0} value={false}>
                  {'Pasif'}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Pazar"
                size="small"
                fullWidth
                select
                autoComplete="no"
                variant="outlined"
                value={tourSundayCheck}
                onChange={(event) => {
                  setTourSundayCheck(event.target.value);
                }}
              >
                <MenuItem key={1} value={true}>
                  {'Aktif'}
                </MenuItem>

                <MenuItem key={0} value={false}>
                  {'Pasif'}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Cumartesi Çalışma Durumu"
                size="small"
                fullWidth
                select
                autoComplete="no"
                variant="outlined"
                value={tourSaturdayWorkingCheck}
                onChange={(event) => {
                  setTourSaturdayWorkingCheck(event.target.value);
                }}
              >
                <MenuItem key={1} value={true}>
                  {'Aktif'}
                </MenuItem>

                <MenuItem key={0} value={false}>
                  {'Pasif'}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Yükleme"
                size="small"
                fullWidth
                select
                autoComplete="no"
                variant="outlined"
                value={tourShipmentCheck}
                onChange={(event) => {
                  setTourShipmentCheck(event.target.value);
                }}
              >
                <MenuItem key={0} value={1}>
                  {'Ertesi Gün'}
                </MenuItem>

                <MenuItem key={1} value={2}>
                  {'Aynı Gün'}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Teslimat"
                size="small"
                fullWidth
                select
                autoComplete="no"
                variant="outlined"
                value={tourDeliveryCheck}
                onChange={(event) => {
                  setTourDeliveryCheck(event.target.value);
                }}
              >
                <MenuItem key={0} value={1}>
                  {'Ertesi Gün'}
                </MenuItem>

                <MenuItem key={1} value={2}>
                  {'Aynı Gün'}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                onClick={() => {
                  updateInformationsOfTour();
                }}
              >
                Tur Bilgilerini Güncelle
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => {
              setTourInfoUpdatePopUp(false);
            }}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TourManagement;
