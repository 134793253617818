
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { Backdrop, Box, Card, CardContent, CircularProgress, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { DataGrid, trTR } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAuthorizedMenu, updateMenuRoles } from 'src/store/slices/authorizationSlice';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  color: {
    color: '#424242'
  },
  button: {
    color: '#FF9800', backgroundColor: '#424242'
  },
  checked: {
    color: '#FF9800',
    '&.Mui-checked': {
      color: '#FF9800',
    }
  },
}));

const defaultRoles = {
  Normal: false,
  Admin: false
}

function MenuRoleAutho() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [menuList, setMenuList] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [selectedMenu, setSelectedMenu] = React.useState([])
  const [selectedMenuRole, setSelectedMenuRole] = React.useState(defaultRoles)
  const [changeRoleModal, setChangeRoleModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)


  useEffect(() => {
    setLoading(true)
    dispatch(getAuthorizedMenu()).then(response => {
      if (response.payload?.length > 0) {
        setMenuList(response.payload)
        setLoading(false)
      }
    })
  }, [dispatch]);

  useEffect(() => {
    const searchedRows = menuList.filter(i => i.MENU_NAME.toLowerCase().includes(search.toLowerCase()))
    setRows(searchedRows)
  }, [search, menuList]);


  const updateMenuRoleClick = () => {
    if (!selectedMenuRole || selectedMenuRole.length == 0) return

    var menuRoles = Object.keys(selectedMenuRole).map(i => { return selectedMenuRole[i] * 1 }).join(',')
    const data = {
      MenuRoles: menuRoles,
      MenuId: selectedMenu.MENU_ID
    }

    setLoading(true)
    dispatch(updateMenuRoles(data)).then(response => {
      dispatch(getAuthorizedMenu()).then(response => {
        if (response.payload?.length > 0) {
          setMenuList(response.payload)
          setLoading(false)
        }
      })
      setChangeRoleModal(false)
      setLoading(false)
      setSelectedMenu([])
      setSelectedMenuRole(defaultRoles)
    })
  }
  const handleUpdateRoleClick = (row) => {
    setSelectedMenu(row)
    setChangeRoleModal(true)

    if (!row?.Roles) {
      setSelectedMenuRole(defaultRoles)
      return
    }
    var roles = row.Roles.split(',')
    const userRoles = {
      Normal: roles.includes('1'),
      Admin: roles.includes('2'),
      DegisimNormal: roles.includes('3'),
      DegisimAdmin: roles.includes('4'),
      UrunDegisimYonetici: roles.includes('5'),
      CozumMerkeziBPONormal: roles.includes('6'),
      ParaIadesiAdmin: roles.includes('7'),
      SatisYoneticiligi: roles.includes('8'),
      SistemKlima: roles.includes('9'),
      Muhasebe: roles.includes('10'),
      TedarikZinciri: roles.includes('11'),
      KaliteGuvenceYoneticiligi: roles.includes('12'),
      MuadilUrunNormal: roles.includes('13'),
      MuadilUrunAdmin: roles.includes('14')
    }
    setSelectedMenuRole(userRoles)
  }
  const columns = [
    { field: 'MENU_ID', headerName: 'Menü Sıra', width: 100 },
    { field: 'MENU_NAME', headerName: 'Menü', width: 300 },
    {
      field: 'Güncelle',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', "& > :not(style)": { m: 1 } }}>
            <Tooltip title="Güncelle">
              <IconButton onClick={() => { handleUpdateRoleClick(cellValues.row) }} >
                <CreateIcon className={classes.color} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }, width: 150
    }
  ];

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item sm={6}></Grid>
            <Grid item sm={6} spacing={2}>
              <TextField
                variant="standard"
                size='small'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Ara..."
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item sm={12} style={{ paddingTop: 5 }}></Grid>
          <DataGrid
            style={{ height: 600, width: '100%', textAlign: 'center', justifyItems: 'center', }}
            columns={columns}
            rows={rows}
            getRowId={(row) => row.MENU_ID}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
          />
        </CardContent>
      </Card>
      <Dialog
        open={changeRoleModal}
        onClose={() => setChangeRoleModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ width: '100%' }} >{"Menü Rol"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                disabled
                value={selectedMenu.MENU_NAME}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl sx={{ m: 3 }} component="div" variant="standard">
                <FormLabel component="legend" className={classes.color}>Kullanıcı rolleri</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.Normal}
                        onChange={() => setSelectedMenuRole({ ...selectedMenuRole, Normal: !selectedMenuRole.Normal })}
                        name="ÇözümMerkeziNormal"
                        className={classes.checked}
                      />
                    }
                    label="Çözüm Merkezi Normal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.Admin}
                        onChange={() => setSelectedMenuRole({ ...selectedMenuRole, Admin: !selectedMenuRole.Admin })}
                        name="ÇözümMerkeziAdmin"
                        className={classes.checked}
                      />
                    }
                    label="Çözüm Merkezi Admin"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.DegisimNormal}
                        onChange={() => setSelectedMenuRole({ ...selectedMenuRole, DegisimNormal: !selectedMenuRole.DegisimNormal })}
                        name="Değişim Normal"
                        className={classes.checked}
                      />
                    }
                    label="Değişim Ofis Normal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.DegisimAdmin}
                        onChange={() => setSelectedMenuRole({ ...selectedMenuRole, DegisimAdmin: !selectedMenuRole.DegisimAdmin })}
                        name="Değişim Admin"
                        className={classes.checked}
                      />
                    }
                    label="Değişim Ofis Admin"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.UrunDegisimYonetici}
                        onChange={() => { setSelectedMenuRole({ ...selectedMenuRole, UrunDegisimYonetici: !selectedMenuRole.UrunDegisimYonetici }) }}
                        name="Ürün Değişim Yöneticisi"
                        className={classes.checked}
                      />
                    }
                    label="Ürün Değişim Yöneticisi"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.CozumMerkeziBPONormal}
                        onChange={() => { setSelectedMenuRole({ ...selectedMenuRole, CozumMerkeziBPONormal: !selectedMenuRole.CozumMerkeziBPONormal }) }}
                        name="Çözüm Merkezi BPO Normal"
                        className={classes.checked}
                      />
                    }
                    label="Çözüm Merkezi BPO Normal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.ParaIadesiAdmin}
                        onChange={() => { setSelectedMenuRole({ ...selectedMenuRole, ParaIadesiAdmin: !selectedMenuRole.ParaIadesiAdmin }) }}
                        name="Para İadesi Admin"
                        className={classes.checked}
                      />
                    }
                    label="Para İadesi Admin"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.SatisYoneticiligi}
                        onChange={() => setSelectedMenuRole({ ...selectedMenuRole, SatisYoneticiligi: !selectedMenuRole.SatisYoneticiligi })}
                        name="Satış Yöneticiliği"
                        className={classes.checked}
                      />
                    }
                    label="Satış Yöneticiliği"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.SistemKlima}
                        onChange={() => setSelectedMenuRole({ ...selectedMenuRole, SistemKlima: !selectedMenuRole.SistemKlima })}
                        name="Sistem Klima"
                        className={classes.checked}
                      />
                    }
                    label="Sistem Klima"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.Muhasebe}
                        onChange={() => setSelectedMenuRole({ ...selectedMenuRole, Muhasebe: !selectedMenuRole.Muhasebe })}
                        name="Muhasebe"
                        className={classes.checked}
                      />
                    }
                    label="Muhasebe"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.TedarikZinciri}
                        onChange={() => setSelectedMenuRole({ ...selectedMenuRole, TedarikZinciri: !selectedMenuRole.TedarikZinciri })}
                        name="Tedarik Zinciri"
                        className={classes.checked}
                      />
                    }
                    label="Tedarik Zinciri"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.KaliteGuvenceYoneticiligi}
                        onChange={() => setSelectedMenuRole({ ...selectedMenuRole, KaliteGuvenceYoneticiligi: !selectedMenuRole.KaliteGuvenceYoneticiligi })}
                        name="Kalite Güvence Yöneticiliği"
                        className={classes.checked}
                      />
                    }
                    label="Kalite Güvence Yöneticiliği"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.MuadilUrunNormal}
                        onChange={() => setSelectedMenuRole({ ...selectedMenuRole, MuadilUrunNormal: !selectedMenuRole.MuadilUrunNormal })}
                        name="Muadil Ürün Normal"
                        className={classes.checked}
                      />
                    }
                    label="Muadil Ürün Normal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenuRole.MuadilUrunAdmin}
                        onChange={() => setSelectedMenuRole({ ...selectedMenuRole, MuadilUrunAdmin: !selectedMenuRole.MuadilUrunAdmin })}
                        name="Muadil Ürün Admin"
                        className={classes.checked}
                      />
                    }
                    label="Muadil Ürün Admin"
                  />
                </FormGroup>
                <FormHelperText>Birden fazla seçim yapabilirsiniz.</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            fullWidth
            size="small"
            className={classes.button}
            onClick={() => updateMenuRoleClick()}
          >Güncelle</Button>
        </DialogContent>
        <DialogActions>
          <IconButton
            className={classes.color}
            onClick={() => setChangeRoleModal(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MenuRoleAutho;
