import React, { useEffect, useState } from 'react';
import { Card, CardContent, TableContainer, Paper, TableHead, TableBody, Tooltip, TableCell, TableRow, Table, CircularProgress, Backdrop, Grid, Switch, Button, Dialog, DialogContent, DialogActions, DialogTitle, IconButton, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'src/utils/axios';
import SaveIcon from '@mui/icons-material/Save';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }

}));

function SetStatus() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [savei, setsavei] = useState(false);
  const [aradurumlar, setaradurumlar] = useState([{}]);
  const [durumlist, setdurumlist] = useState([]);
  const [newsubstatus, setnewsubstatus] = useState(false);
  const [cozummerkezivale, setcozummerkezivalue] = useState(0);
  const [degisimofisivale, setdegisimofisivale] = useState(0);
  const [aradurumadi, setaradurumadi] = useState(false);
  const [aradurumadivalue, setaradurumadivalue] = useState("");
  const setaradurum = event => {
    if (aradurumadi === false) { setaradurumadi(true); }
    setaradurumadivalue(event.target.value);
  }
  const getAraDurum = () => {
    axios.get("/GetStatusList", {
    }).then(response => {
      setaradurumlar(response.data);
      setLoading(false);
    });
  }
  const setDurum = (value, substatus, status) => {
    if (value.target.checked === true) {
      setdurumlist([...durumlist, { value: 1, substatus: substatus, status: status }])
    }
    else {
      setdurumlist([...durumlist, { value: 0, substatus: substatus, status: status }])

    }
    //console.log(durumlist);
    setsavei(true);

  }
  if (aradurumlar.length < 2) {
    getAraDurum();
  }

  const Kaydet = () => {
    setLoading(true);
    for (let i = 0, len = durumlist.length; i < len; i++) {
      const durum = durumlist[i];
      axios.post('SetStatusList', durum, {
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {

        setdurumlist([]);
        setsavei(false);
        setdegisimofisivale(false);
        setcozummerkezivalue(false);
        setLoading(false);
      });

    }
  }
  const closenewsubstatus = () => {
    setnewsubstatus(false);
    setLoading(true);
    getAraDurum();
    setcozummerkezivalue(false);
    setdegisimofisivale(false);
    setaradurumadivalue("");
  }
  const ekle = (aciklama, cm, dof) => {
    setLoading(true);
    if (cm === true) { cm = 1; }
    else { cm = 0; }
    if (dof === true) { dof = 1; }
    else { dof = 0; }
    const durum = { aciklama: aciklama, cozummerkezi: cm, degisimofisi: dof };
    console.log(durum);
    axios.post('InsertToStatusList', durum, {
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {

      setaradurumadivalue("");
      setLoading(false);
      alert(response.data.ErrorDescription);
      closenewsubstatus();
    });

  }
  useEffect(() => {
    if (aradurumadivalue === "") { setaradurumadi(false) }
  }, [aradurumadivalue]);

  const label = { inputProps: { 'aria-label': 'Color switch demo' } };
  return (
    <Card>
      <CardContent>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* <Button
          variant="contained"
          color="primary"
          size="small"
          component="span"
          sx={{ color: '#FF9800', backgroundColor: '#424242' }}
          endIcon={<AddIcon>Kaydet</AddIcon>}
          onClick={() => setnewsubstatus(true)}>
          Yeni Ara Durum
        </Button> */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><b>Ara Durum</b></TableCell>
                <TableCell align='center'><b>Çözüm Merkezi</b></TableCell>
                <TableCell align='center'><b>Değişim Ofisi</b></TableCell>
                <TableCell align='center'>  <Tooltip title="Yeni Ara Durum Ekle">
                  <IconButton
                    color="primary"
                    aria-label="close"
                    component="span"
                    variant="contained"
                    onClick={() => setnewsubstatus(true)}
                  >
                    <PersonAddIcon sx={{ color: '#FF9800' }} />
                  </IconButton>
                </Tooltip></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow></TableRow>
              {
                aradurumlar.length > 2 && aradurumlar?.map(item =>
                  <TableRow>
                    <TableCell><b>{item.ACIKLAMA}</b></TableCell>
                    <TableCell align='center'><Switch {...label} defaultChecked={item.COZUM_MERKEZI} color="warning" onChange={(value, substatus, status) => setDurum(value, item.ARA_DURUM_ID, 23)} /></TableCell>
                    <TableCell align='center'><Switch {...label} defaultChecked={item.DEGISIM_OFISI} color="warning" onChange={(value, substatus, status) => setDurum(value, item.ARA_DURUM_ID, 14)} /></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )
              }

            </TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align='center'>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  component="span"
                  sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                  endIcon={<SaveIcon>Kaydet</SaveIcon>}
                  disabled={!savei}
                  onClick={Kaydet}>
                  Kaydet
                </Button></TableCell>
            </TableRow>
          </Table>
        </TableContainer>

      </CardContent>
      <Dialog
        open={newsubstatus}
        close={closenewsubstatus}
        fullWidth>
        <DialogTitle sx={{ width: '100%' }} >{"Yeni Ara Durum"}</DialogTitle>
        <DialogContent>

          <CardContent>
            <Grid container spacing={1}>

              <Grid item xs={6} md={6}>
                <b>Çözüm Merkezi</b>

                <Switch {...label} defaultChecked={cozummerkezivale} color="warning" onChange={() => setcozummerkezivalue(!cozummerkezivale)} />
              </Grid>


              <Grid item xs={6} md={6}>
                <b>Değişim Ofisi</b>

                <Switch {...label} defaultChecked={degisimofisivale} color="warning" onChange={() => setdegisimofisivale(!degisimofisivale)} />
              </Grid>
              <br></br>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Ara Durum Adı"
                  size="small"
                  onChange={setaradurum}
                />
              </Grid>
              <Grid item xs={12} md={12}>

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  component="submit"
                  sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                  endIcon={<SaveIcon>Kaydet</SaveIcon>}
                  disabled={!aradurumadi}
                  onClick={() => ekle(aradurumadivalue, cozummerkezivale, degisimofisivale)}>
                  Ekle
                </Button>
              </Grid>
            </Grid>
          </CardContent>

        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={closenewsubstatus}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>

    </Card>

  );
}

export default SetStatus;
