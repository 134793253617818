
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Card, CardContent } from '@mui/material';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import EquivalentProductManagement from 'src/components/EquivalentProductManagement';
import EquivalentProductManager from 'src/components/EquivalentProductManager';
import BalanceIcon from '@mui/icons-material/Balance';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { getUserRole } from 'src/utils/Common';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI",
    width: '100%',
    height: '100%'
  }

}));

function EquivalentProducts(){
  
  const classes = useStyles();
  const [value, setValue] = React.useState('1');
  const [admin,setAdmin] = React.useState(false);
  const Role = getUserRole().RoleId;



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if(Role?.filter(i => i.ROLE_ID == 14).length > 0){
      setAdmin(true)
    }
  }, []); 
  return( <>
    <Card>
      <CardContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList variant="scrollable" onChange={handleChange}
                sx={{
                  textColor: '#424242',
                  "& .css-xe9pe1-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                    color: "#FF9800"
                  }, "& .css-10hkket.Mui-selected": {
                    color: "#FF9800"
                  }
                }}
                TabIndicatorProps={{ style: { background: '#FF9800' } }}>
                <Tab sx={{ width: '100%' }} icon={<BalanceIcon />} label="Ürün Düzenleme" value="1" />
                <Tab sx={{ width: '100%' }} icon={<SupervisorAccountIcon />} label="Yönetİm" value="2" disabled={!admin}/>
              </TabList>
            </Box>
            <TabPanel value="1">
              <EquivalentProductManagement />
            </TabPanel>
            <TabPanel value="2">
            <EquivalentProductManager />
            </TabPanel>
            

          </TabContext>
        </Box>
      </CardContent>
    </Card>
  </>);
}

export default EquivalentProducts;