import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};

export const solutionCenterAccept = createAsyncThunk('/SolutionCenterAccept', async (data) => {
  const response = await axios.post('/SolutionCenterAccept', data, dataConfig);
  return response.data;
});

export const updateRefundConvince = createAsyncThunk('/UpdateRefundConvince', async () => {
  const response = await axios.get('/UpdateRefundConvince', dataConfig);
  return response.data;
});


export const getMaterialCost = createAsyncThunk('/GetMaterialCost', async (receiptNo) => {
  const response = await axios.get(`/GetMaterialCost/${receiptNo}`, dataConfig);
  return response.data;
});

const solutionCenterProcessSlice = createSlice({
  name: 'solutionCenterProcess',
  initialState: {
    loading: '',
    error: '',

  },
  reducers: {
  },
  extraReducers: {



  }
});
export const solutionCenterProcessActions = solutionCenterProcessSlice.actions;
export default solutionCenterProcessSlice;