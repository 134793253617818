import { IconButton, CardContent, Tooltip, Box, Grid, TextField, Autocomplete, Button, Backdrop, CircularProgress } from '@mui/material';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { getCampaignProductDynamic, insertCampaignProductDynamic, deleteCampaignProductDynamic } from 'src/store/slices/dynamicCatalogSlice';
import { getCustomerTypes } from 'src/store/slices/assigmentSlice';
import { getChangeReasons, getChangeReasonWithUp } from 'src/store/slices/commonSlice';
import { getUser } from 'src/utils/Common';
import AlertAndLoading from 'src/components/AlertAndLoading';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }
}));


function CampaignProductSkuDynamic() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();

  const customerTypes = useSelector((state) => state.assigmentSlice.customerTypes);
  const changeReasonData = useSelector((state) => state.commonSlice.changeReasons);
  const upChangeData = useSelector((state) => state.commonSlice.upChangeReasons);
  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);
  //const [changeReasonList, setChangeReasonList] = React.useState(changeReasonData.filter(i => i.ChangeReason == invoiceData[0]?.Exchange?.ExchangeResonMain)[0]);
  const [changeReasonn, setChangeReasons] = React.useState(null);// Const adı değiştirilecek!!!!
  const [upChangeReason, setUpChangeReasons] = React.useState(null);
  const [campaignProduct, setCampaignProduct] = React.useState([]);
  const [campaignProductModelCode, setCampaignProductModelCode] = React.useState("");
  const [customerType, setCustomerType] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });


  useEffect(() => {
    setLoading(true);
    dispatch(getCampaignProductDynamic()).then(response => {
      if (response.payload?.length > 0) {
        setCampaignProduct(response.payload);
        setLoading(false);
      }
      setLoading(false);
    })
    dispatch(getCustomerTypes());
    dispatch(getChangeReasons());
  }, [dispatch]);

  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  const columns = [
    { field: 'MODEL_CODE', headerName: 'Model Kodu', flex: 1, },
    { field: 'CUSTOMER_TYPE', headerName: 'Müşteri Tipi', flex: 1, },
    { field: 'EXCHANGE_REASON', headerName: 'Değişim Nedeni', flex: 1, },
    { field: 'EXCHANGE_REASON_DETAIL', headerName: 'Değişim Nedeni Detay', flex: 1, },
    { field: 'SICILNO', headerName: 'Sicil No', flex: 1, },
    {
      field: 'ADDED_DATE', headerName: 'Eklenen Tarih', width: 200,
      renderCell: (cellValues) => {
        return (
          moment(cellValues.row.ADDED_DATE).format('DD-MM-YYYY')
        );
      }
    },
    {
      field: 'Sil',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', "& > :not(style)": { m: 1 } }}>
            <Tooltip title="sil">
              <IconButton onClick={() => deleteCampaignProductDynamicClick(cellValues.row.ID)} >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }, width: 150
    }
  ];

  function changeReason(val) {//Değişim nedeni
    setLoading(true);
    if (val?.ChangeReason) {
      setChangeReasons(val);
      dispatch(getChangeReasonWithUp(val?.ChangeReason)).then((response) => {
        setUpChangeReasons(response.payload)
        setLoading(false);
      })
    } else {
      setLoading(false);
      return
    }
    return
  }




  function InsertCampaignProductClick(val) {
    setLoading(true)
    if (campaignProductModelCode == "" || changeReasonn == null || customerType == null || upChangeReason.ACIKLAMA == null) {
      InfoAlert('Lütfen Kutucukları Boş Bırakmayınız!...', 'error', 'Hata');
      setLoading(false)
      return
    }


    const data = {
      ModelCode: campaignProductModelCode,
      CustomerType: customerType.Id,
      ExchangeReason: changeReasonn.ChangeReason,
      ExchangeReasonDetail: upChangeReason.DEGISIM_NEDENI,
      SicilNo: SessionUser?.UserId?.toString(),
    }

    dispatch(insertCampaignProductDynamic(data)).then(response => {
      if (response.payload.ErrorCode == 0) {
        InfoAlert(response.payload.ErrorDescription, 'success', 'Başarılı');
        dispatch(getCampaignProductDynamic()).then(response => {
          if (response.payload?.length > 0) {
            setCampaignProduct(response.payload);
            setLoading(false);
          }
        })
      } else {
        InfoAlert(response.payload.ErrorDescription, 'error', 'Hata');
        setLoading(false);
      }
      setLoading(false);
    })
  }

  function deleteCampaignProductDynamicClick(Id) {
    setLoading(true)
    dispatch(deleteCampaignProductDynamic([Id])).then(response => {
      if (response.payload.ErrorCode == 0) {
        InfoAlert(response.payload.ErrorDescription, 'success', 'Başarılı');
        dispatch(getCampaignProductDynamic()).then(response => {
          if (response.payload?.length > 0) {
            setCampaignProduct(response.payload);
            setLoading(false);

          }
        })
      } else {
        InfoAlert(response.payload.ErrorDescription, 'error', 'Hata');
        setLoading(false);
      }
      setLoading(false);
    })
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  return (
    <>
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              size="small"
              fullWidth
              variant="outlined"
              label="Kampanya Model Kodu"
              value={campaignProductModelCode}
              onChange={(e) => setCampaignProductModelCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              options={customerTypes}
              getOptionLabel={(option) => option.Dsc}
              size="small"
              fullWidth
              renderInput={(params) => <TextField {...params} label="Müşteri Tipi" />}
              onChange={(event, newValue) => { setCustomerType(newValue) }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              options={changeReasonData}
              getOptionLabel={(option) => option.Dsc}
              size="small"
              fullWidth
              renderInput={(params) => <TextField {...params} label="Değişim Nedeni" />}
              onChange={(event, val) => {
                changeReason(val)
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              options={upChangeData}
              getOptionLabel={(option) => option.ACIKLAMA}
              size="small"
              fullWidth
              renderInput={(params) => <TextField {...params} label="Değişim Nedeni Detay" />}
              onChange={(event, val) => {
                setUpChangeReasons(val)
              }}
            />
          </Grid>
          <Grid item xs={9} md={9}></Grid>
          <Grid item xs={3} md={3}>
            <Button
              fullWidth
              size="small"
              variant="contained"
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              style={{ marginTop: 10 }}
              onClick={() => { InsertCampaignProductClick() }}
            >
              Ekle
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid
                rows={campaignProduct}
                columns={columns}
                getRowId={(row) => row.ID}
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}

export default CampaignProductSkuDynamic;






