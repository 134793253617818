import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { Autocomplete, Card, CardContent, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { generalFollowActions, getBrandDescriptionOfExchangeVoucher, getExchangeVoucherInvoicePrice, getWebPointInvoiceDetailFromSap } from 'src/store/slices/generalFollowSlice';
import { getExchangeVoucherInformation } from 'src/store/slices/generalFollowSlice';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import NumberFormat from 'react-number-format';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import moment from 'moment';
import AlertAndLoading from './AlertAndLoading';
import axios from 'src/utils/axios';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  div: {
    fontWeight: 'bold',
    fontSize: '14px',
    width: '100%',
    textDecoration: 'underline',
    paddingTop: '15px'

  },
  disabledTextfield: {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: 'black',
    }
  }
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value || 0,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowNegative={false}
      isNumericString
      suffix="₺"
    />
  );
});

function WebPoint() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);
  const CustomerphoneNum = useSelector((state) => state.generalFollowSlice.invoiceDetail)[0].Exchange?.WebPointCustomerPhoneNumber;


  const phoneNumberOne = invoiceData[0]?.Consumer?.Phone1;
  const phoneNumberTwo = invoiceData[0]?.Consumer?.Phone2 ? invoiceData[0]?.Consumer?.Phone2 : '';
  const phoneNums = [phoneNumberOne, phoneNumberTwo];

  const [equalProductStokNumber, setEqualProductStokNumber] = React.useState(invoiceData[0]?.Exchange?.WebPointStockNumber ? invoiceData[0]?.Exchange?.WebPointStockNumber : invoiceData[0]?.Exchange?.GiveProductStokNumber ? invoiceData[0]?.Exchange?.GiveProductStokNumber : invoiceData[0]?.Model?.UserModelCode);
  const [equalProductDesc, setEqualProductDesc] = React.useState(invoiceData[0]?.Exchange?.GiveProductDesc ? invoiceData[0]?.Exchange?.GiveProductDesc : invoiceData[0]?.Model.ProductDesc ? invoiceData[0]?.Model.ProductDesc : '');
  const [productBrand, setProductBrand] = React.useState(invoiceData[0]?.Apply?.Brand);
  const [webPointInvoicePrice, setWebPointInvoicePrice] = React.useState(invoiceData[0]?.Exchange?.WebPointInvoicePrice);
  const [webPointAvailablePrice, setWebPointAvailablePrice] = React.useState(invoiceData[0]?.Exchange?.WebPointAvaiblePrice);
  const [webPointInfoPopUp, setWebPointInfoPopUp] = React.useState(false);
  const [phoneNumbers, setPhoneNumbers] = React.useState(phoneNums);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = React.useState(
    phoneNums?.length > 0 && CustomerphoneNum
      ? phoneNums?.includes(CustomerphoneNum)
        ? CustomerphoneNum
        : phoneNums[0]
      : null
  );
  const [exchangeVoucherInfoData, setExchangeVoucherInfoData] = React.useState([]);
  const [exchangeVoucherCreatedDate, setExchangeVoucherCreatedDate] = React.useState([]);
  const [exchangeVoucherExpirationDate, setExchangeVoucherExpirationDate] = React.useState([]);
  const [exchangeVoucherTotalAmount, setExchangeVoucherTotalAmount] = React.useState([]);
  const [exchangeVoucherRemainingAmount, setExchangeVoucherRemainingAmount] = React.useState([]);
  const [exchangeVoucherSpendingAmount, setExchangeVoucherSpendingAmount] = React.useState([]);
  const [exchangeVoucherInvoiceDetail, setExchangeVoucherInvoiceDetail] = React.useState([]);
  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });
  const [loading, setLoading] = React.useState(false);
  const [giveStokNumber, setGiveStokNumber] = React.useState(invoiceData[0]?.Exchange?.GiveProductStokNumber ? invoiceData[0]?.Exchange?.GiveProductStokNumber : invoiceData[0]?.Model?.UserModelCode);
  const [equalProductNumber, setEqualProductNumber] = React.useState(invoiceData[0]?.Exchange?.EqualProductStokNo ? invoiceData[0]?.Exchange?.EqualProductStokNo : invoiceData[0]?.Model?.UserModelCode);

  const shouldShowWarning = invoiceData[0]?.Exchange?.WebPointInvoicePriceControlForWarning > 0 && invoiceData[0]?.Exchange?.WebPointInvoiceFlag === 0;
  const isInvoiceValid = invoiceData[0]?.Exchange?.WebPointInvoicePriceControlForWarning === 0 && invoiceData[0]?.Exchange?.WebPointInvoiceFlag === 0;

  useEffect(() => {
    changeWebPointStockNumber(invoiceData[0]?.Exchange?.WebPointStockNumber ? invoiceData[0]?.Exchange?.WebPointStockNumber : invoiceData[0]?.Exchange?.GiveProductStokNumber ? invoiceData[0]?.Exchange?.GiveProductStokNumber : invoiceData[0]?.Model?.UserModelCode)
  }, [invoiceData[0].Exchange.GiveProductStokNumber]);

  // Verilen Ürün Bilgilerinde Sorgula butonuna basıldığında Değişim Çeki alanında bulunan Ürün Kodu ve Açıklamasının anlık olarak güncellenmesi istenmektedir.
  useEffect(() => {
    if (invoiceData[0]?.Consumer?.UpTypeId === 7) {
      setGiveStokNumber(invoiceData[0]?.Exchange?.GiveProductStokNumber ? invoiceData[0]?.Exchange?.GiveProductStokNumber : invoiceData[0]?.Model?.UserModelCode);
      setEqualProductNumber(invoiceData[0]?.Exchange?.EqualProductStokNo ? invoiceData[0]?.Exchange?.EqualProductStokNo : invoiceData[0]?.Model?.UserModelCode);
      setEqualProductDesc(invoiceData[0]?.Exchange?.GiveProductDesc);
      getBrandDescOfExchangeVoucher(invoiceData[0].Exchange?.GiveProductStokNumber);
    }
  }, [invoiceData[0].Exchange.GiveProductStokNumber])

  useEffect(() => {
    changeWebPointPhoneNumber(phoneNums?.length > 0 && CustomerphoneNum
      ? phoneNums?.includes(CustomerphoneNum)
        ? CustomerphoneNum
        : phoneNums[0]
      : null)
  }, []);


  function changeWebPointInvoicePrice(val) {//Fatura Tutarı
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.WebPointInvoicePrice = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setWebPointInvoicePrice(val)
  }

  function changeWebPointPhoneNumber(val) {//Telefon numarası
    if (!val) {
      val = phoneNums[0];
    }
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.WebPointCustomerPhoneNumber = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setSelectedPhoneNumbers(val)
  }

  function changeWebPointStockNumber(val) {
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.WebPointStockNumber = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setEqualProductStokNumber(val)
  }

  function changeWebPointAvailablePrice(val) {
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.WebPointAvaiblePrice = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setWebPointAvailablePrice(val)
  }

  const getExchangeVoucherInfo = (ReceiptNumber) => {
    dispatch(getExchangeVoucherInformation(ReceiptNumber)).then(response => {
      if (response.payload?.length > 0) {
        // Genel datayı sakladığım kısım
        setExchangeVoucherInfoData(response.payload);

        // Alanların Doldurulduğu kısım
        setExchangeVoucherCreatedDate(moment(response.payload[0]?.OLUSTURMA_TARIHI).format('DD.MM.YYYY HH:mm:ss'));
        setExchangeVoucherTotalAmount(response.payload[0]?.TOPLAM_TUTAR);
        setExchangeVoucherSpendingAmount(response.payload[0]?.PUANISLEMTUTAR);
        setExchangeVoucherRemainingAmount(response.payload[0]?.KALANTUTAR);
        setExchangeVoucherExpirationDate(moment(response.payload[0]?.SON_KULLANMA_TARIHI).format('DD.MM.YYYY HH:mm:ss'));

        // PopUp açılıyor.
        setWebPointInfoPopUp(true);
      } else {
        InfoAlert('Bu fiş numarasına ait bir değişim çeki bulunmamaktadır.', 'error', 'Hata')
        setWebPointInfoPopUp(false);
      }
    })
  }

  function getInvoicePriceAndAvaiablePrice(ReceiptNumber) {
    const data = [
      giveStokNumber?.trim()?.toString() || null,
      invoiceData[0]?.Model?.RevisionStokNo?.toString() || null,
      null,
      null,
      invoiceData[0]?.Exchange?.Service
    ]
    setLoading(true);

    axios.get(`/GetProductPrice/${data[0]}/${data[1]}/${data[2]}/${data[3]}/${data[4]}`, {
    }).then(response => {
      if (response.data.GiveProductStokPrice === 0) {
        data[0] = equalProductNumber?.trim()?.toString();
        axios.get(`/GetProductPrice/${data[0]}/${data[1]}/${data[2]}/${data[3]}/${data[4]}`, {
        }).then(response => {
          var tempData = JSON.parse(JSON.stringify(invoiceData))
          if (response.data.GiveProductStokPrice === 0) {
            tempData[0].Exchange.SubStatus = 3
            tempData[0].Exchange.IndermediateState = 'Muadil Belirleme'
          }


          tempData[0].Exchange.WebPointAvaiblePrice = response.data.GiveProductStokPrice
          setWebPointAvailablePrice(response.data.GiveProductStokPrice);
          dispatch(generalFollowActions.updateNewData(tempData))

          GetExchangeVoucherInvoiceDetail(invoiceData[0]?.Apply?.Barcode);
          getWebInvoicePrice(ReceiptNumber);
        })
      } else {

        var tempData = JSON.parse(JSON.stringify(invoiceData))
        tempData[0].Exchange.WebPointAvaiblePrice = response.data.GiveProductStokPrice
        setWebPointAvailablePrice(response.data.GiveProductStokPrice);
        dispatch(generalFollowActions.updateNewData(tempData))

        GetExchangeVoucherInvoiceDetail(invoiceData[0]?.Apply?.Barcode);
        getWebInvoicePrice(ReceiptNumber);
      }
    }).catch(ex => {
      setLoading(false);
      return;
    })

  }

  const getWebInvoicePrice = (ReceiptNumber) => {
    //Fatura Bedel Alanı ve Redux alanını güncelleme
    dispatch(getExchangeVoucherInvoicePrice(ReceiptNumber)).then(response => {
      if (response.payload?.length > 0) {
        setWebPointInvoicePrice(response.payload[0]?.WEB_PUAN_FATURA_BEDEL);
        dispatch(generalFollowActions.updateWebPointInvoicePrice(response.payload[0]?.WEB_PUAN_FATURA_BEDEL));
        if (response.payload[0]?.WEB_PUAN_FATURA_FLAG === 0) {
          InfoAlert('Bu fatura kullanılamaz! Lütfen müşteriden yeni fatura talep ediniz!...', 'warning', 'Uyarı');
        }
        setLoading(false);
      } else {
        InfoAlert('Bu fiş numarasına ait bir fatura bedeli bulunmamaktadır.', 'error', 'Hata')
        setLoading(false);
      }
    }).catch(ex => {
      setLoading(false);
      return;
    })
  }

  const GetExchangeVoucherInvoiceDetail = (BarcodeNumber) => {
    const data = {
      BarcodeNumber: BarcodeNumber
    }

    dispatch(getWebPointInvoiceDetailFromSap(data)).then(response => {
      if (response.payload?.length > 0) {
        //data uygun şekilde düzenlenecek.
        setExchangeVoucherInvoiceDetail(response.payload[0]);
      }
    }).catch(ex => {
      setLoading(false);
      return;
    })
  }

  const getBrandDescOfExchangeVoucher = (KulModelCode) => {
    if (KulModelCode) {
      dispatch(getBrandDescriptionOfExchangeVoucher(KulModelCode.toString())).then(response => {
        if (response.payload?.length > 0) {
          setProductBrand(response.payload[0]?.MARKA_ADI);
        }
      })
    }
  }


  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  return (
    <>
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <Grid style={{ paddingTop: 5 }} >
        <Card sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }} >
          <CardContent >
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography sx={{ width: '100%', fontWeight: 'bold', fontSize: '14px', color: 'red' }} variant="subtitle1">
                  Değişim Çeki Alanı
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title="Değişim Çeki Bilgileri">
                  <IconButton className={classes.action} key="close" onClick={() => { getExchangeVoucherInfo(invoiceData[0]?.Apply?.ReceiptNumber) }}>
                    <InfoIcon sx={{ color: '#FF9800' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              {(invoiceData[0]?.Consumer?.ConsumerType == 1 || invoiceData[0]?.Consumer?.ConsumerType == 2) && (phoneNumbers.length > 0) ?
                <Grid item xs={12}>
                  <Tooltip title="Değişim Çeki Tanımlanacak Telefon Numarası">
                    <Autocomplete
                      options={phoneNumbers}
                      size="small"
                      fullWidth
                      value={selectedPhoneNumbers} // Seçili değeri belirtin
                      renderInput={(params) => <TextField {...params} label="Değişim Çeki Tanımlanacak Telefon" />}
                      onChange={(event, newValue) => { changeWebPointPhoneNumber(newValue) }}
                    /></Tooltip>
                </Grid>
                :
                null
              }
              <Grid item xs={6}>
                <Tooltip title="Ürün Kodu">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Ürün Kodu"
                    size="small"
                    value={equalProductStokNumber}
                    onChange={(event) => { changeWebPointStockNumber(event.target.value) }}
                    fullWidth
                    variant="outlined"
                  /></Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Ürün Açıklama">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Ürün Açıklama"
                    value={equalProductDesc}
                    onChange={(event) => { setEqualProductDesc(event.target.value) }}
                    size="small"
                    fullWidth
                    variant="outlined"
                  /></Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Marka">
                  <TextField
                    id="input-with-icon-textfield"
                    value={productBrand}
                    label="Marka"
                    size="small"
                    fullWidth
                    variant="outlined"
                  /></Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Tooltip title="Güncel Bedel Bilgisi">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Güncel Bedel Bilgisi"
                    size="small"
                    value={webPointAvailablePrice}
                    onChange={(event) => { changeWebPointAvailablePrice(event.target.value) }}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  /></Tooltip>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title="Sorgula">
                  <IconButton
                    size="large"
                    key="close"
                    onClick={() => { getInvoicePriceAndAvaiablePrice(invoiceData[0]?.Apply?.ReceiptNumber); }}
                  >
                    <SavedSearchIcon sx={{ color: '#FF9800' }} size="large" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Fatura Tutarı">
                  <TextField
                    label="Fatura Tutarı"
                    name="numberformat"
                    value={webPointInvoicePrice}
                    onChange={(event) => { changeWebPointInvoicePrice(event.target.value) }}
                    id="formatted-numberformat-input"
                    size="small"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  /></Tooltip>
              </Grid>
              <Grid item xs={6}>
                {invoiceData[0]?.Exchange?.WebPointType === 1 ?
                  <Typography variant="subtitle1" className={classes.div}>
                    Fatura Tutarı manuel eklenmiştir.
                  </Typography> :
                  invoiceData[0]?.Exchange?.WebPointType === 2 ?
                    <Typography variant="subtitle1" className={classes.div}>
                      Fatura Tutarı Rapid'den gelmiştir.
                    </Typography> :
                    invoiceData[0]?.Exchange?.WebPointType === 3 ?
                      <Typography variant="subtitle1" className={classes.div}>
                        Fatura Tutarı ABS'den gelmiştir.
                      </Typography> : null
                }
              </Grid>
              {
                !isInvoiceValid && shouldShowWarning ?
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" style={{ color: 'red' }} className={classes.div}>
                      Bu Fatura kullanıma uygun değildir!
                    </Typography>
                  </Grid>
                  :
                  null
              }
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Dialog
        open={webPointInfoPopUp}
        onClose={() => setWebPointInfoPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ width: '100%', color: 'red', fontWeight: 'bold', fontSize: '14px' }} >{"Değişim Çeki Bilgileri"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={6}>
              <TextField
                id="input-with-icon-textfield"
                label="Değişim Çeki Tanımlama Tarihi"
                size="small"
                disabled
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  style: { color: 'black' }
                }}
                value={exchangeVoucherCreatedDate}
                className={classes.disabledTextfield}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-with-icon-textfield"
                label="Değişim Çeki Tanımlanan Miktar"
                size="small"
                disabled
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  style: { color: 'black' }
                }}
                value={exchangeVoucherTotalAmount + '₺'}
                className={classes.disabledTextfield}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-with-icon-textfield"
                label="Değişim Çeki Kullanılan Miktar"
                size="small"
                disabled
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  style: { color: 'black' }
                }}
                value={exchangeVoucherSpendingAmount + '₺'}
                className={classes.disabledTextfield}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-with-icon-textfield"
                label="Değişim Çeki Kalan Miktar"
                size="small"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  style: { color: 'black' }
                }}
                value={exchangeVoucherRemainingAmount + '₺'}
                className={classes.disabledTextfield}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-with-icon-textfield"
                label="Değişim Çeki Son Kullanma Tarihi"
                size="small"
                disabled
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  style: { color: 'black' }
                }}
                value={exchangeVoucherExpirationDate}
                className={classes.disabledTextfield}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            sx={{ color: '#424242' }}
            onClick={() => setWebPointInfoPopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>


    </>
  );
}

export default WebPoint;