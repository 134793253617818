import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Skeleton
} from '@mui/material';
import Chart from 'react-apexcharts';
import { productGroupStatusChangeChart } from 'src/store/slices/generalMonitoringSlice';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({}));

function ProductGroupStatusChangeChart() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [productGroupStatusChangeData, setProductGroupStatusChangeData] =
    React.useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetProductGroupStatusChangeChart();
  }, []);

  useEffect(() => {
    if (productGroupStatusChangeData?.length > 0) {
      const splitDataProductGroup = productGroupStatusChangeData?.map(
        (i) => i.ANA_GRUP
      );
      const splitDataCount = productGroupStatusChangeData?.map((i) => i.ADET);
      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: splitDataProductGroup
        },
        series: [
          {
            name: 'Adet',
            data: splitDataCount
          }
        ]
      }));
      setLoading(false);
    }
  }, [productGroupStatusChangeData]);

  // State'i kullanarak options ve series'i tanımlıyoruz
  const [options, setOptions] = useState({
    chart: {
      id: 'apexchart-example'
    },
    xaxis: {
      categories: []
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '0.8rem'
        }
      }
    },
    series: [],
    plotOptions: {
      bar: {
        horizontal: true
      }
    }
  });

  const GetProductGroupStatusChangeChart = () => {
    dispatch(productGroupStatusChangeChart()).then((response) => {
      const data = response.payload;
      if (data.length > 0) {
        setProductGroupStatusChangeData(data);
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card container spacing={2}>
          <CardHeader
            titleTypographyProps={{
              variant: 'body1',
              fontWeight: 'bold'
            }}
            title="Ürün Grubu Bazında Açık Değişimler Grafiği"
          />
          <CardContent>
            {loading ? (
              <>
                <Grid item xs={12} md={12} lg={12}>
                  {Array.from(new Array(3)).map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      width="100%"
                      height={150}
                      sx={{ margin: 1 }}
                    />
                  ))}
                </Grid>
              </>
            ) : (
              <Chart
                options={options}
                series={options.series}
                type="bar"
                width={'100%'}
                height={450}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ProductGroupStatusChangeChart;
