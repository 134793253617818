import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { MenuItem, Grid, IconButton, Tooltip, Typography, TextField, Card, CardContent, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { generalFollowActions } from 'src/store/slices/generalFollowSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'src/utils/axios';
import { getUser } from 'src/utils/Common';
import AlertAndLoading from 'src/components/AlertAndLoading';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  div: {
    fontWeight: 'bold',
    fontSize: '14px',
    width: '100%',
    color: 'red'
  },
}));

function WebPointCancel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();

  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);
  const UpdatedInformation = useSelector((state) => state.generalFollowSlice.updatedProductInformation);


  const [expanded, setExpanded] = React.useState(true);
  const [webPointCancelType, setWebPointCancelType] = React.useState(invoiceData[0].Exchange.WebPointCancelType);
  const [loading, setLoading] = React.useState(false);
  const [uyari, setUyari] = React.useState(false);

  const [responseMessage, setresponseMessage] = React.useState('');
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });

  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  const data = {
    ServiceNumber: invoiceData[0]?.Exchange?.Service.toString(),//Servis Numarası
    ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(),//Başvuru No
    OutOfStok: invoiceData[0]?.Exchange?.NoneProduct?.toString(),//Teminsiz Stok
    Status: invoiceData[0]?.Exchange?.Status,
    BankCode: invoiceData[0]?.Exchange?.BankCode?.toString(),//Banka Kodu
    BankName: invoiceData[0]?.Exchange?.BankName?.toString(),//Banka Adı
    IBANNO: invoiceData[0]?.Exchange?.IBANNO?.toString(),//Iban No
    WebSalesInvoiceNumber: invoiceData[0]?.Exchange?.WebSalesInvoiceNumber,//Web Satış Fatura No
    PaymentOwner: invoiceData[0]?.Exchange?.ABSPayAccount?.toString(),//Ödeme Sahibi
    InvoiceNumber: invoiceData[0]?.Consumer?.ChangeInvoiceNo?.toString() || null,//Fatura No
    InvoiceDate: invoiceData[0]?.Consumer?.ChangeInvoiceDate?.toString() || null,//Fiş Tarihi
    InvoiceItemQuantity: invoiceData[0]?.Consumer?.ChangeInvoiceItemQuantity, //DEGISIM_FATURA_KALEM_ADET
    InvoicePrice: invoiceData[0]?.Consumer?.ChangeInvoiceAmount,// Fatura tutarı
    ChangeInvoiceItemOrder: invoiceData[0]?.Consumer?.ChangeInvoiceItemOrder || null,// dEGISIM_FATURA_KALEM_SIRA
    ExchangeReason: invoiceData[0]?.Exchange?.ExchangeResonMain,//altDegisimNedeni
    ExchangeReasonDetail: invoiceData[0]?.Exchange?.ExchangeReson,//degisimNedeniDetay
    IVRGetPrice: invoiceData[0]?.Exchange?.IVRGetPrice?.toString(),
    UpdateUser: SessionUser?.UserId?.toString(),
    GiveProductNumber: invoiceData[0]?.Model?.UserModelCode?.toString() || "",//kul model kodu !! tekrar sor
    ServiceRefundReason: invoiceData[0]?.Exchange?.ServiceRefund?.toString() || "",
    ExchangeType: invoiceData[0]?.Consumer?.LowTypeId,//alt onay türü
    CurrentStatus: invoiceData[0]?.Exchange?.Status,//mevcut durum
    CustomerType: invoiceData[0]?.Consumer?.ConsumerType,//Müşteri tipi
    TopConfirmType: invoiceData[0]?.Consumer?.UpTypeId,//Üst onay türü
    UpConfirmType: invoiceData[0]?.Consumer?.LowTypeId,//alt onay türü
    ProductGetStockNumber: invoiceData[0]?.Model?.RevisionStokNo?.toString() || null, // revizyonlu stok numarası
    UYear: invoiceData[0]?.Apply?.UYear,
    UMonth: invoiceData[0]?.Apply?.UMonth,
    City: invoiceData[0]?.Consumer?.ConsumerType == 3 ? invoiceData[0]?.Retailer?.City : invoiceData[0]?.Consumer?.City,//sehir
    UserModelCode: invoiceData[0]?.Model?.UserModelCode?.toString() || null,
    SubStatus: invoiceData[0]?.Exchange?.SubStatus,
    GiveProductStokNumber: invoiceData[0]?.Exchange?.GiveProductStokNumber?.toString() || "",//verilenUrunStokNo
    GiveProductDesc: invoiceData[0]?.Exchange?.GiveProductDesc?.toString() || null,//verilen ürün açıklama
    GiveProductPrice: invoiceData[0]?.Exchange?.GiveProductPrice || null,//VERİLEN ÜRÜN Ücret
    stokTotal: invoiceData[0]?.Exchange?.stokTotal?.toString() || null,
    ProductTotalPrice: invoiceData[0]?.Exchange?.ProductTotalPrice?.toString() || null,//toplam tutar
    RecomendedPrice: invoiceData[0]?.Exchange?.RecomendedPrice?.toString() || null,//Tavsiye edilen ürün değişim ücreti
    Price: invoiceData[0]?.Exchange?.Price?.toString() || null,//ucretliDegisimTutari ,
    TopModelDifPrice: invoiceData[0]?.Exchange?.ProductRemainPrice?.toString() || null,//üst model fark tutar
    PaymentOwner: invoiceData[0]?.Exchange?.ABSPayAccount?.toString() || null,//dekont sahibi
    RefundConvince: invoiceData[0]?.Exchange?.RefundConvince?.toString() || "",//ikna nedenleri
    UpdateStatus: -1,
    UserID: invoiceData[0]?.UserID,
    ProductBuyStockNumber: invoiceData[0]?.Model.RevisionStokNo,//alınan ürün stok number
    ProductBuyStockPrice: invoiceData[0]?.Exchange?.ReceivedProductPrice,//alınan ürün fiyat
    PaymentCustomerTC: invoiceData[0]?.Exchange?.RefundCustomerTC?.toString() || null,//Tc müşteri
    PaymentCustomerName: invoiceData[0]?.Exchange?.RefundCustomerName?.toString() || null,//müşteri ad
    isServiceGetProduct: invoiceData[0]?.Exchange?.ServiceTakeCustomerProduct,
    ChangeSasNo: invoiceData[0]?.Consumer.ChangeSasNo,
    ChangeSdNo: invoiceData[0]?.Consumer.ChangeSdNo,
    WebPointCancelType: invoiceData[0]?.Exchange.WebPointCancelType,
    WebPointInvoicePrice: invoiceData[0]?.Exchange?.WebPointInvoicePrice,
    WebPointCustomerPhoneNumber: invoiceData[0]?.Exchange.WebPointCustomerPhoneNumber,
    WebPointProductStokNumber: invoiceData[0]?.Exchange?.WebPointStockNumber,
    WebPointAvaiblePrice: invoiceData[0]?.Exchange?.WebPointAvaiblePrice,
  }

  function changeWebPuanCancelType(val) {//verilen ürün stok numarası
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.WebPointCancelType = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setWebPointCancelType(val)
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  function pageUpdate() {
    dispatch(generalFollowActions.updateActiveTab("1"));
  }

  function removeRowInTable() {
    const orjinalData = [...UpdatedInformation];
    const removeRow = orjinalData?.findIndex(x => x.FIS_NO == invoiceData[0]?.Apply?.ReceiptNumber);
    orjinalData.splice(removeRow, 1);
    const xd = orjinalData.length
    dispatch(generalFollowActions.updateProductInformation(orjinalData))
    dispatch(generalFollowActions.updateRowCount(xd))
  }

  function SapSend() {
    setLoading(true);
    data.Status = invoiceData[0]?.Exchange?.Status
    axios.post('ExchangeOfficeSendToSap', data, {
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      if (response.data.ErrorCode == 100) {
        InfoAlert(response.data.ErrorDescription, 'error', 'Hata')
        setLoading(false);
        return
      }
      if (response.data.ErrorCode == 1000) {
        InfoAlert(response.data.ErrorDescription, 'error', 'Hata')
        setLoading(false);
        return
      }
      setLoading(false);
      return
    }).catch(err => {
      setLoading(false);
      return
    });
  }

  function CancelToTani() {
    var receiptNumber = invoiceData[0]?.Apply?.ReceiptNumber
    axios.get("/CancelFromTanı/" + receiptNumber, {
      responseType: 'application/json'
    }).then(response => {
    });
  }

  return (
    <>
      <Grid style={{ paddingTop: 5 }} >
        <Card sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }} >
          <CardContent >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.div} variant="subtitle1">
                  Değişim Çeki İptal
                </Typography>
              </Grid>
              <Grid item xs={2}> </Grid>
              <Grid item xs={8}>
                <Button
                  fullWidth
                  size="medium"
                  sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                  onClick={() => { CancelToTani() }}
                >Tanıdan İptal Et</Button>
              </Grid>
              <Grid item xs={2}> </Grid>
              <Grid item xs={10}>
                <TextField
                  size="small"
                  fullWidth
                  select
                  label="Red Nedeni"
                  variant="outlined"
                  value={webPointCancelType}
                  onChange={(event) => { changeWebPuanCancelType(event.target.value); }}
                  defaultValue={'ZFG'}
                >
                  <MenuItem key={1} value={'ZFG'}>{'Fiyat güncelleme'}</MenuItem>
                  <MenuItem key={2} value={'ZUI'}>{'Değişim çekinden ücret iadesine dönüş'}</MenuItem>
                  <MenuItem key={3} value={'ZUD'}>{'Değişim çekinden ürün değişimine dönüş'}</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title="Değişim Çeki İptal" >
                  <IconButton style={{ color: 'red' }} onClick={() => SapSend()} >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default WebPointCancel;