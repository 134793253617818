import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import { Backdrop, Button, Card, CardContent, CircularProgress, Grid, ListItem, TextField, Box, Badge } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'src/utils/Common';
import AlertAndLoading from 'src/components/AlertAndLoading';
import axios from 'src/utils/axios';
import { generalFollowActions } from 'src/store/slices/generalFollowSlice';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const CompressMaterial = require('compress.js');
const compressMaterial = new CompressMaterial();

const useStyles = makeStyles((theme) => ({
  divCenter: {
    textAlign: "center"
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  divider: {
    backgroundColor: '#FF9800'
  },
  div: {
    fontWeight: 'bold',
    fontSize: '14px',
    width: '100%',
    color: 'red'
  },
  div2: {
    fontSize: '12px',
    width: '100%'
  },
  img: {
    width: '100%',
    maxWidth: '600px'
  }
}));

function FileInfo() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const SessionUser = getUser();

  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);


  const [showFile, setShowFile] = React.useState(false);
  const [uploadFilePopUp, setUploadFilePopUp] = React.useState(false);
  const [openFilePopUp, setOpenFilePopUp] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null)
  const [fileList, setFileList] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [selectedFileType, setSelectedFileType] = React.useState(null);
  const [uyari, setUyari] = React.useState(false);
  const [openFileCount, setOpenFileCount] = React.useState(0);
  const [renk, setRenk] = React.useState('black');

  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });
  const [responseMessage, setresponseMessage] = React.useState('');

  const fileTypeData = useSelector((state) => state.generalFollowSlice.fileTypes);
  const receiptNumber = useSelector((state) => state.generalFollowSlice.invoiceDetail)[0]?.Apply?.ReceiptNumber;


  useEffect(() => {
    getOpenFile();
  }, [dispatch]);

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  const getOpenFile = () => {
    axios.get("/OpenFileCount/" + receiptNumber, {
      responseType: 'arrayBuffer'
    }).then(response => {
      setOpenFileCount(response.data)
    });
  }

  const getReceiptFiles = () => {
    setLoading(true)
    if (!receiptNumber) {
      return;
    }
    var images = []
    axios.get("/GetReceiptFiles/" + receiptNumber, {
      responseType: 'arrayBuffer'
    }).then(response => {
      setOpenFilePopUp(true);
      response.data.forEach(element => {
        const fileData = {
          fileURL: `data:${element.File.ContentType};base64,${element.File.FileContents}`,
          fileName: element.FileDesc + ' - ' + element.File.FileDownloadName + ' - ' + element.FileCreateUser,
          fileId: element.FileId,
          fileCreateUserId: element.FileCreateUserId,
          contentType: element.File.ContentType,
          FileTypeId: element.FileTypeId
        }

        images.push(fileData)
      });
      setFileList(images)
      setLoading(false)
    });
    setLoading(false)
  }

  function uploadFiles(filedata) {
    console.log(filedata)
    setLoading(true)
    axios.post("/UploadFile", filedata, {
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      setOpenFilePopUp(false);
      getOpenFile();
      setSelectedFileType(null);
      if (filedata.TypeId === 1) {
        var tempData = JSON.parse(JSON.stringify(invoiceData))
        tempData[0].Exchange.BankFile = 1;
        dispatch(generalFollowActions.updateNewData(tempData))
      }
      setLoading(false)
    }).catch(err => {
      setresponseMessage({
        ErrorCode: 0,
        ErrorDescription: err.response.data,
      });
      setUyari(true);
      setOpenFilePopUp(false);
      setSelectedFileType(null);
      setLoading(false)
      return
    });
  }

  const handleDeleteSureClick = (fileId, fileCreateUserId) => {
    if (fileCreateUserId !== SessionUser.UserId.toString()) {
      InfoAlert('Sadece eklediğiniz dosyaları silebilirsiniz!...', 'error', 'Hata')
      return
    }

    setOpenFilePopUp(false);
    confirmAlert({
      message: ('Silmek istediğinizden emin misiniz?'),
      buttons: [
        {
          label: ('Evet'),
          onClick: () => handleDeleteClick(fileId)
        },
        {
          label: ('Hayır'),
          onClick: () => { setOpenFilePopUp(true); }
        }
      ]
    });
  }

  const handleDeleteClick = (fileId) => {
    const Filecount = fileList.filter(obj => obj.FileTypeId === 1).length;
    setLoading(true)
    axios.get("/DeleteFile/" + fileId, {
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      setOpenFilePopUp(false);
      getOpenFile();
      setLoading(false)
    });
    if (Filecount > 1) {
      var tempData = JSON.parse(JSON.stringify(invoiceData))
      tempData[0].Exchange.BankFile = 1;
      dispatch(generalFollowActions.updateNewData(tempData))
    }
    else {
      var tempData = JSON.parse(JSON.stringify(invoiceData))
      tempData[0].Exchange.BankFile = 0;
      dispatch(generalFollowActions.updateNewData(tempData))
    }
  }


  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  async function base64Reader(file) {
    const result_base64 = await new Promise(resolve => {
      const fileReader = new FileReader();
      fileReader.onload = e => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
    return result_base64.split(',')[1] || '';
  }

  const handleChangeStatus = () => { };
  const handleSubmitImage = async (files, allFiles) => {
    setLoading(true)
    for (let i = 0; i < files.length; i++) {
      const fileMaterial = files[i].file;
      const metaMaterial = files[i].meta;
      let base64ResultMaterial = '';
      if (metaMaterial.type.indexOf('image') !== -1) {
        const resizedImageMaterial = await compressMaterial.compress(
          [fileMaterial],
          {
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 1920, // the max width of the output image, defaults to 1920px
            maxHeight: 1920, // the max height of the output image, defaults to 1920px
            resize: true // defaults to true, set false if you do not want to resize the image width and height
          }
        );
        const imgMaterial = resizedImageMaterial[0];
        base64ResultMaterial = imgMaterial.data;
      } else {
        base64ResultMaterial = await base64Reader(fileMaterial);
      }
      const data = {
        FileName: metaMaterial.name,
        FileType: metaMaterial.type,
        ReceiptNumber: receiptNumber,
        FileData: base64ResultMaterial,
        TypeId: selectedFileType?.TIP_ID,
        CreateUser: SessionUser.UserId
      }
      if (data.TypeId == undefined) {
        setresponseMessage({
          ErrorCode: 0,
          ErrorDescription: ('İşlem başarısız lütfen dosya tipini seçiniz.'),
        });
        setUyari(true);
        setLoading(false);
        return
      }
      else {
        uploadFiles(data)
        allFiles.forEach(f => f.remove())
        setLoading(false)
        setUploadFilePopUp(false)
      }
    }
  };

  const setNewWindowOpen = (row) => {
    if (row?.contentType?.includes('image')) {
      var image = new Image();
      image.src = row.fileURL;
      image.style.cssText = 'max-width: 1000px; max-height: 700px;';
      var w = window.open("");
      w.document.write(image.outerHTML);
      w.document.title = row.fileName;
      w.document.close();

    } else {
      const pdfWindow = window.open("");
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='${encodeURI(
          row.fileURL
        )}''></iframe>`
      );
      pdfWindow.document.title = row.fileName;
      pdfWindow.document.close();
    }
  }

  function HandleChangeColorClick(Color) {
    setRenk({ ...renk, [Color]: 'purple' });
  }

  function HandleChangeColorResetClick() {
    setRenk('black');
  }


  return (
    <Card sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.div} variant="subtitle1">
              Dosya Bilgileri
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={1}>
            <Tooltip title="Dosya Bilgilerini Görüntüle" style={{ cursor: 'pointer' }}>
              <Box sx={{ m: 1 }}>
                <Badge badgeContent={openFileCount} color="primary" onClick={() => getReceiptFiles()}>
                  <FolderIcon sx={{ color: '#FF9800' }} />
                </Badge>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Dosya Ekle">
              <IconButton
                className={classes.action}
                style={{ color: '#424242' }}
                key="close"
                onClick={() => setUploadFilePopUp(true)}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Dialog
          open={openFilePopUp}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogContent>
            <Grid container spacing={2}>
              <List>
                {fileList.map((row, index) => (
                  <ListItem key={index}>
                    <ListItemButton
                      style={{ display: 'flex', cursor: 'pointer' }}
                    >
                      <ListItemIcon>
                        <DeleteIcon onClick={() => { handleDeleteSureClick(row.fileId, row.fileCreateUserId) }} style={{ color: '#FF9800' }} />
                        <FolderIcon style={{ color: '#424242' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={<Typography type="body2" className={classes.div2}>{row.fileName}</Typography>}
                        style={{
                          color: renk[row.fileId] || 'black',
                        }}
                        onClick={() => {
                          console.log(row)
                          setNewWindowOpen(row);
                          // setShowFile(true)
                          // setSelectedFile(row)
                          HandleChangeColorClick(row.fileId);
                        }}
                      />
                      <ListItemText
                        primary={row.ContentType}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </DialogContent>
          <DialogActions>
            <IconButton
              color="primary"
              onClick={() => {
                setOpenFilePopUp(false);
                HandleChangeColorResetClick();
              }}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>

        </Dialog>
      </CardContent>
      <Dialog
        fullScreen
        open={showFile}
        onClose={() => setShowFile(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setShowFile(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Dosya
            </Typography>
            <Button autoFocus color="inherit" onClick={() => setShowFile(false)}>
              Kapat
            </Button>
          </Toolbar>
        </AppBar>
        {selectedFile?.contentType.includes('image') ?
          <Grid container
            alignItems="center"
            justify="center">
            <Grid item sm={12} alignItems="center"
              justify="center" textAlign="center">
              <img
                src={selectedFile.fileURL}
                srcSet={selectedFile.fileURL}
                alt={selectedFile.fileURL}
                loading="lazy"
                style={{ objectFit: 'contain', padding: 5, maxWidth: '100%', maxHeight: 50 }}
              />
            </Grid>
          </Grid>
          :
          (<iframe width='100%' height='100%' src={selectedFile?.fileURL}></iframe>)

        }
      </Dialog>
      <Dialog
        open={uploadFilePopUp}
        onClose={() => setUploadFilePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ width: '100%', color: 'red', fontWeight: 'bold' }} >Dosya Yükle</DialogTitle>
        <DialogContent>
          <Grid item xs={12} style={{ paddingTop: 20 }} >
            <Autocomplete
              id="combo-box-demo"
              options={fileTypeData}
              getOptionLabel={(i) => i.ACIKLAMA}
              value={selectedFileType || null}
              size="small"
              renderInput={(params) => <TextField {...params} label="Dosya Tipi" />}
              onChange={(event, val) => {
                setSelectedFileType(val);
              }}
            />
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: 20 }}>
            <Grid item xs={12} md={12}>
              <Dropzone
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmitImage}
                styles={{
                  dropzone: { minHeight: 200, maxHeight: 250 }
                }}
                submitButtonContent="Yükle"
                minSize={0}
                maxSize={8072000}
                inputWithFilesContent={files => `${5 - files.length} ekle`}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <IconButton
              color="primary"
              onClick={() => { setUploadFilePopUp(false); setSelectedFileType(null) }}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogActions>
      </Dialog>
    </Card >

  );
}

export default FileInfo;