import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import assigmentSlice from './slices/assigmentSlice';
import authorizationSlice from './slices/authorizationSlice';
import commonSlice from './slices/commonSlice';
import consumerInformationSlice from './slices/consumerInformationSlice';
import controlApprovalSlice from './slices/controlApprovalSlice';
import diffModelSuggestSlice from './slices/diffModelSuggestSlice';
import dynamicCatalogSlice from './slices/dynamicCatalogSlice';
import exchangeOfficeProcessSlice from './slices/exchangeOfficeProcessSlice';
import generalFollowSlice from './slices/generalFollowSlice';
import informationChatSlice from './slices/informationChatSlice';
import solutionCenterProcessSlice from './slices/solutionCenterProcessSlice';

const persistConfig = {
  key: 'root',
  storage
};

const reducers = combineReducers({
  generalFollowSlice: generalFollowSlice.reducer,
  commonSlice: commonSlice.reducer,
  assigmentSlice: assigmentSlice.reducer,
  controlApprovalSlice: controlApprovalSlice.reducer,
  informationChatSlice: informationChatSlice.reducer,
  consumerInformationSlice: consumerInformationSlice.reducer,
  dynamicCatalogSlice: dynamicCatalogSlice.reducer,
  authorizationSlice: authorizationSlice.reducer,
  solutionCenterProcessSlice: solutionCenterProcessSlice.reducer,
  diffModelSuggestSlice: diffModelSuggestSlice.reducer,
  exchangeOfficeProcessSlice: exchangeOfficeProcessSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]

});

export default store;