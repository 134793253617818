
import { RuleFolderSharp } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { Backdrop, Box, Card, CardContent, CircularProgress, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { DataGrid, trTR } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAuthorizedUsers, updateUserRole } from 'src/store/slices/authorizationSlice';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  checked: {
    color: '#FF9800',
    '&.Mui-checked': {
      color: '#FF9800',
    }
  },
  color: {
    color: '#424242'
  },
  button: {
    color: '#FF9800', backgroundColor: '#424242'
  }
}));

const defaultRoles = {
  Normal: false,
  Admin: false,
  // SuperAdmin: false,
}

function UserRoleAutho() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userList, setUserList] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [selectedUser, setSelectedUser] = React.useState([])
  const [selectedUserRole, setSelectedUserRole] = React.useState(defaultRoles)
  const [changeRoleModal, setChangeRoleModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)


  useEffect(() => {
    setLoading(true)
    dispatch(getAuthorizedUsers()).then(response => {
      if (response.payload?.length > 0) {
        setUserList(response.payload)
        setLoading(false)
      }
    })
  }, [dispatch]);

  useEffect(() => {
    const searchedRows = userList.filter(i => i.ADSOYAD.toLowerCase().includes(search.toLowerCase()) || i.SICILNO.includes(search))
    setRows(searchedRows)
  }, [search, userList]);


  const updateUserRoleClick = () => {
    if (!selectedUserRole || selectedUserRole.length == 0) return

    var userRoles = Object.keys(selectedUserRole).map(i => { return selectedUserRole[i] * 1 }).join(',')
    const data = {
      UserRoles: userRoles,
      UserId: selectedUser.ID
    }

    setLoading(true)
    dispatch(updateUserRole(data)).then(response => {
      dispatch(getAuthorizedUsers()).then(response => {
        if (response.payload?.length > 0) {
          setUserList(response.payload)
          setLoading(false)
        }
      })
      setChangeRoleModal(false)
      setLoading(false)
      setSelectedUser([])
      setSelectedUserRole(defaultRoles)
    })
  }
  const handleUpdateRoleClick = (row) => {
    setSelectedUser(row)
    setChangeRoleModal(true)

    if (!row?.Roles) {
      setSelectedUserRole(defaultRoles)
      return
    }
    var roles = row.Roles.split(',')
    const userRoles = {
      Normal: roles.includes('1'),
      Admin: roles.includes('2'),
      DegisimNormal: roles.includes('3'),
      DegisimAdmin: roles.includes('4'),
      UrunDegisimYonetici: roles.includes('5'),
      CozumMerkeziBPONormal: roles.includes('6'),
      ParaIadesiAdmin: roles.includes('7'),
      SatisYoneticiligi: roles.includes('8'),
      SistemKlima: roles.includes('9'),
      Muhasebe: roles.includes('10'),
      TedarikZinciri: roles.includes('11'),
      KaliteGuvenceYoneticiligi: roles.includes('12'),
      MuadilUrunNormal: roles.includes('13'),
      MuadilUrunAdmin: roles.includes('14')
    }
    setSelectedUserRole(userRoles)
  }
  const columns = [
    { field: 'ADSOYAD', headerName: 'Ad Soyad', width: 300 },
    { field: 'SICILNO', headerName: 'Sicil No', width: 200 },
    {
      field: 'Güncelle',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', "& > :not(style)": { m: 1 } }}>
            <Tooltip title="Güncelle">
              <IconButton onClick={() => { handleUpdateRoleClick(cellValues.row) }} >
                <CreateIcon className={classes.color} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }, width: 150
    }
  ];

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={6}></Grid>
            <Grid item sm={6}>
              <TextField
                variant="standard"
                size='small'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Ara..."
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item sm={12} style={{ paddingTop: 5 }}></Grid>
          <DataGrid
            style={{ height: 600, width: '100%', textAlign: 'center', justifyItems: 'center', }}
            columns={columns}
            rows={rows}
            getRowId={(row) => row.ID}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
          />
        </CardContent>
      </Card>
      <Dialog
        open={changeRoleModal}
        onClose={() => setChangeRoleModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ width: '100%' }} >{"Kullanıcı Rolü Değiştirme"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                disabled
                value={selectedUser.ADSOYAD + '-' + selectedUser.SICILNO}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl sx={{ m: 3 }} component="div" variant="standard">
                <FormLabel component="legend" className={classes.color}>Kullanıcı rolleri</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.Normal}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, Normal: !selectedUserRole.Normal })}
                        name="ÇözümMerkeziNormal"
                        className={classes.checked}
                      />
                    }
                    label="Çözüm Merkezi Normal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.Admin}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, Admin: !selectedUserRole.Admin })}
                        name="ÇözümMerkeziAdmin"
                        className={classes.checked}
                      />
                    }
                    label="Çözüm Merkezi Admin"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.DegisimNormal}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, DegisimNormal: !selectedUserRole.DegisimNormal })}
                        name="Değişim Normal"
                        className={classes.checked}
                      />
                    }
                    label="Değişim Ofis Normal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.DegisimAdmin}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, DegisimAdmin: !selectedUserRole.DegisimAdmin })}
                        name="Değişim Admin"
                        className={classes.checked}
                      />
                    }
                    label="Değişim Ofis Admin"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.UrunDegisimYonetici}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, UrunDegisimYonetici: !selectedUserRole.UrunDegisimYonetici })}
                        name="Ürün Değişim Yönetici"
                        className={classes.checked}
                      />
                    }
                    label="Ürün Değişim Yönetici"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.CozumMerkeziBPONormal}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, CozumMerkeziBPONormal: !selectedUserRole.CozumMerkeziBPONormal })}
                        name="Çözüm Merkezi BPO Normal"
                        className={classes.checked}
                      />
                    }
                    label="Çözüm Merkezi BPO Normal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.ParaIadesiAdmin}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, ParaIadesiAdmin: !selectedUserRole.ParaIadesiAdmin })}
                        name="Para İadesi Admin"
                        className={classes.checked}
                      />
                    }
                    label="Para İadesi Admin"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.SatisYoneticiligi}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, SatisYoneticiligi: !selectedUserRole.SatisYoneticiligi })}
                        name="Satış Yöneticiliği"
                        className={classes.checked}
                      />
                    }
                    label="Satış Yöneticiliği"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.SistemKlima}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, SistemKlima: !selectedUserRole.SistemKlima })}
                        name="Sistem Klima"
                        className={classes.checked}
                      />
                    }
                    label="Sistem Klima"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.Muhasebe}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, Muhasebe: !selectedUserRole.Muhasebe })}
                        name="Muhasebe"
                        className={classes.checked}
                      />
                    }
                    label="Muhasebe"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.TedarikZinciri}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, TedarikZinciri: !selectedUserRole.TedarikZinciri })}
                        name="Tedarik Zinciri"
                        className={classes.checked}
                      />
                    }
                    label="Tedarik Zinciri"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.KaliteGuvenceYoneticiligi}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, KaliteGuvenceYoneticiligi: !selectedUserRole.KaliteGuvenceYoneticiligi })}
                        name="Kalite Güvence Yöneticiliği"
                        className={classes.checked}
                      />
                    }
                    label="Kalite Güvence Yöneticiliği"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.MuadilUrunNormal}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, MuadilUrunNormal: !selectedUserRole.MuadilUrunNormal })}
                        name="Muadil Ürün Normal"
                        className={classes.checked}
                      />
                    }
                    label="Muadil Ürün Normal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserRole.MuadilUrunAdmin}
                        onChange={() => setSelectedUserRole({ ...selectedUserRole, MuadilUrunAdmin: !selectedUserRole.MuadilUrunAdmin })}
                        name="Muadil Ürün Admin"
                        className={classes.checked}
                      />
                    }
                    label="Muadil Ürün Admin"
                  />
                </FormGroup>
                <FormHelperText>Birden fazla seçim yapabilirsiniz.</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            fullWidth
            size="small"
            className={classes.button}
            onClick={() => updateUserRoleClick()}
          >Güncelle</Button>
        </DialogContent>
        <DialogActions>
          <IconButton
            className={classes.color}
            onClick={() => setChangeRoleModal(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserRoleAutho;
