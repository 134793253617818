export const Types = {
  GET_TASK_REQUEST: 'GET_TASK_REQUEST',
  SET_CURRENT_TASK: 'SET_CURRENT_TASK',
  GET_TASK_SUCCESS: 'GET_TASK_SUCCESS',
  SELECT_TASK: 'SELECT_TASK',
  RESET_SELECTED_TASK: 'RESET_SELECTED_TASK',
  SET_INVOICE_FILTER_KEY: 'SET_INVOICE_FILTER_KEY',
  REMOVE_INVOICE_TASK_LIST: 'REMOVE_INVOICE_TASK_LIST',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_USER_INFO: 'SET_USER_INFO', // Burada olmamalı
}


export const getTasksRequest = () => ({
  type: Types.GET_TASK_REQUEST
});

export const setTask = (task) => ({
  type: Types.SET_CURRENT_TASK,
  payload: { task }
});

export const getTasksSuccess = tasks => (
  {
    type: Types.GET_TASK_SUCCESS,
    payload: { tasks }
  });

export const selectTask = task => ({
  type: Types.SELECT_TASK,
  payload: { task }
});

export const resetSelectedTask = task => ({
  type: Types.RESET_SELECTED_TASK,
  payload: { task }
});

export const setInoviceFilterKey = value => ({
  type: Types.SET_INVOICE_FILTER_KEY,
  payload: { value }
});

export const removeInvoiceTaskList = value => ({
  type: Types.REMOVE_INVOICE_TASK_LIST,
  payload: { value }
});

export const setPageSize = value => ({
  type: Types.SET_PAGE_SIZE,
  payload: { value }
});

export const setUserInfo = value => ({
  type: Types.SET_USER_INFO,
  payload: { value }
});





