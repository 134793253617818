import { Button, List, ListItemIcon, ListItemText, Typography } from '@mui/material';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { getUserRole, getTokenWs } from 'src/utils/Common';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';



const useStyles = makeStyles(() => ({
  navButton: {
    color: 'rgba(0, 0, 0, 0.87)', textAlign: 'left', textTransform: 'none', width: '100%',
  },
  navSection: {
    fontSize: 13, color: '#FF9800', fontWeight: 'bold',
  },
  navIcon: {
    color: 'inherit', minWidth: '40px',
  },
  navButtonActive: {
    backgroundColor: '#FF9800 !important', borderRadius: '10px',
    color: '#fff',
    textAlign: 'left', textTransform: 'none', width: '100%',
  },
  chatButton: {
    textTransform: 'none', color: '#424242', justifyContent: "flex-start",
    borderRadius: '10px', width: '100%', fontFamily: 'sans-serif', fontSize: '1rem'
  }
}));


function NavBar({ open, Menu, onClose, onOpenNavBar, changeTopBarRotation }) {
  const classes = useStyles();
  const tokenWs = getTokenWs();
  const userRole = getUserRole().Menu
  const Role = getUserRole().RoleId;

  const [MenuShow, setMenuShow] = React.useState(true);

  function ekutuphane() {
    var deger;
    deger = "&SessionToken=" + tokenWs;
    var targetPage = "https://servis.arcelik.com.tr/arek/aspx/frmEkutuphane.aspx?" + deger
    var width = 1150;
    var height = 500;
    var left = parseInt((window.screen.availWidth / 2) - (width / 2));
    var top = parseInt((window.screen.availHeight / 2) - (height / 2));
    var windowFeatures = "width=" + width + ",height=" + height + ",status,resizable,left=" + left + ",top=" + top + "screenX=" + left + ",screenY=" + top;
    window.open(targetPage, "subWind", windowFeatures);
  }

  const setMenuShowFnc = () => {
    if (MenuShow) {
      setMenuShow(false);
    } else {
      setMenuShow(true);
    }

    changeTopBarRotation()
    onOpenNavBar();

  }

  function degYorumlarGoster() {
    // var deger;
    // deger = "&bn=" + bn;
    // deger += "&bk=" + bk;
    var targetPage = "microsoft-edge:http://mhnet/THPortal/YORUM/aspx/Main.aspx"
    var width = 1150;
    var height = 500;
    var left = parseInt((window.screen.availWidth / 2) - (width / 2));
    var top = parseInt((window.screen.availHeight / 2) - (height / 2));
    var windowFeatures = "width=" + width + ",height=" + height + ",status,resizable,left=" + left + ",top=" + top + "screenX=" + left + ",screenY=" + top;
    const newWindow = window.open(targetPage, 'subWind', windowFeatures); // Chrome veya diğer tarayıcılar üzerinden açılan sayfanın kapanmasını sağlamak için
    if (newWindow) {
      setTimeout(() => { newWindow.close(); }, 15000); // 1 saniye bekleyip kapatmaya çalışır 
    }
  }

  return (
    <>
      <Drawer
        sx={{
          width: 270,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 270,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >

        <Toolbar sx={{ backgroundColor: '#424242', }}>
          <Typography variant="h6" noWrap component="div" sx={{ color: '#FF9800', }}>
            Dia
          </Typography>
        </Toolbar>
        <Divider />
        <List >
          {Menu.map((section, index) => (
            <div key={"section" + index}>
              <ListItem >
                <Typography
                  component="h5"
                  gutterBottom
                  variant="h6"
                  className={classes.navSection}
                >
                  {section.title}
                </Typography>
              </ListItem>
              {section.items.map((item, index) => (
                userRole?.filter(i => i.MENU_NAME == item.title).length > 0 &&
                <ListItem key={"nav" + index} >
                  <Button
                    component={RouterLink} to={item.href}
                    className={window.location.pathname === item.href ? classes.navButtonActive : classes.navButton}
                    sx={{ color: '#FF9800' }}

                    onClick={setMenuShowFnc}
                  >
                    <ListItemIcon className={classes.navIcon}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} style={{ fontSize: '1rem' }} />
                  </Button>
                </ListItem>
              ))}
            </div>
          ))}
        </List>
        {
          (Role?.filter(i => i.ROLE_ID !== 11).length > 0) ?
            <>
              <ListItem>
                <Button className={classes.chatButton} onClick={() => ekutuphane()} startIcon={<LocalLibraryIcon className={classes.navIcon} />}>
                  {('E-Kütüphane')}
                </Button>
              </ListItem>
              <ListItem>
                <Button className={classes.chatButton} onClick={() => { degYorumlarGoster(); }} startIcon={<QuestionAnswerIcon className={classes.navIcon} />}>
                  {('Yorumlar')}
                </Button>
              </ListItem>
            </>
            :
            null
        }

      </Drawer>

    </>
  );
}

NavBar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default NavBar;
