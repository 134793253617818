import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { createBrowserHistory } from 'history';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider as StoreProvider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { oktaClientId, oktaIssuer } from 'src/utils/contants';
import './assets/css/main-style.css';
import './assets/scss/main.scss';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import Auth from './layouts/Auth';
import store from './store';

const THEME = createTheme((theme) => ({

  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
}))
const persistor = persistStore(store);
const history = createBrowserHistory();

const config = {
  issuer: oktaIssuer,
  redirectUri: `${window.location.origin}/implicit/callback`,
  clientId: oktaClientId,
  pkce: true,
  ignoreLifetime: true,
  scopes: ['openid', 'email', 'profile'],
  cookies: {
    secure: false
  }
};

const oktaAuth = new OktaAuth(config);

const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  history.replace(toRelativeUrl(originalUri, window.location.origin));
};

function App() {
  return (
    <StoreProvider store={store}>
      <PersistGate
        loading={<CircularProgress sytle={{ color: '#424242', backgroundColor: '#424242' }} status={'loading'} />}
        persistor={persistor}>
        <ThemeProvider theme={THEME}>
          <StylesProvider direction="ltr">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router history={history}>
                <ScrollReset />
                <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                  <Switch>
                    <Route
                      exact
                      path="/implicit/callback"
                      component={LoginCallback}
                    />
                    <Route path="/" component={Auth} />
                  </Switch>
                </Security>
              </Router>
            </LocalizationProvider>
          </StylesProvider>
        </ThemeProvider>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
