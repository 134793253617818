import React, { useEffect, useState, useRef } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import axios from 'src/utils/axios'; 
import trLocale from 'date-fns/locale/tr';
import FolderIcon from '@mui/icons-material/Folder';
import { Backdrop, Card, CardContent, Grid, TextField, Button, CircularProgress, Table, TableHead, TableBody,TableCell,
         TableRow,TableContainer, DialogContent, DialogActions,Dialog,DialogTitle,IconButton,Select,MenuItem,FormControl,
         InputLabel,Stack,Alert,Snackbar,Switch } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getMuadilSearchBox,getMuadilProductList,getMuadilComboBox } from 'src/store/slices/equivalentProductsSlice';
import { getUser } from 'src/utils/Common';
import { confirmAlert } from 'react-confirm-alert';
import MuadilFile from 'src/components/MuadilFile.js';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      fontFamily: "Segoe UI",
      width: '100%',
      height: '100%'
    },
    generalCards: {
      zIndex: 1
    },
    backdrop: {
      zIndex: 1500,
      color: '#fff'
    }
  
  }));

function EquivalentProductManagement(){


    const today = new Date();
    const SessionUser = getUser();
    const classes = useStyles();
    const containerRef = useRef();
    const dispatch = useDispatch();
    const [searchName,setSearchName] = useState('');
    const [muadilSearchName,setMuadilSearchName] = useState('');
    const [alert,setAlert] = useState(false);
    const [alertColor,setAlertColor] = useState('#FF0000');
    const [selectedMuadilId,setSelectedMuadilId] = useState(0);
    const [alertMessage,setAlertMessage] = useState('');
    const [searchList,setSearchList] = useState(false);
    const [searchMuadilList,setSearchMuadilList] = useState(false);
    const [isMuadilChoosed,setIsMuadilChoosed] = useState(false);
    const [loading,setLoading] = useState(false);
    const [searchResponse, setSearchResponse] = useState([]);
    const [searchMuadilResponse, setSearchMuadilResponse] = useState([]);
    const [productList,setProductList] = useState([]);
    const [comboBox,setComboBox] = useState([]);
    const [modelKodu,setModelKodu] = useState(0);
    const [model,setModel] = useState("");
    const [listVisible,setListVisible] = useState(false);
    const [insertScr,setInsertScr] = useState(false);
    const [fileScr,setFileScr] = useState(false);
    const [updateScr,setUpdateScr] = useState(false);
    const [updateProduct,setUpdateProduct] = useState({ muadilId: 0,
                                                        muuadilSKU: '',
                                                        muadilModel: '',
                                                        oncelik: 0,
                                                        muadilTıpId: -1,
                                                        gecerlilikSurersi: '',
                                                        muadilAciklama: '',
                                                        yoneticiOnayTipi: ''});
    const insertInitial = { muadilModelKodu: 0,
                            oncelik: 0,
                            muadilTıpId: -1,
                            gecerlilikSurersi: today,
                            muadilAciklama: ''};
    const [insertProduct,setInsertProduct] = useState(insertInitial);
    const [filter, setFilter] = useState('All');
    const [filteredProductList, setFilteredProductList] = useState([]);
    const handleFilterChange = (event) => {
      setFilteredProductList([]); 
      setFilter(event.target.value);
    };
  
    const callAlert = (type,messageAlert) => {
      if(type=="err"){
        setAlertMessage(messageAlert);
        setAlertColor("#FF0000");
        setAlert(true);
      }
      if(type=="suc"){
        setAlertMessage(messageAlert);
        setAlertColor("#21E805");
        setAlert(true);
      }
    }
  
    const handleOpenFile = () => setFileScr(true);
    const handleCloseFile = () => setFileScr(false);

    const searchProduct = () => {
      setLoading(true);
      if(searchName==""){
        setSearchList(false);
        setLoading(false);
        return;
      }
      dispatch(getMuadilSearchBox(searchName)).then(response => {
        setSearchResponse(response?.payload);
        setSearchList(true);
      });
      setLoading(false);
    }

    const searchMuadilProduct = () => {
      setLoading(true);
      if(muadilSearchName==""){
        setSearchMuadilList(false);
        setLoading(false);
        return;
      }
      dispatch(getMuadilSearchBox(muadilSearchName)).then(response => {
        setSearchMuadilResponse(response?.payload);
        setSearchMuadilList(true);
      });
      setLoading(false);
    }
    const chooseModel = (model,aciklama) => {
      setLoading(true);
      setListVisible(false);
      setSearchName(aciklama);
      setSearchList(false);
      setModelKodu(model);
      setModel(aciklama);
      getList(model);
      setLoading(false);
    }
    const chooseMuadilModel = (model,aciklama) => {
      setLoading(true);
      setMuadilSearchName(aciklama);
      setSearchMuadilList(false);
      setInsertProduct(prevState => ({
        ...prevState,
        muadilModelKodu: model
      }))
      setIsMuadilChoosed(true);
      setLoading(false);
    }
    
    const getList = (model1) => { 
      setLoading(true);
      if(model1==0){
        setLoading(false);
        return;
      }
      dispatch(getMuadilProductList(model1)).then(response => {
        setProductList([]);
        setProductList(response?.payload)
        //alert ayarlanacak
      });
      setListVisible(true);
      setLoading(false);
    }
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        searchProduct();
      }
    };

    const handleKeyPressMuadil = (event) => {
      if (event.key === 'Enter') {
        searchMuadilProduct();
      }
    };
  
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setSearchList(false);
      }
    };
  
    const handleProductUpdate = (muadilId,muuadilSKU,muadilModel,oncelik,muadilTıpId,gecerlilikSurersi,muadilAciklama,yoneticiOnayTipi) => {
        setUpdateProduct ( {
          muadilId:muadilId,
          muuadilSKU:muuadilSKU,
          muadilModel:muadilModel,
          oncelik:oncelik,
          muadilTıpId:muadilTıpId,
          gecerlilikSurersi:gecerlilikSurersi,
          muadilAciklama:muadilAciklama,
          yoneticiOnayTipi: yoneticiOnayTipi
        })
        setUpdateScr(true);
    }
    //moment(updateProduct.gecerlilikSurersi).format('DD-MM-YYYY'),
     useEffect(() => {
      if(comboBox.length==0){
        dispatch(getMuadilComboBox()).then(response => {
          setComboBox(response?.payload);
        }); 
      }
      if(searchResponse.length==1){
        searchResponse.map((item,index)=> chooseModel(item.MODEL_KODU,item.ACIKLAMA))
      }
    }, [searchResponse]); 
    
    useEffect(() => {
      const newFilteredProductList = productList.filter((row) => {
        if (filter === 'All') return true;
        if (filter === 'Active') return row.AKTIF;
        if (filter === 'Passive') return !row.AKTIF;
        return true;
      });
      setFilteredProductList(newFilteredProductList);
    }, [filter, productList]);
  
    const saveUpdate = () => {
      if(updateProduct.oncelik<1){
        callAlert("err","Geçerli bir öncelik değeri girin!");
        return;
      }
      if(updateProduct.muadilTıpId<0){
        callAlert("err","Geçerli bir muadil tipi girin!");
        return;
      }
      if(updateProduct.muadilAciklama==''){
        callAlert("err","Açıklama alanı boş girilemez!");
        return;
      }
      var gecerlilikSurersiDate = new Date(updateProduct.gecerlilikSurersi);
      if (gecerlilikSurersiDate < today) {
        callAlert("err",'İleri bir tarih seçiniz!');
        return;
      } 
      setLoading(true);
      const data = {
        MuadilId:updateProduct.muadilId,
        Oncelik:updateProduct.oncelik,
        MuadilTipId:updateProduct.muadilTıpId,
        GecerlilikSuresi:moment(updateProduct.gecerlilikSurersi).format('DD-MM-YYYY'),
        MuadilAciklama:updateProduct.muadilAciklama
      }
      axios.post('SetMuadilUrunUpdate', data, {
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        if (response.data.ErrorCode == 0) {
          setLoading(false);
          callAlert("suc",response.data.ErrorDescription)
          getList(modelKodu);
          setUpdateScr(false);
        } else {
          setLoading(false);
          callAlert("err",response.data.ErrorDescription)
        }
      }).catch(err => {
        setLoading(false)
        return
      });
  
    }
     
    const saveInsert = (yonetici) => {
      if(insertProduct.muadilModelKodu==0){
        callAlert("err","Model seçiniz!");
        return;
      }
      if(!isMuadilChoosed){
        callAlert("err","Model seçiniz!");
        return;
      }
      if(insertProduct.oncelik<1){
        callAlert("err","Geçerli bir öncelik değeri girin!");
        return;
      }
      if(insertProduct.muadilTıpId<0){
        callAlert("err","Geçerli bir muadil tipi girin!");
        return;
      }
      if(insertProduct.muadilAciklama==''){
        callAlert("err","Açıklama alanı boş girilemez!");
        return;
      }
      var gecerlilikSurersiDate = new Date(insertProduct.gecerlilikSurersi);
      if (gecerlilikSurersiDate < today) {
        callAlert("err",'İleri bir tarih seçiniz!');
        return;
      }
      setLoading(true); 
      const data = {
        Model:modelKodu,
        MuadilModelKodu: insertProduct.muadilModelKodu,
        Oncelik:insertProduct.oncelik,
        MuadilTipId:insertProduct.muadilTıpId,
        GecerlilikSuresi:moment(insertProduct.gecerlilikSurersi).format('DD-MM-YYYY'),
        MuadilAciklama:insertProduct.muadilAciklama,
        Kullanici:SessionUser?.UserId?.toString(),
        YoneticiOnayina:yonetici  
      }   
      axios.post('SetMuadilUrunInsert', data, {
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        if (response.data.ErrorCode == 0) {
          setLoading(false);
          callAlert("suc",response.data.ErrorDescription)
          getList(modelKodu);
          setInsertProduct(insertInitial);
          setInsertScr(false);
        } else if(response.data.ErrorCode == 1000){
          setLoading(false);
          callAlert("err",response.data.ErrorDescription)
        } else{
            setLoading(false);
            setInsertScr(false);
             confirmAlert({
                message: (response.data.ErrorDescription),
                buttons: [
                  {
                    label: ('Gönder'),
                    onClick: () => {
                        saveInsert(1);
                    }
                  },
                  {
                    label: ('Revize et'),
                    onClick: () => {
                        setInsertScr(true);
                    }
                  }
                ],
              })   
        }
      }).catch(err => {
        setLoading(false)
        return
      });
    }
    const setDisabled = (muadilId) => {
      setLoading(true);
      const data = {
        MuadilId:muadilId,
        Kullanici:SessionUser?.UserId?.toString()
      }
      axios.post('SetMuadilUrunDisabled', data, {
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        if (response.data.ErrorCode == 0) {
          getList(modelKodu);
          setLoading(false);
          callAlert("suc",response.data.ErrorDescription)
          
        } else {
          setLoading(false);
          callAlert("err","Bir hata oluştu.")
        }
      }).catch(err => {
        setLoading(false)
        return
      });
    }
    const handleFileUpload = (muadilId) => {
        setSelectedMuadilId(muadilId);
        setFileScr(true);
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    

  
    const label = { inputProps: { 'aria-label': 'Color switch demo' } };
      return(<>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack spacing={8}>
          <Snackbar sx={{ width: '100%',alignItems: 'center', justifyContent: 'center', height: '100vh' }} open={alert} autoHideDuration={6000} onClose={()=>setAlert(false)}>
            <Alert 
              style={{ minWidth: 500 }}
              sx={{
                alignItems: "center",
                verticalAlign: "center",
                backgroundColor: alertColor
  
              }} onClose={()=>setAlert(false)} variant="filled" >
              {alertMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Card className={classes.generalCards}>
        <CardContent>
          <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <b>Muadil Ürün Yönetimi</b> (Enter tuşu ile arat, birden fazla model geldiyse seç sonra listelelenecektir.)
              </Grid>
              <Grid item xs={8} md={8}>
              <TextField
                      id="standard-basic"
                      label="Model Adı ya da SKU"
                      value={searchName}
                      onChange={(event) => {
                        setSearchName(event.target.value)
                      }} 
                      onKeyPress={handleKeyPress}
                    fullWidth size="small" />
                </Grid>
                <Grid item xs={2} md={2} sx={{ display: 'none'}}>
                <Button type="submit" fullWidth variant="contained" sx={{ color: '#FF9800', backgroundColor: '#424242', display: 'none'}} className={classes.submit} onClick={searchProduct}>
                  Ara
                </Button>
                </Grid>
                <Grid item xs={4} md={4} sx={{ display: 'none'}}>
           {/*      <Button type="button" fullWidth variant="contained" sx={{ color: '#FF9800', backgroundColor: '#424242' }} onClick={getList}>
                  Listele
                </Button> */}
                </Grid>
                {searchList ? <Grid ref={containerRef} item xs={8} md={8} style={{backgroundColor:"white", position: 'fixed', zIndex: 100, top:"218px" }}><ul style={{ marginTop: '0px', marginLeft: '10px',listStyleType: 'none', padding: 0 }}>
                    {searchResponse?.map((item, index) => (
                      <li key={item.MODEL_KODU} style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                        transition: 'background-color 0.3s', 
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'} 
                      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'} 
                      onClick={(model,aciklama)=>chooseModel(item.MODEL_KODU,item.ACIKLAMA)}
                    >
                        {item.ACIKLAMA}
                      </li>
                    ))}
                  </ul></Grid>:<></>}
                  {listVisible?<>
                  <Grid item xs={4} md={4}>
                    <Button fullWidth variant="contained" sx={{ color: '#424242', backgroundColor: '#7DED4D' }} onClick={()=>setInsertScr(true)}>Ekle</Button>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <h3><b>{model} ürünü için {productList.length} adet kayıt bulundu!</b></h3>
                  </Grid>
                  <Grid item xs={4} md={4}  sx={{ display: 'none'}}>
                  <Button fullWidth variant="contained" sx={{ color: '#424242', backgroundColor: '#FF9800' }}>Excel Toplu Yükleme</Button>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                        <TableCell className={classes.tableCell} align="left"> </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Muadil SKU	                          </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Muadil						  </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Özellikleri					  </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Öncelik Sırası 				  </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Muadil tipi					  </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Yönetici Onayı 				  </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Geçerlilik Süresi son tarih	  </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Açıklama 				  </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Dosya 					  </TableCell>
                        <TableCell className={classes.tableCell} align="left">  <Select
                                                                                    labelId="filter-label"
                                                                                    id="filter"
                                                                                    value={filter}
                                                                                    label="Filtre"
                                                                                    onChange={handleFilterChange}
                                                                                  >
                                                                                    <MenuItem value="All">Tümü</MenuItem>
                                                                                    <MenuItem value="Active">Aktif</MenuItem>
                                                                                    <MenuItem value="Passive">Pasif</MenuItem>
                                                                                  </Select>				  </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Oluşturan kullanıcı			  </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Pasif eden kullanıcı			  </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Pasif edilme tarihi			  </TableCell>
                        <TableCell className={classes.tableCell} align="left"> Yönetici Red Nedeni 			  </TableCell>
  
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredProductList?.map((row)=> (
                        <TableRow>
                        <TableCell className={classes.tableCell} align="left"> <Button sx={{ color: '#424242', backgroundColor: '#FF9800', '&:disabled': {backgroundColor: '#E0E0E0'} }} 
                                                                                      onClick={()=>handleProductUpdate(row.MUADIL_ID,row.SKU,row.ACIKLAMA,row.ONCELIK_SIRASI,row.MUADIL_TIPI_ID,row.GECERLILIK_SURESI,row.MUADIL_ACIKLAMA,row.YONETICI_ONAY_TIPI)}
                                                                                      disabled={!row.AKTIF}>Düzenle</Button></TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.SKU}</TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.ACIKLAMA}</TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.OZELLIKLER}</TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.ONCELIK_SIRASI}</TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.MUADIL_TIPI}</TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.YONETICI_ONAY_TIPI}</TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.GECERLILIK_SURESI}</TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.MUADIL_ACIKLAMA}</TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">	<IconButton sx={{ color: '#424242', backgroundColor: '#FF9800', '&:disabled': {backgroundColor: '#E0E0E0'} }} 
                                                                                      onClick={()=>handleFileUpload(row.MUADIL_ID)}
                                                                                      disabled={!row.DOSYA_AKTIF}><FolderIcon /></IconButton></TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left"><Switch {...label} color="warning" onChange={(muadilId)=>setDisabled(row.MUADIL_ID)} 
                                                                                  disabled={!row.AKTIF}
                                                                                  defaultChecked={row.AKTIF} /></TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.OLUSTURAN_KULLANICI}</TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.PASIF_EDEN_KULLANICI}</TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.PASIF_EDILME_TARIHI}</TableCell>
                        <TableCell className={classes.tableCellBilgi} align="left">{row.YONETICI_RED_NEDENI}</TableCell>
                        
                        </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    </TableContainer>
                    </Grid>
                    </>:<></>}
            </Grid>
        </CardContent>
        </Card>
        <Dialog 
          open={fileScr}
          onClose={()=>setFileScr(false)}>
            <DialogTitle sx={{ width: '100%', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>Dosyalar</DialogTitle>
            <DialogContent>
            <MuadilFile 
              muadilId={selectedMuadilId}
              handleOpenFile= {handleOpenFile}
              handleCloseFile= {handleCloseFile}
              readOnly = {false}
            />
        </DialogContent>
            <DialogActions>
              <IconButton
                  sx={{color:"#FF9800"}}
                  onClick={() =>  setFileScr(false) }
                  aria-label="close"
                  component="span"
                  variant="contained"
                >
                  <CloseIcon />
                </IconButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={insertScr}
          onClose={()=>setInsertScr(false)}
  
        >
           <DialogTitle sx={{ width: '100%', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>Muadil Ekleme</DialogTitle>
          <DialogContent>
          <br></br>
           
           <Grid container spacing={2}>
           <Grid item xs={12} md={12}>
           <TextField
                     type='text'
                     label="Muadil Model Adı ya da SKU"
                      value={muadilSearchName}
                      onChange={(event) => {
                        setMuadilSearchName(event.target.value);
                        setIsMuadilChoosed(false);
                      }} 
                      onKeyPress={handleKeyPressMuadil}
                     inputProps={{ maxLength: 30 }}
                     fullWidth
                     style={{
                      backgroundColor: isMuadilChoosed ? '#B2FFA3' : 'transparent'
                    }}
                   />     
             </Grid>
             {searchMuadilList ? <Grid ref={containerRef} item xs={8} md={8} style={{backgroundColor:"white", position: 'fixed', zIndex: 100, top:"260px" }}><ul style={{ marginTop: '0px', marginLeft: '10px',listStyleType: 'none', padding: 0 }}>
                    {searchMuadilResponse?.map((item, index) => (
                      <li key={item.MODEL_KODU} style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                        transition: 'background-color 0.3s', 
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'} 
                      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'} 
                      onClick={(model,aciklama)=>chooseMuadilModel(item.MODEL_KODU,item.ACIKLAMA)}
                    >
                        {item.ACIKLAMA}
                      </li>
                    ))}
                  </ul></Grid>:<></>}
             <Grid item xs={12} md={12}>
             <TextField
                 label="Öncelik Sırası"
                 type="number"
                 value={insertProduct.oncelik}
                 onChange={(event)=>setInsertProduct(prevState => ({
                   ...prevState,
                   oncelik: event.target.value
                 }))}
                 InputLabelProps={{
                   shrink: true,
                 }}
               />
             </Grid>
             <Grid item xs={12} md={12}>
             <FormControl fullWidth>
                   <InputLabel id="MuadilTipi">Muadil Tipi</InputLabel>
                       {comboBox?.map((type)=>(
                               <Select
                                       labelId="MuadilTipi"
                                       value={insertProduct.muadilTıpId}
                                       label="MuadilTipi"
                                       onChange={(event)=>setInsertProduct(prevState => ({
                                         ...prevState,
                                         muadilTıpId: event.target.value
                                       }))}
                                       fullWidth
                                     >
                                                 {type?.MuadilTipi.map(
                                                 (opt) => (
                                                   <MenuItem value={opt.ID}>{opt.ACIKLAMA}</MenuItem>
                                                 )
                                                 )
                                               }
                                               </Select>)
                                               )
                                               }
               </FormControl>
                         
             </Grid>
             <Grid item xs={12} md={12}>
             <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
                             <DatePicker
                               label="Geçerlilik Son Tarihi"
                               value={insertProduct.gecerlilikSurersi}
                               format={'dd/MM/yyyy'}
                               onChange={(event)=>setInsertProduct(prevState => ({
                                 ...prevState,
                                 gecerlilikSurersi: event
                               }))}
                               renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                             />
                           </LocalizationProvider>
             </Grid> 
             <Grid item xs={12} md={12}>
               <TextField
                     id="outlined-number"
                     label="Açıklama"
                     type='text'
                     value={insertProduct.muadilAciklama}
                     onChange={(event)=>setInsertProduct(prevState => ({
                       ...prevState,
                       muadilAciklama: event.target.value
                     }))}
                     multiline
                     rows={4} 
                     inputProps={{ maxLength: 450 }}
                     fullWidth
                   />     
             </Grid>
             <Grid item xs={6} md={6}>
             <Button sx={{ color: '#424242', backgroundColor: '#FF9800' }}
                    onClick={(yonetici)=>saveInsert(0)} fullWidth>Kaydet</Button>
             </Grid>
           </Grid>
          </DialogContent>
          <DialogActions>
          <IconButton
              sx={{color:"#FF9800"}}
              onClick={() =>  setInsertScr(false) }
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={updateScr}
          onClose={()=>setUpdateScr(false)}
          maxWidth="l"
        >
           <DialogTitle sx={{ width: '100%', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>Muadil Güncelle</DialogTitle>
          <DialogContent>
            <br></br>
           
            <Grid container spacing={2}>
            <Grid item xs={2} md={2}>
                <p><b>SKU</b></p>
              </Grid>
              <Grid item xs={10} md={10}>
                <p>{updateProduct.muuadilSKU}</p>
              </Grid>
              <Grid item xs={2} md={2}>
                <p><b>Model</b></p>
              </Grid>
              <Grid item xs={10} md={10}>
                <p>{updateProduct.muadilModel}</p>
              </Grid>
              <Grid item xs={12} md={12}>
              <TextField
                  id="outlined-number"
                  label="Öncelik Sırası"
                  type="number"
                  value={updateProduct.oncelik}
                  onChange={(event)=>setUpdateProduct(prevState => ({
                    ...prevState,
                    oncelik: event.target.value
                  }))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                    <InputLabel id="MuadilTipi">Muadil Tipi</InputLabel>
                        {comboBox?.map((type)=>(
                                <Select
                                        labelId="MuadilTipi"
                                        value={updateProduct.muadilTıpId}
                                        label="MuadilTipi"
                                        onChange={(event)=>setUpdateProduct(prevState => ({
                                          ...prevState,
                                          muadilTıpId: event.target.value
                                        }))}
                                        fullWidth
                                        disabled
                                      >
                                                  {type?.MuadilTipi.map(
                                                  (opt) => (
                                                    <MenuItem value={opt.ID}>{opt.ACIKLAMA}</MenuItem>
                                                  )
                                                  )
                                                }
                                                </Select>)
                                                )
                                                }
                </FormControl>
                          
              </Grid>
              <Grid item xs={12} md={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
                              <DatePicker
                                label="Geçerlilik Son Tarihi"
                                value={updateProduct.gecerlilikSurersi}
                                format={'dd/MM/yyyy'}
                                onChange={(event)=>setUpdateProduct(prevState => ({
                                  ...prevState,
                                  gecerlilikSurersi: event
                                }))}
                                renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                               disabled={updateProduct.yoneticiOnayTipi=="Onaylandı"}/>
                            </LocalizationProvider>
              </Grid> 
              <Grid item xs={12} md={12}>
                <TextField
                      id="outlined-number"
                      label="Açıklama"
                      type='text'
                      value={updateProduct.muadilAciklama}
                      onChange={(event)=>setUpdateProduct(prevState => ({
                        ...prevState,
                        muadilAciklama: event.target.value
                      }))}
                      multiline
                      rows={4} 
                      inputProps={{ maxLength: 450 }}
                      fullWidth
                    />     
              </Grid>
              <Grid item xs={6} md={6}>
              <Button sx={{ color: '#424242', backgroundColor: '#FF9800' }}
                     onClick={()=>saveUpdate()} fullWidth>Kaydet</Button>
              </Grid>
            </Grid>
            
          </DialogContent>
          <DialogActions>
          <IconButton
              sx={{color:"#FF9800"}}
              onClick={() =>  setUpdateScr(false) }
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
        </>
      );
  }

  export default EquivalentProductManagement;