import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
    headers: { 'Content-Type': 'application/json' }
};

export const getUserAssigned = createAsyncThunk('/GetUserAssigned', async () => {
    const response = await axios.post('/GetUserAssigned', dataConfig);
    return response.data;
});

export const transferJobTail = createAsyncThunk('/TransferJobTail', async (data) => {
    const response = await axios.post('/TransferJobTail', data, dataConfig);
    return response.data;
});

const controlApprovalSlice = createSlice({
    name: 'controlApproval',
    initialState: {
        loading: '',
        error: '',

    },
    reducers: {
    },
    extraReducers: {

    }
});
export const controlApprovalActions = controlApprovalSlice.actions;
export default controlApprovalSlice;