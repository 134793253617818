import { Card, CardContent, Grid, Button, DialogContent, Typography, Backdrop, CircularProgress, Stack, Alert, Snackbar, Dialog, DialogActions, IconButton, DialogTitle } from '@mui/material';
import MaterialTable from 'material-table'
import { makeStyles } from '@mui/styles';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import EditIcon from '@mui/icons-material/Edit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterListIcon from '@mui/icons-material/FilterList';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import * as XLSX from 'xlsx'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { forwardRef } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import ExcelExport from 'src/components/ExcelExport';
import React from 'react';
import axios from 'src/utils/axios';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getAbsPreviousUploads } from 'src/store/slices/dynamicCatalogSlice';
import { getUser } from 'src/utils/Common';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }
}));


const EXTENSIONS = ['xlsx', 'xls', 'csv']
const columns = ["KulModelKodu", "Fiyat"]

function AbsPriceUploadDynamic() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();

  const [colDefs, setColDefs] = React.useState()
  const [saveFileData, setSaveFileData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [responseMessage, setresponseMessage] = React.useState({
    ErrorCode: '0',
    ErrorDescription: ('success_message')
  });
  const [uyari, setUyari] = React.useState(false);
  const [previousUploadsPopUp, setPreviousUploadsPopup] = React.useState(false);
  const [previousUploadsData, setPreviousUploadsData] = React.useState([]);
  const [previousUploadsLog, setPreviousUploadsLog] = React.useState([]);


  const temp = [{ KulModelKodu: '7296303210', Fiyat: '9576' },
  { KulModelKodu: '7296123210', Fiyat: '9875' },
  { KulModelKodu: '7296123291  ', Fiyat: '10408' },
  { KulModelKodu: '7295923210  ', Fiyat: '10406' },
  { KulModelKodu: '7295923281  ', Fiyat: '10403' },
  { KulModelKodu: '7237023212  ', Fiyat: '3462' },
  { KulModelKodu: '7237023281  ', Fiyat: '3469' },
  { KulModelKodu: '7262423214  ', Fiyat: '3123' },
  { KulModelKodu: '7283023214  ', Fiyat: '3461' },
  { KulModelKodu: '7704330201  ', Fiyat: '3465' },
  ]

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  const getExention = (file) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    return EXTENSIONS.includes(extension) // return boolean
  }
  const importExcel = (e) => {
    const files = e.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      setLoading(true)
      if (getExention(file)) {
        reader.readAsBinaryString(file)
      }
      reader.onload = (event) => {
        //parse data
        const bstr = event.target.result
        const workBook = XLSX.read(bstr, { type: "binary" })
        //get first sheet
        const workSheetName = workBook.SheetNames[0]
        const workSheet = workBook.Sheets[workSheetName]
        //convert to array
        const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
        const headers = fileData[0]
        const tempResult = []
        for (var i = 1; i < fileData.length; i++) {
          const tmp = {
            KulModelKodu: String(fileData[i][0]),
            Fiyat: fileData[i][1]
          }
          tempResult.push(tmp)
        }

        /* GEÇMİŞ İŞLEMLERİ LOGLAMA */
        const LogtempResult = []
        for (var i = 1; i < fileData.length; i++) {
          const tmpLog = {
            KulModelKodu: String(fileData[i][0]),
            Fiyat: fileData[i][1],
            SicilNo: SessionUser?.FirstName + " " + SessionUser?.LastName
          }
          LogtempResult.push(tmpLog)
        }


        findTheDifference(tempResult, LogtempResult)
        setColDefs(columns.map(head => ({ title: head, field: head })))
        setLoading(false)
        fileData.splice(0, 1)
      }
    }
  }

  const dfSave = () => {
    setLoading(true)
    var data = [];
    var dataLog = [];
    saveFileData.map(i => {
      try {
        data.push(
          {
            UserModelCode: i.KulModelKodu,
            Price: i.Fiyat
          }
        );
      } catch (ex) {
        setLoading(false)
      }
    })

    /* GEÇMİŞ İŞLEMLERİ LOGLAMA */
    previousUploadsLog.map(i => {
      try {
        dataLog.push(
          {
            UserModelCode: i.KulModelKodu,
            Price: i.Fiyat,
            SessionUser: SessionUser.FirstName + " " + SessionUser.LastName
          }
        )
      } catch (ex) {
        setLoading(false)
      }
    })

    axios.post('SetAbsPriceUpload', data, {
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      setSaveFileData([])
      setresponseMessage({
        ErrorCode: 1000,
        ErrorDescription: 'İşleminiz başarılıyla gerçekleşmiştir...',
      });
      setUyari(true);
      setLoading(false)
      return
    }).catch(err => {
      setresponseMessage({
        ErrorCode: 0,
        ErrorDescription: 'Kaydedilemedi... Lütfen daha sonra tekrar deneyiniz...',
      });
      setUyari(true);
      setLoading(false)
      return
    });

    /* GEÇMİŞ İŞLEMLERİ LOGLAMA */
    axios.post('SetAbsPreviousUploadsLog', dataLog, {
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      setPreviousUploadsData([])
      setresponseMessage({
        ErrorCode: 1000,
        ErrorDescription: 'İşleminiz başarılıyla gerçekleşmiştir...',
      });
      setUyari(true);
      setLoading(false)
      return
    }).catch(err => {
      setresponseMessage({
        ErrorCode: 0,
        ErrorDescription: 'Kaydedilemedi... Lütfen daha sonra tekrar deneyiniz...',
      });
      setUyari(true);
      setLoading(false)
      return
    });


  }

  const findTheDifference = (data, LogData) => {
    var changedValues = []
    var previousUploadsDataLog = [];
    data.map(i => {
      try {
        changedValues.push(
          {
            KulModelKodu: i.KulModelKodu,
            Fiyat: i.Fiyat
          }
        );
      } catch (ex) {
      }
    })

    /* LOGLAMA İŞLEMİ */
    LogData.map(i => {
      try {
        previousUploadsDataLog.push(
          {
            KulModelKodu: i.KulModelKodu,
            Fiyat: i.Fiyat,
            SessionUser: i.SicilNo
          }
        )
      } catch (ex) {

      }
    })

    setSaveFileData(changedValues);
    setPreviousUploadsLog(previousUploadsDataLog);
  }

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBoxIcon {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutlineIcon {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAltIcon {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterListIcon {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeftIcon {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownwardIcon {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <RemoveIcon {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumnIcon {...props} ref={ref} />)
  };

  function PreviousUploadsClicked() {
    dispatch(getAbsPreviousUploads()).then(response => {
      if (response.payload?.length > 0) {
        setPreviousUploadsData(response.payload);
      }
    })
  }

  const previousUploadsColumns = [
    { field: 'KUL_MODEL_KODU', headerName: 'Ürün SKU', width: 400 },
    { field: 'MODEL_KODU_ACIKLAMA', headerName: 'Model Kodu', width: 400 },
    { field: 'FIYAT', headerName: 'Fiyat', width: 400 },
    { field: 'YUKLEYEN_KISI', headerName: 'Yükleyen Kişi', width: 400 },
    {
      field: 'YUKLENEN_TARIH', headerName: 'Eklenen Tarih', width: 200,
      renderCell: (cellValues) => {
        return (
          moment(cellValues.row.YUKLENEN_TARIH).format('DD-MM-YYYY')
        );
      }
    }
  ]

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={8}>
        <Snackbar sx={{ width: '100%' }} open={uyari} autoHideDuration={6000} onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >{responseMessage.ErrorCode === 0 ?
          <Alert icon={<MoodBadIcon />}
            style={{ minWidth: 500 }}
            sx={{
              "& .MuiAlert-icon": {
                color: "white"
              },
              height: 75,
              alignItems: "center",
              verticalAlign: "center"

            }} onClose={uyariKapat} variant="filled" severity="error" >
            {responseMessage.ErrorDescription}
          </Alert>
          :
          <Alert icon={<TagFacesIcon />}
            style={{ minWidth: 500 }}
            sx={{
              "& .MuiAlert-icon": {
                color: "white"
              },
              height: 75,
              alignItems: "center",
              verticalAlign: "center"

            }} onClose={uyariKapat} variant="filled" severity="success" >
            {responseMessage.ErrorDescription}
          </Alert>}
        </Snackbar>
      </Stack>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                component="span"
                onClick={() => { PreviousUploadsClicked(); setPreviousUploadsPopup(true) }}
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              >
                {'Geçmiş Yüklemeler'}
              </Button>
            </Grid>
            <Grid item xs={12} md={2} >
              <ExcelExport JsonData={temp} title={('Örnek Excel')} />
            </Grid>
            <Grid item xs={12} md={2} >
              <input
                id="FileInput"
                type='file'
                onChange={importExcel}
                onClick={(event) => { event.target.value = null }}
                hidden
                accept=".xlsx, .xls, .xml"
              >
              </input>
              <label htmlFor="FileInput">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  component="span"
                  sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                >
                  {'Yükle'}
                </Button>
              </label>

            </Grid>
            <Grid item xs={12} md={2} >
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={dfSave}
                component="span"
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              >
                {'Kaydet'}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography style={{ color: 'red', fontWeight: 5, fontWeight: 'bold' }} variant="div" component="div">
              *Lütfen örnek excel formatında yükleyiniz...!
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
          </Grid>
          <Grid container spacing={2}>
            <DialogContent>
              <MaterialTable
                title={'Fiyat Yükleme'}
                data={saveFileData}
                columns={colDefs}
                icons={tableIcons}
                fullWidth
              />
            </DialogContent>
          </Grid>
        </CardContent>
      </Card>

      <Dialog
        open={previousUploadsPopUp}
        onClose={() => setPreviousUploadsPopup(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle sx={{ width: '100%', color: 'red', fontSize: '16px', fontWeight: 'bold' }}> Geçmiş Yüklemeler</DialogTitle>
        <DialogContent>
          <Grid item xs={12} md={12}>
            <DataGrid
              style={{ height: 600, width: "100%", textAlign: 'center', justifyItems: 'center', }}
              columns={previousUploadsColumns}
              rows={previousUploadsData}
              getRowId={(rows) => rows.ID}
            />
          </Grid>
          <Grid item xs={12} md={12}></Grid>
          <Grid item xs={12} md={12}></Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => { setPreviousUploadsPopup(false) }}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AbsPriceUploadDynamic;
