import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { CardContent, Grid, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLegalEntityControl, deleteLegalEntityControl, insertLegalEntityControl, updateLegalEntityControl } from 'src/store/slices/dynamicCatalogSlice';
import { getUser } from 'src/utils/Common';
import AlertAndLoading from 'src/components/AlertAndLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI",
    width: '100%',
    height: '100%'
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }

}));



function LegalEntityControlDynamic() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();
  const [legalControl, setLegalControl] = React.useState([]);
  const [updatePopUp, setUpdatePopUp] = React.useState(false);
  const [InsertPopUp, setInsertPopUp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState("")
  const [selectedId, setSelectedId] = React.useState("")
  const [UpdateDsc, setUpdateDsc] = React.useState("");
  const [InsertDsc, setInsertDsc] = React.useState("");
  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });



  useEffect(() => {
    setLoading(true);
    dispatch(getLegalEntityControl()).then(response => {
      if (response.payload?.length > 0) {
        setLegalControl(response.payload);
        setLoading(false);
      }
      setLoading(false);
    })
  }, [dispatch]);


  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  function deleteLegalEntityCont(Id) {
    setLoading(true)
    dispatch(deleteLegalEntityControl(Id)).then(response => {
      dispatch(getLegalEntityControl()).then(response => {
        if (response.payload?.length > 0) {
          setLegalControl(response.payload);
          setLoading(false);
        }
      })
      setLoading(false);
    })
  }

  function insertLegalEntityCont() {
    if (!InsertDsc) {
      InfoAlert('Lütfen Kutucukları Boş Bırakmayınız!...', 'error', 'Hata');
      return
    }
    var Acıklama = InsertDsc;
    setLoading(true);
    setInsertPopUp(false);
    setInsertDsc("");
    dispatch(insertLegalEntityControl(Acıklama)).then(response => {
      dispatch(getLegalEntityControl()).then(response => {
        if (response.payload?.length > 0) {
          setLegalControl(response.payload);
          setLoading(false);
        }
      })
      setLoading(false);
    })
  }


  function updateLegalEntityCont() {
    if (!selectedId) {
      return
    }
    setUpdatePopUp(false);
    setLoading(true)
    dispatch(updateLegalEntityControl([selected, selectedId])).then(response => {
      dispatch(getLegalEntityControl()).then(response => {
        if (response.payload?.length > 0) {
          setLegalControl(response.payload);
          setLoading(false);
        }
      })
      setLoading(false);
      setSelected("");
      setSelectedId("");
    })
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  return (
    <>
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <CardContent>
        <TableContainer component={Paper} sx={{ maxHeight: 500, minHeight: 300 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Kelimeler</TableCell>
                <TableCell align="center">Güncelle</TableCell>
                <TableCell align="center">Sil</TableCell>
                <TableCell align="center">
                  <Tooltip title="Ekle">
                    <IconButton onClick={() => setInsertPopUp(true)}  >
                      <PersonAddIcon sx={{ color: '#FF9800' }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {legalControl?.map((row) => (
                <TableRow
                  key={row.ID}
                >
                  <TableCell align="center">{row.ACIKLAMA}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Güncelle">
                      <IconButton onClick={() => { setUpdatePopUp(true); setSelected(row.ACIKLAMA); setSelectedId(row.ID); }}  >
                        <CreateIcon sx={{ color: '#FF9800' }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Sil">
                      <IconButton onClick={() => deleteLegalEntityCont(row.ID)} >
                        <DeleteIcon sx={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent >

      <Dialog
        open={InsertPopUp}
        onClose={() => setInsertPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ width: '100%' }} >{'Kelime Ekleme'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                id="input-with-icon-textfield"
                label="Kelime"
                onChange={(e) => setInsertDsc(e.target.value)}
                value={InsertDsc}
                multiline
                rows={3}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => insertLegalEntityCont()}
            style={{ marginTop: 10 }}
          >{'Ekle'}</Button>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => setInsertPopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={updatePopUp}
        onClose={() => setUpdatePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ width: '100%' }} >{'Kelime Ekleme'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                id="input-with-icon-textfield"
                label="Kelime"
                onChange={(e) => setSelected(e.target.value)}
                value={selected}
                multiline
                rows={3}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => updateLegalEntityCont()}
            style={{ marginTop: 10 }}
          >{'Güncelleme'}</Button>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => setUpdatePopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LegalEntityControlDynamic;
