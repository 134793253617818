import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, LinearProgress, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { getMenu } from 'src/utils/storage/NavBarAdet';
import NavBar from './NavBar';
import TopBar from './TopBar';
function Dashboard({ route }) {

  const [openNavBar, setOpenNavBar] = useState(true);
  const Menu = getMenu();
  const Tab = Menu.find(i => i.items.find(j => j.href === window.location.pathname));
  const [topBarRotation, setTopBarRotation] = useState(true)

  function changeTopBarRotation() {
    setTopBarRotation(!topBarRotation)
  }

  return (
    <>
      <TopBar onOpenNavBar={() => setOpenNavBar(!openNavBar)}
        topBarRotation={topBarRotation}
        changeTopBarRotation={() => changeTopBarRotation()}
      />
      <NavBar open={openNavBar} Menu={Menu}
        onOpenNavBar={() => setOpenNavBar(!openNavBar)}
        changeTopBarRotation={() => changeTopBarRotation()}
      />
      <div>
        <div style={{ paddingLeft: openNavBar ? 300 : 0 }}>
          <Box mt={2} mb={4}>
            <Breadcrumbs style={{ margin: 15 }} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link underline="hover" key="1" color="inherit" href="/" >Dia</Link>,
              <Link underline="hover" key="2" color="inherit" >
                {Tab?.title}
              </Link>,
              <Typography key="3" color="text.primary">
                {Tab.items.find(j => j.href === window.location.pathname).title}
              </Typography>,
            </Breadcrumbs>
          </Box>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
