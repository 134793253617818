import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import { getUser } from 'src/utils/Common';
import { makeStyles } from '@mui/styles';
import { Alert, Backdrop, Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, CircularProgress, DialogContent, DialogTitle, Grid, Autocomplete, MenuItem, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'src/utils/axios';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import TagFacesIcon from '@mui/icons-material/TagFaces';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Segoe UI",
    width: '100%',
    height: '100%'
  },
  buttons: {
    "& .css-u1865r-MuiButtonBase-root-MuiButton-root:hover": {
      backgroundColor: '#424242'
    },
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  checked: {
    color: '#424242',
    '&.Mui-checked': {
      color: '#FF9800',
    }
  },
}));


function DeliveryTime() {
  const SessionUser = getUser();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [tourGeneralInformations, setTourGeneralInformations] = React.useState([]);
  const [tourData, setTourData] = React.useState([]);
  const [tourTimeUpdatePopUp, setTourTimeUpdatePopUp] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [unitCode, setUnitCode] = React.useState(null);
  const [unitCodeSearch, setUnitCodeSearch] = React.useState('');
  const tourNoInputRef = useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [uyari, setUyari] = React.useState(false);
  const [responseMessage, setresponseMessage] = React.useState({
    ErrorCode: '0',
    ErrorDescription: ('success_message')
  });

  useEffect(() => {
    getTourPlannigList(0);
  }, []);
  const handleSearchChange = (event) => {
    setUnitCodeSearch(event.target.value);
  };

  const getTourPlannigList = (x) => {
    var UstBirimKodu = SessionUser.TopServiceShopCode

    setLoading(true);
    if (x == 0) {
      var ServisNo = 0
    }
    else {
      var ServisNo = unitCodeSearch
      if (ServisNo == '') {
        var ServisNo = 0
      }
      console.log(ServisNo)
    }
    axios.get(`/GetTourPlannigList/${UstBirimKodu}/${ServisNo}`, {
    }).then(response => {
      if (response?.data?.length > 0) {
        setTourGeneralInformations(response.data)
      }
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  }

  const getTourTimeUpdateClick = (row) => {
    var UstBirimKodu = SessionUser.TopServiceShopCode
    setUnitCode(row.KUL_BIRIM_KODU)
    setTourTimeUpdatePopUp(true)
    setLoading(true);
    axios.get(`/GetTourPlannigDropdown/${UstBirimKodu}`, {
    }).then(response => {
      if (response?.data?.length > 0) {
        setTourData(response.data)
      }
      else {
        setTourData([])
      }
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  }

  const getTourUpdateClick = () => {
    var TurId = selectedOption?.Id
    if (selectedOption?.Id == undefined || selectedOption?.Id == null) {
      setresponseMessage({
        ErrorCode: 1000,
        ErrorDescription: 'Lütfen Tur Seçiniz!!!',
      });
      setUyari(true);
      return;
    }
    if (unitCode == undefined || unitCode == null || unitCode < 0) {
      setresponseMessage({
        ErrorCode: 1000,
        ErrorDescription: 'Lütfen Daha Sonra Tekrar Deneyiniz!!',
      });
      setUyari(true);
      return;
    }
    setLoading(true);
    axios.get(`/UpdateTourId/${TurId}/${unitCode}`, {
    }).then(response => {
      if (response.data.ErrorCode === "0") {
        setresponseMessage({
          ErrorCode: 0,
          ErrorDescription: 'İşleminiz başarılıyla gerçekleşmiştir...',
        });
        setUyari(true);
        setLoading(false);
        getTourPlannigList(0);
        setUnitCode(null)
        setSelectedOption(null)
        setTourTimeUpdatePopUp(false);
        return
      } else {
        setresponseMessage({
          ErrorCode: 1000,
          ErrorDescription: response.data.ErrorDescription,
        });
        setUyari(true);

        setLoading(false);
        return
      }
    }).catch(err => {
      setLoading(false);
    });

  }

  const columns = [
    { field: 'ServisNo', headerName: 'Servis Numarası', width: 200 },
    { field: 'ServisAd', headerName: 'Servis Adı', width: 200 },
    { field: 'Ilce', headerName: 'İlçe', width: 200 },
    { field: 'Il', headerName: 'İl', width: 200 },
    { field: 'Tur', headerName: 'Tur', width: 200 },
    {
      field: 'Güncelleme',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', "& > :not(style)": { m: 1 } }}>
            <Button
              variant='outlined'
              size="small"
              fullWidth
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={() => { getTourTimeUpdateClick(cellValues.row); }}
            >
              Güncelle
            </Button>
          </Box>
        );
      }, width: 150
    }
  ];

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={8}>
        <Snackbar sx={{ width: '100%' }} open={uyari} autoHideDuration={6000} onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >{responseMessage.ErrorCode === 1000 ?
          <Alert icon={<MoodBadIcon />}
            style={{ minWidth: 500 }}
            sx={{
              "& .MuiAlert-icon": {
                color: "white"
              },
              height: 75,
              alignItems: "center",
              verticalAlign: "center"

            }} onClose={uyariKapat} variant="filled" severity="error" >
            {responseMessage.ErrorDescription}
          </Alert>
          :
          <Alert icon={<TagFacesIcon />}
            style={{ minWidth: 500 }}
            sx={{
              "& .MuiAlert-icon": {
                color: "white"
              },
              height: 75,
              alignItems: "center",
              verticalAlign: "center"

            }} onClose={uyariKapat} variant="filled" severity="success" >
            {responseMessage.ErrorDescription}
          </Alert>}
        </Snackbar>
      </Stack>
      <Card container className={classes.root} component={Page}>
        <Grid item xs={12} sm={12} md={12}>
          <CardHeader title={`Stok Temin Zamanı Hesaplama: ${SessionUser.TopServiceShopCode}`} sx={{ color: 'red' }} />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={2}>
                <TextField
                  variant='outlined'
                  size='small'
                  fullWidth
                  label='Birim Kodu'
                  onChange={handleSearchChange}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                  onClick={() => { getTourPlannigList(1) }}
                >
                  Ara
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CardContent>
            <DataGrid
              style={{ height: 600, width: "100%", textAlign: 'center', justifyItems: 'center', }}
              columns={columns}
              rows={tourGeneralInformations}
              getRowId={(rows) => rows.ServisNo}
              rowsPerPageOptions={[25, 50, 100]}
            />
          </CardContent>
        </Grid>
      </Card>

      {/* Tur Güncelleme PopUp */}
      <Dialog
        open={tourTimeUpdatePopUp}
        onClose={() => setTourTimeUpdatePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ width: '100%', color: 'red', fontSize: '16px', fontWeight: 'bold' }}> Birim Süresini Güncelle</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                options={tourData}
                getOptionLabel={(option) => option.Tur}
                value={selectedOption}
                onChange={(event, newValue) => setSelectedOption(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth size="small" placeholder='Tur' />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            size="small"
            fullWidth
            sx={{ color: '#FF9800', backgroundColor: '#424242' }}
            onClick={() => { getTourUpdateClick() }}
          >
            Güncelle
          </Button>
          <Button
            variant='outlined'
            size="small"
            fullWidth
            sx={{ color: '#FF9800', backgroundColor: '#424242' }}
            onClick={() => { setTourTimeUpdatePopUp(false); setSelectedOption(null); }}
          >
            Vazgeç
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeliveryTime;
