import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};

export const getAuthorizedUsers = createAsyncThunk(
  '/getAuthorizedUsers',
  async () => {
    const response = await axios.post('/GetAuthorizedUsers', dataConfig);
    return response.data;
  }
);

export const getUserBasedComponentAuthorizedUsers = createAsyncThunk(
  '/GetUserBasedComponentAuthorizedUsers',
  async () => {
    const response = await axios.get(
      '/GetUserBasedComponentAuthorizedUsers',
      dataConfig
    );
    return response.data;
  }
);

export const getAllComponents = createAsyncThunk(
  '/GetAllComponents',
  async () => {
    const response = await axios.get('/GetAllComponents', dataConfig);
    return response.data;
  }
);

export const updateUserBasedComponentsAuthorization = createAsyncThunk(
  '/UpdateUserBasedComponentsAuthorization',
  async (data) => {
    const response = await axios.post('/UpdateUserBasedComponentsAuthorization', data, dataConfig);
    return response.data;
  }
);

export const updateUserRole = createAsyncThunk(
  '/updateUserRoles',
  async (data) => {
    const response = await axios.post('/UpdateUserRoles', data, dataConfig);
    return response.data;
  }
);

export const getAuthorizedMenu = createAsyncThunk(
  '/GetAuthorizedUsers',
  async () => {
    const response = await axios.get('/GetAuthorizedMenu', dataConfig);
    return response.data;
  }
);

export const getAuthorizedComponent = createAsyncThunk(
  '/GetAuthorizedComponent',
  async () => {
    const response = await axios.get('/GetAuthorizedComponent', dataConfig);
    return response.data;
  }
);

export const updateMenuRoles = createAsyncThunk(
  '/UpdateMenuRoles',
  async (data) => {
    const response = await axios.post('/UpdateMenuRoles', data, dataConfig);
    return response.data;
  }
);

export const updateComponentRoles = createAsyncThunk(
  '/UpdateComponentRoles',
  async (data) => {
    const response = await axios.post(
      '/UpdateComponentRoles',
      data,
      dataConfig
    );
    return response.data;
  }
);

const authorizationSlice = createSlice({
  name: 'common',
  initialState: {
    loading: '',
    error: ''
  },
  reducers: {},
  extraReducers: {}
});
export const authorizationActions = authorizationSlice.actions;
export default authorizationSlice;
