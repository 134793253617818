import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};

export const getProductPrice = createAsyncThunk('/GetProductPrice', async (params) => {
  const response = await axios.get(`/GetProductPrice/${params[0]}/${params[1]}/${params[2]}`).then(response => { return response.data; }).catch(err => { return (err.response.data) })

});



const diffModelSuggestSlice = createSlice({
  name: 'diffModelSuggest',
  initialState: {
    loading: '',
    error: '',


  },
  reducers: {

  },
  extraReducers: {
  }
});


export const diffModelSuggestActions = diffModelSuggestSlice.actions;
export default diffModelSuggestSlice;