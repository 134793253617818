import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { getAllComponents, getUserBasedComponentAuthorizedUsers, updateUserBasedComponentsAuthorization } from 'src/store/slices/authorizationSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  checked: {
    color: '#FF9800',
    '&.Mui-checked': {
      color: '#FF9800',
    }
  },
  color: {
    color: '#424242'
  },
  button: {
    color: '#FF9800', backgroundColor: '#424242'
  }
}));

function UserBasedComponentAuth() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [userList, setUserList] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [allComponents, setAllComponents] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userPermissions, setUserPermissions] = useState([]);  // Seçilen Kullanıcının Sahip Olduğu Componentlerin Yetkileri
  const [openDialog, setOpenDialog] = useState(false);

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    getAllUserData();
    getAllComponentsData();
  }, []);

  useEffect(() => {
    const searchedRows = userList.filter(
      (i) =>
        i.ADSOYAD.toLowerCase().includes(search.toLowerCase()) ||
        i.SICILNO.includes(search)
    );
    setRows(searchedRows);
  }, [search, userList]);

  const getAllUserData = () => {
    setLoading(true);
    dispatch(getUserBasedComponentAuthorizedUsers()).then((response) => {
      if (response.payload?.length > 0) {
        setUserList(response.payload);
        setLoading(false);
      }
    }).catch(err => {
      setLoading(false);
    })
  }

  const getAllComponentsData = () => {
    setLoading(true);
    dispatch(getAllComponents()).then((response) => {
      if (response.payload?.length > 0) {
        const data = response.payload;
        setAllComponents(data);
        setLoading(false);
      }
    });
  }

  const columns = [
    { field: 'ADSOYAD', headerName: 'Ad Soyad', width: 300 },
    { field: 'SICILNO', headerName: 'Sicil No', width: 200 },
    {
      field: 'Güncelle',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}>
            <Tooltip title="Güncelle">
              <IconButton
                onClick={() => {
                  handleUpdateRoleClick(cellValues.row);
                }}
              >
                <CreateIcon className={classes.color} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
      width: 150
    }
  ];

  const handleUpdateRoleClick = (params) => {
    setSelectedUser(params);

    // Kullanıcının yetkilerini getir
    fetchUserPermissions(params);

    // Dialog'u aç
    setOpenDialog(true);
  };

  // Kullanıcının sahip olduğu yetkileri API'den çekme (örnek verilerle simüle)
  const fetchUserPermissions = (params) => {
    // Seçilen kullanıcıyı userList'ten bul
    const selectedUser = userList?.find(user => user.ID === params.ID);
    
    // Kullanıcının ActiveComponents bilgisini diziye çevir
    const activeComponentIDs = selectedUser?.ActiveComponents?.split(',').map(Number);
      
    // Kullanıcının sahip olduğu yetkileri filtrele
    const permissions = allComponents?.filter(component => 
      activeComponentIDs?.includes(component.ID)
    );
    setUserPermissions(permissions);
  };

  const handleCheckboxChange = (event, componentId) => {
    let updatedPermissions;
  
    if (event.target.checked) {
      updatedPermissions = [...userPermissions, componentId]; // ID'yi ekle
    } else {
      updatedPermissions = userPermissions.filter(id => id !== componentId); // ID'yi kaldır
    }
  
    setUserPermissions(updatedPermissions);
  };
  
  const handleUpdateUserAuthorization = () => {
    var permissionList = [];
    userPermissions.map(row => {
      permissionList.push(row.ID);
    });

    const data = {
      UserPermissions: permissionList.toString(),
      UserId: selectedUser.ID
    }
    setLoading(true);
    dispatch(updateUserBasedComponentsAuthorization(data)).then(response => {
      dispatch(getUserBasedComponentAuthorizedUsers()).then(response => {
        if (response.payload?.length > 0) {
          setUserList(response.payload);
          setLoading(false);
        }
      })
      
      setOpenDialog(false);
      setSelectedUser([]);
      setLoading(false);
    })

  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={6}></Grid>
            <Grid item sm={6}>
              <TextField
                variant="standard"
                size="small"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Ara..."
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item sm={12} style={{ paddingTop: 5 }}></Grid>
          <DataGrid
            style={{
              height: 600,
              width: '100%',
              textAlign: 'center',
              justifyItems: 'center'
            }}
            columns={columns}
            rows={rows}
            getRowId={(row) => row.ID}
          />
        </CardContent>
      </Card>
      <Dialog 
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ width: '100%' }} >{"Kullanıcı Bazlı İşlem Yetkilendirme"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  disabled
                  value={selectedUser?.ADSOYAD + '-' + selectedUser?.SICILNO}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl sx={{ m: 3 }} component="div" variant="standard">
                  <FormLabel component="legend" className={classes.color}>{'Kullanıcı rolleri'}</FormLabel>
                  {allComponents.map(component => (
                    <Grid item sm={12} key={component.ID}>
                      <FormControlLabel
                        control={
                          <Checkbox 
                          checked={userPermissions.some(permission => permission.ID === component.ID)} // userPermissions dizisinde component.ID ile eşleşen bir nesne var mı kontrol ediliyor
                          name={component.COMPONENT_NAME}
                          className={classes.checked}
                          onChange={(event) => handleCheckboxChange(event, component)}
                          />
                        }
                        label={component.COMPONENT_NAME}
                      />
                    </Grid>
                  ))}
                  <FormHelperText>Birden fazla seçim yapabilirsiniz.</FormHelperText>
                  </FormControl>
              </Grid>
              <Button 
                variant='contained' 
                fullWidth
                size="medium"
                className={classes.button}
                onClick={() => {handleUpdateUserAuthorization();}}
              >
                Güncelle
              </Button>
            </Grid>
        </DialogContent>
        <DialogActions>
        <IconButton
            className={classes.color}
            onClick={() => setOpenDialog(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserBasedComponentAuth;
