import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};

export const getMuadilSearchBox = createAsyncThunk('/GetMuadilSearchBox', async (Model) => {
  const response = await axios.get(`/GetMuadilSearchBox/${Model}`);
  return response.data;
});

export const getMuadilComboBox = createAsyncThunk('/GetMuadilComboBox', async () => {
  const response = await axios.get(`/GetMuadilComboBox`);
  return response.data;
});

export const getMuadilProductList = createAsyncThunk('/GetMuadilProductList', async (ModelKodu) => {
  const response = await axios.get(`/GetMuadilProductList/${ModelKodu}`);
  return response.data;
});

export const getDiscountedUser = createAsyncThunk('/GetDiscountedUser', async () => {
  const response = await axios.get('/GetDiscountedUser', dataConfig);
  return response.data;
});

export const getMuadilAdminQueue = createAsyncThunk('/GetMuadilAdminQueue', async () => {
  const response = await axios.get(`/GetMuadilAdminQueue`);
  return response.data;
});

export const getMuadilProductListStocks = createAsyncThunk('/GetMuadilProductListStocks', async (params) => {
  const response = await axios.get(`/GetMuadilProductListStocks/${params[0]}/${params[1]}`);
  return response.data;
});

const equivalentProductsSlice = createSlice({
  name: 'equivalentProducts',
  initialState: {
    loading: '',
    error: '',
    discountedUsers: [],
    lastRequestGetDiscountedUser: '',
  },
  reducers: {
  },
  extraReducers: {
    [getDiscountedUser.pending]: (state) => {
      state.loading = true;
    },
    [getDiscountedUser.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getDiscountedUser.fulfilled]: (state, action) => {
      state.discountedUsers = action.payload;
      state.loading = false;
      state.lastRequestGetDiscountedUser = Date();
    },

  }
});
export const equivalentProductsActions = equivalentProductsSlice.actions;
export default equivalentProductsSlice;