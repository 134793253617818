
import CloseIcon from '@mui/icons-material/Close';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CreateIcon from '@mui/icons-material/Create';
import Collapse from '@mui/material/Collapse';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Grid, MenuItem, TextField, ListItem, List, ListItemIcon, Button, Tooltip,
  ListItemText, CardContent, ListItemButton, Checkbox, Backdrop, CircularProgress
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getConfirmTypeForDynamic, updateConfirmTypeDynamic, addNewConfirmTypeDynamic } from 'src/store/slices/dynamicCatalogSlice';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AlertAndLoading from 'src/components/AlertAndLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  }

}));

function ConfirmTypeDynamic() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirmType, setConfirmType] = React.useState([]);
  const [confirmTypeAddPopUp, setConfirmTypeAddPopUp] = React.useState(false);
  const [SelectedValue, setSelectedValue] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [addOrUpdate, setAddOrUpdate] = React.useState(0);
  const [confirmTypeDsc, setConfirmTypeDsc] = React.useState('');
  const [confirmTypeDetailDsc, setConfirmTypeDetailDsc] = React.useState('');
  const [confirmTypeId, setConfirmTypeId] = React.useState(null);
  const [confirmTypeDetailId, setConfirmTypeDetailId] = React.useState(null);
  const [ActiveOrPassive, setActiveOrPassive] = React.useState(1);
  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });


  const handleClick = (index) => {
    var SelectedValueCopy = [...SelectedValue];
    SelectedValueCopy[index].IsOpen = !SelectedValueCopy[index].IsOpen;
    setSelectedValue(SelectedValueCopy);
  };



  useEffect(() => {
    setLoading(true);
    dispatch(getConfirmTypeForDynamic())
      .then(response => {
        setLoading(false);
        setConfirmType(response.payload);
        var findIn = [];
        for (var i = 0; i < response.payload.length; i++) {
          findIn.push({
            id: i,
            IsOpen: false
          })
        }
        setSelectedValue(findIn);
      }).catch(err => { setLoading(false); return });
  }, [dispatch]);

  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  function handleAdd() {
    setConfirmTypeAddPopUp(true);
    setAddOrUpdate(1);
    setConfirmTypeDsc('');
    setConfirmTypeDetailDsc('')
    setAddOrUpdate(0);
  }
  function RowClick(row) {

    console.log(confirmType)
    setConfirmTypeAddPopUp(true);
    setConfirmTypeDsc(row.UST_DEGISIM_ACIKLAMA);
    setConfirmTypeDetailDsc(row.ACIKLAMA);
    setConfirmTypeId(row.UST_DEGISIM_TURU);
    setConfirmTypeDetailId(row.DEGISIM_TURU);
    setAddOrUpdate(1);
  }

  function UpdateOrAddClick() {
    if (!confirmTypeDetailDsc || !confirmTypeId) {
      InfoAlert('Lütfen Kutucukları Boş Bırakmayınız!...', 'error', 'Hata');
      return;
    }
    setConfirmTypeAddPopUp(false);
    const data = {
      ConfirmTypeDsc: confirmTypeDsc,
      ConfirmTypeDetailDsc: confirmTypeDetailDsc,
      Active: ActiveOrPassive,
      ConfirmTypeId: confirmTypeId,
      ConfirmTypeDetailId: confirmTypeDetailId || 0
    }
    setLoading(true)
    if (addOrUpdate == 1) {
      dispatch(updateConfirmTypeDynamic(data)).then(response => {
        dispatch(getConfirmTypeForDynamic()).then(response => {
          setConfirmType(response.payload);
          setActiveOrPassive(1);
          setLoading(false);
        })
        setLoading(false);
      })
    }
    else {
      dispatch(addNewConfirmTypeDynamic(data)).then(response => {
        dispatch(getConfirmTypeForDynamic()).then(response => {
          setConfirmType(response.payload);
          setActiveOrPassive(1);
          setLoading(false);
        })
        setLoading(false);
      })

    }
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  return (
    <>
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
        </Grid>
        <Grid item xs={12} md={8}>
          <CardContent >
            {confirmType?.map((index, i) => (
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
              >
                <ListItemButton key={index.Id} onClick={() => { handleClick(i); }}>
                  <ListItemIcon>
                    <Checkbox
                      name="checkedB"
                      checkedIcon={<ClearAllIcon color='success' />}
                      icon={<ClearAllIcon sx={{ color: 'red' }} />}
                      checked={index.Active === true}
                    />
                  </ListItemIcon>
                  <ListItemText primary={index.Dsc} sx={{ fontWeight: 'bold' }} />
                  {SelectedValue[i]?.IsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={SelectedValue[i]?.IsOpen}
                  timeout="auto" unmountOnExit>
                  {index?.Detail?.map((index, i) => (
                    <List component="div" disablePadding>
                      <ListItem sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <Checkbox
                            name="checkedB"
                            checkedIcon={<ThumbUpAltIcon color='success' />}
                            icon={<ThumbDownAltIcon sx={{ color: 'red' }} />}
                            checked={index.Aktif === true}
                          />
                        </ListItemIcon>
                        <ListItem key={index.DEGISIM_TURU}
                          className={classes.nested} >
                          <ListItemText primary={index?.ACIKLAMA} />
                        </ListItem>
                        <ListItemIcon>
                          <IconButton
                            color="primary"
                            aria-label="close"
                            component="span"
                            variant="contained"
                            onClick={() => { RowClick(index) }}
                          >
                            <CreateIcon sx={{ color: '#FF9800' }} />
                          </IconButton>
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  ))}
                </Collapse>
              </List>
            ))}
          </CardContent>
        </Grid>
        <Grid item xs={12} md={2}>
          <Tooltip title="Yeni Onay Tipi Ekle">
            <IconButton
              color="primary"
              aria-label="close"
              component="span"
              variant="contained"
              onClick={() => { handleAdd(); }}
            >
              <PersonAddIcon sx={{ color: '#FF9800' }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Dialog
        open={confirmTypeAddPopUp}
        onClose={() => setConfirmTypeAddPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle sx={{ width: '100%' }} >{"Onay Tipi Ekleme"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                select
                autoComplete="no"
                id="OnayTürüAktif"
                value={ActiveOrPassive}
                onChange={(e) => { setActiveOrPassive(e.target.value); }}
                variant="outlined"
              >
                <MenuItem key={1} value={1}>
                  {('Aktif')}
                </MenuItem>

                <MenuItem key={0} value={0}>
                  {('Pasif')}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                select
                autoComplete="no"
                id="exchangeReasonName"
                label="Üst Değişim Türü"
                variant="outlined"
                value={confirmTypeId}
                helperText={!confirmTypeId ? "Değişim Türü Seçiniz" : ""}
                onChange={(e) => setConfirmTypeId(e.target.value)}
              >
                {confirmType?.filter((a) => a.Active == true).map((index) => (
                  <MenuItem key={index.Id} value={index.Id}>
                    {index.Dsc}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="input-with-icon-textfield"
                label="Onay Türü Detay"
                value={confirmTypeDetailDsc}
                helperText={!confirmTypeDetailDsc ? "Değişim Türü Detayını yazınız" : ""}
                size="small"
                fullWidth
                variant="outlined"
                onChange={(e) => setConfirmTypeDetailDsc(e.target.value)}
              />
            </Grid>
            <Button
              fullWidth
              size="small"
              variant="contained"
              onClick={() => UpdateOrAddClick()}
              style={{ marginTop: 10 }}
            >{addOrUpdate == 1 ? 'Güncelle' : 'Ekle'}</Button>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => setConfirmTypeAddPopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmTypeDynamic;
