
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Backdrop, Box, Card, CardContent, CircularProgress, Grid, TextField, Typography, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { DataGrid, trTR } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MuiInput from '@mui/material/Input';
import { getUser } from 'src/utils/Common';
import { getDiscountRateUsers, updateDiscountRateUsers } from 'src/store/slices/dynamicCatalogSlice';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  checked: {
    color: '#FF9800',
    '&.Mui-checked': {
      color: '#FF9800',
    }
  },
  color: {
    color: '#424242'
  },
  button: {
    color: '#FF9800', backgroundColor: '#424242'
  }
}));

const Input = styled(MuiInput)`
  width: 50;
`;

function DiscountRateDynamic() {
  const classes = useStyles();
  const SessionUser = getUser();
  const dispatch = useDispatch();
  const [userList, setUserList] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [selectedUser, setSelectedUser] = React.useState([])
  const [changeRateUserModal, setChangeRateUserModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [value, setValue] = React.useState(0);
  const [oldValue, setOldValue] = React.useState(1);
  const [valueTopModel, setValueTopModel] = React.useState(0);
  const [oldValueTopModel, setOldValueTopModel] = React.useState(1);


  const handleSliderChangeL = (event, newValue) => {
    setOldValue(value);
    setValue(newValue);
  };

  const handleInputChangeL = event => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleSliderChangeLTopModel = (event, newValue) => {
    setOldValueTopModel(valueTopModel);
    setValueTopModel(newValue);
  };

  const handleInputChangeLTopModel = event => {
    setValueTopModel(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }

    if (valueTopModel < 0) {
      setValueTopModel(0);
    } else if (valueTopModel > 100) {
      setValueTopModel(100);
    }
  };
  useEffect(() => {
    setLoading(true)
    dispatch(getDiscountRateUsers()).then(response => {
      if (response.payload?.length > 0) {
        setUserList(response.payload)
        setLoading(false)
      }
    })
  }, [dispatch]);

  useEffect(() => {
    const searchedRows = userList.filter(i => i.ADSOYAD.toLowerCase().includes(search.toLowerCase()) || i.SICILNO.includes(search))
    setRows(searchedRows)
  }, [search, userList]);

  const columns = [
    { field: 'ADSOYAD', headerName: 'Ad Soyad', width: 400 },
    { field: 'SICILNO', headerName: 'Sicil No', width: 100 },
    { field: 'UcretliDegisimIndirimOranlari', headerName: 'Ücretli Değişim İndirim Oranları', width: 200, align: 'center' },
    { field: 'UstModelFarkIndirimOran', headerName: 'Üst Model Fark İndirim Oranları', width: 200, align: 'center' },
    {
      field: 'Güncelle',
      renderCell: (cellValues) => {
        return (
          <Box sx={{ justifyContent: 'center', "& > :not(style)": { m: 1 } }}>
            <Tooltip title="Güncelle">
              <IconButton onClick={() => { handleUpdateRoleClick(cellValues.row) }} >
                <CreateIcon sx={{ color: '#FF9800' }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }, width: 150
    }
  ];


  const handleUpdateRoleClick = (row) => {
    setSelectedUser(row)
    setChangeRateUserModal(true)
    setValue(row.UcretliDegisimIndirimOranlari)
    setValueTopModel(row.UstModelFarkIndirimOran)
  }

  const updateDiscountRateClick = () => {
    const data = {
      UserId: selectedUser.ID,
      UcretliDegisimIndirimOran: value,
      UstModelFarkIndirimOran: valueTopModel,
      SessionUserId: SessionUser?.UserId
    }

    setLoading(true)
    dispatch(updateDiscountRateUsers(data)).then(response => {
      dispatch(getDiscountRateUsers()).then(response => {
        if (response.payload?.length > 0) {
          setUserList(response.payload)
          setLoading(false)
        }
      })
      setChangeRateUserModal(false)
      setLoading(false)
      setSelectedUser([])
    })
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={6}></Grid>
            <Grid item sm={6}>
              <TextField
                variant="standard"
                size='small'
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Ara..."
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item sm={12} style={{ paddingTop: 5 }}></Grid>
          <DataGrid
            style={{ height: 600, width: '100%', textAlign: 'center', justifyItems: 'center', }}
            columns={columns}
            rows={rows}
            getRowId={(row) => row.ID}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
          />
        </CardContent>
      </Card>
      <Dialog
        open={changeRateUserModal}
        onClose={() => setChangeRateUserModal(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ width: '100%' }} >{"Kullanıcı Bazlı İndirim Oran"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                disabled
                value={selectedUser.ADSOYAD + '-' + selectedUser.SICILNO}
              />
            </Grid>
          </Grid>
          <br></br>
          <Typography>
            Ücretli Değişim İndirim Oranı
          </Typography>
          <br></br>
          <Box fullWidth>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <CheckCircleIcon sx={{ color: '#FF9800' }} />
              </Grid>
              <Grid item lg>
                <Slider
                  value={typeof value === 'number' ? value : 0}
                  onChange={handleSliderChangeL}
                  aria-labelledby="input-slider"
                  sx={{ color: '#FF9800' }}
                  step={5}
                  min={0}
                  max={100}
                />
              </Grid>
              <Grid item>
                <Input
                  className={classes.input}
                  value={value}
                  margin="dense"
                  disableUnderline={true}
                  onChange={handleInputChangeL}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 5,
                    min: 0,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider'
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <br></br>
          <Typography>
            Üst Model Fark İndirim Oranı
          </Typography>
          <br></br>
          <Box fullWidth>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <CheckCircleIcon sx={{ color: '#FF9800' }} />
              </Grid>
              <Grid item lg>
                <Slider
                  value={typeof valueTopModel === 'number' ? valueTopModel : 0}
                  onChange={handleSliderChangeLTopModel}
                  aria-labelledby="input-slider"
                  sx={{ color: '#FF9800' }}
                  step={5}
                  min={0}
                  max={100}
                />
              </Grid>
              <Grid item>
                <Input
                  className={classes.input}
                  value={valueTopModel}
                  margin="dense"
                  disableUnderline={true}
                  onChange={handleInputChangeLTopModel}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 5,
                    min: 0,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider'
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <br></br>
          <Button
            fullWidth
            size="small"
            className={classes.button}
            onClick={() => updateDiscountRateClick()}
          >Güncelle</Button>
        </DialogContent>
        <DialogActions>
          <IconButton
            className={classes.color}
            onClick={() => setChangeRateUserModal(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DiscountRateDynamic;
