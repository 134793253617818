import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};

export const getDiscountedUser = createAsyncThunk('/GetDiscountedUser', async () => {
  const response = await axios.get('/GetDiscountedUser', dataConfig);
  return response.data;
});

export const getLegalEntityControl = createAsyncThunk('/GetLegalEntityControl', async () => {
  const response = await axios.get('/GetLegalEntityControl', dataConfig);
  return response.data;
});

export const AddOrUpdateDiscountedUser = createAsyncThunk('/AddOrUpdateDiscountedUser', async (data) => {
  const response = await axios.post('/AddOrUpdateDiscountedUser', data, dataConfig);
  return response.data;
});

export const InsertQualityProductControl = createAsyncThunk('/InsertQualityProductControl', async (data) => {
  const response = await axios.post('/InsertQualityProductControl', data, dataConfig);
  return response.data;
});

export const getServiceRejectionReasons = createAsyncThunk('/GetServiceRejectionReasons', async () => {
  const response = await axios.get('/GetServiceRejectionReasons', dataConfig);
  return response.data;
});

export const AddOrUpdateServiceRejectionReason = createAsyncThunk('/AddOrUpdateServiceRejectionReason', async (data) => {
  const response = await axios.post('/AddOrUpdateServiceRejectionReason', data, dataConfig);
  return response.data;
});

export const deleteLegalEntityControl = createAsyncThunk('/DeleteLegalEntityControl', async (Id) => {
  const response = await axios.get(`/DeleteLegalEntityControl/${Id}`);
  return response.data;
});

export const insertLegalEntityControl = createAsyncThunk('/InsertLegalEntityControl', async (Acıklama) => {
  const response = await axios.get(`/InsertLegalEntityControl/${Acıklama}`);
  return response.data;
});

export const updateLegalEntityControl = createAsyncThunk('/UpdateLegalEntityControl', async (data) => {
  const response = await axios.get(`/UpdateLegalEntityControl/${data[0]}/${data[1]}`);
  return response.data;
});


export const getDiscountRateUsers = createAsyncThunk('/GetDiscountRateUsers', async () => {
  const response = await axios.get('/GetDiscountRateUsers', dataConfig);
  return response.data;
});


export const updateDiscountRateUsers = createAsyncThunk('/UpdateDiscountRateUsers', async (data) => {
  const response = await axios.post('/UpdateDiscountRateUsers', data, dataConfig);
  return response.data;
});

export const getConfirmTypeForDynamic = createAsyncThunk('/GetConfirmTypeForDynamic', async () => {
  const response = await axios.get('/GetConfirmTypeForDynamic', dataConfig);
  return response.data;
});

export const updateConfirmTypeDynamic = createAsyncThunk('/UpdateConfirmTypeDynamic', async (data) => {
  const response = await axios.post('/UpdateConfirmTypeDynamic', data, dataConfig);
});

export const getExchangeReasonForDynamic = createAsyncThunk('/GetExchangeReasonForDynamic', async () => {
  const response = await axios.get('/GetExchangeReasonForDynamic', dataConfig);
  return response.data;
});

export const addNewExchangeReasonForDynamic = createAsyncThunk('/AddNewExchangeReasonForDynamic', async (data) => {
  const response = await axios.post('/AddNewExchangeReasonForDynamic', data, dataConfig);
  return response.data;
});

export const updateNewExchangeReasonForDynamic = createAsyncThunk('/UpdateNewExchangeReasonForDynamic', async (data) => {
  const response = await axios.post('/UpdateNewExchangeReasonForDynamic', data, dataConfig);
  return response.data;
});

export const addNewConfirmTypeDynamic = createAsyncThunk('/AddNewConfirmTypeDynamic', async (data) => {
  const response = await axios.post('/AddNewConfirmTypeDynamic', data, dataConfig);
  return response.data;
});

export const fillMainProductGroups = createAsyncThunk('/FillMainProductGroups', async () => {
  const response = await axios.get('/FillMainProductGroups');
  return response.data;
});

export const secondMainProductGroups = createAsyncThunk('/SecondMainProductGroups', async (TopModelCode) => {
  const response = await axios.get(`/SecondMainProductGroups/${TopModelCode}`);
  return response.data;
});

export const getQualityProductControl = createAsyncThunk('/GetQualityProductControl', async () => {
  const response = await axios.get('/GetQualityProductControl');
  return response.data;
});

export const deleteQualityProduct = createAsyncThunk('/DeleteQualityProduct', async (params) => {
  const response = await axios.get(`/DeleteQualityProduct/${params[0]}/${params[1]}`);
  return response.data;
});
export const thirdMainProductGroups = createAsyncThunk('/thirdMainProductGroups', async (TopModelCode) => {
  const response = await axios.get(`/thirdMainProductGroups/${TopModelCode}`);
  return response.data;
});

export const showList = createAsyncThunk('/ShowList', async () => {
  const response = await axios.get('/ShowList');
  return response.data;
});

export const getProductBasedExchangeRateForDynamic = createAsyncThunk('/GetProductBasedExchangeRateForDynamic', async () => {
  const response = await axios.get('/GetProductBasedExchangeRateForDynamic', dataConfig);
  return response.data;
});

export const getModelForDynamic = createAsyncThunk('/GetModelForDynamic', async () => {
  const response = await axios.get('/GetModelForDynamic', dataConfig);
  return response.data;
});

export const updateProductBasedExchangeRate = createAsyncThunk('/UpdateProductBasedExchangeRate', async (data) => {
  const response = await axios.post('/UpdateProductBasedExchangeRate', data, dataConfig);
  return response.data;
});

export const deleteProductBasedExchangeRate = createAsyncThunk('/DeleteProductBasedExchangeRate', async (data) => {
  const response = await axios.get(`/DeleteProductBasedExchangeRate/${data}`);
  return response.data;
});

export const addProductBasedExchangeRate = createAsyncThunk('/AddProductBasedExchangeRate', async (data) => {
  const response = await axios.post('/AddProductBasedExchangeRate', data, dataConfig);
  return response.data;
});


export const deleteAutomaticApprovalChangeReason = createAsyncThunk('/DeleteAutomaticApprovalChangeReason', async (data) => {
  const response = await axios.get(`/DeleteAutomaticApprovalChangeReason/${data[0]}`);
  return response.data;
});

export const xd = createAsyncThunk('/InsertAutomaticApprovalChangeReason', async (data) => {
  const response = await axios.post('/InsertAutomaticApprovalChangeReason', data, dataConfig);
  return response.data;
});

export const getCampaignProductDynamic = createAsyncThunk('/GetCampaignProductDynamic', async () => {
  const response = await axios.get('/GetCampaignProductDynamic');
  return response.data;
});

export const insertCampaignProductDynamic = createAsyncThunk('/InsertCampaignProductDynamic', async (data) => {
  const response = await axios.post('/InsertCampaignProductDynamic', data, dataConfig);
  return response.data;
});

export const deleteCampaignProductDynamic = createAsyncThunk('/DeleteCampaignProductDynamic', async (params) => {
  const response = await axios.get(`/DeleteCampaignProductDynamic/${params[0]}`);
  return response.data;
});

export const getAbsPreviousUploads = createAsyncThunk('/GetAbsPreviousUploads', async () => {
  const response = await axios.get('/GetAbsPreviousUploads');
  return response.data;
});

export const insertDeletedAutomaticApprovalChangeReason = createAsyncThunk('/InsertDeletedAutomaticApprovalChangeReason', async (data) => {
  const response = await axios.post('/InsertDeletedAutomaticApprovalChangeReason', data, dataConfig);
  return response.data;
});

export const deletedAutomaticApprovalChangeReasonShowList = createAsyncThunk('/DeletedAutomaticApprovalChangeReasonShowList', async () => {
  const response = await axios.get('/DeletedAutomaticApprovalChangeReasonShowList');
  return response.data;
});

export const getAllRefundConvinceType = createAsyncThunk('/GetAllRefundConvinceType', async () => {
  const response = await axios.get('/GetAllRefundConvinceType', dataConfig);
  return response.data;
});

export const addOrUpdateConvinceType = createAsyncThunk('/AddOrUpdateConvince', async (data) => {
  const response = await axios.post('/AddOrUpdateConvince', data, dataConfig);
  return response.data;
});

export const updateExchangeReasonSurveyControl = createAsyncThunk('/UpdateExchangeReasonSurveyControl', async (data) => {
  const response = await axios.post('/UpdateExchangeReasonSurveyControl', data, dataConfig);
  return response.data;
});


const dynamicCatalogSlice = createSlice({
  name: 'dynamicCatalog',
  initialState: {
    loading: '',
    error: '',
    discountedUsers: [],
    lastRequestGetDiscountedUser: '',
  },
  reducers: {
  },
  extraReducers: {
    [getDiscountedUser.pending]: (state) => {
      state.loading = true;
    },
    [getDiscountedUser.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getDiscountedUser.fulfilled]: (state, action) => {
      state.discountedUsers = action.payload;
      state.loading = false;
      state.lastRequestGetDiscountedUser = Date();
    },

  }
});
export const dynamicCatalogActions = dynamicCatalogSlice.actions;
export default dynamicCatalogSlice;