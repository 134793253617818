import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, IconButton, Toolbar, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import avatar from 'src/assets/images/technician.png';
import { getUser, handleLogoutMain } from 'src/utils/Common';
import { isProduction } from 'src/utils/contants';
import { closeAlert, getTasksRequest } from '../../store/actions';
import { useOktaAuth } from '@okta/okta-react';


const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'rgb(250, 251, 251)', boxShadow: 'none',
  },
  menuButton: {
    marginLeft: 'auto',
  },
}));

function TopBar({ onOpenNavBar, drawerWidth = 300, topBarRotation, changeTopBarRotation }) {
  const classes = useStyles();
  const [expireTime, setExpireTime] = useState(null);
  const SessionUser = getUser();
  const { oktaAuth } = useOktaAuth();

  const TechnicianImage = 'https://yetkiliservis.arcelik.com/SchedulingQuota/TechnicianImage/' + SessionUser.TechnicianId ? 0 : SessionUser.TechnicianId + '.png';

  const setMenuShowFnc = () => {
    changeTopBarRotation()
    onOpenNavBar();
  }


  useEffect(() => {
    var control = localStorage.getItem('okta-token-storage') || [];
    var timestamp = JSON.parse(control).accessToken.expiresAt

    var formatedDate = new Intl.DateTimeFormat('tr-TR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp * 1000)
    setExpireTime(formatedDate);
  }, []);


  const Logout = async () => {
    handleLogoutMain();
    await oktaAuth.signOut();
  };



  const Beyaz = {
    color: '#FFFFFF'
  };

  return (
    <AppBar
      className={classes.appBar}
      position="fixed"
      style={topBarRotation ? { marginLeft: drawerWidth, width: `calc(100% - ${drawerWidth}px)`, } : {}}
    >
      <Toolbar sx={{ backgroundColor: '#424242' }}>
        <IconButton onClick={setMenuShowFnc}>
          <MenuIcon sx={{ color: '#FF9800' }} />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" noWrap component="div" sx={{ color: '#FF9800' }}>
              Dia
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ width: '100%', color: 'text.secondary', fontSize: '15px', color: '#FF9800' }}>
              {(expireTime + '' + ' \'de oturumunuz sonlanacaktır.')}
            </Typography>
            <Grid item xs={12} md={2}>
            </Grid>
          </Grid>
        </Grid>

        <IconButton
          className={classes.menuButton}
          style={Beyaz}
          onClick={() => Logout()}
          data-tour="LogoutButton">
          <ExitToAppIcon sx={{ color: '#FF9800' }} />
        </IconButton>
        <div className={classes.profile}>
          <Avatar
            alt="Person"
            className={classes.avatar}
            src={!isProduction ? avatar : TechnicianImage}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBar: PropTypes.func
};

export default connect(
  state => ({ alertReducer: state.alertReducer }),
  {
    getTasksRequest, closeAlert
  }
)(TopBar);
