import MoodBadIcon from '@mui/icons-material/MoodBad';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { Alert, Autocomplete, Backdrop, CircularProgress, Grid, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { generalFollowActions } from 'src/store/slices/generalFollowSlice';
import axios from 'src/utils/axios';
import { getSubStatusForExOffice } from 'src/store/slices/commonSlice';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
}));

function ExchangeOfficeStatus() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail)
  const subStatusDataForExOffice = useSelector((state) => state.commonSlice.subStatusesForExOffice)
  const updatedInfoChat = useSelector((state) => state.generalFollowSlice.updatedInfoChat);
  const [subStatusForExOff, setSubStatusForExOff] = React.useState(subStatusDataForExOffice.filter(i => i.Id == invoiceData[0]?.Exchange?.SubStatus)[0]);
  const [loading, setLoading] = React.useState(false);
  const [uyari, setUyari] = React.useState(false);
  const [responseMessage, setresponseMessage] = React.useState({
    ErrorCode: '0',
    ErrorDescription: ('success_message')
  });

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  useEffect(() => {
    dispatch(getSubStatusForExOffice());
  }, [dispatch]);

  function warningAlert(ErrorCode, ErrorDescription) {
    setresponseMessage({
      ErrorCode: ErrorCode,
      ErrorDescription: ErrorDescription,
    });
    setUyari(true);
    setLoading(false);
  }
  function pageUpdate() {
    dispatch(generalFollowActions.updateActiveTab("1"));
  }

  function changeSubStatus(val) {//Alt durum 
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.SubStatus = val?.Id
    tempData[0].Exchange.IndermediateState = val?.Dsc
    dispatch(generalFollowActions.updateNewData(tempData))
    setSubStatusForExOff(val)
  }
  function setSubStatus() {
    const data = {
      ServiceNumber: invoiceData[0]?.Exchange?.Service?.toString(),//Servis Numarası
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(),//Başvuru No
      Note: updatedInfoChat?.toString(),//Mesaj alanı
      CurrentStatus: invoiceData[0]?.Exchange?.Status,//mevcut durum
      SubStatus: invoiceData[0]?.Exchange?.SubStatus,//AltDurum
      UserID: invoiceData[0]?.UserID,
    }
    confirmAlert({
      message: ('Ara durumu kayıt ediyoruz.Kaydetmek İstediğinizden Emin misiniz?'),
      buttons: [
        {
          label: ('Evet'),
          onClick: () => {
            if (!updatedInfoChat && updatedInfoChat == '') {
              warningAlert(0, 'Lütfen Mesaj Giriniz!')
              setUyari(true);
              return
            }
            if (invoiceData[0]?.Exchange?.SubStatus == '') {
              warningAlert(0, 'Lütfen Ara Durum seçiniz!')
              setUyari(true);
              return
            }
            setLoading(true);
            axios.post('SetExchangeOfficeSaveSubStatus', data, {
              headers: { 'Content-Type': 'application/json' }
            }).then(response => {
              warningAlert(1000, 'Ara durum başarı ile kaydedildi')
              setTimeout(pageUpdate, 500);
              setLoading(false);
              return;
            }).catch(err => {
              warningAlert(0, 'Hata');
              setLoading(false);
              return;
            });
          }
        },
        {
          label: ('Hayır'),
          onClick: () => { }
        }
      ],
    })
  }
  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={8}>
        <Snackbar sx={{ width: '100%' }} open={uyari} autoHideDuration={6000} onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >{responseMessage.ErrorCode === 0 ?
          <Alert icon={<MoodBadIcon />}
            style={{ minWidth: 500 }}
            sx={{
              "& .MuiAlert-icon": {
                color: "white"
              },
              height: 75,
              alignItems: "center",
              verticalAlign: "center"

            }} onClose={uyariKapat} variant="filled" severity="error" >
            {responseMessage.ErrorDescription}
          </Alert>
          :
          <Alert icon={<TagFacesIcon />}
            style={{ minWidth: 500 }}
            sx={{
              "& .MuiAlert-icon": {
                color: "white"
              },
              height: 75,
              alignItems: "center",
              verticalAlign: "center"

            }} onClose={uyariKapat} variant="filled" severity="success" >
            {responseMessage.ErrorDescription}
          </Alert>}
        </Snackbar>
      </Stack>
      <Grid item xs={12} md={12}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={subStatusDataForExOffice}
          getOptionLabel={(i) => i.Dsc}
          disabled={invoiceData[0].Exchange.Status == 24 || invoiceData[0].Exchange.Status == 27 ? true : false}
          value={subStatusForExOff}
          size="small"
          renderInput={(params) => <TextField {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: '14px' },
            }}
            label="Ara Durum" />}
          renderOption={(props, option) => (
            <li {...props}>
              <Typography variant="body2" style={{ fontSize: '14px' }}>{option.Dsc}</Typography>
            </li>
          )}
          onChange={(event, val) => {
            changeSubStatus(val);
          }}
        />
      </Grid>

    </>
  );
}

export default ExchangeOfficeStatus;