import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import HistoryIcon from '@mui/icons-material/History';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import {
  Alert,
  Backdrop,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMuadilProductList,
  getMuadilProductListStocks
} from 'src/store/slices/equivalentProductsSlice';
import {
  getProductProp,
  getWarranty
} from 'src/store/slices/generalFollowSlice';
import axios from 'src/utils/axios';

const useStyles = makeStyles((theme) => ({
  divCenter: {
    textAlign: 'left'
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  divider: {
    backgroundColor: '#FF9800'
  },
  cardscontent: {
    minHeight: 250,
    width: '100%'
  },
  myClassName: {
    backgroundColor: 'transparent',
    position: 'relative'
  },
  Badge: {
    '& .css-106c1u2-MuiBadge-badge ': {
      backgroundColor: '#424242'
    }
  },
  div: {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '14px',
    width: '100%'
  },
  div2: {
    fontSize: '12px',
    width: '100%',
    color: 'black'
  },
  icerikBilgi: {
    margin: '0px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    color: 'black',
    marginBottom: '7.5px'
  },
  tableCell: {
    fontWeight: '600',
    fontSize: '14px',
    color: 'black',
    whiteSpace: 'nowrap'
  },
  tableCellBilgi: {
    fontSize: '14px',
    color: 'black'
  }
}));

function ChangeProductInfo() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const invoiceData = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  );

  const [loading, setLoading] = React.useState(false);
  const [muadilData, setMuadilData] = React.useState([]);

  const [productData, setProductData] = React.useState([]);
  const [equivalentPopUp, setEquivalentPopUp] = React.useState(false); //muadil pop-up
  const [warrantyTypePopUp, setWarrantyTypePopUp] = React.useState(false);
  const [selectedServiceRefundPopUp, setSelectedServiceRefundPopUp] =
    React.useState(false);
  const [warrantyData, setWarrantyData] = React.useState([]);
  const [selectedRefundData, setSelectedRefundData] = React.useState([]);
  const [muadilPrice, setMuaidilPrice] = React.useState(0);
  const [manuelMuadilScreenActive, setManuelMuadilScreenActive] =
    React.useState(false);
  const [productList, setProductList] = React.useState([]);
  const [detailLoading, setDetailLoading] = React.useState(false);
  const [detailManuelLoading, setDetailManuelLoading] = React.useState(false);
  const [responseMessage, setresponseMessage] = React.useState({
    ErrorCode: '0',
    ErrorDescription: 'success_message'
  });
  const [uyari, setUyari] = React.useState(false);
  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  const columns = [
    { field: 'ACIKLAMA', headerName: 'Servis Red Nedeni', flex: 1 }
  ];

  const getMuadil = () => {
    setMuadilData([]);
    setLoading(true);
    const data = {
      ProductCode: invoiceData[0]?.Model?.UserModelCode1?.toString(),
      UnitCode: invoiceData[0]?.Exchange?.Service?.toString(),
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString()
    };
    dispatch(getProductProp(data)).then((response) => {
      setEquivalentPopUp(true);
      if (response.payload.length > 0) {
        setMuaidilPrice(response?.payload[0]?.PRICE);
        setProductData(response?.payload);
      }
      setLoading(false);
      setDetailLoading(true);
      axios
        .post('GetMuadilList', data, {
          headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => {
          setMuadilData(response.data);
          setDetailLoading(false);
          if (response.data.includes('error') == true) {
            setresponseMessage({
              ErrorCode: 1000,
              ErrorDescription: response.data
            });
            setUyari(true);
            return;
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    });
  };

  function getWarrantyType() {
    const data = [
      invoiceData[0]?.Model?.ProductId,
      invoiceData[0]?.Apply.SerialNumber,
      invoiceData[0]?.Apply.UYear,
      invoiceData[0]?.Apply.UMonth,
      invoiceData[0]?.Apply.AttributeCode
    ];

    if (!data[0] || !data[1] || !data[2] || !data[3] || !data[4]) {
      return;
    }

    setWarrantyTypePopUp(true);
    setLoading(true);
    dispatch(getWarranty(data)).then((response) => {
      if (response.payload.length > 0) {
        setWarrantyData(response.payload);
      }
      setLoading(false);
    });
  }

  function degTarihce(bk, bn) {
    var deger;
    deger = '&BASVURU_NO=' + bk;
    deger += '&BIRIM_KODU=' + bn;
    var targetPage =
      'http://arreportsql/ReportServer/Pages/ReportViewer.aspx?%2fTHRaporlar/Genel%2fUrunDegisimTarihceYeni&rs:Command=Render&rc:Toolbar=true&rc:Parameters=false' +
      deger;
    var width = 1150;
    var height = 500;
    var left = parseInt(window.screen.availWidth / 2 - width / 2);
    var top = parseInt(window.screen.availHeight / 2 - height / 2);
    var windowFeatures =
      'width=' +
      width +
      ',height=' +
      height +
      ',status,resizable,left=' +
      left +
      ',top=' +
      top +
      'screenX=' +
      left +
      ',screenY=' +
      top;
    window.open(targetPage, 'subWind', windowFeatures);
  }

  function degFisGoster(bk, bn) {
    var deger;
    deger = '&bn=' + bn;
    deger += '&bk=' + bk;
    var targetPage =
      'microsoft-edge:http://mhnet/THPortal/FISSORGU/FisGoster.aspx?' + deger;
    var width = 1150;
    var height = 500;
    var left = parseInt(window.screen.availWidth / 2 - width / 2);
    var top = parseInt(window.screen.availHeight / 2 - height / 2);
    var windowFeatures =
      'width=' +
      width +
      ',height=' +
      height +
      ',status,resizable,left=' +
      left +
      ',top=' +
      top +
      'screenX=' +
      left +
      ',screenY=' +
      top;
    const newWindow = window.open(targetPage, 'subWind', windowFeatures); // Chrome veya diğer tarayıcılar üzerinden açılan sayfanın kapanmasını sağlamak için
    if (newWindow) {
      setTimeout(() => {
        newWindow.close();
      }, 15000); // 1 saniye bekleyip kapatmaya çalışır
    }
  }

  function degYorumGoster(bk, bn) {
    var deger;
    deger = '&BASVURU_NO=' + bn;
    deger += '&BIRIM_KODU=' + bk;
    var targetPage =
      'microsoft-edge:http://arreportsql/ReportServer/Pages/ReportViewer.aspx?%2fTHRaporlar/Genel%2fFisYorum&rs:Command=Render&rc:Toolbar=true&rc:Parameters=false' +
      deger;
    var width = 1150;
    var height = 500;
    var left = parseInt(window.screen.availWidth / 2 - width / 2);
    var top = parseInt(window.screen.availHeight / 2 - height / 2);
    var windowFeatures =
      'width=' +
      width +
      ',height=' +
      height +
      ',status,resizable,left=' +
      left +
      ',top=' +
      top +
      'screenX=' +
      left +
      ',screenY=' +
      top;
    const newWindow = window.open(targetPage, 'subWind', windowFeatures); // Chrome veya diğer tarayıcılar üzerinden açılan sayfanın kapanmasını sağlamak için
    if (newWindow) {
      setTimeout(() => {
        newWindow.close();
      }, 15000); // 1 saniye bekleyip kapatmaya çalışır
    }
  }

  function degYorumYaz(bk, bn, fis, Id) {
    var deger;
    deger = 'FIS_NO=' + Id;
    deger += '&BASVURU_NO=' + bk;
    deger += '&BIRIM_KODU=' + bn;
    deger += '&GELENLINK=UrunDegisimMuhendis';

    var targetPage =
      'microsoft-edge:http://mhnet/THPortal/YORUM/aspx/Main.aspx?' + deger;

    var width = 1150;
    var height = 500;
    var left = parseInt(window.screen.availWidth / 2 - width / 2);
    var top = parseInt(window.screen.availHeight / 2 - height / 2);
    var windowFeatures =
      'width=' +
      width +
      ',height=' +
      height +
      ',status,resizable,left=' +
      left +
      ',top=' +
      top +
      'screenX=' +
      left +
      ',screenY=' +
      top;
    const newWindow = window.open(targetPage, 'subWind', windowFeatures); // Chrome veya diğer tarayıcılar üzerinden açılan sayfanın kapanmasını sağlamak için
    if (newWindow) {
      setTimeout(() => {
        newWindow.close();
      }, 15000); // 1 saniye bekleyip kapatmaya çalışır
    }
  }

  const handleOpenManualMdmScreen = (userModel, ServiceNumber, isActive) => {
    //Aynı muadil sayfası açık olması durumunda buton ile
    //tekrar tetiklenme durumunun engellenmesi için bu koşul eklenmiştir.
    if (manuelMuadilScreenActive) {
      return;
    }
    //Product Listesi Temizleme İşlemi
    setProductList([]);
    //Manuel Muadil Butonuna basıldığında Manuel Muadil Listesinin Gözükmesini Sağlamaktadır.
    if (isActive === 1) {
      setManuelMuadilScreenActive(true);
    }

    const data = [userModel, ServiceNumber];

    setDetailManuelLoading(true);
    dispatch(getMuadilProductListStocks(data))
      .then((response) => {
        if (response.payload.length > 0) {
          setProductList(response?.payload);
        } else {
          setresponseMessage({
            ErrorCode: 1000,
            ErrorDescription: `Manuel Muadil Hata: ${invoiceData[0]?.Model?.UserModelCode} İçin Uygun Muadil Ürün Bulunamadı! MDM Muadil Ürün aramaya devam ediyor! Lütfen Bekleyiniz...`
          });
          setUyari(true);
        }
        setDetailManuelLoading(false);
      })
      .catch((er) => {
        setDetailManuelLoading(false);
      });
  };

  const handleOpenAutomaticMdmScreen = () => {
    //Aynı muadil sayfası açık olması durumunda buton ile
    //tekrar tetiklenme durumunun engellenmesi için bu koşul eklenmiştir.
    if (!manuelMuadilScreenActive) {
      return;
    }
    setManuelMuadilScreenActive(false);
    getMuadil(); // MDM Muadil Bilgilerini getiriyor.
    handleOpenManualMdmScreen(
      invoiceData[0]?.Model?.ProductId,
      invoiceData[0]?.Exchange?.Service,
      0
    ); // Manuel Muadil Bilgilerini getiriyor.
  };

  return (
    <Card
      className={classes.cardscontent}
      sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}
    >
      <Stack spacing={8}>
        <Snackbar
          sx={{ width: '100%' }}
          open={uyari}
          autoHideDuration={6000}
          onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          {responseMessage.ErrorCode === 1000 ? (
            <Alert
              icon={<MoodBadIcon />}
              style={{ minWidth: 500 }}
              sx={{
                '& .MuiAlert-icon': {
                  color: 'white'
                },
                height: 75,
                alignItems: 'center',
                verticalAlign: 'center'
              }}
              onClose={uyariKapat}
              variant="filled"
              severity="error"
            >
              {responseMessage.ErrorDescription}
            </Alert>
          ) : (
            <Alert
              icon={<TagFacesIcon />}
              style={{ minWidth: 500 }}
              sx={{
                '& .MuiAlert-icon': {
                  color: 'white'
                },
                height: 75,
                alignItems: 'center',
                verticalAlign: 'center'
              }}
              onClose={uyariKapat}
              variant="filled"
              severity="success"
            >
              {responseMessage.ErrorDescription}
            </Alert>
          )}
        </Snackbar>
      </Stack>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.div} variant="subtitle1">
              Değişen Ürün Bilgileri
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: 5 }}>
          <Grid item xs={6} md={6}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.icerikBilgi}
            >
              Fiş No:&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                className={classes.div2}
              >
                <Link
                  sx={{ color: '#424242' }}
                  onClick={() =>
                    degFisGoster(
                      invoiceData[0]?.Exchange?.Service,
                      invoiceData[0]?.Exchange?.ApplyNo
                    )
                  }
                >
                  {invoiceData[0]?.Apply?.ReceiptNumber
                    ? invoiceData[0]?.Apply?.ReceiptNumber
                    : '-'}
                </Link>
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              sx={{
                fontWeight: 'bold',
                width: '100%',
                color: '#FF9800',
                fontSize: '12px'
              }}
            >
              {invoiceData[0]?.Apply?.AttributeCode === 1 ? 'Revizyonlu' : ''}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={10} md={10}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.icerikBilgi}
            >
              Ürün:&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                className={classes.div2}
              >
                {invoiceData[0]?.Model?.UserModelCode1}-
                {invoiceData[0]?.Model?.ProductDesc}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              sx={{
                fontWeight: 'bold',
                width: '100%',
                color: '#FF9800',
                fontSize: '12px'
              }}
            >
              {invoiceData[0]?.ISOSAS}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3} md={3}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.icerikBilgi}
            >
              Yıl/Ay/Seri:&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                className={classes.div2}
              >
                {invoiceData[0]?.Apply?.UYear +
                  '/' +
                  invoiceData[0]?.Apply?.UMonth +
                  '/' +
                  invoiceData[0]?.Apply?.SerialNumber}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.icerikBilgi}
            >
              Garanti:&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                className={classes.div2}
              >
                {invoiceData[0]?.Apply?.GuaranteeDate
                  ? moment(invoiceData[0]?.Apply?.GuaranteeDate).format(
                      'DD/MM/YYYY'
                    )
                  : '-'}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacin0g={2}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.icerikBilgi}
            >
              Barkod:&nbsp;&nbsp;&nbsp;&nbsp;
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                className={classes.div2}
              >
                {invoiceData[0]?.Apply?.Barcode}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.icerikBilgi}
            >
              {'Yorum:'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.icerikBilgi}
            >
              {'Yorum Yaz:'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.icerikBilgi}
            >
              {'Değişim Tarihçesi:'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.icerikBilgi}
            >
              {'Muadil:'}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.icerikBilgi}
            >
              {'Garanti Bilgileri:'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3} className={classes.divCenter}>
            <Box sx={{ m: 1 }}>
              <Badge
                className={classes.Badge}
                badgeContent={invoiceData[0]?.Apply?.CommentCount}
                color="primary"
                onClick={() =>
                  degYorumGoster(
                    invoiceData[0]?.Exchange?.Service,
                    invoiceData[0]?.Exchange?.ApplyNo
                  )
                }
              >
                <ChatBubbleIcon sx={{ color: '#FF9800' }} />
              </Badge>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.divCenter}>
            <Box sx={{ m: 1 }}>
              <IconButton
                sx={{ color: '#FF9800' }}
                onClick={() =>
                  degYorumYaz(
                    invoiceData[0]?.Exchange?.ApplyNo,
                    invoiceData[0]?.Exchange?.Service,
                    invoiceData[0]?.Exchange?.ReceiptNumber
                  )
                }
              >
                <ReplyAllIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.divCenter}>
            <Box sx={{ m: 1 }}>
              <IconButton
                sx={{ color: '#FF9800' }}
                onClick={() =>
                  degTarihce(
                    invoiceData[0]?.Exchange?.ApplyNo,
                    invoiceData[0]?.Exchange?.Service
                  )
                }
              >
                <HistoryIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.divCenter}>
            <Box sx={{ m: 1 }}>
              <IconButton
                sx={{ color: '#FF9800' }}
                onClick={() => {
                  // if (!muadilData?.length)
                  setManuelMuadilScreenActive(false);
                  getMuadil(); // MDM Muadil Bilgilerini getiriyor.
                  handleOpenManualMdmScreen(
                    invoiceData[0]?.Model?.ProductId,
                    invoiceData[0]?.Exchange?.Service,
                    0
                  ); // Manuel Muadil Bilgilerini getiriyor.
                }}
              >
                <DisplaySettingsIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={3} className={classes.divCenter}>
            <Box sx={{ m: 1 }}>
              <IconButton
                sx={{ color: '#FF9800' }}
                onClick={() => {
                  getWarrantyType();
                }}
              >
                <DateRangeIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      {/* // sx={{ Muadil Pop-up }} */}

      <Dialog
        open={equivalentPopUp}
        // onClose={() => setEquivalentPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle sx={{ width: '100%' }}>
          <Stack direction={'row'} spacing={1}>
            <Button
              color="primary"
              variant="contained"
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={() => {
                handleOpenAutomaticMdmScreen();
              }}
            >
              Mdm Muadil
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={() => {
                handleOpenManualMdmScreen(
                  invoiceData[0]?.Model?.ProductId,
                  invoiceData[0]?.Exchange?.Service,
                  1
                );
              }}
            >
              Manuel Muadil
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ paddingTop: 20 }}>
            <Grid item xs={4} md={4}>
              <TextField
                id="outlined-start-adornment"
                fullWidth
                size="small"
                value={invoiceData[0]?.Model?.UserModelCode}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Stok Kodu</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextField
                id="outlined-start-adornment"
                fullWidth
                size="small"
                value={invoiceData[0]?.Model?.ProductDesc}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Açıklama</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextField
                id="outlined-start-adornment"
                fullWidth
                size="small"
                disabled
                value={muadilPrice}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Fiyat</InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: 40 }}>
            <Typography
              sx={{ width: '100%', fontWeight: 'bold' }}
              variant="subtitle1"
            >
              Ürün
            </Typography>
            <TableContainer
              className={classes.container}
              sx={{ maxHeight: 440 }}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {productData?.map((row, index) => (
                      <TableCell
                        style={{ width: '1px', whiteSpace: 'nowrap' }}
                        key={index}
                      >
                        {row.ATTRIBUTE_DESCRIPTION}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {productData?.map((row, index) => (
                      <TableCell
                        key={index}
                        style={{ width: '1px', whiteSpace: 'nowrap' }}
                      >
                        {row.ATTRIBUTE_VALUE}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Divider className={classes.divider} />
            {!manuelMuadilScreenActive ? (
              <>
                <Typography
                  sx={{ width: '100%', fontWeight: 'bold' }}
                  variant="subtitle1"
                >
                  Manuel Muadiller
                </Typography>
                <TableContainer
                  className={classes.container}
                  sx={{ maxHeight: 450 }}
                >
                  <Table
                    stickyHeader
                    fullWidth
                    aria-label="sticky table"
                    size="medium"
                    sx={{ minHeight: 100 }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Ürün Kodu
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Ürün Adı
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Öncelik Sırası
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Muadil Tipi
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Geçerlilik Süresi
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Muadil Açıklama
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Depo Adı
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Stok Adet
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detailManuelLoading == true ? (
                        <TableCell colSpan={9}>
                          <Stack
                            spacing={9}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CircularProgress size={50} color="success" />
                          </Stack>
                        </TableCell>
                      ) : (
                        productList.length > 0 &&
                        productList.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.SKU}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.ACIKLAMA}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.ONCELIK_SIRASI}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.MUADIL_TIPI}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {moment(row.GECERLILIK_SURESI).format(
                                'DD/MM/YYYY'
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.MUADIL_ACIKLAMA}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.StockName ? row.StockName : ''}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.StockTotal ? row.StockTotal : ''}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  sx={{ width: '100%', fontWeight: 'bold' }}
                  variant="subtitle1"
                >
                  MDM Muadiller
                </Typography>
                <TableContainer
                  className={classes.container}
                  sx={{ maxHeight: 440 }}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    size="large"
                    sx={{ minHeight: 100 }}
                  >
                    <TableHead>
                      <TableRow>
                        {muadilData?.columns?.map((col, index) => (
                          <TableCell
                            style={{ width: '1px', whiteSpace: 'nowrap' }}
                            key={index}
                          >
                            {col}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detailLoading == true ? (
                        <Stack spacing={8} alignItems="center">
                          <CircularProgress size={50} color="success" />{' '}
                        </Stack>
                      ) : (
                        muadilData?.data?.map((row, index) => (
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 }
                            }}
                            key={index}
                          >
                            {row?.map((col, index) => (
                              <TableCell
                                key={index}
                                style={{ width: '1px', whiteSpace: 'nowrap' }}
                              >
                                {col}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <>
                <Typography
                  sx={{ width: '100%', fontWeight: 'bold' }}
                  variant="subtitle1"
                >
                  Manuel Muadiller
                </Typography>
                <TableContainer
                  className={classes.container}
                  sx={{ maxHeight: 450 }}
                >
                  <Table
                    stickyHeader
                    fullWidth
                    aria-label="sticky table"
                    size="medium"
                    sx={{ minHeight: 100 }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Ürün Kodu
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Ürün Adı
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Öncelik Sırası
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Muadil Tipi
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Geçerlilik Süresi
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Muadil Açıklama
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Depo Adı
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: '1px', whiteSpace: 'nowrap' }}
                        >
                          Stok Adet
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detailManuelLoading == true ? (
                        <TableCell colSpan={9}>
                          <Stack
                            spacing={9}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CircularProgress size={50} color="success" />
                          </Stack>
                        </TableCell>
                      ) : (
                        productList.length > 0 &&
                        productList.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.SKU}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.ACIKLAMA}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.ONCELIK_SIRASI}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.MUADIL_TIPI}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {moment(row.GECERLILIK_SURESI).format(
                                'DD/MM/YYYY'
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.MUADIL_ACIKLAMA}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.StockName ? row.StockName : ''}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: '1px', whiteSpace: 'nowrap' }}
                            >
                              {row.StockTotal ? row.StockTotal : ''}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <IconButton
              sx={{ color: '#424242' }}
              onClick={() => setEquivalentPopUp(false)}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogActions>
      </Dialog>

      {/*Garanti Bilgileri Detay popup başlangıcı */}

      <Dialog
        open={warrantyTypePopUp}
        onClose={() => setWarrantyTypePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          sx={{
            width: '100%',
            color: 'red',
            fontSize: '14px',
            fontWeight: 'bold'
          }}
        >
          {'Garanti Bilgileri'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="center">
                        Garanti Tip
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Kalan Süre(Gün)
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Mevcut Garanti Bilgisi
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Garanti Başlangıç Tarihi
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Garanti Bitiş Tarihi
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Süre
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {warrantyData?.map((row) => (
                      <TableRow key={row.TIP}>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.TIP}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.KALAN_SURE}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.GARANTI_VAR}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.BASLANGIC_TARIHI}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.BITIS_TARIHI}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.SURE}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            sx={{ color: '#424242' }}
            onClick={() => setWarrantyTypePopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>

      {/*Servis Red Nedeni Var ise bu pop up açılır*/}

      <Dialog
        open={selectedServiceRefundPopUp}
        onClose={() => setSelectedServiceRefundPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={selectedRefundData}
                  columns={columns}
                  getRowId={(row) => row.DEGISIM_RED_NEDENI}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <IconButton
              color="primary"
              onClick={() => setSelectedServiceRefundPopUp(false)}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default ChangeProductInfo;
