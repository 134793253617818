import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from "react";
import { confirmAlert } from 'react-confirm-alert';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { exchangeOfferPrice, generalFollowActions, getBrandCodeOfProduct } from 'src/store/slices/generalFollowSlice';
import axios from 'src/utils/axios';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import AlertAndLoading from 'src/components/AlertAndLoading';

const useStyles = makeStyles((theme) => ({
  div: {
    fontWeight: 'bold',
    fontSize: '14px',
    width: '100%',
    color: 'red'
  }
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value || 0,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowNegative={false}
      isNumericString
      suffix="₺"
    />
  );
});

function DifferentModelSuggest() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);

  const [expanded, setExpanded] = React.useState(true);
  const [equalProductStokNumber, setEqualProductStokNumber] = React.useState(invoiceData[0]?.Exchange?.EqualProductStokNo ? invoiceData[0]?.Exchange?.EqualProductStokNo : invoiceData[0]?.Model?.UserModelCode);
  const [equalProductDesc, setEqualProductDesc] = React.useState(invoiceData[0]?.Exchange?.EqualProductDesc ? invoiceData[0]?.Exchange?.EqualProductDesc : '');
  const [equalProductPrice, setEqualProductPrice] = React.useState(invoiceData[0]?.Exchange?.EqualProductPrice ? invoiceData[0]?.Exchange?.EqualProductPrice : 0);
  const [giveStokNumber, setGiveStokNumber] = React.useState(invoiceData[0]?.Exchange?.GiveProductStokNumber ? invoiceData[0]?.Exchange?.GiveProductStokNumber : invoiceData[0]?.Model?.UserModelCode);
  const [giveProductDesc, setGiveProductDesc] = React.useState(invoiceData[0]?.Exchange?.GiveProductDesc ? invoiceData[0]?.Exchange?.GiveProductDesc : '');
  const [giveProductPrice, setGiveProductPrice] = React.useState(invoiceData[0]?.Exchange?.GiveProductPrice ? invoiceData[0]?.Exchange?.GiveProductPrice : 0);
  const [requestedModelCode, setRequestedModelCode] = React.useState(invoiceData[0]?.RequestedModelCode ? invoiceData[0]?.RequestedModelCode : '');
  const [requestedModelDsc, setRequestedModelDsc] = React.useState(invoiceData[0]?.RequestedModelDsc ? invoiceData[0]?.RequestedModelDsc : '');
  const [requestedModelPrice, setRequestedModelPrice] = React.useState(invoiceData[0]?.Exchange?.RequestedModelPrice ? invoiceData[0]?.Exchange?.RequestedModelPrice : 0);
  const [stockTotal, setStockTotal] = React.useState(0);
  const [requestedStockTotal, setRequestStockTotal] = React.useState(0);
  const [requestedStockRegionName, setRequestedStockRegionName] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [stockRegionName, setStockRegionName] = React.useState('');
  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });
  const [giveProductNumberBrand, setGiveProductNumberBrand] = React.useState([]);


  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  function clearRequestModel() {
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].RequestedModelCode = null;
    tempData[0].RequestedModelDsc = null;
    tempData[0].Exchange.RequestedModelPrice = null;
    dispatch(generalFollowActions.updateNewData(tempData));
    setRequestedModelCode('')
    setRequestedModelDsc('')
    setRequestedModelPrice('')
    setRequestStockTotal(0)
    setRequestedStockRegionName('')
  }

  function changeGiveStokNumber(val) {//verilen ürün stok numarası
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.GiveProductStokNumber = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setGiveStokNumber(val)
  }
  function changeGiveStokProductDesc(val) {//verilen ürün açıklama
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.GiveProductDesc = val;
    dispatch(generalFollowActions.updateNewData(tempData))
    setGiveProductDesc(val)
  }
  function changeGiveProductPrice(val) {//verilen ürün ücret
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.GiveProductPrice = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setGiveProductPrice(val)
  }

  function changeGiveStokTotal(val) {//verilen ürün stok adet
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.stokTotal = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setStockTotal(val)
  }

  function changeRequestedModelPrice(val) {
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.RequestedModelPrice = val
    dispatch(generalFollowActions.updateNewData(tempData))

    if (!invoiceData[0]?.Exchange?.EqualProductPrice) {
      var result = Math.abs(val) - Math.abs(giveProductPrice)
      if (result <= 0) {
        dispatch(generalFollowActions.updateTopModelDifferentPrice(0))
      }
      else {
        dispatch(generalFollowActions.updateTopModelDifferentPrice(result))
      }
    }
    else {
      var result1 = Math.abs(val) - Math.abs(invoiceData[0]?.Exchange?.EqualProductPrice)
      if (result1 <= 0) {
        dispatch(generalFollowActions.updateTopModelDifferentPrice(0))
      } else {
        dispatch(generalFollowActions.updateTopModelDifferentPrice(result1))
      }
    }
    setRequestedModelPrice(val)
  }

  function changeRequestedModelCode(val) {
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].RequestedModelCode = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setRequestedModelCode(val)
  }

  function changeEqualProductStokNumber(val) {
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.EqualProductStokNo = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setEqualProductStokNumber(val)
  }

  function changeEqualProductPrice(val) {
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.EqualProductPrice = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setEqualProductPrice(val)
  }

  function GetProductPrice() {
    if (giveStokNumber.length <= 0) {
      return;
    }
    const data = [
      giveStokNumber?.trim()?.toString() || null,
      null,
      requestedModelCode?.trim()?.toString() || null,
      equalProductStokNumber?.trim()?.toString() || null,
      invoiceData[0]?.Exchange?.Service
    ]
    setLoading(true)

    axios.get(`/GetProductPrice/${data[0]}/${data[1]}/${data[2]}/${data[3]}/${data[4]}`, {
    }).then(response => {
      var tempData = JSON.parse(JSON.stringify(invoiceData))
      var OldResultForProductRemainPriceForNoAnswer = tempData[0].Exchange.Price;
      tempData[0].RequestedModelDsc = response.data.RequestedModelStokDsc
      tempData[0].RequestedModelCode = response.data.RequestedModelStokNumber
      tempData[0].Exchange.RequestedModelPrice = response.data.RequestedModelPrice
      tempData[0].Exchange.EqualProductStokNo = response.data.EqualModelStockNumber
      tempData[0].Exchange.EqualProductPrice = response.data.EqualModelPrice

      setRequestedModelCode(response.data.RequestedModelStokNumber)
      setRequestedModelDsc(response.data.RequestedModelStokDsc)
      setRequestedModelPrice(response.data.RequestedModelPrice)

      setEqualProductStokNumber(response.data.EqualModelStockNumber)
      setEqualProductPrice(response.data.EqualModelPrice)
      setEqualProductDesc(response.data.EqualModelStokDsc)

      setStockTotal(response.data.StoreStockTotal)
      setStockRegionName(response.data.StoreStockName)
      setRequestedStockRegionName(response.data.RequestedStockName)
      setRequestStockTotal(response.data.RequestedStockTotal)

      var requestModelPriceRslt = response.data.RequestedModelPrice;
      var equalModelPriceRslt = response.data.EqualModelPrice;

      var equalModelStokNumberRslt = response.data.EqualModelStockNumber;
      var requestedModelStokNumberRslt = response.data.RequestedModelStokNumber;


      if (requestedModelStokNumberRslt === null) {
        tempData[0].Exchange.GiveProductStokNumber = response.data.EqualModelStockNumber
        tempData[0].Exchange.GiveProductDesc = response.data.EqualModelStokDsc
        tempData[0].Exchange.GiveProductPrice = response.data.EqualModelPrice
        setGiveStokNumber(response.data.EqualModelStockNumber)
        setGiveProductDesc(response.data.EqualModelStokDsc)
        setGiveProductPrice(response.data.EqualModelPrice)
        var ModelStokNumberResult = equalModelStokNumberRslt.toString();
        var ModelPriceRslt = equalModelPriceRslt
      }

      if (requestedModelStokNumberRslt !== null) {
        tempData[0].Exchange.GiveProductStokNumber = response.data.RequestedModelStokNumber
        tempData[0].Exchange.GiveProductDesc = response.data.RequestedModelStokDsc
        tempData[0].Exchange.GiveProductPrice = response.data.RequestedModelPrice
        setGiveStokNumber(response.data.RequestedModelStokNumber)
        setGiveProductDesc(response.data.RequestedModelStokDsc)
        setGiveProductPrice(response.data.RequestedModelPrice)

        var result = requestModelPriceRslt - equalModelPriceRslt
        var OldResultForProductRemainPrice;
        if (result <= 0) {
          OldResultForProductRemainPrice = tempData[0].Exchange.ProductRemainPrice;
          tempData[0].Exchange.ProductRemainPrice = 0
          InfoAlert('Talep edilen  model fiyat olarak olarak daha uygundur. Ara fark hesaplanmadı!!.', 'info', 'Bilgilendirme');
          dispatch(generalFollowActions.updateTopModelDifferentPrice(0))

        }
        else {
          OldResultForProductRemainPrice = tempData[0].Exchange.ProductRemainPrice;
          tempData[0].Exchange.ProductRemainPrice = result
          dispatch(generalFollowActions.updateTopModelDifferentPrice(result))
        }
        if (equalModelPriceRslt > requestModelPriceRslt) {
          var ModelStokNumberResult = requestedModelStokNumberRslt.toString();
          var ModelPriceRslt = requestModelPriceRslt
        }
        else {
          var ModelStokNumberResult = equalModelStokNumberRslt.toString();
          var ModelPriceRslt = equalModelPriceRslt
        }
      }

      // Sorgula butonuna basıldığında Değişim Çeki alanında bulunan Ürün Kodu ve Açıklamasının anlık olarak güncellenmesi istenmektedir.
      tempData[0].Exchange.WebPointStockNumber = tempData[0].Exchange.GiveProductStokNumber;

      //MHTU-3836 Ürün Açıklama Güncellenmesi içindir.
      var giveProductDescription = tempData[0].Exchange.GiveProductDesc;
      var giveProductStokNum = tempData[0].Exchange.GiveProductStokNumber;
      var giveProductStockPrice = tempData[0].Exchange.GiveProductPrice;

      if (result === undefined || result === null) {
        OldResultForProductRemainPrice = tempData[0].Exchange.ProductRemainPrice;
      }

      if (response.data.isProductControl == 1) {
        InfoAlert('Ürün Temini Sağlanamıyor...', 'info', 'Bilgilendirme');
      }

      dispatch(generalFollowActions.updateNewData(tempData));

      //MHTU-3836 BAŞLANGIÇ
      //Arçelik-Beko-Altus-Grundig-Leisure
      const brands = [3, 4, 18, 28, 43];
      if (giveProductStokNum && invoiceData[0]?.Exchange?.IsManagerAppliedDifferenBrand === 0 && brands.includes(invoiceData[0]?.Apply.BrandCode)) {
        dispatch(getBrandCodeOfProduct(giveProductStokNum.toString())).then(response => {
          if (response.payload?.length > 0) {
            var BrandCode = response.payload[0]?.MARKA_KODU;
            if ((BrandCode !== invoiceData[0]?.Apply.BrandCode)) {
              confirmAlert({
                message: ('Farklı Marka Ürün Seçtiniz! Yönetici Onayı Alarak Devam Edebilirsiniz. Devam Etmek istiyor musunuz?'),
                buttons: [
                  {
                    label: ('Devam Et'),
                    onClick: () => {
                      var tempData = JSON.parse(JSON.stringify(invoiceData));
                      tempData[0].Exchange.DifferentBrandManagerControl = 1;
                      tempData[0].Exchange.GiveProductStokNumber = giveProductStokNum;
                      tempData[0].Exchange.GiveProductDesc = giveProductDescription;
                      dispatch(generalFollowActions.updateNewData(tempData))
                    }
                  },
                  {
                    label: ('Vazgeç'),
                    onClick: () => {
                      var tempData = JSON.parse(JSON.stringify(invoiceData));
                      tempData[0].Exchange.DifferentBrandManagerControl = 0;
                      tempData[0].Exchange.GiveProductStokNumber = null;
                      tempData[0].Exchange.GiveProductDesc = null;
                      tempData[0].Exchange.GiveProductPrice = null;
                      setGiveStokNumber([])
                      setGiveProductDesc([])
                      setGiveProductPrice(0)
                      dispatch(generalFollowActions.updateNewData(tempData))
                    }
                  }
                ],
                closeOnClickOutside: false,
                closeOnEscape: false
              })
            }
            else {
              var tempData = JSON.parse(JSON.stringify(invoiceData));
              tempData[0].Exchange.DifferentBrandManagerControl = 0;
              tempData[0].Exchange.GiveProductDesc = giveProductDescription;
              tempData[0].Exchange.GiveProductStokNumber = giveProductStokNum;
              tempData[0].Exchange.GiveProductPrice = giveProductStockPrice
              dispatch(generalFollowActions.updateNewData(tempData))
            }
          }
        })
      }
      //MHTU-3836 BİTİŞ
      setLoading(false);
      if (invoiceData[0]?.Consumer?.UpTypeId === 3 && response.data.GiveProductStokPrice > 0) {
        if (invoiceData[0]?.Exchange.ExchangeReson == 302 || invoiceData[0]?.Apply?.GuaranteeType == 2 || invoiceData[0]?.Apply?.GuaranteeType == 9)// yedek parça lojıstıgınde  ve garanti içi, ek garanti ise tavsiye edilen tutar hesaplanmaz!
        {
          return;
        }
        confirmAlert({
          message: ('Ücretli Değişim Tutarı değişecek. Devam etmek istiyor musunuz?'),
          buttons: [
            {
              label: ('Evet'),
              onClick: () => {
                if (!invoiceData[0]?.Apply?.GuaranteeDate) {
                  InfoAlert('Garanti bilgisini kontrol ediniz.', 'error', 'Uyarı');
                  return;
                }
                setLoading(true)
                const data = {
                  ServiceNumber: invoiceData[0]?.Exchange?.Service?.toString(),//Servis Numarası
                  ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(),//Başvuru No
                  UpTypeId: invoiceData[0]?.Consumer?.UpTypeId?.toString(),//Üst onay türü
                  GuaranteeDate: moment(invoiceData[0]?.Apply?.GuaranteeDate).format('DD-MM-YYYY'),
                  GiveProductCode: ModelStokNumberResult,
                  GiveProductPrice: ModelPriceRslt?.toString()
                }
                dispatch(exchangeOfferPrice(data)).then(response => {
                  if (response.payload == 0) {
                    dispatch(generalFollowActions.updateRecomendedPrice(0))
                    dispatch(generalFollowActions.updateOfferPrice(0))
                    InfoAlert('Bu ürün için tavsiye edilen ürün fiyatı hesaplanmaz.', 'error', 'Uyarı');
                    return;
                  }
                  if (response.payload == -1) {
                    dispatch(generalFollowActions.updateRecomendedPrice(0))
                    dispatch(generalFollowActions.updateOfferPrice(0))
                    InfoAlert('Ürün için indirim tanımlı değil!', 'error', 'Uyarı');
                    return;
                  }
                  if (response.payload == -2) {
                    dispatch(generalFollowActions.updateRecomendedPrice(0))
                    dispatch(generalFollowActions.updateOfferPrice(0))
                    InfoAlert('Kullanım ömrü bittiğinden değişim yapılmamalı, yeni ürün satışına yönlendirilmelidir.!', 'error', 'Uyarı');
                    return;
                  }
                  else {
                    dispatch(generalFollowActions.updateRecomendedPrice(response.payload))
                  }
                }).catch(err => {
                  setLoading(false);
                  return;
                });
                setLoading(false);
              }
            },
            {
              label: ('Hayır'),
              onClick: () => {
                
                setLoading(true);
                const data = {
                  ServiceNumber: invoiceData[0]?.Exchange?.Service?.toString(),//Servis Numarası
                  ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(),//Başvuru No
                  UpTypeId: invoiceData[0]?.Consumer?.UpTypeId?.toString(),//Üst onay türü
                  GuaranteeDate: moment(invoiceData[0]?.Apply?.GuaranteeDate).format('DD-MM-YYYY'),
                  GiveProductCode: ModelStokNumberResult,
                  GiveProductPrice: ModelPriceRslt?.toString()
                }

                dispatch(exchangeOfferPrice(data)).then(response => {
                  if (response.payload == 0) {
                    dispatch(generalFollowActions.updateRecomendedPrice(0))
                    dispatch(generalFollowActions.updateOfferPrice(OldResultForProductRemainPriceForNoAnswer))
                    InfoAlert('Bu ürün için tavsiye edilen ürün fiyatı hesaplanmaz.', 'error', 'Uyarı');
                    return;
                  }
                  if (response.payload == -1) {
                    dispatch(generalFollowActions.updateRecomendedPrice(0))
                    dispatch(generalFollowActions.updateOfferPrice(OldResultForProductRemainPriceForNoAnswer))
                    InfoAlert('Ürün için indirim tanımlı değil!', 'error', 'Uyarı');
                    return;
                  }
                  if (response.payload == -2) {
                    dispatch(generalFollowActions.updateRecomendedPrice(0))
                    dispatch(generalFollowActions.updateOfferPrice(OldResultForProductRemainPriceForNoAnswer))
                    InfoAlert('Kullanım ömrü bittiğinden değişim yapılmamalı, yeni ürün satışına yönlendirilmelidir.!', 'error', 'Uyarı');
                    return;
                  }
                  else {
                    dispatch(generalFollowActions.updateRecomendedPrice(response.payload))
                    dispatch(generalFollowActions.updateOfferPrice(OldResultForProductRemainPriceForNoAnswer))
                  }
                }).catch(err => {
                  setLoading(false);
                  return;
                });
                dispatch(generalFollowActions.updateTopModelDifferentPrice(OldResultForProductRemainPrice));
                var tempData = JSON.parse(JSON.stringify(invoiceData));
                tempData[0].Exchange.ProductRemainPrice = OldResultForProductRemainPrice;
                dispatch(generalFollowActions.updateNewData(tempData));
                setLoading(false);
              }
            }
          ],
        })

      }

    }).catch(err => {
      setLoading(false);
    });
  }

  return (
    <>
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.div} variant="subtitle1">
            Verilen Ürün Bilgileri
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="Muadil Model">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Muadil Model"
                    value={equalProductStokNumber ? equalProductStokNumber : ''}
                    onChange={(event) => { changeEqualProductStokNumber(event.target.value) }}
                    size="small"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton size="small" onClick={() => { setEqualProductStokNumber('') }}>
                            <CloseIcon sx={{ color: '#424242', fontSize: 15 }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="Muadil Model Açıklama">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Muadil Model Açıklama"
                    value={equalProductDesc}
                    onChange={(event) => { setEqualProductDesc(event.target.value) }}
                    size="small"
                    fullWidth
                    disabled
                    variant="outlined"
                    autoComplete="off"
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <Tooltip title="Muadil Model Fiyat">
                <TextField
                  label="Muadil Model Fiyat"
                  value={equalProductPrice}
                  onChange={(event) => { changeEqualProductPrice(event.target.value) }}
                  name="numberformat"
                  id="formatted-numberformat-input"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  variant="outlined"
                  autoComplete="off"
                />
              </Tooltip>
            </Box>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="Talep Edilen Model">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Talep Edilen Model"
                    value={requestedModelCode}
                    onChange={(event) => { changeRequestedModelCode(event.target.value) }}
                    size="small"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton size="small" onClick={() => { clearRequestModel() }}>
                            <CloseIcon sx={{ color: '#424242', fontSize: 15 }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="Verilecek Model">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Verilecek Model"
                    size="small"
                    value={giveStokNumber ? giveStokNumber : ''}
                    fullWidth
                    variant="outlined"
                    onChange={(event) => { changeGiveStokNumber(event.target.value) }}
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton size="small" onClick={() => { setGiveStokNumber('') }}>
                            <CloseIcon sx={{ color: '#424242', fontSize: 15 }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title={requestedModelDsc}>
                  <TextField
                    id="input-with-icon-textfield"
                    label="Açıklama"
                    size="small"
                    value={requestedModelDsc}
                    onChange={(event) => { setRequestedModelDsc(event.target.value) }}
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title={giveProductDesc ? giveProductDesc : ''}>
                  <TextField
                    id="input-with-icon-textfield"
                    label="Açıklama"
                    size="small"
                    value={giveProductDesc ? giveProductDesc : ''}
                    onChange={(event) => { changeGiveStokProductDesc(event.target.value) }}
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <TextField
                  label="Perakende Fiyat"
                  name="numberformat"
                  id="formatted-numberformat-input"
                  size="small"
                  fullWidth
                  value={requestedModelPrice}
                  onChange={(event) => { changeRequestedModelPrice(event.target.value) }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  variant="outlined"
                  autoComplete="off"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <TextField
                  label="Perakende Fiyat"
                  value={giveProductPrice}
                  onChange={(event) => { changeGiveProductPrice(event.target.value) }}
                  name="numberformat"
                  id="formatted-numberformat-input"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  variant="outlined"
                  autoComplete="off"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="Verilen Ürün Depo Adı">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Depo Adı"
                    size="small"
                    disabled
                    fullWidth
                    value={requestedStockRegionName ? requestedStockRegionName : ''}
                    onChange={(event) => { setRequestedStockRegionName(event.target.value) }}
                    variant="outlined"
                    autoComplete="off"
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="Verilen Ürün Depo Adı">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Depo Adı"
                    size="small"
                    value={stockRegionName ? stockRegionName : ''}
                    disabled
                    fullWidth
                    variant="outlined"
                    onChange={(event) => { setStockRegionName(event.target.value) }}
                    autoComplete="off"
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="Talep Edilen Model Stok Adet">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Talep Edilen Model Stok A. "
                    size="small"
                    disabled
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    value={requestedStockTotal}

                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="Verilecek Ürün Stok Adet">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Verilecek Ürün Stok Adet"
                    size="small"
                    disabled
                    fullWidth
                    variant="outlined"
                    value={stockTotal}
                    onChange={(event) => { changeGiveStokTotal(event.target.value) }}
                    autoComplete="off"
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="Sorgula">
                  <IconButton
                    className={classes.action}
                    size="large"
                    key="close"
                    onClick={() => { GetProductPrice() }}
                  >
                    <SavedSearchIcon sx={{ color: '#FF9800' }} size="large" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default DifferentModelSuggest;

