
import axios from 'src/utils/axios';


// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) {
    return JSON.parse(userStr);
  } else {
    setUserSessionFromLocale();
    return localStorage.getItem('user');
  }
}

export const getUserRole = () => {
  const userRoleStr = sessionStorage.getItem('Role');
  if (userRoleStr) {
    return JSON.parse(userRoleStr);
  }
  return localStorage.getItem('Role');
}

export const GetLocalMaterialSearch = () => {
  return JSON.parse(localStorage.getItem('MaterialSearch'));
}

export const SetLocalMaterialSearch = (Data) => {
  localStorage.removeItem('MaterialSearch');
  localStorage.setItem('MaterialSearch', JSON.stringify(Data));
}

export const GetLocalInvoice = () => {
  return JSON.parse(localStorage.getItem('InvoiceDetail'));
}
export const GetWarehouseAddress = (ServiceShopId) => {
  return JSON.parse(localStorage.getItem('WarehouseAddress'));

}

export const mergeArrayWithObject = (arr, obj) => { arr && arr.map(t => t.id === obj.id ? obj : t); return arr; };
export const modifyObject = (obj, name, value) => { obj[0][name] = value; return obj; }
export const modifyAnObjectInArray = (arr, id, name, value) => {
  return (arr.map(el => el.id === id ? { ...el, [name]: value } : el));
}

//{ arr && arr.map(t => t.id === id ? Object.assign(t, t.map(el => el.key === name ? value : el)) : t); return arr; }


//{ Object.assign({obj}, {name: value}); console.log(obj);console.log(Object.assign({obj}, {unsaved: true}));}


export const setUserMobile = (MobileNumber) => {
  const ChangeuserStr = sessionStorage.getItem('user');
  const ChangeuserStr1 = JSON.parse(ChangeuserStr);
  ChangeuserStr1.MobileNumber = MobileNumber;

  sessionStorage.removeItem('user');
  sessionStorage.setItem('user', JSON.stringify(ChangeuserStr1));

  localStorage.removeItem('user');
  localStorage.setItem('user', JSON.stringify(ChangeuserStr1));

}
export const setCounting = (value) => {
  const ChangeuserStr = sessionStorage.getItem('user');
  const ChangeuserStr1 = JSON.parse(ChangeuserStr);
  ChangeuserStr1.Counting = value;

  sessionStorage.removeItem('user');
  sessionStorage.setItem('user', JSON.stringify(ChangeuserStr1));

  localStorage.removeItem('user');
  localStorage.setItem('user', JSON.stringify(ChangeuserStr1));

}
// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
}

export const getTokenWs = () => {
  return sessionStorage.getItem('tokenWs') || null;
}

export const getIpAddress = () => {
  return localStorage.getItem('IpAddress') || null;
}
export const getSepetAdet = () => {
  return localStorage.getItem('SepetAdet') || null;
}

export const setIpAddress = (IpAddress) => {  // T_ARTEMIS kontrolü yaparken tokensız gitmesin diye eklendi. Okta sonrası kaldırılacak.
  localStorage.setItem('IpAddress', IpAddress);
}


export const setFiyatDegisiklikAdet = (Adet) => {
  localStorage.removeItem('FiyatDegisiklikAdet');
  localStorage.setItem('FiyatDegisiklikAdet', Adet);
}
export const getFiyatDegisiklikAdet = () => {
  return localStorage.getItem('FiyatDegisiklikAdet') || null;
}

export const setSepetAdet = (Adet) => {
  localStorage.removeItem('SepetAdet');
  localStorage.setItem('SepetAdet', Adet);
}


// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('tokenWs');
  sessionStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('tokenWs');
  localStorage.removeItem('user');
  localStorage.removeItem('lastLogin');
}

// set the token and user from the session storage
export const setUserSession = (token, tokenWs, user) => {
  localStorage.setItem('token', token);
  localStorage.setItem('tokenWs', tokenWs);
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('lastLogin', new Date());
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('tokenWs', tokenWs);
  sessionStorage.setItem('user', JSON.stringify(user));
}

export const setRoleSession = (roles) => {
  localStorage.setItem('Role', JSON.stringify(roles));
  sessionStorage.setItem('Role', JSON.stringify(roles));
}

// set the token and user from the session storage
export const setSessionToken = (token, tokenWs) => {  // T_ARTEMIS kontrolü yaparken tokensız gitmesin diye eklendi. Okta sonrası kaldırılacak.
  localStorage.removeItem('user');
  localStorage.removeItem('lastLogin');
  localStorage.setItem('token', token);
  localStorage.setItem('tokenWs', tokenWs);
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('tokenWs', tokenWs);
}

export const setUserSessionFromLocale = () => {
  const lastLogin = localStorage.getItem('lastLogin');
  const token = localStorage.getItem('token');
  const tokenWs = localStorage.getItem('tokenWs');
  const user = localStorage.getItem('user');
  if (lastLogin === null || token === null || user === null) {
    return false;
  }
  const now = new Date();
  const ll = new Date(lastLogin);
  if (now.getYear() === ll.getYear() && now.getMonth() === ll.getMonth() && now.getDate() === ll.getDate()) {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('tokenWs', tokenWs);
    sessionStorage.setItem('user', user);
    return true;
  }
  return false;
}



// set Language preference
export const setLanguage = lang => {
  sessionStorage.removeItem('language');
  sessionStorage.setItem('language', lang);
}

//get Language preference, if it is not set, return english as default.
export const getLanguage = () => {
  return sessionStorage.getItem('language') || 'tr';
}

export const getKoordinat = (Param) => {
  return sessionStorage.getItem(Param) || null;
}

export const setKoordinat = (Enlem, Boylam,) => {
  sessionStorage.removeItem('Enlem');
  sessionStorage.removeItem('Boylam');
  sessionStorage.setItem('Enlem', Enlem);
  sessionStorage.setItem('Boylam', Boylam);
}

export var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

export const distinct = (a) => {
  a.forEach((chunk, idx) => { a[idx] = JSON.stringify(chunk); });
  a = [...new Set(a)];
  a.forEach((chunk, idx) => { a[idx] = JSON.parse(chunk); });
  return a;
}

// string comparison
export const sc = (str, key) => {
  return (str || '').toString().toLocaleLowerCase('tr-TR').indexOf((key || '').toString().toLocaleLowerCase('tr-TR')) !== -1;
}

// return the plate from the session storage
export const getPlate = () => {
  return localStorage.getItem('plate') || '';
}

// update plate.
export const setPlate = (p) => {
  localStorage.removeItem('plate');
  localStorage.setItem('plate', p);
}

// return the tour open from the local storage
export const getTourOpen = () => {
  return localStorage.getItem('tourOpen') === 'false' ? false : true;
}

// update tour open.
export const setTourOpen = () => {
  localStorage.removeItem('tourOpen');
  localStorage.setItem('tourOpen', 'false');
}

// return the last phone and sms code
export const getLastSms = () => {
  return [localStorage.getItem('SMS_TEL') || '', localStorage.getItem('SMS_KOD') || ''];
}

// update tour open.
export const setLastSms = (tel, phone) => {
  localStorage.removeItem('SMS_TEL');
  localStorage.removeItem('SMS_KOD');
  localStorage.setItem('SMS_TEL', tel);
  localStorage.setItem('SMS_KOD', phone);
}


export const handleLogout = async () => {
  // let user = getUser();

  // if (user === undefined || user === null) {
  //   return;
  // }

  // const dataConfig = {
  //   headers: { 'Content-Type': 'application/json' }
  // };
  // await axios.post("/SetUserLog", { UserName: user.UserId, Status: 0 }, dataConfig) //Logout log

  removeUserSession();
  sessionStorage.clear();
  localStorage.clear();
  // window.location.href = "http://login.arcelik.com/";

};

export const handleLogoutMain = () => {
  handleLogout();
}

