import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography
} from '@mui/material';
import ProductGroupStatusChangeChartView from 'src/components/ProductGroupStatusChangeChart.js';
import UserTypeBasedSubStatusChart from 'src/components/UserTypeBasedSubStatusChart';
import ExchangeReceiptDayDistributionsChart from 'src/components/ExchangeReceiptDayDistributionsChart';
import TodayRefundTotalPriceChart from 'src/components/TodayRefundTotalPriceChart';
import ExchangeReceiptsBasedWarrantyTypesChart from 'src/components/ExchangeReceiptsBasedWarrantyTypesChart';
import OpenExchangeReceiptsWithDaysRangeChart from 'src/components/OpenExchangeReceiptsWithDaysRangeChart';
import LoginLogoutInformationChart from 'src/components/LoginLogoutInformationChart';
import AssignedJobPoolChart from 'src/components/AssignedJobPoolChart';
import AssembledReceiptOfExchangeReceiptsChart from 'src/components/AssembledReceiptOfExchangeReceiptsChart';

function GeneralMonitoring() {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <UserTypeBasedSubStatusChart />
          </Grid>
          <Grid item xs={6} sm={6}>
            <LoginLogoutInformationChart />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <AssignedJobPoolChart />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <ExchangeReceiptDayDistributionsChart />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <TodayRefundTotalPriceChart />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <ExchangeReceiptsBasedWarrantyTypesChart />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <OpenExchangeReceiptsWithDaysRangeChart />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <AssembledReceiptOfExchangeReceiptsChart />
          </Grid>
          <Grid item xs={6} sm={6}>
            <ProductGroupStatusChangeChartView />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default GeneralMonitoring;
