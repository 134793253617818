import React, { useEffect, useState, useRef } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import axios from 'src/utils/axios'; 
import trLocale from 'date-fns/locale/tr';
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Card, CardContent, Grid, TextField, Button, CircularProgress, Table, TableHead, TableBody,TableCell,
    TableRow,TableContainer, DialogContent, DialogActions,Dialog,DialogTitle,IconButton,Stack,Alert,Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getUser } from 'src/utils/Common';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { getMuadilAdminQueue } from 'src/store/slices/equivalentProductsSlice';
import MuadilFile from 'src/components/MuadilFile.js';
import FolderIcon from '@mui/icons-material/Folder';


    const useStyles = makeStyles((theme) => ({
        root: {
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          fontFamily: "Segoe UI",
          width: '100%',
          height: '100%'
        },
        generalCards: {
          zIndex: 1
        },
        backdrop: {
          zIndex: 1500,
          color: '#fff'
        }
      
      }));
      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
      }));

    function EquivalentProductManager(){
        const today = new Date();
        const classes = useStyles();
        const [alert,setAlert] = useState(false);
        const [alertColor,setAlertColor] = useState('#FF0000');
        const [alertMessage,setAlertMessage] = useState('')
        const [loading,setLoading] = useState(false);
        const dispatch = useDispatch();
        const [productList,setProductList] = useState([]);
        const [onayScr,setOnayScr] = useState(false);
        const [redScr,setRedScr] = useState(false);
        const [gecerlilikSuresi,setGecerlilikSuresi] = useState('');
        const [muadilId,setMuadilId] = useState(0);
        const [redNotu,setRedNotu] = useState('');
        const [selectedMuadilId,setSelectedMuadilId] = useState(0);
        const [fileScr,setFileScr] = useState(false);
        const handleOpenFile = () => setFileScr(true);
        const handleCloseFile = () => setFileScr(false);
        const callAlert = (type,messageAlert) => {
            if(type=="err"){
              setAlertMessage(messageAlert);
              setAlertColor("#FF0000");
              setAlert(true);
            }
            if(type=="suc"){
              setAlertMessage(messageAlert);
              setAlertColor("#21E805");
              setAlert(true);
            }
          }
        
        const handleOnay = (id,sure) => {

            setGecerlilikSuresi(sure);
            setMuadilId(id);
            setOnayScr(true);
        }

        const handleRed = (id) => {

            setMuadilId(id);
            setRedScr(true);
        }

        const handleFileUpload = (muadilId) => {
          setSelectedMuadilId(muadilId);
          setFileScr(true);
      }

        const save = (onay) => {
            setLoading(true);
            let data = {
                MuadilId:muadilId,
                GecerlilikSuresi:"",
                YoneticiRedNotu:"",
                YoneticiOnay:onay
              }
            if(onay==1){
                var gecerlilikSuresiDate = new Date(gecerlilikSuresi);
                if (gecerlilikSuresiDate < today) {
                    callAlert("err",'İleri bir tarih seçiniz!');
                    setLoading(false);
                    return;
                }
                data = {
                ...data,
                GecerlilikSuresi:moment(gecerlilikSuresi).format('DD-MM-YYYY'),
                };
            }
            if(onay==0){
                if(redNotu==''){
                    callAlert("err","Red notu boş girilemez.");
                    setLoading(false);
                    return;
                }
                data = {
                ...data,
                YoneticiRedNotu:redNotu,
                };
            }
            axios.post('SetMuadilYoneticiOnay', data, {
                headers: { 'Content-Type': 'application/json' }
              }).then(response => {
                if (response.data.ErrorCode == 0) {
                  setLoading(false);
                  callAlert("suc",response.data.ErrorDescription)
                  setOnayScr(false)
                  setRedScr(false)
                  dispatch(getMuadilAdminQueue()).then(response => {
                    setProductList(response?.payload)
                  });
                } else {
                  setLoading(false);
                  callAlert("err",response.data.ErrorDescription)
                }
              }).catch(err => {
                setLoading(false)
                return
              });
        }

          useEffect(() => {
            dispatch(getMuadilAdminQueue()).then(response => {
                setProductList(response?.payload)
              });
          }, []); 
        
        return(<>
         <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack spacing={8}>
          <Snackbar sx={{ width: '100%',alignItems: 'center', justifyContent: 'center', height: '100vh' }} open={alert} autoHideDuration={6000} onClose={()=>setAlert(false)}>
            <Alert 
              style={{ minWidth: 500 }}
              sx={{
                alignItems: "center",
                verticalAlign: "center",
                backgroundColor: alertColor
  
              }} onClose={()=>setAlert(false)} variant="filled" >
              {alertMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Card className={classes.generalCards}>
        <CardContent>
          <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
                    <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                            <StyledTableCell backgroundColor="gray" align="left" colSpan={4}>
                                Değişen Ürün
                            </StyledTableCell>
                            <TableCell align="left" colSpan={4}>
                                 Verilecek Ürün
                            </TableCell>
                            <StyledTableCell align="left" colSpan={4}>
                                 Kullanıcıdan Gelen
                            </StyledTableCell>
                            <TableCell align="left" colSpan={2}>
                                 Yönetici Onayı
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell className={classes.tableCell} align="left"> Mailyet</StyledTableCell>
                            <StyledTableCell className={classes.tableCell} align="left"> Marka                          </StyledTableCell>
                            <StyledTableCell className={classes.tableCell} align="left"> SKU						  </StyledTableCell>
                            <StyledTableCell className={classes.tableCell} align="left"> Ürün Adı					  </StyledTableCell>
                            <TableCell className={classes.tableCell} align="left"> Mailyet                         </TableCell>
                            <TableCell className={classes.tableCell} align="left"> Marka                          </TableCell>
                            <TableCell className={classes.tableCell} align="left"> SKU						  </TableCell>
                            <TableCell className={classes.tableCell} align="left"> Ürün Adı					  </TableCell>
                            <StyledTableCell className={classes.tableCell} align="left"> Ekleri Gör				  </StyledTableCell>
                            <StyledTableCell className={classes.tableCell} align="left"> Geçerlilik Süresi				  </StyledTableCell>
                            <StyledTableCell className={classes.tableCell} align="left"> Durum				  </StyledTableCell>
                            <StyledTableCell className={classes.tableCell} align="left"> Açıklama				  </StyledTableCell>
                            <TableCell className={classes.tableCell} align="left"> 	  </TableCell>
                            <TableCell className={classes.tableCell} align="left"> 	  </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productList?.map((row)=> (
                        <TableRow>
                            <StyledTableCell className={classes.tableCellBilgi} align="left">{row.MALIYET1}</StyledTableCell>
                            <StyledTableCell className={classes.tableCellBilgi} align="left">{row.MARKA1}</StyledTableCell>
                            <StyledTableCell className={classes.tableCellBilgi} align="left">{row.SKU1}</StyledTableCell>
                            <StyledTableCell className={classes.tableCellBilgi} align="left">{row.URUN1}</StyledTableCell>
                            <TableCell className={classes.tableCellBilgi} align="left">{row.MALIYET2}</TableCell>
                            <TableCell className={classes.tableCellBilgi} align="left">{row.MARKA2}</TableCell>
                            <TableCell className={classes.tableCellBilgi} align="left">{row.SKU2}</TableCell>
                            <TableCell className={classes.tableCellBilgi} align="left">{row.URUN2}</TableCell>
                            <StyledTableCell className={classes.tableCellBilgi} align="left"><IconButton sx={{ color: '#424242', backgroundColor: '#FF9800', '&:disabled': {backgroundColor: '#E0E0E0'} }} 
                                                                                      onClick={()=>handleFileUpload(row.MUADIL_ID)}
                                                                                      ><FolderIcon /></IconButton></StyledTableCell>		
                            <StyledTableCell className={classes.tableCellBilgi} align="left">{row.GECERLILIK_SURESI}</StyledTableCell>
                            <StyledTableCell className={classes.tableCellBilgi} align="left">{row.YONETICI_ONAY_TIPI}</StyledTableCell>
                            <StyledTableCell className={classes.tableCellBilgi} align="left">{row.MUADIL_ACIKLAMA}</StyledTableCell>
                            <TableCell className={classes.tableCellBilgi} align="left"><Button fullWidth variant="contained" 
                                                                                        sx={{ color: '#424242', backgroundColor: '#7DED4D' }}
                                                                                        onClick={()=>handleOnay(row.MUADIL_ID,row.GECERLILIK_SURESI)}>Onayla</Button></TableCell>
                            <TableCell className={classes.tableCellBilgi} align="left"><Button fullWidth variant="contained" 
                                                                                        sx={{ color: '#424242', backgroundColor: '#E10000' }}
                                                                                        onClick={()=>handleRed(row.MUADIL_ID)}>Reddet</Button></TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                </Table>
            </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
        </Card>
        <Dialog 
          open={fileScr}
          onClose={()=>setFileScr(false)}>
            <DialogTitle sx={{ width: '100%', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>Dosyalar</DialogTitle>
            <DialogContent>
            <MuadilFile 
              muadilId={selectedMuadilId}
              handleOpenFile= {handleOpenFile}
              handleCloseFile= {handleCloseFile}
              readOnly = {true}
            />
        </DialogContent>
            <DialogActions>
              <IconButton
                  sx={{color:"#FF9800"}}
                  onClick={() =>  setFileScr(false) }
                  aria-label="close"
                  component="span"
                  variant="contained"
                >
                  <CloseIcon />
                </IconButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={onayScr}
          onClose={()=>setOnayScr(false)}
          maxWidth="l"
        >
           <DialogTitle sx={{ width: '100%', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>Muadil Onayla</DialogTitle>
          <DialogContent>
            <br></br>
           
            <Grid container spacing={2}>
            
              <Grid item xs={12} md={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
                              <DatePicker
                                label="Geçerlilik Son Tarihi"
                                value={gecerlilikSuresi}
                                format={'dd/MM/yyyy'}
                                onChange={(event)=>setGecerlilikSuresi(event)}
                                renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                              />
                            </LocalizationProvider>
              </Grid> 
              
              <Grid item xs={6} md={6}>
              <Button sx={{ color: '#424242', backgroundColor: '#7DED4D' }}
                     onClick={()=>save(1)} fullWidth>Kaydet</Button>
              </Grid>
            </Grid>
            
          </DialogContent>
          <DialogActions>
          <IconButton
              sx={{color:"#FF9800"}}
              onClick={() =>  setOnayScr(false) }
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={redScr}
          onClose={()=>setRedScr(false)}
          maxWidth="l"
        >
           <DialogTitle sx={{ width: '100%', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>Muadil Reddet</DialogTitle>
          <DialogContent>
            <br></br>
           
            <Grid container spacing={2}>
            
              <Grid item xs={12} md={12}>
              <TextField
                      id="outlined-number"
                      label="Red Nedeni"
                      type='text'
                      value={redNotu}
                      onChange={(event)=>setRedNotu(event.target.value)}
                      multiline
                      rows={4} 
                      inputProps={{ maxLength: 450 }}
                      fullWidth
                    />     

              </Grid> 
              
              <Grid item xs={6} md={6}>
              <Button sx={{ color: '#424242', backgroundColor: '#E10000' }}
                     onClick={()=>save(0)} fullWidth>Kaydet</Button>
              </Grid>
            </Grid>
            
          </DialogContent>
          <DialogActions>
          <IconButton
              sx={{color:"#FF9800"}}
              onClick={() =>  setRedScr(false) }
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
        </>);
    
    }

    export default EquivalentProductManager;