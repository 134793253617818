import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};

export const getRetailer = createAsyncThunk('/GetRetailer', async (RetailerCode) => {
  const response = await axios.get(`/GetRetailer/${RetailerCode}`);
  return response.data;
});

export const getCustomersFromPhoneNumber = createAsyncThunk('/GetCustomersFromPhoneNumber', async (Phone1) => {
  const response = await axios.get(`/GetCustomersFromPhoneNumber/${Phone1}`);
  return response.data;
});

export const getCustomerHistory = createAsyncThunk('/GetCustomerHistory', async (GetCustomerHistory) => {
  const response = await axios.get(`/GetCustomerHistory/${GetCustomerHistory}`);
  return response.data;
});

export const getMatchingConsumerInformation = createAsyncThunk('/GetMatchingConsumerInformation', async (FisNo) => {
  const response = await axios.get(`/GetMatchingConsumerInformation/${FisNo}`);
  return response.data;
});

export const getWebPointInvoiceDetail = createAsyncThunk('/GetWebPointInvoiceDetail', async (FisNo) => {
  const response = await axios.get(`/GetWebPointInvoiceDetail/${FisNo}`);
  return response.data;
});

const consumerInformationSlice = createSlice({
  name: 'consumerInformation',
  initialState: {
    loading: '',
    error: '',
    informationChat: []

  },
  reducers: {
  },
  extraReducers: {

  }
});
export const consumerInformationActions = consumerInformationSlice.actions;
export default consumerInformationSlice;