import React from 'react';
import * as XLSX from 'xlsx';
import { BottomNavigationAction, Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

function ExcelExport({ JsonData, ButtonType, DirectDownload = false, fileName = 'Örnek Excel', title }) {
  const handleDownload = () => {
    if (JsonData.length === 0) return;

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(JsonData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');

    // Write the workbook to a file
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  if (JsonData.length === 0) {
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        fullWidth
        startIcon={<CloudDownloadOutlinedIcon />}
      >
        {title}
      </Button>
    );
  }

  return (
    <div onClick={handleDownload}>
      {ButtonType === 1 ? (
        <BottomNavigationAction showLabel label={title} icon={<CloudDownloadIcon />} />
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          fullWidth
          startIcon={<CloudDownloadOutlinedIcon />}
        >
          {title}
        </Button>
      )}
    </div>
  );
}

ExcelExport.propTypes = {
  // Define your prop types here
};

export default ExcelExport;